/* eslint-disable no-underscore-dangle */
/* eslint-disable radix */
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { upperCase } from 'lodash';

import swal from 'sweetalert';
import AgeChart from './Graph/AgeChart';
import AttendanceChart from './Graph/AttendanceChart';
import CardChart from './Graph/CardChart';
import CashChart from './Graph/CashChart';
import GenderChart from './Graph/GenderChart';
import LocationChart from './Graph/LocationChart';
import RevenueChart from './Graph/RevenueChart';
import VisitInsightChart from './Graph/VisitInsightChart';
import {
  extractLablesAndDataByMonth,
  extractLablesAndDataByDay,
} from '../utils/helpers';
import PromotersChart from './Graph/PromotersChart';

const analyticsQuery = gql`
  query($filters: AnalyticsFilter, $groupBy: AnalyticsGroupBy) {
    analytics(filters: $filters, groupBy: $groupBy) {
      gender {
        key
        value
      }
      age {
        key
        value
      }
      revenue {
        label
        total
        card
        cash
      }
      attendance {
        label
        pending
        authorized
        checkedIn
        rejected
        refunded
      }
      location {
        key
        value
      }
    }
  }
`;

const meQuery = gql`
  query meData {
    me {
      profile {
        currency
      }
    }
  }
`;

const Container = styled.div`
  .column {
    > div {
      background-color: #fff;
      box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.06);
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }
  .column {
    > .field {
      box-shadow: none !important;
    }
  }
  .chart-title {
    color: #42474a;
    font-weight: 600;
    padding: 18px 27px;
    border-bottom: 1px solid #e0e0e0;
    .detail {
      margin-left: 20px;
      color: #858585;
      font-weight: 400;
      .tag {
        margin-right: 20px;
      }
    }
    .tag {
      background-color: #0fe133;
      color: #fff;
      margin-left: 10px;
    }
  }
  .chart-wrapper,
  .promoter-chart-wrapper {
    position: relative;
    padding: 18px 27px;
    flex: 1;
    canvas {
      height: 100% !important;
      width: 100% !important;
    }
  }
  .chart-wrapper {
    max-height: 300px;
  }
`;

const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,
};

const currencyFormatter = require('currency-formatter');

const ViewCharts = ({ match }) => {
  const [overAllAttendanceState, setOverAllAttendanceState] = useState({
    enter: 0,
    deny: 0,
  });
  const handleAttendanceState = useCallback(values => {
    setOverAllAttendanceState(values);
  }, []);

  const { eventId } = match.params || null;
  const variables = eventId
    ? {
        groupBy: 'perDay',
        filters: { eventId },
      }
    : {
        groupBy: 'perMonth',
      };

  const analyticsData = useQuery(analyticsQuery, {
    variables,
    fetchPolicy: 'cache-and-network',
  });
  const { data } = useQuery(meQuery, {
    variables,
    fetchPolicy: 'no-cache',
  });
  const meData = data && data.me ? data.me : {};
  const currencySymbol = currencyFormatter.findCurrency(
    upperCase(
      meData.profile && meData.profile.currency
        ? meData.profile.currency
        : 'gbp',
    ),
  ).symbol;
  const { age, gender, revenue, attendance, location } =
    analyticsData.data && analyticsData.data.analytics
      ? analyticsData.data.analytics
      : {};
  const overAllRevenue = eventId
    ? extractLablesAndDataByDay(revenue)
    : extractLablesAndDataByMonth(revenue);
  const overAllRevenueState =
    overAllRevenue.length === 0
      ? { cash: 0, card: 0, all: 0 }
      : { ...overAllRevenue.total };
  if (analyticsData.error) {
    swal('Something went wrong!', `${analyticsData.error.message}`, 'error');
  }
  return (
    <Container>
      <div className="columns is-multiline">
        <div className="column is-5-widescreen is-12-desktop is-12-tablet is-12-mobile">
          <div>
            <h2 className="chart-title">
              Overall Revenue
              <span className="tag">
                {`${currencySymbol} ${overAllRevenueState.all}`}
              </span>
            </h2>
            <div className="chart-wrapper">
              <RevenueChart
                data={overAllRevenue}
                loading={analyticsData.loading}
                // error={analyticsData.error}
                options={chartOptions}
              />
            </div>
          </div>
        </div>
        <div className="column is-3-widescreen is-6-desktop is-6-tablet is-12-mobile">
          <div>
            <h2 className="chart-title">Gender</h2>
            <div className="chart-wrapper">
              <GenderChart
                data={gender}
                loading={analyticsData.loading}
                // error={analyticsData.error}
                options={chartOptions}
              />
            </div>
          </div>
        </div>
        <div className="column is-4-widescreen is-6-desktop is-6-tablet is-12-mobile">
          <div>
            <h2 className="chart-title">Age</h2>
            <div className="chart-wrapper">
              <AgeChart
                data={age}
                // error={analyticsData.error}
                loading={analyticsData.loading}
                options={chartOptions}
              />
            </div>
          </div>
        </div>
        <div className="column is-12-desktop is-12-tablet is-12-mobile">
          <div>
            <h2 className="chart-title">
              Overall Attendance
              <span className="detail">
                Accepted
                <span className="tag">{overAllAttendanceState.enter}</span>
                Denied
                <span className="tag">{overAllAttendanceState.deny}</span>
              </span>
            </h2>
            <div className="chart-wrapper">
              <AttendanceChart
                data={attendance}
                // error={analyticsData.error}
                loading={analyticsData.loading}
                options={chartOptions}
                handleAttendanceState={handleAttendanceState}
                isSingleEvent={!!eventId}
              />
            </div>
          </div>
        </div>
        <div className="column is-half">
          <div>
            <h2 className="chart-title">
              Overall Cash Sales
              <span className="tag">
                {`${currencySymbol} ${overAllRevenueState.cash}`}
              </span>
            </h2>
            <div className="chart-wrapper">
              <CashChart
                data={overAllRevenue}
                loading={analyticsData.loading}
                // error={analyticsData.error}
                options={chartOptions}
              />
            </div>
          </div>
        </div>
        <div className="column is-half">
          <div>
            <h2 className="chart-title">
              Overall Card Sales
              <span className="tag">
                {`${currencySymbol} ${overAllRevenueState.card}`}
              </span>
            </h2>
            <div className="chart-wrapper">
              <CardChart
                data={overAllRevenue}
                loading={analyticsData.loading}
                // error={analyticsData.error}
                options={chartOptions}
              />
            </div>
          </div>
        </div>
        <div className="column is-half-widescreen is-12-desktop is-12-tablet is-12-mobile">
          <div>
            <h2 className="chart-title">Visit Insights</h2>
            <div className="chart-wrapper">
              <VisitInsightChart
                data={attendance}
                // error={analyticsData.error}
                loading={analyticsData.loading}
                options={chartOptions}
                isSingleEvent={!!eventId}
              />
            </div>
          </div>
        </div>
        <div className="column is-half-widescreen is-12-desktop is-12-tablet is-12-mobile">
          <div>
            <h2 className="chart-title">Customers Locations</h2>
            <div className="chart-wrapper">
              <LocationChart
                data={location}
                // error={analyticsData.error}
                loading={analyticsData.loading}
                options={chartOptions}
              />
            </div>
          </div>
        </div>
        <div className="column is-12">
          <div>
            <h2 className="chart-title">Promoters Analytics</h2>
            <div className="promoter-chart-wrapper">
              {eventId && <PromotersChart eventId={eventId} />}
              {!eventId && <PromotersChart />}
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ViewCharts;
