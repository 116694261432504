import React, { useState, useEffect } from 'react';
import { head, uniqBy } from 'lodash';
import { Bar } from 'react-chartjs-2';
import styled from 'styled-components';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Select, Loading } from '../elements';
import {
  generateLastTwelveMonths,
  convertNumberToMonth,
  extractLablesAndDataByMonth,
  generateHours,
  extractLablesAndDataByDay,
} from '../../utils/helpers';

const InputWrapper = styled.div`
  .field {
    flex: 1;
  }
`;
const eventsPromotersQuery = gql`
  query eventsPromoter {
    brandEvents {
      promoters {
        user {
          id
          profile {
            firstName
            lastName
          }
        }
      }
    }
  }
`;

const singleEventQuery = gql`
  query singleEvent($id: ID!) {
    singleEvent(id: $id) {
      id
      promoters {
        user {
          id
          username
          rating
          profile {
            firstName
            lastName
            image
          }
        }
      }
    }
  }
`;

const analyticsQuery = gql`
  query analytics($filters: AnalyticsFilter, $groupBy: AnalyticsGroupBy) {
    analytics(filters: $filters, groupBy: $groupBy) {
      revenue {
        label
        total
        card
        cash
      }
      attendance {
        label
        pending
        authorized
        checkedIn
        rejected
        refunded
      }
    }
  }
`;

const barOptions = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
};

const PromotersChart = ({ eventId }) => {
  const [promotersList, setPromoterList] = useState([]);
  const [promoterId, setPromoterId] = useState('');
  const [overAllAttendance, setOverAllAttendance] = useState({});

  const variables = eventId
    ? {
        groupBy: 'perDay',
        filters: { eventId, promoterId },
      }
    : {
        groupBy: 'perMonth',
        filters: { promoterId },
      };

  // const [promoter, setPromoter] = useState({});
  const [getAnalyticsData, { data: data2, loading, error }] = useLazyQuery(
    analyticsQuery,
    {
      variables,
      fetchPolicy: 'cache-and-network',
    },
  );
  const { data } = useQuery(eventsPromotersQuery, {
    fetchPolicy: 'cache-and-network',
  });
  const { data: singleEventData } = useQuery(singleEventQuery, {
    fetchPolicy: 'no-cache',
    variables: { id: eventId },
  });
  const analytics = data2 && data2.analytics ? data2.analytics : {};
  const singleEvent =
    singleEventData && singleEventData.singleEvent
      ? singleEventData.singleEvent
      : null;
  // console.log('singleEvent', singleEvent);
  const { attendance } = analytics;
  const { revenue } = analytics;

  useEffect(() => {
    if (promoterId) {
      getAnalyticsData({
        variables,
      });
    }
  }, [promoterId]);
  // console.log('singleEvent', singleEvent);
  useEffect(() => {
    if (eventId && singleEvent) {
      const allPromoters = [];
      singleEvent.promoters.forEach(item => {
        if (item.user) {
          allPromoters.push({
            value: item.user.id,
            label: `${item.user.profile.firstName} ${
              item.user.profile.lastName ? item.user.profile.lastName : ''
            }`,
          });
        }
      });
      const promoters = uniqBy(allPromoters, 'value');
      setPromoterList(promoters);
      if (promoters.length > 0) {
        setPromoterId(head(promoters).value);
      }
    } else if (!eventId && data && data.brandEvents) {
      const allPromoters = [];
      data.brandEvents.forEach(item => {
        item.promoters.forEach(item2 => {
          if (item2.user) {
            allPromoters.push({
              value: item2.user.id,
              label: `${item2.user.profile.firstName} ${
                item2.user.profile.lastName ? item2.user.profile.lastName : ''
              }`,
            });
          }
        });
      });
      const promoters = uniqBy(allPromoters, 'value');
      setPromoterList(promoters);
      if (promoters.length > 0) {
        setPromoterId(head(promoters).value);
      }
    }
  }, [data, singleEventData, eventId]);

  useEffect(() => {
    setOverAllAttendance({});
    if (attendance && attendance.length !== 0) {
      const param = {
        checkedIn: 0,
        rejected: 0,
        pending: 0,
        authorized: 0,
        refunded: 0,
      };
      // const overAllRevenue = extractLablesAndDataByMonth(revenue);
      const overAllRevenue = eventId
        ? extractLablesAndDataByDay(revenue)
        : extractLablesAndDataByMonth(revenue);
      const {
        numb: { all },
      } = overAllRevenue;
      // const units = generateLastTwelveMonths(param);
      const units = singleEvent
        ? generateHours(param)
        : generateLastTwelveMonths(param);

      const lbl = [];
      const checkedIn = [];
      const rejected = [];
      const listed = [];
      const totalRevenue = all.reverse();

      attendance.forEach(item => {
        if (Object.keys(units).includes(item.label)) {
          units[item.label] = {
            checkedIn: item.checkedIn,
            rejected: item.rejected,
            pending: item.pending,
            authorized: item.authorized,
            refunded: item.refunded,
          };
        }
      });

      if (singleEvent) {
        Object.keys(units)
          .sort((a, b) => b - a)
          .forEach(e => {
            lbl.push(`${e}:00`);
            checkedIn.push(units[e].checkedIn);
            rejected.push(units[e].rejected);
            listed.push(
              units[e].checkedIn ||
                units[e].rejected ||
                units[e].authorized ||
                units[e].refunded,
            );
          });
      } else {
        Object.keys(units).forEach(e => {
          const listedItems =
            units[e].checkedIn +
            units[e].rejected +
            units[e].authorized +
            units[e].refunded;
          lbl.push(convertNumberToMonth(e));
          checkedIn.push(units[e].checkedIn);
          rejected.push(units[e].rejected);
          listed.push(listedItems);
        });
      }
      setOverAllAttendance({
        labels: lbl.reverse(),
        datasets: [
          {
            label: 'Listed',
            backgroundColor: '#A5227E',
            data: listed.reverse(),
          },
          {
            label: 'Accepted',
            backgroundColor: '#61D836',
            data: checkedIn.reverse(),
          },
          {
            label: 'Declined',
            backgroundColor: '#A2A4B9',
            data: rejected.reverse(),
          },
          {
            label: 'Revenue',
            data: totalRevenue,
            backgroundColor: '#00A3FF',
          },
        ],
      });
    }
  }, [attendance, data2]);

  return (
    <div>
      <div className="columns">
        <InputWrapper className="column is-5 is-align-items-center is-flex">
          <span className="mr-3">Grouped Event</span>
          <Select
            isMedium
            placeholder="---Select---"
            options={promotersList}
            value={promoterId}
            onChange={e => {
              setPromoterId(e.target.value);
            }}
          />
        </InputWrapper>
      </div>
      {loading && !attendance && !revenue && <Loading />}
      {error && !attendance && !revenue && `Error! ${error.message}`}
      {(!attendance && !revenue) ||
      (attendance.length === 0 && revenue.length === 0) ? (
        <p className="has-text-centered has-text-weight-semibold is-size-4 my-6 py-6">
          No data available
        </p>
      ) : (
        <Bar
          data={overAllAttendance}
          options={barOptions}
          backgroundColor="#fff"
        />
      )}
    </div>
  );
};

export default PromotersChart;
