import React from 'react';
import styled from 'styled-components';

import config from '../../utils/config';

const Wrapper = styled.div`
  .textarea {
    color: ${config.placeholderColor};
    padding-left: 1rem;
    padding-right: 1rem;
    :focus,
    :active {
      border-color: #b5b5b5;
      box-shadow: none;
    }
  }
`;

const TextArea = styled.textarea`
  border-radius: ${props => props.isMedium && '10px'} !important;
`;

const TextBox = ({
  label,
  hasBackgroudColor,
  errors,
  value,
  isMedium,
  ...inputProps
}) => (
  <>
    <Wrapper className="field" hasBackgroudColor={hasBackgroudColor}>
      <label className="label has-text-weight-normal is-capitalized mb-3">
        {label}
      </label>
      <div className="control">
        <TextArea
          isMedium={isMedium}
          className="textarea is-rounded"
          value={value || ''}
          {...inputProps}
        />
      </div>
    </Wrapper>
    {errors && <p className="help is-danger">{errors}</p>}
  </>
);

export default TextBox;
