import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { moment } from '../../utils/helpers';
import { ContentWithIcon, Button, Tag } from '../elements';

const Container = styled.div`
  border-radius: 0.5rem;
  height: 100%;
  border: 1px solid #e6e6e6;
  box-shadow: none;
  display: flex;
  flex-flow: column wrap;
  :hover {
    box-shadow: 0 0.5em 3em 0.5em rgba(0, 0, 0, 0.1),
      0 0px 0 1px rgba(0, 0, 0, 0.02);
  }
  .card-image {
    max-height: 300px;
    overflow: hidden;
    width: 100%;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    img {
      object-fit: cover;
    }
  }
  .title {
    ${'' /* font-family: ${props => props.theme.subHeaderFontFamily}; */}
    font-size: 20px;
    line-height: 30px;
    padding: 10px 0;
    a {
      color: #4a4a4a;
    }
  }

  .card-content {
    padding: 30px 15px 13px;
    flex: 1;
  }
  .footer-content {
    padding: 14px 15px 22px;
    position: relative;
    bottom: 0;
    height: min-content;
    a {
      margin-left: 10px;
      &:first-of-type {
        margin-left: auto;
      }
    }
  }
  .detail {
    ${'' /* font-family: ${props => props.theme.subHeaderFontFamily}; */}
    font-size: 14px;
    line-height: 24px;
    color: ${props => props.theme.subHeaderColor};
  }
  .date-time {
    display: flex;
  }
`;

const TimeLabel = styled.div`
  font-size: 12px;
  display: flex;
  align-items: center;
`;

const Flex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ImageWrapper = styled(Link)`
  pointer-events: ${props => (props.isDisabled ? 'none' : 'auto')};
`;

const EventItem = ({ event, isPast, isPartnerEvent, handleRemove }) => {
  const eventStartDate = moment(event.eventStartTime).format('D MMM YYYY');
  const eventEndDate = moment(event.eventEndTime).format('D MMM YYYY');
  // console.log(eventStartDate, eventEndDate.replace, event);
  const disableEdit = event.status === 'started' || event.status === 'close';
  return (
    <Container className="card">
      <div className="card-image">
        <ImageWrapper isDisabled={disableEdit} to={`/update-event/${event.id}`}>
          <figure className="image is-5by4 is-hidden-mobile">
            {event.featuredImage ? (
              <img
                src={event.featuredImage || '/images/landing-background.jpg'}
                alt={event.name}
              />
            ) : (
              <img src="/images/event-placeholder.png" alt="event dummy" />
            )}
          </figure>
          <figure className="image is-5by3 is-hidden-tablet">
            {event.featuredImage ? (
              <img
                src={event.featuredImage || '/images/landing-background.jpg'}
                alt={event.name}
              />
            ) : (
              <img src="/images/event-placeholder.png" alt="event dummy" />
            )}
          </figure>
        </ImageWrapper>
      </div>
      <div className="card-content">
        <div className="media-content is-capitalized">
          <TimeLabel>
            <i className="far fa-clock mr-1" />
            {eventStartDate === eventEndDate ? (
              <span className="">
                {moment(event.eventStartTime).format('D MMM YYYY')} from{' '}
                {moment(event.eventStartTime).format('HH:mm')} till{' '}
                {moment(event.eventEndTime).format('HH:mm')}
              </span>
            ) : (
              <span>
                {moment(event.eventStartTime).format('D MMM YYYY HH:mm')} to{' '}
                {moment(event.eventEndTime).format('D MMM YYYY HH:mm')}
              </span>
            )}
          </TimeLabel>
          <p className="title">
            <Link to={`/update-event/${event.id}`}>{event.name}</Link> &nbsp;
            <Tag
              autoWidth
              className={event.status}
              size="is-small"
              label={event.status === 'open' ? 'Published' : event.status}
            />
          </p>
        </div>
        {event.status !== '' ? (
          <div className="detail">
            {event.venueAddress && (
              <ContentWithIcon
                data={`${
                  event.venueAddress.name ? event.venueAddress.name : ''
                }, ${
                  event.venueAddress.addressLine1
                    ? event.venueAddress.addressLine1
                    : ''
                }, ${event.venueAddress.city ? event.venueAddress.city : ''}, ${
                  event.venueAddress.postcode ? event.venueAddress.postcode : ''
                }, ${
                  event.venueAddress.state ? event.venueAddress.state : ''
                }, ${
                  event.venueAddress.country ? event.venueAddress.country : ''
                }`}
                icon="null"
                className="location"
              />
            )}
          </div>
        ) : (
          <div className="detail draft-text">
            Please Complete All details of This Events
          </div>
        )}
      </div>
      <footer className="content footer-content">
        <Flex>
          {isPast === false ? (
            !event.isArchived && (
              <>
                {/* <ContentWithIcon
                data={moment(event.eventStartTime).format('hh:mm A')}
                icon="far fa-clock"
                className="time"
              /> */}

                {!isPartnerEvent && (
                  <>
                    <Link to={`/update-event/${event.id}`}>
                      <Button
                        disabled={disableEdit}
                        title="Edit"
                        className="is-small button half-rounded"
                      />
                    </Link>
                    <Button
                      disabled={
                        event.status === 'open' || event.status === 'started'
                      }
                      title="Delete"
                      className="is-small button half-rounded ml-3"
                      onClick={() => handleRemove(event.id)}
                    />
                  </>
                )}
                <Link to={`/guest-list/${event.id}`}>
                  <Button
                    // disabled={
                    //   event.status === 'draft' ||
                    //   event.status === 'close' ||
                    //   event.status === 'soldOut'
                    // }
                    title="Guestlist"
                    className="is-small button is-primary half-rounded"
                  />
                </Link>
              </>
            )
          ) : (
            <>
              <Link to={`/guest-list/${event.id}`}>
                <Button
                  title="Guestlist"
                  className="is-small button is-primary half-rounded"
                />
              </Link>
              <Link to={`/payment-analytics/${event.id}`}>
                <Button
                  title="View Analytics"
                  className="is-small button is-primary half-rounded"
                />
              </Link>
            </>
          )}
        </Flex>
      </footer>
    </Container>
  );
};

export default EventItem;
