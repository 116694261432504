import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { concat, capitalize } from 'lodash';
import swal from 'sweetalert';
import { useMutation } from 'react-apollo';
import gql from 'graphql-tag';

import { Subtitle, Button } from '../elements';
import PartnersList from './PartnersList';

const Container = styled.div``;

const updateEventPartnerMutation = gql`
  mutation updateEventPartner(
    $eventId: ID!
    $userId: ID!
    $commission: Float
    $type: UpdateEventPartnerType!
    $method: UpdateEventPartnerMethod!
  ) {
    updateEventPartner(
      eventId: $eventId
      userId: $userId
      type: $type
      method: $method
      commission: $commission
    ) {
      id
    }
  }
`;

const Partners = ({
  event,
  refetch,
  updatePartnersLoading,
  handleBackPress,
  handleNextPress,
}) => {
  const [executeMutation, res] = useMutation(updateEventPartnerMutation);

  const [promoters, setPromoters] = useState([]);
  const [influencers, setInfluencers] = useState([]);
  const [partners, setPartners] = useState([]);
  const [doorPickers, setDoorPickers] = useState([]);
  const [djs, setDjs] = useState([]);

  const partnersArray = concat(
    promoters,
    influencers,
    partners,
    doorPickers,
    djs,
  );

  const allPartners = partnersArray.map(item => ({
    id: item.user,
  }));

  useEffect(() => {
    setDoorPickers(
      event.doorPickers &&
        event.doorPickers.map(item => ({
          user: item.user.id,
          commission: item.commission,
        })),
    );
  }, [event.doorPickers]);

  useEffect(() => {
    setDjs(
      event.djs &&
        event.djs.map(item => ({
          user: item.user.id,
          commission: item.commission,
        })),
    );
  }, [event.djs]);

  useEffect(() => {
    setPromoters(
      event.promoters &&
        event.promoters.map(item => ({
          user: item.user.id,
          commission: item.commission,
        })),
    );
  }, [event.promoters]);

  useEffect(() => {
    setInfluencers(
      event.influencers &&
        event.influencers.map(item => ({
          user: item.user.id,
          commission: item.commission,
        })),
    );
  }, [event.influencers]);

  useEffect(() => {
    setPartners(
      event.partners &&
        event.partners.map(item => ({
          user: item.user.id,
          commission: item.commission,
        })),
    );
  }, [event.partners]);

  useEffect(() => {
    if (res.error) {
      swal('Oops!', res.error.message, 'error');
    }
  }, [res.error]);

  const onChange = async formData => {
    const resp = await executeMutation({
      variables: { eventId: event.id, ...formData },
    });
    if (resp) {
      if (formData.method === 'remove') {
        swal({
          title: '',
          text: `${capitalize(
            formData.type.slice(0, -1),
          )} removed successfully!`,
          icon: 'success',
        });
      } else {
        await swal({
          title: '',
          text: `${capitalize(formData.type.slice(0, -1))} added successfully!`,
          icon: 'success',
        });
      }
      refetch();
    }
  };

  const handleRemovePromoter = userId => {
    swal({
      title: 'Are you sure?',
      text: 'You want to remove this promoter!',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then(async willDelete => {
      if (willDelete) {
        await onChange({
          userId,
          type: 'promoters',
          method: 'remove',
        });
      }
    });
  };

  const handleRemoveDj = userId => {
    swal({
      title: 'Are you sure?',
      text: 'You want to remove this DJ!',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then(async willDelete => {
      if (willDelete) {
        await onChange({
          userId,
          type: 'djs',
          method: 'remove',
        });
      }
    });
  };

  // const handleRemoveInfluencers = userId => {
  //   swal({
  //     title: 'Are you sure?',
  //     text: 'You want to remove these influencer!',
  //     icon: 'warning',
  //     buttons: true,
  //     dangerMode: true,
  //   }).then(async willDelete => {
  //     if (willDelete) {
  //       await onChange({
  //         userId,
  //         type: 'influencer',
  //         method: 'remove',
  //       });
  //     }
  //   });
  // };

  const handleRemovePartner = userId => {
    swal({
      title: 'Are you sure?',
      text: 'You want to remove this partner!',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then(async willDelete => {
      if (willDelete) {
        await onChange({
          userId,
          type: 'partners',
          method: 'remove',
        });
      }
    });
  };

  return (
    <Container className="mt-5">
      <Subtitle>Please select your promoters / partners (Optional)</Subtitle>
      {/* <Subtitle>
        Please select your influencer/promoters/ partners (Optional)
      </Subtitle> */}
      <PartnersList
        refetch={refetch}
        title="Promoter" // DO NOT CHANGE TEXT
        modelTitle="Add Promoters"
        eventId={event.id}
        currency={event.currency}
        partners={event.promoters}
        allPartners={allPartners}
        loading={updatePartnersLoading}
        handleAddPartner={async formData => {
          await onChange({
            userId: formData.user,
            commission: formData.commission,
            type: 'promoters',
            method: 'add',
          });
        }}
        handleRemove={handleRemovePromoter}
      />

      <PartnersList
        refetch={refetch}
        title="Dj" // DO NOT CHANGE TEXT
        modelTitle="Add Djs"
        eventId={event.id}
        currency={event.currency}
        partners={event.djs}
        allPartners={allPartners}
        loading={updatePartnersLoading}
        handleAddPartner={async formData => {
          await onChange({
            userId: formData.user,
            commission: formData.commission,
            type: 'djs',
            method: 'add',
          });
        }}
        handleRemove={handleRemoveDj}
      />

      {/*
      <PartnersList
        refetch={refetch}
        title="Influencer" // DO NOT CHANGE TEXT
        modelTitle="Add Influencer"
        eventId={event.id}
        currency={event.currency}
        partners={event.influencers}
        allPartners={allPartners}
        loading={updatePartnersLoading}
        handleAddPartner={async formData => {
            await onChange({
            userId: formData.user,
            commission: formData.commission,
            type: 'influencers',
            method: 'add',
          });
        }}
        handleRemove={handleRemoveInfluencers}
      /> */}

      <PartnersList
        refetch={refetch}
        title="Partner" // DO NOT CHANGE TEXT
        modelTitle="Add Partners"
        eventId={event.id}
        currency={event.currency}
        partners={event.partners}
        allPartners={allPartners}
        loading={updatePartnersLoading}
        handleAddPartner={async formData => {
          await onChange({
            userId: formData.user,
            commission: formData.commission,
            type: 'partners',
            method: 'add',
          });
        }}
        handleRemove={handleRemovePartner}
      />

      <p className="buttons is-justify-content-center mt-6">
        <Button
          onClick={handleBackPress}
          title="Back"
          className="is-rounded button is-medium  px-6"
          type="button"
        />
        <Button
          onClick={handleNextPress}
          title="Next"
          className="is-rounded button is-medium is-primary px-6"
          type="button"
        />
      </p>
    </Container>
  );
};

export default Partners;
