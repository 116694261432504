import React, { useEffect } from 'react';
import { useMutation } from 'react-apollo';
import gql from 'graphql-tag';
import swal from 'sweetalert';

import styled from 'styled-components';
import ForgotPasswordForm from '../components/form/ForgotPasswordForm';
import { Heading, HeadingWrapper } from '../components/elements';

const Container = styled.div`

@media only screen and (max-width: 768px) {
    .button.is-large {
      font-size: 1.25rem; !important;
      margin-top: 2rem !important
    }
    .logo-image-mobile {
      width: 170px !important
    }
  }
`;

const forgotPasswordMutation = gql`
  mutation forgotPassword($input: ForgotPasswordInput!) {
    forgotPassword(input: $input) {
      success
    }
  }
`;

const ForgotPassword = () => {
  const [executeMutation, res] = useMutation(forgotPasswordMutation);
  useEffect(() => {
    if (res.error) swal('Something went wrong!', res.error.message, 'warning');
  }, [res.error]);

  return (
    <Container className="section has-background-light min-h-full">
      <div className="container py-6">
        <div className="columns is-centered my-6">
          <div className="column is-6 px-5">
            <div className="has-text-centered mb-6">
              <img
                src="/images/logo.png"
                alt="logo"
                width="250"
                className="logo-image-mobile"
              />
            </div>

            <HeadingWrapper>
              <Heading title="Forgot Password?" />
            </HeadingWrapper>
            <ForgotPasswordForm
              onSubmit={async data => {
                await executeMutation({ variables: { input: data } });
                swal(
                  '',
                  'An email has been sent, please check your Inbox',
                  'success',
                );
              }}
            />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ForgotPassword;
