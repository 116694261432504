import React from 'react';
import styled from 'styled-components';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import HeaderNav from '../events/Header';

const Container = styled.nav`
  min-height: 4.5rem;
  box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.05);
  margin-left: 20%;
  @media screen and (max-width: 1023px), print {
    display: flex;
    align-items: stretch;
  }
`;

const meQuery = gql`
  query meHeader {
    me {
      id
      subscription {
        status
      }
    }
  }
`;

const Header = () => (
  <Container className="navbar" role="navigation" aria-label="main navigation">
    <Query query={meQuery}>
      {({ data }) => {
        return <HeaderNav data={data} />;
      }}
    </Query>
  </Container>
);

export default Header;
