/* eslint-disable no-unused-vars */
/* eslint-disable no-lone-blocks */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import swal from 'sweetalert';

import SubscribersCountRow from '../components/global/SubscribersCountRow';
import { Loading } from '../components/elements';
import TableWithPagination from '../components/global/TableWithPagination';
import TableRow from '../components/followers/TableRow';

const Layout = styled.div`
  padding: calc(2.25rem + 0.75rem);
  @media only screen and (max-width: 768px) {
    padding: 2.25rem 1.5rem;
  }
`;

const Container = styled.div`
  table {
    border-collapse: inherit;
    border-spacing: 0 1em;
  }
  padding: 3rem 5%;
  background: #ffffff;
  border: 2px solid #f0f0f0;
  border-radius: 10px;
  table th {
    border: none;
    font-weight: 700 !important;
    text-align: left !important;
    :first-child {
      padding-left: 6%;
    }
  }
  .all-attend {
    margin-left: calc(2vw - 0.75rem);
    font-weight: 800;
    color: #858585;
  }
  .see-more {
    span {
      cursor: pointer;
      ${'' /* font-family: ${props => props.theme.subHeaderFontFamily}; */}
      position: relative;
      color: ${props => props.theme.buttonColor};
      font-weight: 700;
      &:after {
        content: '>';
        position: absolute;
        top: 0;
        bottom: 0;
        line-height: 12px;
        font-size: 12px;
        margin: auto 8px;
        padding: 0 3px;
        margin-left: 8px;
        background-color: ${props => props.theme.buttonColor};
        color: #fff;
        border-radius: 50%;
        width: 14px;
        height: 14px;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    padding: 0;
    td .checkbox {
      margin-left: 5px !important;
    }
  }
`;

const meQuery = gql`
  query meFollowers {
    me {
      id
      follower {
        id
        username
        email
        verificationStatus
        covidPassport
        profile {
          firstName
          lastName
          image
          sex
          location
        }
        status
      }
      blockedUsers {
        id
        email
        username
        verificationStatus
        covidPassport
        profile {
          firstName
          lastName
          image
          sex
          location
        }
        status
      }
    }
  }
`;

const brandStatsQuery = gql`
  query brandStats {
    brandStats {
      totalListedCount
      totalAttendanceCount
      blockedCount
    }
  }
`;

const tableHeadData = [
  {
    Header: 'Account Status',
    accessor: 'status',
  },
  {
    Header: 'User Name',
    accessor: 'profile.firstName',
  },
  {
    Header: 'Verified ID',
    accessor: 'verificationStatus',
  },
  {
    Header: 'Covid ID',
    accessor: 'covidPassport',
  },
  {
    Header: 'Action',
  },
];

const Followers = () => {
  const { data, error, loading, refetch } = useQuery(meQuery, {
    fetchPolicy: 'no-cache',
  });
  const {
    data: data2,
    error: error2,
    loading: loading2,
    refetch: refetch2,
  } = useQuery(brandStatsQuery, {
    fetchPolicy: 'no-cache',
  });
  const [meData, setMeData] = useState(null);
  // const me = data && data.me ? data.me : {};
  const brandStats = data2 && data2.brandStats ? data2.brandStats : {};
  const followers = meData ? meData.follower : [];
  const blockedUsers = meData ? meData.blockedUsers : [];

  const handleRefetch = () => {
    refetch();
    refetch2();
  };

  useEffect(() => {
    if (data && data.me) {
      setMeData(data.me);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      swal('Oops!', error.message, 'error');
    }
    if (error2) {
      swal('Oops!', error2.message, 'error');
    }
  }, [error, error2]);

  const columns = React.useMemo(() => tableHeadData, []);
  return (
    <Layout className="section">
      {(loading || loading2) && !meData ? (
        <Loading />
      ) : (
        <>
          <SubscribersCountRow
            followersCount={followers.length}
            listCount={brandStats.totalListedCount}
            attendanceCount={brandStats.totalAttendanceCount}
            restrictionsCount={brandStats.blockedCount || blockedUsers?.length}
          />

          <Container className="columns is-multiline">
            <div className="column is-12">
              <TableWithPagination
                marginRight="2%"
                columns={columns}
                data={followers}
                emptyStateTitle="No followers found"
                loading={loading}
                totalCount={followers?.length || 0}
                rowItem={row => (
                  <TableRow
                    key={row.id}
                    follower={row.original}
                    refetch={handleRefetch}
                  />
                )}
              />
            </div>
            <div className="column is-full all-attend">
              {followers.length} Users ({brandStats.totalAttendanceCount} Users
              attended){' '}
            </div>
          </Container>
          <Container className="columns is-multiline mt-6">
            <div className="column is-12">
              <TableWithPagination
                marginRight="2%"
                removeFilter
                columns={columns}
                data={blockedUsers}
                emptyStateTitle="No block users found"
                loading={loading}
                totalCount={blockedUsers?.length || 0}
                rowItem={row => (
                  <TableRow
                    key={row.id}
                    blockedUser
                    follower={row.original}
                    refetch={handleRefetch}
                  />
                )}
              />
            </div>

            <div className="column is-full all-attend">
              {blockedUsers.length} Users Blocked
            </div>
          </Container>
        </>
      )}{' '}
    </Layout>
  );
};

export default Followers;
