import React from 'react';
import styled from 'styled-components';
import { Route, NavLink, Switch } from 'react-router-dom';

import ListView from '../components/ListView';
import ViewCharts from '../components/ViewCharts';
import { Button } from '../components/elements';

const Container = styled.div`
  padding: 2.25rem;
  .submenu {
    display: flex;
    margin-bottom: 2.25rem;
    > div {
      margin-right: 1.5rem;
    }
    .is-active {
      button {
        background: ${props => props.theme.bgLinerGradient};
        ${'' /* background-color: ${props => props.theme.buttonColor}; */}
        color: #fff;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    padding: 2.25rem 0.75rem;
  }
`;

const Analytics = ({ match }) => {
  const { eventId } = match.params;
  const isSingleEvent = eventId && eventId !== 'list-view';

  return (
    <Container className="section">
      <div className="submenu">
        <div>
          <NavLink
            exact
            activeClassName="is-active"
            to={
              isSingleEvent
                ? `/payment-analytics/${eventId}`
                : '/payment-analytics'
            }
          >
            <Button
              title="Chart View"
              className="button is-small half-rounded"
            />
          </NavLink>
        </div>
        <div>
          <NavLink
            exact
            activeClassName="is-active"
            to={
              isSingleEvent
                ? `/payment-analytics/list-view/${eventId}`
                : '/payment-analytics/list-view'
            }
          >
            <Button
              title="List View"
              className="button is-small half-rounded"
            />
          </NavLink>
        </div>
        {/* <div className="is-hidden">
          <NavLink exact activeClassName="is-active" to="/#">
            <Button title="YTD" className="button is-small half-rounded" />
          </NavLink>
        </div>
        <div className="is-hidden">
          <NavLink exact activeClassName="is-active" to="/#">
            <Button title="Monthly" className="button is-small half-rounded" />
          </NavLink>
        </div> */}
      </div>
      <Switch>
        <Route
          exact
          path="/payment-analytics"
          render={props => <ViewCharts {...props} />}
        />
        <Route
          exact
          path="/payment-analytics/list-view"
          render={props => <ListView {...props} />}
        />
        <Route
          exact
          path="/payment-analytics/:eventId"
          render={props => <ViewCharts {...props} />}
        />
        <Route
          exact
          path="/payment-analytics/list-view/:eventId"
          render={props => <ListView {...props} />}
        />
      </Switch>
    </Container>
  );
};

export default Analytics;
