import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Redirect, Link } from 'react-router-dom';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';

import { SearchProvider } from '../../store/context';
import { Logout } from '../elements/SvgIcon';
import Sidebar from './Sidebar';
import { Loading, Stars } from '../elements';
import Header from './Header';
import config from '../../utils/config';

const HeroBody = styled.div`
  background-color: ${config.backgroundColor};
`;
const Columns = styled.div`
  height: 100%;
  @media screen and (max-width: 1023px), print {
    display: block !important;
  }
`;
const LeftContainer = styled.div`
  margin-top: -4.5rem !important;
  background: ${config.menuColor};
  box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.05);
  border-radius: 0 0 20px 0;
  .top-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 4.5rem;
    .image-logo {
      width: 120px;
    }
    .navbar-burger {
      width: 1.25rem;
      margin: 0 15px 0 0;
      span {
        background-color: #a2a4b9;
      }
      &:hover {
        background-color: transparent;
        span {
          background: ${props => props.theme.bgLinerGradient};
          ${'' /* background-color: ${props => props.theme.buttonColor}; */}
        }
      }
    }
  }
  @media screen and (min-width: 769px), print {
    display: flex;
    flex-direction: column;
  }
  @media screen and (max-width: 1023px), print {
    height: 4.5rem;
    border-radius: 0;
    .top-wrapper {
      justify-content: flex-start;
      margin-left: 2.25rem;
    }
    &.is-active {
      height: auto;
      width: 250px;
      position: absolute;
      z-index: 99;
      border-radius: 0 0 20px 0;
      .top-wrapper {
        .navbar-burger {
          span {
            ${'' /* background-color: ${props => props.theme.buttonColor}; */}
            background: ${props => props.theme.bgLinerGradient};
          }
        }
      }
    }
  }
  .bottom-wrapper {
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 1023px), print {
      &.is-active {
        display: flex;
      }
      display: none;
    }
  }
  @media only screen and (max-width: 768px) {
    .top-wrapper {
      margin-left: 1rem !important;
    }
`;

const RightContainer = styled.div`
  overflow: auto;
  height: 100%;
`;

const User = styled.div`
  padding: 1rem 0 2.5rem;
  text-align: center;
  display: unset;
  /* .profile-image {
    display: grid;
    justify-content: center;
    img {
      border-radius: 50%;
    }
  } */
  .first-name {
    color: ${props => props.theme.buttonColor};
  }
  .media-content {
    margin-top: 5px;
    text-align: center;
  }
  @media screen and (max-width: 768px) {
    padding: 2rem 1rem;
    float: right;
  }
`;

const LogoutWrapper = styled.div`
  margin-top: 20px;
  text-align: center;
  cursor: pointer;
  margin-bottom: 20px;
  span {
    color: #a2a4b9;
    font-size: 18px;
    line-height: 24px;
    svg {
      stroke: #a2a4b9;
    }
  }
  .logout-text {
    margin-left: 10px;
  }
  &:hover span {
    color: ${props => props.theme.buttonColor};
    svg {
      stroke: ${props => props.theme.buttonColor};
    }
  }
`;

const meQuery = gql`
  query meLayout {
    me {
      id
      username
      email
      telephone
      rating
      profile {
        firstName
        lastName
        image
      }
    }
  }
`;
class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: true,
      isMenuActive: false,
    };
  }

  logout = () => {
    window.location.reload(true);
    window.localStorage.clear();
    this.setState({ isLoggedIn: false });
  };

  setMenuStatus = () => {
    this.setState({ isMenuActive: !this.isMenuActive }, () => {
      document.addEventListener('click', this.closeMenu);
    });
  };

  closeMenu = e => {
    if (this.menu && !this.menu.contains(e.target)) {
      this.setState({ isMenuActive: false }, () => {
        document.removeEventListener('click', this.closeMenu);
      });
    }
  };

  render() {
    const { children } = this.props;
    const { isLoggedIn, isMenuActive } = this.state;
    return (
      <SearchProvider>
        <div className="hero">
          <Header />
          <HeroBody className="hero-body">
            <Columns className="columns is-gapless">
              <LeftContainer
                className={`column is-one-fifth ${isMenuActive && 'is-active'}`}
              >
                <div className="top-wrapper">
                  <a
                    className="navbar-burger"
                    onClick={() => this.setMenuStatus()}
                  >
                    <span />
                    <span />
                    <span />
                  </a>
                  <div className="logo-container has-text-centered">
                    <Link to="/">
                      <img
                        className="image-logo"
                        src="/images/logo-purple.png"
                        alt="logo"
                      />
                    </Link>
                  </div>
                </div>
                <div
                  className={`bottom-wrapper ${isMenuActive && 'is-active'}`}
                  ref={e => {
                    this.menu = e;
                  }}
                >
                  <Query query={meQuery}>
                    {({ data, loading, error }) => {
                      if (loading) {
                        return <Loading />;
                      }
                      if (error && !data)
                        return <p> `Error! ${error.message}`</p>;
                      return (
                        <User to="/settings">
                          <div>
                            {data.me &&
                              data.me.profile &&
                              data.me.profile.image &&
                              data.me.profile.image.length > 0 && (
                                <div className="media-content profile-image">
                                  <figure className="image is-64x64 is-hidden-mobile mx-auto">
                                    <img
                                      className="is-rounded"
                                      src={
                                        data.me.profile
                                          ? data.me.profile.image
                                          : '/images/avatar.svg'
                                      }
                                      alt={
                                        data.me.profile
                                          ? data.me.profile.firstName
                                          : ''
                                      }
                                      // style={{
                                      //   objectFit: 'cover',
                                      //   width: '70px',
                                      //   height: '70px',
                                      // }}
                                    />
                                  </figure>
                                </div>
                              )}
                            <div className="media-content">
                              <Stars starNum={data.me && data.me.rating} />
                              <p className="title is-5">
                                <span className="first-name">
                                  {data && data.me && data.me.profile
                                    ? data.me.profile.firstName
                                    : ''}{' '}
                                  {data && data.me && data.me.profile
                                    ? data.me.profile.lastName
                                    : ''}
                                </span>
                              </p>
                              {data && data.me ? (
                                <p className="subtitle is-7">
                                  {`@${data.me.username}` ||
                                    data.me.email ||
                                    data.me.telephone}
                                </p>
                              ) : null}
                            </div>
                          </div>
                        </User>
                      );
                    }}
                  </Query>
                  <Sidebar />
                  <LogoutWrapper
                    className="logout"
                    onClick={() => this.logout()}
                  >
                    <span className="icon">
                      <Logout />
                    </span>
                    <span className="logout-text">Log Out</span>
                  </LogoutWrapper>
                </div>
              </LeftContainer>
              <RightContainer className="column"> {children}</RightContainer>
            </Columns>
          </HeroBody>
          {!isLoggedIn && (
            <Redirect
              push
              to={{
                pathname: '/login',
              }}
            />
          )}
        </div>
      </SearchProvider>
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node,
};

export default Layout;
