import React from 'react';

import { Modal } from '../elements';

const BookingReportModal = ({
  event,
  ejectReason,
  isActive,
  onClose,
  status,
  checkInBy,
  ejectedAt,
  checkInAt,
  invitedBy,
}) => (
  <Modal title="Incident report" isActive={isActive} onClose={onClose}>
    <p className="has-text-weight-semibold mb-3">
      Brand Name :-
      <span className="ml-2 has-text-weight-normal">
        {event.createdBy && event.createdBy.profile
          ? `${event.createdBy.profile.firstName} ${
              event.createdBy.profile.lastName
                ? event.createdBy.profile.lastName
                : ''
            }`
          : ''}
      </span>
    </p>
    <p className="has-text-weight-semibold mb-3">
      Event Name :-
      <span className="ml-2 has-text-weight-normal">
        {event.name ? event.name : ''}
      </span>
    </p>
    <p className="has-text-weight-semibold mb-3">
      Booking Status :-
      <span className="ml-2 has-text-weight-normal is-capitalized">
        {status || ''}
      </span>
    </p>
    {invitedBy && (
      <p className="has-text-weight-semibold mb-3">
        Invited by :-
        <span className="ml-2 has-text-weight-normal is-capitalized">
          {invitedBy}
        </span>
      </p>
    )}
    <p className="has-text-weight-semibold mb-3">
      Check in at :-
      <span className="ml-2 has-text-weight-normal">{checkInAt || ''}</span>
    </p>
    <p className="has-text-weight-semibold mb-3">
      Checked in by :-
      <span className="ml-2 has-text-weight-normal">{checkInBy || ''}</span>
    </p>
    <p className="has-text-weight-semibold mb-3">
      Ejected date / time :-
      <span className="ml-2 has-text-weight-normal">
        {ejectedAt || 'Not available!'}
      </span>
    </p>
    <p className="has-text-weight-semibold mb-3">
      Ejected Report :-
      <span className="ml-2 has-text-weight-normal">
        {ejectReason || 'Report not available!'}
      </span>
    </p>
  </Modal>
);

export default BookingReportModal;
