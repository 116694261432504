import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import swal from 'sweetalert';
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { find } from 'lodash';
import { useHistory } from 'react-router-dom';

import { Button } from '../elements';
import EventAddress from './EventAddress';
// import EventDjCardItem from './EventDjCardItem';
import EventServices from './EventServices';
import EventTypeDetails from './EventTypeDetails';

import PartnerCardItem from './PartnerCardItem';
import { moment } from '../../utils/helpers';
// import eventBgBanner from '../../assets/createEventIcons/event-bg-banner.jpg';

const Container = styled.div`
  /* .banner {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    max-height: 300px;
    object-fit: cover;
  } */
  .draft-button {
    color: ${props => props.theme.mainBrandColor};
    padding-left: 2rem;
  }
  .edit-icon {
    color: ${props => props.theme.textColorLight};
    :hover {
      background-color: transparent;
      color: ${props => props.theme.mainBrandColor};
    }
  }

  @media only screen and (max-width: 768px) {
    .px-5 {
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
  }
`;

const Middle = styled.div`
  .event-image {
    object-fit: cover;
    height: 160px;
    width: 160px;
    margin: auto;
    /* margin-top: -5rem; */
    border: 1px solid ${props => props.theme.mainBrandColor};
  }
  .subtitle {
    line-height: 1.5;
  }
  .step-title {
    margin-top: 2rem;
  }
`;

const meQuery = gql`
  query meEvents {
    me {
      id
      stripeConnectId
      createdAt
      subscription {
        status
      }
    }
  }
`;

const Step = ({ title, onClick }) => (
  <h4 className="title  is-4 has-text-weight-semibold is-flex is-justify-content-space-between mb-2 step-title">
    <span>{title}</span>
    <button onClick={onClick} className="button is-white p-0 edit-icon">
      <i className="fas fa-pencil-alt" />
    </button>
  </h4>
);

const EmptyState = () => (
  <span className="pl-3 subtitle is-6 has-text-centered">None</span>
);

const EventView = ({
  event,
  handleBackPress,
  onPublishPress,
  onDraftPress,
  setActiveStep,
}) => {
  // console.log(event);

  const { data: meData } = useQuery(meQuery, {
    fetchPolicy: 'cache-and-network',
  });
  const eventStartDate = moment(event.eventStartTime).format('D MMM YYYY');
  const eventEndDate = moment(event.eventEndTime).format('D MMM YYYY');

  const [draftLoading, setDraftLoading] = useState(false);
  const [publishLoading, setPublishLoading] = useState(false);
  const me = meData && meData.me ? meData.me : {};
  const isPaidGuestList =
    event.pricing &&
    find(event.pricing.guestList, o => {
      return o.price > 0 && o.isVisible === true;
    });
  const isPaidTicket =
    event.pricing &&
    find(event.pricing.ticket, o => {
      return o.price > 0 && o.isVisible === true;
    });
  const isPaidReservation =
    event.pricing &&
    find(event.pricing.reservations, o => {
      return o.price > 0 && o.isVisible === true;
    });
  const isPaidVirtual =
    event.pricing &&
    find(event.pricing.virtual, o => {
      return o.price > 0 && o.isVisible === true;
    });
  const history = useHistory();

  const handlePublishEvent = () => {
    const nowDate = moment(new Date());
    const joiningDate = moment(me.createdAt);
    const freePlanExpired = nowDate.diff(joiningDate, 'days') > 365;
    // console.log('freePlanExpired', freePlanExpired);
    const subscriptionNotActivated =
      freePlanExpired &&
      (me.subscription === null || me.subscription?.status !== 'active');
    const stripeNotConnected = me.stripeConnectId === null;
    const paidEvent =
      isPaidGuestList || isPaidTicket || isPaidReservation || isPaidVirtual;
    if (stripeNotConnected && paidEvent) {
      swal({
        title: '',
        text:
          'Please connect your business bank account so we can pay you, visit the payment tab in the settings area.',
        icon: 'info',
        buttons: true,
      }).then(willConfirm => {
        if (willConfirm) {
          history.push('/settings?step=2');
        }
      });
    } else if (subscriptionNotActivated) {
      swal({
        title: '',
        text:
          'Please activate your subscription, visit the settings area to activate',
        icon: 'info',
        buttons: true,
      }).then(willConfirm => {
        if (willConfirm) {
          history.push('/settings?step=3');
        }
      });
      // swal(
      //   '',
      //   'Please activate your subscription, visit the settings area to activate',
      //   'info',
      //   { onClick: () => console.log('subscriptionNotActivated') },
      // );
    } else {
      onPublishPress();
      setPublishLoading(true);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setDraftLoading(false);
      setPublishLoading(false);
    }, 1000);
  }, [draftLoading, publishLoading]);

  return (
    <Container className="pb-6 has-background-white has-shadow-large has-border-radius-medium mt-5 ">
      {/* <figure className="image ">
        <img className="banner" src={eventBgBanner} alt="event-banner" />
      </figure> */}
      <Middle className="px-4">
        <div className="columns is-centered">
          <div className="has-text-centered mb-5 column is-5">
            <figure className="image mt-5">
              <img
                className="is-rounded event-image"
                src={
                  event.featuredImage
                    ? event.featuredImage
                    : 'https://bulma.io/images/placeholders/480x480.png'
                }
                alt={event.name}
              />
            </figure>
            <h4 className="subtitle is-size-6-mobile mb-3 is-4 has-text-weight-semibold mt-2 is-capitalized">
              {event.name}
            </h4>{' '}
            {event.venueAddress && (
              <p className="subtitle is-6 is-size-7-mobile mb-2">
                <EventAddress address={event.venueAddress} />
              </p>
            )}
            <div className="subtitle is-6 is-size-7-mobile has-text-centered">
              {eventStartDate === eventEndDate ? (
                <span className="">
                  {moment(event.eventStartTime).format('D MMM YYYY')} From{' '}
                  {moment(event.eventStartTime).format('HH:mm')} Till{' '}
                  {moment(event.eventEndTime).format('HH:mm')}
                </span>
              ) : (
                <span>
                  {moment(event.eventStartTime).format('D MMM YYYY @HH:mm')} -{' '}
                  {moment(event.eventEndTime).format('D MMM YYYY  @HH:mm')}
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="px-5">
          <Step title="Event Services" onClick={() => setActiveStep(1)} />
          <EventServices pricing={event.pricing} currency={event.currency} />
        </div>
        <div className="px-5">
          <Step title="Event Type" onClick={() => setActiveStep(2)} />
          <EventTypeDetails event={event} />
        </div>
        <div className="px-5 mt-5">
          <Step title="DJ & Entertainment" onClick={() => setActiveStep(4)} />
          <div className="columns is-multiline ">
            {event.djs && event.djs.length > 0 ? (
              event.djs.map(item => (
                <PartnerCardItem key={item.user.id} item={item} />
              ))
            ) : (
              <EmptyState />
            )}
          </div>
        </div>
        <div className="px-5 mt-5">
          <Step title="Event Promoters" onClick={() => setActiveStep(4)} />
          <div className="columns is-multiline ">
            {event.promoters && event.promoters.length > 0 ? (
              event.promoters.map(item => (
                <PartnerCardItem key={item.user.id} item={item} />
              ))
            ) : (
              <EmptyState />
            )}
          </div>
        </div>
        {/* <div className="px-5 mt-5">
          <Step title="Event Influencers" onClick={() => setActiveStep(4)} />
          <div className="columns is-multiline ">
            {event.influencers && event.influencers.length > 0 ? (
              event.influencers.map(item => (
                <PartnerCardItem key={item.user.id} item={item} />
              ))
            ) : (
              <EmptyState />
            )}
          </div>
        </div> */}
        <div className="px-5 mt-5">
          <Step title="Event Partners" onClick={() => setActiveStep(4)} />
          <div className="columns is-multiline ">
            {event.partners && event.partners.length > 0 ? (
              event.partners.map(item => (
                <PartnerCardItem key={item.user.id} item={item} />
              ))
            ) : (
              <EmptyState />
            )}
          </div>
        </div>
      </Middle>

      <p className="buttons is-justify-content-center mt-6">
        <Button
          onClick={handleBackPress}
          title="Back"
          className="is-rounded button is-medium  px-6"
          type="button"
        />
        <Button
          onClick={handlePublishEvent}
          loading={publishLoading}
          title="Publish"
          className="button  is-rounded button is-medium is-primary px-6"
          type="button"
        />
        <button
          onClick={() => {
            onDraftPress();
            setDraftLoading(true);
          }}
          className={`button ${draftLoading &&
            'is-loading'}  is-rounded is-large is-white  draft-button`}
          type="button"
        >
          <i className="far fa-bookmark" />
        </button>
      </p>
    </Container>
  );
};

export default EventView;
