import React from 'react';
import styled from 'styled-components';
import { Subtitle } from '../../elements';

const Container = styled.div`
  p {
    padding: 0 0 0 10px !important;
  }
`;
const InputWrapper = styled.div`
  width: ${props => props.isFullWidth && '100%'};
  input {
    width: ${props => props.isFullWidth && '100%'};
    min-width: 100px;
    max-width: ${props => (props.width ? props.width : '100px')};
    border-top: none;
    border-right: none;
    border-left: none;
    box-shadow: none;
    border-bottom: 1px solid
      ${props =>
        props.isSecondary ? 'transparent' : props.theme.textLightGrey};
    border-radius: 0 !important;
    padding: ${props => props.isSecondary && 0} !important;
    font-size: 14px !important;
    color: ${props => props.isSecondary && props.theme.textLightGrey};
    text-align: ${props => props.hasTextCentered && 'center'};
    :hover {
      border-color: ${props =>
        props.isSecondary ? 'transparent' : props.theme.textLightGrey};
    }
    :active,
    :focus {
      box-shadow: none;
      outline: none;
      border-right: none;
      border-left: none;
      box-shadow: none;
      border-bottom: 1px solid
        ${props =>
          props.isSecondary
            ? 'transparent'
            : props.theme.textLightGrey} !important;
    }
  }
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
`;

const TextInput = ({
  icon,
  isSecondary,
  width,
  text,
  isFullWidth,
  hasTextCentered,
  hasJustifyStart,
  textColor,
  errors,
  ...props
}) => {
  return (
    <>
      <Container
        className={`is-flex	is-align-items-center	${!hasJustifyStart &&
          'is-justify-content-center'}`}
      >
        <InputWrapper
          hasTextCentered={hasTextCentered}
          isFullWidth={isFullWidth}
          textColor={textColor}
          width={width}
          isSecondary={isSecondary}
          className={`control ${icon && 'has-icons-left'}`}
        >
          <input className="input is-small" {...props} />
          {icon && (
            <span className="icon is-small is-left">
              <i className={icon} />
            </span>
          )}
        </InputWrapper>
        {text && <Subtitle>{text}</Subtitle>}
      </Container>
      {errors && <div className="error has-text-danger my-2">{errors}</div>}
    </>
  );
};

export default TextInput;
