import React from 'react';
import { Link } from 'react-router-dom';

import { Button } from '../elements';

const TopBar = ({ handleBulkCheckIn, handleBulkAutoApproved, eventId }) => (
  <div className="level">
    <div className="level-left">
      <div className="level-item">
        <h3 className="has-text-weight-bold">Approval</h3>
      </div>
    </div>
    <div className="level-right columns is-mobile is-multiline is-justify-content-space-around">
      <div className="level-item">
        <Link to={`/guest-list/${eventId}`}>
          <Button
            title="View Guestlist"
            className="button is-small half-rounded"
          />
        </Link>
      </div>
      <div className="level-item">
        <Button
          onClick={() => handleBulkCheckIn('rejected')}
          title="Decline All"
          className="button is-small half-rounded"
        />
      </div>
      <div className="level-item">
        <Button
          onClick={() => handleBulkCheckIn('authorized')}
          title="Approve All"
          className="button is-small half-rounded"
        />
      </div>
      <div className="level-item">
        <Button
          onClick={handleBulkAutoApproved}
          title="Auto Approve All"
          className="button is-small half-rounded"
        />
      </div>
    </div>
  </div>
);
export default TopBar;
