import React, { useState } from 'react';
import styled from 'styled-components';
import { omit } from 'lodash';

import { searchInstagramUsers } from '../../utils/instagram';
import config from '../../utils/config';

const Card = styled.div`
  height: 20rem;
  overflow-y: scroll;
`;
const Column = styled.a`
  border-bottom: 2px solid #ccc;
`;

const Wrapper = styled.div`
  .input {
    height: ${props => (props.isMedium ? '2.5rem' : '4rem')} !important;
    border-radius: ${props => props.isMedium && '10px'} !important;
    padding-left: 2rem !important;
    padding-right: 2rem !important;
    :focus,
    :active {
      border-color: #b5b5b5;
      box-shadow: none;
    }
  }
  .card-content {
    background-color: transparent;
    padding: 1rem;
  }
  .icon {
    bottom: 0;
    margin: auto;
  }
  .card {
    margin: 1.5rem 0 1rem 0;
    border-radius: ${props => (props.isMedium ? '10px' : '2rem')};
    box-shadow: 0 0.2em 0.4em 0.3em rgba(0, 0, 0, 0.1),
      0 0px 0 1px rgba(0, 0, 0, 0.01);
  }
  .media-content .subtitle {
    color: ${config.brandColor};
  }
`;

const InstagramSearchInput = ({
  label,
  handleChange,
  value,
  hasBackgroudColor,
  isMedium,
  ...inputProps
}) => {
  const [users, setUsers] = useState([]);
  const [query, setQuery] = useState('');
  const [dj, setDj] = useState(value);
  const fetchInstagramUsers = async input => {
    try {
      const instagramUsers = await searchInstagramUsers(input);
      setUsers(instagramUsers.users);
    } catch (error) {
      return error;
    }
    return null;
  };
  const onChange = event => {
    const input = event.target.value;
    setQuery(input);
    fetchInstagramUsers(input);
  };

  const onHandleClick = user => {
    const newUser = {
      name: user.user.full_name,
      username: user.user.username,
      profilePic: user.user.profile_pic_url,
      redirectUrl: `https://www.instagram.com/${user.user.username}`,
    };
    const data = dj.concat(newUser);
    setDj(data);
    const input = data.map(item => omit(item, ['__typename']));
    handleChange(input);
    setQuery('');
  };

  const handleRemove = username => {
    const newData = dj.filter(item => item.username !== username);
    const input = newData.map(item => omit(item, ['__typename']));
    handleChange(input);
    setDj(newData);
  };
  return (
    <Wrapper hasBackgroudColor={hasBackgroudColor} isMedium={isMedium}>
      <div className="field">
        <label className="label mb-3 has-text-weight-normal is-capitalized">
          {label}
        </label>
        {dj > 0 &&
          dj.map(item => (
            <div className="card">
              <div className="card-content">
                <div className="media">
                  <div className="media-left">
                    <figure className="image is-64x64">
                      <img
                        className="has-border-radius-medium"
                        src={item.profilePic}
                        alt={item.name}
                      />
                    </figure>
                  </div>
                  <div className="media-content">
                    <p className="title is-5">{item.name}</p>
                    <p className="subtitle is-6">@{item.username}</p>
                  </div>
                  <a
                    className="button is-rounded is-small"
                    onClick={() => handleRemove(item.username)}
                    style={{ alignSelf: 'center' }}
                  >
                    remove
                  </a>
                </div>
              </div>
            </div>
          ))}
        <div className="control">
          <>
            <p className="control has-icons-left">
              <input
                className="input is-rounded"
                value={query}
                onChange={onChange}
                {...inputProps}
              />
              <span className="icon is-left has-text-weight-normal">@</span>
            </p>
            {users.length > 0 && (
              <Card className="card">
                <div className="card-content">
                  <div className="columns is-multiline">
                    {users.map(user => (
                      <Column
                        className="column is-12"
                        onClick={() => onHandleClick(user)}
                        key={user.user.username}
                      >
                        <div className="media">
                          <div className="media-left">
                            <figure className="image is-48x48">
                              <img
                                className="is-rounded"
                                src={user.user.profile_pic_url}
                                alt={user.user.full_name}
                              />
                            </figure>
                          </div>
                          <div className="media-content">
                            <p className="title is-5">{user.user.full_name}</p>
                            <p className="subtitle is-6">
                              @{user.user.username}
                            </p>
                          </div>
                        </div>
                      </Column>
                    ))}
                  </div>
                </div>
              </Card>
            )}
          </>
        </div>
      </div>
    </Wrapper>
  );
};

export default InstagramSearchInput;
