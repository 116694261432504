/* eslint no-buffer-constructor: 0 */

import React, { useState, useRef } from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

const ImageCropper = props => {
  const {
    modalVisible,
    src,
    onChange,
    handleClose,
    aspectRatio,
    isSmall,
  } = props;
  const [image, setImage] = useState(undefined);
  // const [cropper, setCropper] = useState();
  const cropperEl = useRef(null);

  const onCrop = () => {
    const imageElement = cropperEl?.current;
    const cropperData = imageElement?.cropper;
    // image in dataUrl
    const theBlob = cropperData
      .getCroppedCanvas({
        maxWidth: isSmall ? 500 : 1000,
        maxHeight: isSmall ? 300 : 550,
      })
      .toDataURL();
    const blob = new Buffer(
      theBlob.replace(/^data:image\/\w+;base64,/, ''),
      'base64',
    );
    // console.log('file', blob);
    setImage(blob);
  };

  return (
    <div className={`modal ${modalVisible ? 'is-active' : ''}`}>
      <div className="modal-background" />
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Crop Image</p>
          <button className="delete" type="button" onClick={handleClose} />
        </header>
        <section className="modal-card-body">
          <Cropper
            ref={cropperEl}
            style={{ height: 400, width: '100%' }}
            // zoomTo={0.5}
            initialAspectRatio={aspectRatio || 4 / 3}
            aspectRatio={aspectRatio || 4 / 3}
            // preview=".img-preview"
            src={src}
            viewMode={1}
            minCropBoxHeight={10}
            minCropBoxWidth={10}
            background={false}
            responsive
            autoCropArea={1}
            checkOrientation={false}
            // onInitialized={instance => {
            //   setCropper(instance);
            // }}
            crop={onCrop}
            guides
          />
        </section>
        <footer className="modal-card-foot">
          <button
            type="button"
            className="button is-success"
            onClick={() => {
              onChange(image);
            }}
          >
            Done
          </button>
          <button type="button" className="button" onClick={handleClose}>
            Cancel
          </button>
        </footer>
      </div>
    </div>
  );
};

export default ImageCropper;
