import React from 'react';
import { withFormik } from 'formik';
import * as yup from 'yup';
import { Field, Button } from '../elements';

const CreateEventForm = props => {
  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    errors,
    touched,
    isSubmitting,
  } = props;

  return (
    <form onSubmit={handleSubmit}>
      <Field
        type="text"
        name="name"
        value={values.name}
        placeholder="Enter the name of event"
        onChange={handleChange}
        onBlur={handleBlur}
        errors={errors.name && touched.name ? errors.name : undefined}
      />
      {/* <Field
        min="0"
        type="number"
        placeholder="Enter maximum number of guests allowed"
        name="noOfGuest"
        value={values.noOfGuest}
        onChange={handleChange}
        onBlur={handleBlur}
        errors={
          errors.noOfGuest && touched.noOfGuest ? errors.noOfGuest : undefined
        }
      /> */}
      <Button
        className={`button is-rounded is-medium is-primary ${isSubmitting &&
          'is-loading'}`}
        disabled={isSubmitting}
        title="Create"
        type="submit"
      />
    </form>
  );
};

export default withFormik({
  mapPropsToValues: () => ({
    name: '',
  }),
  validationSchema: yup.object().shape({
    name: yup
      .string()
      .max(25, 'Max 25 characters allowed!')
      .required('Event name is required.'),
  }),
  handleSubmit: (values, { setSubmitting, resetForm, props }) => {
    // console.log('values', values);
    props
      .onSubmit(values)
      .then(() => {
        setSubmitting(false);
        resetForm();
      })
      .catch(() => {
        setSubmitting(false);
      });
  },

  displayName: 'CreateEventForm',
})(CreateEventForm);
