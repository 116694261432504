import React from 'react';
import styled from 'styled-components';

const Container = styled.span`
  position: relative;
  :hover {
    .info-text-wrapper {
      visibility: visible;
    }
  }
  .info-text-wrapper {
    visibility: hidden;
    position: absolute;
    top: 0;
    z-index: 1;
    min-width: 180px;
    left: ${props => (props.isSecondary ? '25%' : '33%')};
  }
  .info-icon {
    vertical-align: middle;
  }

  svg {
    color: ${props => props.theme.textColorLight};
  }
  .text {
    font-size: 10px !important;
    letter-spacing: 0.5px;
  }
`;

const ToolTip = ({ isSecondary, data }) => (
  <Container isSecondary={isSecondary}>
    <span className="icon ml-2 is-small info-icon">
      <i className="fas fa-info-circle" />
    </span>
    <div className="info-text-wrapper has-shadow-large has-background-white has-border-radius-medium p-2">
      <span className="is-flex">
        {!isSecondary && (
          <div className="icon mr-2 is-size-7 ">
            <i className="fas fa-info-circle" />
          </div>
        )}
        <div className="text">{data}</div>
      </span>
    </div>
  </Container>
);

export default ToolTip;
