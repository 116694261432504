import React from 'react';
import styled from 'styled-components';
import { find } from 'lodash';

import guestListIcon from '../../assets/createEventIcons/list-icon.png';
import guestListActiveIcon from '../../assets/createEventIcons/list-icon-active.png';
import ticketIcon from '../../assets/createEventIcons/ticket-icon.png';
import ticketActiveIcon from '../../assets/createEventIcons/ticket-icon-active.png';
import reservationIcon from '../../assets/createEventIcons/reservation-icon.png';
import reservationActiveIcon from '../../assets/createEventIcons/reservation-icon-active.png';
import virtualIcon from '../../assets/createEventIcons/virtual-icon.png';
import virtualActiveIcon from '../../assets/createEventIcons/virtual-icon-active.png';
import CheckBox from './elements/CheckBox';
import { InfoToolTip } from '../elements';

const LinkStyled = styled.div`
  border: none !important;
  .box {
    height: 100%;
    position: relative;
    border: 1px solid
      ${props =>
        props.isActive
          ? props.theme.mainBrandColor
          : props.theme.textLightGrey};
    padding: 2.25rem 1rem 1.25rem;
    color: ${props => (props.isActive ? '#B7007B' : props.theme.textLightGrey)};
    transition: all 0.1s ease-in-out;
    box-shadow: ${props =>
      props.isActive ? '0px 33px 36px -13px rgba(183, 0, 122, 0.27)' : 'none'};
    background: ${props => (props.isActive ? '#fff' : 'transparent')};
    :hover {
      box-shadow: 0px 33px 36px -13px rgba(183, 0, 122, 0.27) !important;
      background: #fff;
      border-color: transparent;
    }
    figure {
      height: 52px;
      width: 52px;
      margin: 0 auto;
    }
    @media only screen and (max-width: 768px) {
      padding: 0.5rem !important;
    }
  }

  .check-icon {
    /* position: absolute;
    bottom: 5%;
    right: 5%; */
    color: ${props => props.theme.mainBrandColor};
  }
  .info-icon {
    svg {
      font-size: 1.25rem;
    }
  }
  @media only screen and (max-width: 768px) {
    padding: 0.5rem !important;
  }
`;

const Primary = styled.span`
  position: absolute;
  top: 8%;
  right: 5%;
  color: ${props =>
    props.isPrimary ? props.theme.mainBrandColor : props.theme.textLightGrey};
`;
const Info = styled.span`
  position: absolute;
  top: 8%;
  left: 5%;
`;
const EntryTypeOptions = ({
  onClick,
  onChange,
  isActive,
  primary,
  privateServices,
  hasCheckedPricing,
  setPrimary,
}) => {
  const entryTypes = [
    {
      id: 'guestList',
      title: 'Guestlist',
      lightIcon: guestListIcon,
      activeIcon: guestListActiveIcon,
      hasChecked: hasCheckedPricing.guestList,
      description: 'Make your guestlist private & approve who should be listed',
    },
    {
      id: 'ticket',
      title: 'Ticket',
      lightIcon: ticketIcon,
      activeIcon: ticketActiveIcon,
      hasChecked: hasCheckedPricing.ticket,
      description:
        'Make your ticket event private, ticket purchases will be pending until approved by you',
    },
    {
      id: 'reservations',
      title: 'Reservation',
      lightIcon: reservationIcon,
      activeIcon: reservationActiveIcon,
      hasChecked: hasCheckedPricing.reservations,
      description:
        'Make your reservation private, table bookings will be pending until approved by you',
    },
    {
      id: 'virtual',
      title: 'Virtual',
      lightIcon: virtualIcon,
      activeIcon: virtualActiveIcon,
      hasChecked: hasCheckedPricing.virtual,
      description:
        'Make your virtual event private, virtual events will be pending until approved by you',
    },
  ];
  return (
    <div className="columns is-mobile is-multiline is-variable is-6 mt-2">
      {entryTypes.map(item => (
        <LinkStyled
          key={item.id}
          isActive={isActive === item.id}
          onClick={() => {
            onClick(item.id);
          }}
          className="column is-3-desktop column is-6-mobile is-vcentered has-text-centered mb-4"
        >
          <div className="box has-text-centered  has-border-radius-medium">
            <figure className="image mb-2">
              <img
                src={isActive === item.id ? item.activeIcon : item.lightIcon}
                alt={item.title}
              />
            </figure>
            <p
              className={`is-size-5  ${
                isActive === item.id
                  ? 'has-text-weight-semibold'
                  : 'has-text-weight-normal'
              } mt-4 mb-4`}
            >
              {item.title}
            </p>
            <Info className="has-text-left">
              <InfoToolTip isSecondary data={item.description} />
            </Info>
            <Primary
              isPrimary={primary === item.id}
              onClick={() => setPrimary(item.id)}
            >
              <i className="fas fa-star" />
            </Primary>
            <div className="is-flex private is-justify-content-space-between">
              <div className="is-flex">
                <CheckBox
                  checked={
                    privateServices &&
                    find(privateServices, o => {
                      return o === item.id;
                    })
                  }
                  onChange={() => onChange(item.id)}
                />
                <p className="ml-2">Private</p>
              </div>
              {item.hasChecked && (
                <div className="check-icon">
                  <i className="fa fa-check" />
                </div>
              )}
            </div>
          </div>
        </LinkStyled>
      ))}
    </div>
  );
};
export default EntryTypeOptions;
