import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
// import moment from 'moment';
import { isNaN } from 'lodash';
import CheckBox from './elements/CheckBox';
// import Select from './elements/Select';

const Container = styled.div`
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important;
  min-width: max-content;
  box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.06);
  color: ${props => props.theme.textLightGrey} !important;
`;

// const depositOptions = [
//   { value: 10, label: '10%' },
//   { value: 25, label: '25%' },
//   { value: 50, label: '50%' },
// ];

const EventTablesRules = ({ rules, onChange }) => {
  const [isReady, setIsReady] = useState(false);
  const [showPricePerPerson, setShowPricePerPerson] = useState(true);
  const [showTablePrice, setShowTablePrice] = useState(false);

  const [depositPercentage, setDepositPercentage] = useState(0);

  useEffect(() => {
    setIsReady(true);
    if (!isReady) {
      rules.forEach(str => {
        if (str === 'Reservation price per person') {
          setShowPricePerPerson(true);
        }
        if (str === 'Reservation table price') {
          setShowTablePrice(true);
          setShowPricePerPerson(false);
        }
        if (str.search('Deposit Required')) {
          const deposit = str.replace('Deposit Required', '');
          if (!isNaN(parseInt(deposit, 10))) {
            setDepositPercentage(parseInt(deposit, 10));
          }
        }
      });
    }
  }, [rules]);

  useEffect(() => {
    const rulesArray = [];
    if (showPricePerPerson === true) {
      rulesArray.push('Reservation price per person'); // DO NOT CHANGE TEXT
    }
    if (showTablePrice === true) {
      rulesArray.push('Reservation table price'); // DO NOT CHANGE TEXT
    }
    if (depositPercentage !== 0) {
      rulesArray.push(`${depositPercentage}% Deposit Required`); // DO NOT CHANGE TEXT
    }
    onChange(rulesArray);
  }, [showPricePerPerson, showTablePrice, depositPercentage]);

  return (
    <Container className="py-4 has-background-white">
      <p>Rules</p>
      <div>
        <div className="mt-5 is-flex">
          <CheckBox
            checked={showPricePerPerson}
            onChange={() => {
              setShowPricePerPerson(!showPricePerPerson);
              setShowTablePrice(!showTablePrice);
            }}
          />
          <span className="ml-5">Reservation price per person</span>
        </div>
        <div className="mt-5 is-flex">
          <CheckBox
            checked={showTablePrice}
            onChange={() => {
              setShowTablePrice(!showTablePrice);
              setShowPricePerPerson(!showPricePerPerson);
            }}
          />
          <span className="ml-5">Reservation table price</span>
        </div>
        {/* <div className="mt-5 is-flex">
          <CheckBox checked={depositPercentage} />
          <div className="ml-5 is-flex">
            <Select
              min={1}
              placeholder="Select"
              value={depositPercentage}
              onChange={e => setDepositPercentage(parseInt(e.target.value, 10))}
              options={depositOptions}
            />
            <span className="ml-3">Deposit Required</span>
          </div>
        </div> */}
      </div>
    </Container>
  );
};
export default EventTablesRules;
