import React, { useState } from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
// import swal from 'sweetalert';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';

// import { Bell } from '../elements/SvgIcon';
import { SearchInput, Button } from '../elements';
import CreateEventModal from './CreateEventModal';
// import DateRangePicker from '../DateRangePicker';

const Container = styled.div`
  padding: 0 1.5rem;
  .last {
    cursor: pointer;
  }
  .fa-plus {
    height: 9px;
  }
  @media screen and (max-width: 1023px), print {
    &.navbar-menu,
    .navbar-start,
    .navbar-end {
      display: flex;
      align-items: stretch;
    }
    &.navbar-menu {
      flex-grow: 1;
      flex-shrink: 0;
      box-shadow: none;
    }
    .navbar-start {
      justify-content: flex-start;
      margin-right: auto;
    }
    .navbar-end {
      justify-content: flex-end;
      margin-left: auto;
    }
    .navbar-item,
    .navbar-link {
      align-items: center;
      display: flex;
    }
  }
  .primary-color {
    color: ${props => props.theme.mainBrandColor};
  }
  @media only screen and (max-width: 768px) {
    .navbar-item,
    .navbar-link {
      padding: 0.5rem 0.3rem !important;
    }
    .button .icon:last-child:not(:first-child) {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
    padding: 0 0.5rem !important;
  }
`;

const createEventMutation = gql`
  mutation createEvent($name: String!) {
    createEvent(input: { name: $name }) {
      id
      name
    }
  }
`;

const Header = () => {
  const history = useHistory();
  const location = useLocation();
  // console.log(data);
  const [activeModal, setActiveModal] = useState(false);
  // const meData = data && data.me ? data.me : undefined;
  const toggleModal = () => {
    // if (
    //   (meData && meData.subscription === null) ||
    //   (meData && meData.subscription && meData.subscription.status !== 'active')
    // ) {
    //   swal(
    //     'Oops!',
    //     'You can not create an event until your subscription is activated!',
    //     'error',
    //   );
    // } else {
    setActiveModal(!activeModal);
    // }
  };

  const onCreateEventSuccess = data4 => {
    toggleModal();
    console.log('inside create events mutation', data4.createEvent.id, history);
    history.push(`/update-event/${data4.createEvent.id}`);
  };

  // const handleDateChange = date => {
  //   setStartDate(date);
  // };

  const locationArr = [
    '/events',
    '/followers',
    '/list-view',
    '/guest-list',
    '/invite',
    '/guestlist-approval',
  ];
  let placeholder = '';
  switch (location.pathname) {
    case '/events':
      placeholder = 'events';
      break;
    case '/followers':
      placeholder = 'followers';
      break;
    case '/invite':
      placeholder = 'users';
      break;
    case '/payment-analytics/list-view':
      placeholder = 'orders';
      break;
    default:
  }
  return (
    <Container className="navbar-menu">
      <div className="navbar-end">
        {locationArr.some(item => {
          return location.pathname.includes(item);
        }) && (
          <div className="navbar-item">
            <SearchInput placeholder={`Search ${placeholder}`} />
          </div>
        )}
        <div className="navbar-item">
          <Button
            className="button is-primary is-small half-rounded"
            onClick={() => history.push('/generate-qr-code')}
          >
            <span className="is-hidden-mobile">Generate QR Code</span>
            <span className="icon">
              <i className="fas fa-qrcode" />
            </span>
          </Button>
        </div>
        <div className="navbar-item">
          <Button
            className="button is-primary is-small half-rounded"
            onClick={toggleModal}
          >
            <spa className="is-hidden-mobile">Add New</spa>
            <spa className="is-hidden-tablet">Add</spa>
            <span className="icon">
              <i className="fas fa-plus" />
            </span>
          </Button>
        </div>
        {/* <div className="navbar-item">
          <DateRangePicker />
        </div> */}
        <div
          onClick={() => history.push('/widget')}
          className="navbar-item primary-color"
        >
          <span className="icon">
            <i className="fas fa-eye-dropper" />
          </span>
        </div>

        {/* <div className="navbar-item last">
          <span className="icon">
            <Bell />
          </span>
        </div> */}

        <Mutation
          mutation={createEventMutation}
          onCompleted={data2 => onCreateEventSuccess(data2)}
          onError={error => console.log(error)}
        >
          {(createEvent, { loading }) => {
            return (
              <CreateEventModal
                active={activeModal}
                onLoading={loading}
                handelClose={toggleModal}
                handleSubmit={data3 => {
                  // if (!isAlreadyConnected) {
                  //   return swal(
                  //     'Please connect to stripe first in settings to continue',
                  //   ).then(() => {
                  //     toggleModal();
                  //     history.push('/payments');
                  //   });
                  // }

                  return createEvent({
                    variables: {
                      ...data3,
                    },
                  });
                }}
              />
            );
          }}
        </Mutation>
      </div>
    </Container>
  );
};

export default Header;
