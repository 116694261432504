import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  overflow: auto;
  background-color: #fcfcfc;
  table {
    overflow: auto;
    background: transparent !important;
    width: 100%;
    border-radius: 30px;
    border-collapse: separate;
    border-spacing: 0 1em;
  }

  td {
    font-weight: 400 !important;
    padding: 1.3rem !important;
    border: none;
    text-align: center !important;

    :first-child {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      text-align: left !important;
      padding-left: 2.5rem !important;
    }
    :last-child {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      text-align: right !important;
      padding-right: 2.5rem !important;
    }
  }

  thead tr {
    :first-child {
      box-shadow: none;
    }
  }
  button {
    height: auto;
  }
  .fa-eye {
    color: ${props => props.theme.mainBrandColor};
  }
  .fa-eye-slash {
    color: ${props => props.theme.textLightGrey};
  }
`;

const TableWrapper = ({ children, ...props }) => {
  return (
    <Container className="table-wrapper section" {...props}>
      {children}
    </Container>
  );
};

export default TableWrapper;
