import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  .inner-wrapper {
    border-radius: 10px;
    margin: 0px 20px 0px 10px;
    /* min-height: 80vh; */
  }
  @media only screen and (max-width: 768px) {
    .modal-content {
      max-height: 95vh;
    }
  }
  .modal-background {
    background-color: rgba(10, 10, 10, 0.6);
  }
`;

const Modal = ({ isActive, onClose, title, subtitle, children }) => (
  <Container>
    <div className={`modal ${isActive ? 'is-active' : ''}`}>
      <div className="modal-background" />
      <div className="modal-content">
        <div className="has-background-white inner-wrapper px-6 pt-5 pb-6">
          <div className="has-text-right">
            <button
              type="button"
              onClick={onClose}
              className="button is-white is-small"
              aria-label="close"
            >
              <i className="fas fa-times" />
            </button>
          </div>
          {title && (
            <h1
              className={`subtitle is-4 has-text-centered ${
                subtitle ? 'mb-3' : 'mb-6'
              } has-text-weight-bold`}
            >
              {title}
            </h1>
          )}
          {subtitle && (
            <h1 className="subtitle is-5 has-text-centered has-text-black mt-0 mb-5 has-text-weight-semibold">
              {subtitle}
            </h1>
          )}
          {children}
        </div>
      </div>
    </div>
  </Container>
);

export default Modal;
