import React from 'react';
import styled from 'styled-components';
import CheckBox from '../updateEventDetails/elements/CheckBox';

const TableRow = styled.tr`
  td {
    vertical-align: middle !important;
    border-top: 1px solid ${props => props.theme.borderColor} !important;
    border-bottom: 1px solid ${props => props.theme.borderColor} !important;
    border-bottom-width: 1px !important;
    padding: 0.75em 0.75em !important;
    color: ${props => props.theme.placeholderColor};
    :first-child {
      border-top-left-radius: 2.75rem;
      border-bottom-left-radius: 2.75rem;
      border-left: 1px solid ${props => props.theme.borderColor} !important;
    }
    :last-child {
      border-top-right-radius: 2.75rem;
      border-bottom-right-radius: 2.75rem;
      border-right: 1px solid ${props => props.theme.borderColor} !important;
    }
  }
  .status {
    min-width: 110px;
  }
  .invited,
  .walk-in {
    color: ${props => props.theme.mainBrandColor};
    border: 1px solid ${props => props.theme.mainBrandColor};
    /* margin-right: 1.75rem; */
  }
  .registered {
    ${'' /* background-color: ${props => props.theme.mainBrandColor}; */}
    background: ${props => props.theme.bgLinerGradient};
    /* margin-right: 1.75rem; */
  }
  .failed {
    background-color: ${props => props.theme.borderColor};
    border-color: ${props => props.theme.borderColor};
  }
  .refresh {
    vertical-align: middle;
    color: ${props => props.theme.mainBrandColor};
    :hover {
      color: ${props => props.theme.mainBrandColor};
    }
  }
  .fa-envelope {
    position: relative;
    font-size: 18px;
  }
  .fa-check-circle {
    position: absolute;
    bottom: 10px;
    right: 8px;
    font-size: 11px;
    color: #fff !important;
  }
  .icon-primary {
    color: ${props => props.theme.mainBrandColor};
  }
  .icon-grey {
    color: ${props => props.theme.placeholderColor};
  }
  .is-rounded {
    object-fit: cover;
    height: 48px;
    width: 48px;
  }
`;

const UserTableItem = ({ item, activeTab, handleRemove, handleChange }) => {
  return (
    <>
      <TableRow>
        <td className="is-flex is-align-items-center">
          <CheckBox
            checked={item.isChecked}
            onChange={() => {
              handleChange({ isChecked: !item.isChecked });
            }}
          />
          <div className="media ml-5">
            <div className="media-left">
              <figure className="image is-48x48">
                <img
                  className="is-rounded"
                  src={
                    item.profile && item.profile.image
                      ? item.profile.image
                      : '/images/avatar.svg'
                  }
                  alt="Placeholder"
                />
              </figure>
            </div>
            <div className="is-align-self-center">
              <p className="">
                {item.profile
                  ? `${item.profile.firstName} ${
                      item.profile.lastName ? item.profile.lastName : ''
                    }`
                  : `${item.firstName} ${item.lastName}`}
              </p>
            </div>
          </div>
        </td>
        <td>{item.email}</td>
        <td>{item.telephone}</td>
        {activeTab === 'usersList' && (
          <td>
            <p className="is-flex is-justify-content-flex-end mr-3">
              <button onClick={handleRemove} className="button is-white mr-0">
                <i className="far fa-trash-alt icon-grey" />
              </button>
              <span className="button is-white">
                <span
                  className={`icon ${
                    item.isInvited ? 'icon-primary' : 'icon-grey'
                  } `}
                >
                  <i className="fas fa-envelope" />
                  <i className="fas fa-check-circle" />
                </span>
              </span>
              <span className="button is-white ml-2">
                <span
                  className={`icon ${
                    item.isInvited ? 'icon-primary' : 'icon-grey'
                  } `}
                >
                  <i className="fas fa-user " />
                  <i className="fas fa-check is-size-7" />
                </span>
              </span>
            </p>
          </td>
        )}
        {activeTab === 'invitedUsersList' && (
          <td>
            {item.status === 'invited' && (
              <span className="button is-small has-border-radius-small status invited has-text-weight-semibold">
                Invited
              </span>
            )}
            {item.status === 'active' && (
              <span className="button is-small has-border-radius-small status registered has-text-weight-semibold has-text-white">
                Registered
                <span className="ml-3">
                  <i className="fas fa-check" />
                </span>
              </span>
            )}
            {item.status === 'failed' && (
              <div className="is-flex is-justify-content-flex-end">
                <span className="button is-small has-border-radius-small status failed has-text-weight-semibold has-text-white">
                  Failed
                </span>
                <button className="button ml-3 is-white px-0 refresh">
                  <i className="fas fa-sync-alt" />
                </button>
              </div>
            )}
            {item.status === 'walkIn' && (
              <span className="button is-small has-border-radius-small status walk-in has-text-weight-semibold">
                Walked -In
                <span className="ml-3">
                  <i className="fas fa-running" />
                </span>
              </span>
            )}
          </td>
        )}
      </TableRow>
    </>
  );
};

export default UserTableItem;
