import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  padding: 2.25rem;
  > div {
    background-color: #fff;
    border: 2px solid #f0f0f0;
    border-radius: 10px;
    padding: 5%;
  }
  .wrapper {
    &:hover,
    &.active {
      .question {
        ${
          '' /* background: radial-gradient(
          circle farthest-corner at 100% 150%,
          #68044d 10%,
          #9d2b7e 50%
        ); */
        }
        background: ${props => props.theme.bgLinerGradient};

        color: #fff;
        border: 1px solid transparent;
      }
      .toggle-icon {
        color: #fff;
      }
    }
    &.active {
      .answer {
        display: block;
      }
      .toggle-icon {
        &:after {
          transform: rotate(90deg);
        }
      }
    }
  }
  .toggle-icon {
    width: 12px;
    height: 12px;
    position: absolute;
    display: block;
    top: 50%;
    right: 30px;
    transform: translateY(-50%);
    transition: transform 0.4s ease-in-out;
    color: #9d2b7e;
    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      border-style: solid;
      transition: inherit;
    }
    &:before {
      left: 0;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      border-width: 2px 0 0;
    }
    &:after {
      border-width: 0 0 0 2px;
      left: 50%;
      bottom: 0;
      top: 0;
      transform: translateX(-50%);
    }
  }
  .question {
    position: relative;
    cursor: pointer;
    padding: 14px 66px 14px 30px;
    background: transparent;
    border-radius: 35px;
    border: 1px solid #e7e8e8;
    transition: 0.4s ease-in-out;
    font-weight: 600;
  }
  .answer {
    display: none;
    padding: 20px 30px 0 30px;
    transition: 0.4s ease-in-out;
  }
  @media only screen and (max-width: 768px) {
    .question {
      padding: 10px 39px 10px 20px !important;
    }
    .answer {
    padding: 15px 20px 0 20px !important;
    }
    .toggle-icon {
      right: 20px !important;
    }
  }
`;

const data = [
  {
    id: 1,
    question: 'How do you verify/activate your business account?',
    answer:
      'Click settings on the side menu bar. Then click the payment tab, click “connect with stripe” and finally click the activate tab make sure you activate your subscription plan.',
  },
  {
    id: 2,
    question: 'How do I create an event?',
    answer:
      'Once your account is verified/activated you can create the events via the dashboard. Simple and easy by clicking the ‘Add New+” button on the top navigation bar.',
  },
  {
    id: 3,
    question:
      'What does ‘repeat event’ mean when creating an event and selecting weekly?',
    answer:
      'If you select ‘weekly’ the event will repeat weekly i.e. You create an event for the 5th of September 2020. The event will repeat following Saturday (12th) however will appear in the user app 6 days before the actual event date. This gives you enough time to promote your event.',
  },
  {
    id: 4,
    question:
      'What does ‘repeat event’ mean when creating an event and selecting monthly?',
    answer:
      'If you select ‘monthly’ the event will repeat monthly i.e. You create an event for 5th September 2020 then the event will repeat following Saturday (3rd) however will appear in the user app three weeks before the actual event date. This gives you enough time to promote your event.',
  },
  {
    id: 5,
    question: 'What does save and publish mean when creating an event?',
    answer:
      'If you click ‘save’ the event will be saved as a draft and will not appear in the user APP. If you click ‘publish’ the event will appear in the user app.',
  },
  {
    id: 6,
    question: 'What is the past event?',
    answer:
      'All events the brand has ever created are placed here. All data and analytics are never forgotten. ',
  },
  {
    id: 7,
    question: 'How I do view a guestlist for my events?',
    answer:
      'Click ‘events’ in the side menu bar, then click a button called ‘Guestlist’. In this area, you can see all the guests who are either pending entry, accepted or denied into the said event.',
  },
  {
    id: 8,
    question: 'What is a door picker?',
    answer:
      "A door picker is a person who manages the events guestlist. You assign the door picker through your existing follower's database. That way we know the user is ID verified via the ListMe secure verification process. The door picker will use the Digiboard app to view the guestlist and decide whether to accept or deny such users in the event.",
  },
  {
    id: 9,
    question: 'Why pause the event? ',
    answer:
      'If you reach venue capacity you can pause the event, stopping any new users submitting their name on the digital guestlist in the user app.',
  },
  {
    id: 10,
    question: 'What is ‘Walk In’ on guest list area?',
    answer:
      "If a guest turns up and they are not on the list, you click 'walk-in' and they enter the name and number (entered by the door pick using the Digiboard app). They receive an SMS to download the ListMe user app for next time. On this occasion, they enter the old way.",
  },
  {
    id: 11,
    question: 'What is followers?',
    answer:
      'This is where the brand can check and view who is following the brand. You can also get to know your followers better by viewing their event listed history.',
  },
  {
    id: 12,
    question: 'What is analytics?',
    answer:
      'You have full visibility of your brand activity and the events the brand has created. i.e. overall revenue collected, gender ratio, age ratio and much more. True consumer insights at your fingertips.',
  },
  {
    id: 13,
    question: 'What is messaging?',
    answer:
      'Now you have the ability to reply to your customers in single chat platform keeping the conversations relevant and timely.',
  },
  {
    id: 14,
    question: 'What payment methods do you accept?',
    answer:
      'As long as you have a bank account registered in your country, we can get you up and running in no time. You can start accepting mobile payments right away. (cashless society)',
  },
  {
    id: 15,
    question: 'How do I upload/change brand profile pic?',
    answer: 'Click settings and click ‘profile picture’.',
  },
];

const Faq = () => {
  const handleClick = e => {
    e.target.parentNode.classList.toggle('active');
  };

  return (
    <Container className="section">
      <div>
        <div className="columns is-multiline">
          {data.map(item => {
            return (
              <div className="column is-full" key={item.id}>
                <div className="wrapper">
                  <div
                    className="question is-size-7-mobile"
                    onClick={handleClick}
                  >
                    {item.question}
                    <i className="toggle-icon" />
                  </div>
                  <div className="answer is-size-7-mobile">{item.answer}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Container>
  );
};

export default Faq;
