import React from 'react';
import { withFormik } from 'formik';
import * as yup from 'yup';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { Button } from '../../elements';

const Container = styled.form`
  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker__navigation--next,
  .react-datepicker__navigation--previous {
    height: 10px;
  }
  input {
    border-radius: 10px;
    :active,
    :focus {
      outline: none;
      box-shadow: none;
      border-color: #b5b5b5;
    }
  }
`;

const AddTableTimeSlotForm = props => {
  const {
    event,
    values,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    handleBlur,
    errors,
    touched,
  } = props;

  // useEffect(() => {
  //   onChange(values.partners);
  // }, [values.partners]);
  // console.log('startTime', moment(values.startTime).unix());
  return (
    <Container className="mt-4" onSubmit={handleSubmit}>
      <div className="field">
        <DatePicker
          name="startTime"
          placeholderText="Pick start time"
          className="input is-normal"
          selected={values.startTime}
          onChange={e => {
            setFieldValue('startTime', e);
          }}
          onBlur={handleBlur}
          minDate={moment().toDate()}
          maxDate={new Date(event.eventEndTime)}
          showTimeSelect
          timeIntervals={15}
          timeCaption="Time"
          dateFormat="h:mm aa"
        />

        {errors.startTime && touched.startTime && (
          <div className="error has-text-danger my-2 has-text-left">
            {errors.startTime}
          </div>
        )}
      </div>
      <div className="field">
        <DatePicker
          name="endTime"
          placeholderText="Pick end time"
          className="input is-normal"
          selected={values.endTime}
          onChange={e => {
            setFieldValue('endTime', e);
          }}
          onBlur={handleBlur}
          minDate={moment().toDate()}
          maxDate={new Date(event.eventEndTime)}
          showTimeSelect
          timeIntervals={15}
          timeCaption="Time"
          dateFormat="h:mm aa"
        />
        {errors.endTime && touched.endTime && (
          <div className="error has-text-danger my-2 has-text-left">
            {errors.endTime}
          </div>
        )}
      </div>
      <div className="has-text-centered mt-5">
        <Button
          loading={isSubmitting}
          title="Submit"
          className="is-rounded button is-normal is-primary px-6"
          type="submit"
        />
      </div>
    </Container>
  );
};

export default withFormik({
  mapPropsToValues: () => ({
    startTime: undefined,
    endTime: undefined,
  }),
  validationSchema: ({ event }) => {
    // console.log('eventStartTime', moment(event.eventStartTime).unix());
    return yup.object().shape({
      startTime: yup
        .date()
        .required('Start time is required!')
        .test(
          'allowed',
          'Not allowed as this is outside the event start time',
          function(values) {
            return (
              moment(event.eventStartTime).unix() < moment(values).unix() ||
              moment(event.eventStartTime).unix() === moment(values).unix()
            );
          },
        ),
      endTime: yup
        .date()
        .required('End time is required!')
        .test('allowed', 'Not allowed', function(values) {
          return moment(this.parent.startTime).unix() < moment(values).unix();
        })
        .test(
          'allowed',
          'Not allowed as this is outside the event end time',
          function(values) {
            return (
              moment(event.eventEndTime).unix() > moment(values).unix() ||
              moment(event.eventEndTime).unix() === moment(values).unix()
            );
          },
        ),
    });
  },
  handleSubmit: async (values, { setSubmitting, resetForm, props }) => {
    // console.log('handle submit', values, props);
    // props.onSubmit(values);
    // setSubmitting(false);
    try {
      await props.onSubmit(values);
      resetForm({});
    } catch (error) {
      setSubmitting();
    }
  },
  displayName: 'AddTableTimeSlotForm', // helps with React DevTools
})(AddTableTimeSlotForm);
