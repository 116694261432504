import React from 'react';
import GoogleMapReact from 'google-map-react';
import styled from 'styled-components';
import EventAddress from '../updateEventDetails/EventAddress';

const ToolTip = styled.div`
  flex: 1;
  position: relative;
  z-index: 9999;

  transform: translate(-50%, -50%);
  display: inline-block;
  width: 300px;
  .text {
    width: 300px;
    color: ${props => props.theme.textLightGrey};
  }
  .text::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 8px;
    border-style: solid;
    border-color: #fff transparent transparent transparent;
  }
`;

const Point = styled.p`
  height: 65px;
  width: 65px;
  background-color: #a5227e50;
  position: absolute;
  transform: translate(-50%, -50%);
  border-radius: 100%;
  margin-left: 3px;
  color: ${props => props.theme.mainBrandColor};
  margin-top: -17px;
  span {
    border-radius: 100%;
    border: 1px solid ${props => props.theme.mainBrandColor};
    height: 1.25rem;
    width: 1.25rem;
  }
`;

const Location = ({ address }) => (
  <>
    {address.name && (
      <>
        <ToolTip className="p-4 has-background-white has-border-radius-medium has-shadow-large">
          <span className="text">
            <EventAddress address={address} />
          </span>
        </ToolTip>
        <Point className="is-flex is-align-items-center is-justify-content-center is-size-7">
          <span className="has-background-white is-flex is-align-items-center is-justify-content-center">
            <i className="fas fa-circle" />
          </span>
        </Point>
      </>
    )}
  </>
);

const Map = ({ address, location }) => {
  // console.log('location', location);
  return (
    // Important! Always set the container height explicitly
    <div style={{ height: '550px', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'key' }}
        defaultCenter={{
          lat: 39.381266,
          lng: -97.922211,
        }}
        center={{
          lat: location.lat || 39.381266,
          lng: location.lng || -97.922211,
        }}
        defaultZoom={14}
      >
        <Location lat={location.lat} lng={location.lng} address={address} />
      </GoogleMapReact>
    </div>
  );
};

export default Map;
