import React from 'react';

import { Loading } from '../elements';
import TableWrapper from '../table/TableWrapper';
import TableData from './TableData';

const invitedUsersTableHeadData = [
  {
    Header: 'Name',
    accessor: 'profile.firstName',
  },
  {
    Header: 'Email ID',
    accessor: 'email',
  },
  {
    Header: 'Contact',
    accessor: 'telephone',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
];

const InvitedUsersTable = ({
  activeTab,
  invitedUsers,
  selectAllUsers,
  loading,
  handleChange,
  // me,
  // message,
  // setMessage,
  // handleSubmitMessage,
  // handleShare,
}) => {
  const columns = React.useMemo(() => invitedUsersTableHeadData, []);
  return (
    <>
      <TableWrapper className="table-wrapper section pt-5 pb-0 has-background-white">
        {loading &&
          invitedUsers.length === 0 &&
          activeTab === 'invitedUsersList' && <Loading />}
        <TableData
          columns={columns}
          data={invitedUsers}
          activeTab={activeTab}
          handleChange={handleChange}
        />
      </TableWrapper>
      {invitedUsers.length > 0 && (
        <div className="has-text-right select-all-button">
          <button
            onClick={selectAllUsers}
            className="button is-white has-text-weight-semibold"
          >
            Select all
          </button>
        </div>
      )}
    </>
  );
};

export default InvitedUsersTable;
