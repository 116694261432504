import React from 'react';
import styled from 'styled-components';
// import { Subtitle, Button } from '../elements';
import { minBy, isEmpty, filter, find, upperCase } from 'lodash';
import reservationIcon from '../../assets/createEventIcons/reservation.png';
import ticketIcon from '../../assets/createEventIcons/ticket.png';
import guestListIcon from '../../assets/createEventIcons/guestList.png';

const currencyFormatter = require('currency-formatter');

const Container = styled.div`
  .is-flex {
    flex-wrap: wrap;
  }
  .right {
    display: grid;
    justify-content: center;
  }

  @media screen and (max-width: 768px) {
    .media-content {
      overflow-y: hidden;
    }
  }
`;

const Service = styled.div`
  box-shadow: -3px -4px 18px rgba(0, 0, 0, 0.1), 3px 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #f7ebf3;
  .title {
    color: ${props => props.theme.mainBrandColor} !important;
  }
  .tag {
    background: ${props => props.theme.mainBrandColor};
  }
`;

const EventServices = ({ pricing, currency }) => {
  const guestList = filter(pricing.guestList, o => {
    return o.isVisible && o.quantity > 0;
  });
  const ticket = filter(pricing.ticket, o => {
    return o.isVisible && o.quantity > 0;
  });
  const reservations = filter(pricing.reservations, o => {
    return o.isVisible && o.quantity > 0;
  });
  const virtual = filter(pricing.virtual, o => {
    return o.isVisible && o.quantity > 0;
  });

  const services = [
    {
      id: 1,
      icon: ticketIcon,
      title: 'Ticket',
      isVisible: !isEmpty(ticket),
      isPrivate: find(pricing.privateServices, o => {
        return o === 'ticket';
      }),
      price: isEmpty(ticket)
        ? 0
        : minBy(ticket, o => {
            return o.price;
          }).price,
    },
    {
      id: 2,
      icon: reservationIcon,
      title: 'Reservations',
      isVisible: !isEmpty(reservations),
      isPrivate: find(pricing.privateServices, o => {
        return o === 'reservations';
      }),
      price: isEmpty(reservations)
        ? 0
        : minBy(reservations, o => {
            return o.price;
          }).price,
    },
    {
      id: 3,
      icon: guestListIcon,
      title: 'Guestlist',
      isVisible: !isEmpty(guestList),
      isPrivate: find(pricing.privateServices, o => {
        return o === 'guestList';
      }),
      price: isEmpty(guestList)
        ? 0
        : minBy(guestList, o => {
            return o.price;
          }).price,
    },
    {
      id: 4,
      icon: ticketIcon,
      title: 'Virtual',
      isVisible: !isEmpty(virtual),
      isPrivate: find(pricing.privateServices, o => {
        return o === 'virtual';
      }),
      price: isEmpty(virtual)
        ? 0
        : minBy(virtual, o => {
            return o.price;
          }).price || 0,
    },
  ];

  const allServices = filter(services, { isVisible: true });
  return (
    <Container>
      <div className="columns">
        {allServices.map(item => (
          <div key={item.id} className="column is-3">
            <Service className="tile is-child box is-flex is-justify-content-space-between	 pr-0 pl-2">
              <div className="media">
                <div className="media-left">
                  <figure className="image is-32x32">
                    <img src={item.icon} alt="ticket-icon" />
                  </figure>
                </div>
                <div className="media-content">
                  <p className="subtitle is-6 is-size-7-mobile ">
                    {item.title} {item.visible}
                  </p>
                  <p className="title is-4 is-size-5-mobile has-text-weight-normal">
                    <span className="title is-6 mr-2">From</span>
                    {currencyFormatter.format(item.price, {
                      code: upperCase(currency),
                    })}
                  </p>
                </div>
              </div>
              <span className="tag has-text-white is-capitalize">
                {item.isPrivate ? 'Private' : 'Open'}
              </span>
            </Service>
          </div>
        ))}
      </div>
    </Container>
  );
};

export default EventServices;
