import React, { useState } from 'react';
import styled from 'styled-components';
import { sortBy, lowerFirst } from 'lodash';

import { Modal } from '../../elements';
import AddPartnerForm from '../forms/AddPartnerForm';
import AddUserForm from '../../form/AddUserForm';

const Container = styled.form`
  button.is-fullwidth {
    color: ${props => props.theme.textColorLight};
    padding: 0 2rem !important;
  }
`;
const AddPartnersModal = ({
  title,
  currency,
  onClose,
  isActive,
  handleAddPartner,
  handleCreatePartner,
  // followers,
  hideCommissionOptions,
  filteredUsers,
  onSearch,
  loading,
}) => {
  const [addNewPartnerForm, setAddNewPartnerForm] = useState(false);
  const options = sortBy(filteredUsers, [
    o => {
      return o.profile && o.profile.firstName
        ? lowerFirst(o.profile.firstName)
        : lowerFirst(o.username);
    },
  ]);

  // const options =
  //   title === 'Add Partners' || title === 'Add Djs' ? filteredUsers : followers;
  // console.log('options', options);
  return (
    <Modal
      isActive={isActive}
      onClose={() => {
        setAddNewPartnerForm(false);
        onClose();
      }}
      title={title}
    >
      <Container className="mb-3">
        <button
          onClick={() => setAddNewPartnerForm(!addNewPartnerForm)}
          type="button"
          className="button is-fullwidth has-border-radius-medium is-justify-content-space-between	mb-3 mt-3"
        >
          <span>Add New</span>
          <span className="icon is-small">
            <i className="fas fa-plus" />
          </span>
        </button>
        {addNewPartnerForm && (
          <AddUserForm
            emailPlaceholder="Business Email"
            onSubmit={async formData => {
              await handleCreatePartner(formData);
              setAddNewPartnerForm(false);
            }}
            onCancelPress={() => setAddNewPartnerForm(false)}
          />
        )}
      </Container>
      {!addNewPartnerForm && (
        <div className={hideCommissionOptions ? 'mb-6 pt-1 pb-5' : 'mb-0'}>
          <AddPartnerForm
            loading={loading}
            onInputChange={onSearch}
            isPartnerModal={title === 'Add Partners'}
            hideCommissionOptions={hideCommissionOptions}
            currency={currency}
            options={options}
            onSubmit={handleAddPartner}
          />
        </div>
      )}
    </Modal>
  );
};
export default AddPartnersModal;
