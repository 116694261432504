import React from 'react';
import styled from 'styled-components';

const Box = styled.div`
  .box {
    min-height: 290px;
    box-shadow: ${props =>
      props.isActive ? '4px 4px 50px rgba(0, 0, 0, 0.1)' : 'none'};
    border: 1px solid
      ${props =>
        props.isActive
          ? props.theme.mainBrandColor
          : props.theme.backgroundLight};
    color: ${props => (props.isActive ? '#B7007B' : '#cdcdcd')};
    transition: all 0.1s ease-in-out;
    background: ${props =>
      props.isActive ? '#fff' : props.theme.backgroundLight};
  }
  .inner-wrapper {
    margin: auto 0;
  }
`;

const Icon = styled.i`
  font-size: 5rem;
`;

const qrCodeTypes = [
  {
    id: 1,
    title: 'Walk-in',
    type: 'walkIn',
    icon: 'fas fa-running',
  },
  {
    id: 2,
    title: 'Self check-in',
    type: 'selfCheckIn',
    icon: 'fas fa-hand-pointer',
  },
];

const QrCodeTypes = ({ onClick, isActive }) => (
  <div className="columns is-multiline is-centered is-variable is-5 mt-2 mx-0 qrCode-items">
    {qrCodeTypes.map(item => (
      <Box
        key={item.id}
        isActive={isActive === item.type}
        onClick={() => {
          onClick(item.type);
        }}
        className="column is-one-third-widescreen is-half-tablet has-text-centered mb-4"
      >
        <div className="box is-flex is-justify-content-center is-align-items-center  has-border-radius-medium">
          <div className="inner-wrapper">
            <Icon iconColor={item.iconColor} className={`${item.icon} `} />
            <p className="is-size-3 has-text-weight-semibold  mt-4 mb-4">
              {item.title}
            </p>
          </div>
        </div>
      </Box>
    ))}
  </div>
);
export default QrCodeTypes;
