import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useQuery, useMutation } from 'react-apollo';
import gql from 'graphql-tag';
import swal from 'sweetalert';

import { filter } from 'lodash';
import { Loading } from '../elements';
import EventInformationForm from './forms/EventInformationForm';
import EventLocationForm from './forms/EventLocationForm';
import EventTypeDetailsForm from './forms/EventTypeDetailsForm';
import Partners from './Partners';
import EntryType from './EntryType';
import ProgressSteps from './ProgressSteps';
import EventView from './EventView';

const Container = styled.div`
  padding: 2.25rem;
  padding-top: 1.5rem;
  height: 85vh;
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  .subtitle {
    color: #858585;
  }

  .error,
  .has-text-danger {
    font-size: 0.75rem;
  }
  @media screen and (max-width: 768px) {
    padding: 2rem 1.5rem !important;
    .button.is-medium {
      width: 75%;
    }
    input {
      height: auto !important;
    }
  }
`;

const updateEventMutation = gql`
  mutation updateEvent($id: ID!, $input: EventInput) {
    updateEvent(id: $id, input: $input) {
      id
    }
  }
`;

const singleEventQuery = gql`
  query singleEvent($id: ID!) {
    singleEvent(id: $id) {
      id
      name
      description
      featuredImage
      eventStartTime
      eventEndTime
      autoStart
      isRequiredUserVerification
      venueAddress {
        name
        addressLine1
        addressLine2
        city
        state
        postcode
        country
        location {
          coordinates
        }
        formattedAddress
      }
      partyType
      dressCode
      musicPolicy
      eventRules
      attendingPeopleAge
      repeatEvent
      # isPrivate
      isVisible
      status
      doorPickers {
        user {
          id
        }
        commission
      }
      djs {
        commission
        user {
          id
          username
          rating
          profile {
            firstName
            lastName
            image
          }
        }
      }
      promoters {
        user {
          id
          username
          rating
          profile {
            firstName
            lastName
            image
          }
        }
        commission
      }
      influencers {
        user {
          id
          username
          rating
          profile {
            firstName
            lastName
            image
          }
        }
        commission
      }
      partners {
        user {
          id
          username
          rating
          profile {
            firstName
            lastName
            image
          }
        }
        commission
      }
      currency
      pricing {
        primary
        privateServices
        guestList {
          label
          price
          quantity
          bookedQuantity
          isVisible
          isConfirmed
          rules
        }
        reservations {
          label
          details
          price
          quantity
          bookedQuantity
          isVisible
          isConfirmed
          allowedPeopleLimit
          rules
          timeSlots {
            startTime
            endTime
          }
        }
        ticket {
          label
          details
          price
          quantity
          bookedQuantity
          isVisible
          isConfirmed
          rules
          extraInfo
        }
        virtual {
          label
          details
          price
          quantity
          bookedQuantity
          isVisible
          isConfirmed
          rules
          extraInfo
        }
      }
    }
  }
`;

const UpdateEventDetails = ({ match, history }) => {
  const { eventId } = match.params;
  const { data, loading, error, refetch } = useQuery(singleEventQuery, {
    fetchPolicy: 'no-cache',
    variables: { id: eventId },
  });
  // console.log('event', data);
  const [executeMutation, res] = useMutation(updateEventMutation);

  const event = data && data.singleEvent ? data.singleEvent : {};

  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    if (event.status === 'open') {
      setActiveStep(5);
    }
  }, [event.status]);

  const goBack = () => {
    setActiveStep(activeStep - 1);
  };

  const goNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleChange = async formData => {
    if (formData) {
      const resp = await executeMutation({
        variables: {
          id: eventId,
          input: formData,
        },
      });
      if (resp) {
        refetch();
      }
    }
  };
  const handleSubmit = async formData => {
    // console.log(formData);
    const resp = await executeMutation({
      variables: {
        id: eventId,
        input: formData,
      },
    });
    if (resp) {
      refetch();
      if (formData.status) {
        swal({
          title: 'Good Job',
          text: `Your event is ${
            formData.status === 'open' ? 'published' : 'updated'
          }`,
          icon: 'success',
        });
        history.push(`/guest-list/${event.id}`);
      }
      if (activeStep < 5 && !formData.currency) {
        goNext();
      }
    }
  };
  // const updatePartners = async formData => {
  //   const { partnersArray, hasRemove, partnerType } = formData;
  //   // console.log(partnersArray, hasRemove, partnerType, formData);
  //   const resp = await executeMutation({
  //     variables: {
  //       id: eventId,
  //       input: partnersArray,
  //     },
  //   });
  //   if (resp) {
  //     // goNext();
  //     refetch();
  //     if (hasRemove) {
  //       swal({
  //         title: '',
  //         text: `${partnerType} removed Successfully!`,
  //         icon: 'success',
  //       });
  //     } else {
  //       await swal({
  //         title: '',
  //         text: `${partnerType} added Successfully!`,
  //         icon: 'success',
  //       });
  //     }
  //   }
  // };

  useEffect(() => {
    if (res.error) {
      swal('Something went wrong!', res.error.message, 'error');
    }
    // if (error) {
    //   swal('Oops!', error.message, 'error');
    // }
  }, [res.error, error]);

  const activeGuestListPricing =
    event.pricing && event.pricing.guestList
      ? filter(event.pricing.guestList, o => {
          return o.isVisible && o.quantity > 0;
        })
      : [];
  const activeReservationPricing =
    event.pricing && event.pricing.reservation
      ? filter(event.pricing.reservations, o => {
          return (
            o.label &&
            o.details &&
            o.isVisible &&
            o.quantity > 0 &&
            o.allowedPeopleLimit > 0 &&
            o.timeSlots.length > 0
          );
        })
      : [];
  const activeTicketPricing =
    event.pricing && event.pricing.ticket
      ? filter(event.pricing.ticket, o => {
          return o.isVisible && o.quantity > 0;
        })
      : [];
  const activeVirtualPricing =
    event.pricing && event.pricing.virtual
      ? filter(event.pricing.virtual, o => {
          return o.isVisible && o.quantity > 0;
        })
      : [];
  const isVirtualOnly =
    activeGuestListPricing.length === 0 &&
    activeReservationPricing.length === 0 &&
    activeTicketPricing.length === 0 &&
    activeVirtualPricing.length > 0;

  return (
    <Container className="section has-background-white has-shadow-large has-border-radius-medium mt-1">
      <h4 className="subtitle is-5 has-text-weight-semibold">Create Event</h4>
      <ProgressSteps
        activeStep={activeStep}
        setActiveStep={val => {
          if (event && event.status !== 'open' && val > activeStep) {
            swal({
              text: 'Please fill all details step by step and publish event',
              icon: 'info',
            });
          } else {
            setActiveStep(val);
          }
        }}
      />
      {loading || !data || !event.name ? (
        <Loading />
      ) : (
        <>
          {activeStep === 0 && (
            <EventInformationForm
              initialValues={event}
              onSubmit={handleSubmit}
              onChange={handleChange}
            />
          )}
          {activeStep === 1 && (
            <EntryType
              loading={res.loading}
              event={event}
              handleBackPress={goBack}
              onSubmit={handleSubmit}
              onChange={handleChange}
              handleChangeCurrency={handleSubmit}
            />
          )}
          {activeStep === 2 && (
            <div className="mt-5">
              <EventTypeDetailsForm
                initialValues={event}
                handleBackPress={goBack}
                onSubmit={handleSubmit}
                onChange={handleChange}
              />
            </div>
          )}
          {activeStep === 3 && (
            <EventLocationForm
              isVirtualOnly={isVirtualOnly}
              enableReinitialize
              initialValues={event}
              handleBackPress={goBack}
              onSubmit={handleSubmit}
              onChange={handleChange}
              handleSkipStep={() => setActiveStep(activeStep + 1)}
            />
          )}
          {activeStep === 4 && (
            <Partners
              event={event}
              refetch={() => refetch()}
              // onChange={updatePartners}
              handleBackPress={goBack}
              handleNextPress={goNext}
            />
          )}
          {activeStep === 5 && (
            <EventView
              event={event}
              handleBackPress={goBack}
              setActiveStep={step => setActiveStep(step)}
              onPublishPress={() => handleSubmit({ status: 'open' })}
              onDraftPress={() => handleSubmit({ status: 'draft' })}
            />
          )}
        </>
      )}
    </Container>
  );
};

export default UpdateEventDetails;
