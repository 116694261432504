import React from 'react';
import { withFormik } from 'formik';
import * as yup from 'yup';
import styled from 'styled-components';
import { words } from 'lodash';
import { Button, TextBox, MultipleSelectInput, Select } from '../../elements';
import config from '../../../utils/config';

const Container = styled.form`
  .label {
    color: ${props => props.theme.textLightGrey};
  }
`;

const EventTypeDetailsForm = ({
  values,
  touched,
  errors,
  onChange,
  isSubmitting,
  setFieldValue,
  handleSubmit,
  handleChange,
  handleBlur,
  handleBackPress,
}) => {
  return (
    <Container onSubmit={handleSubmit} className="mt-3">
      <div className="columns ">
        <div className="column is-4 ">
          <TextBox
            isMedium
            rows="7"
            hasBackgroudColor
            label="Event Info"
            placeholder="Add Event Information...."
            name="description"
            type="text"
            value={values.description}
            onChange={handleChange}
            onBlur={() => {
              handleBlur();
              onChange({ description: values.description });
            }}
            errors={
              errors.description && touched.description
                ? errors.description
                : undefined
            }
          />
          {/* <InstagramSearchInput
            isMedium
            // label="Event DJ (@ Instagram)"
            label="Add user or add manually"
            placeholder="Add Event Dj"
            name="dj"
            value={values.djs}
            type="text"
            handleChange={djs => setFieldValue('djs', djs)}
            onBlur={() => {
              handleBlur();
              // onChange({ djs: values.djs.map(item => item.value) });
            }}
          /> */}
        </div>
        <div className="column is-4 is-offset-2">
          <MultipleSelectInput
            infoText="What social experience are you creating"
            isMedium
            limit={3}
            selectedOptions={values.partyType}
            label="Event Type"
            name="partyType"
            value={values.partyType}
            onChange={value => setFieldValue('partyType', value)}
            onBlur={() => {
              handleBlur();
              onChange({
                partyType: values.partyType
                  ? values.partyType.map(item => item.value)
                  : [],
              });
            }}
            options={
              values.partyType && values.partyType.length === 3
                ? []
                : config.eventDetails.partyType
            }
            errors={
              errors.partyType && touched.partyType
                ? errors.partyType
                : undefined
            }
          />
          <MultipleSelectInput
            infoText="Information related to event rules for this event"
            isMedium
            limit={5}
            selectedOptions={values.eventRules}
            label="Event Rules"
            name="eventRules"
            value={values.eventRules}
            onChange={value => setFieldValue('eventRules', value)}
            onBlur={() => {
              handleBlur();
              onChange({
                eventRules: values.eventRules
                  ? values.eventRules.map(item => item.value)
                  : [],
              });
            }}
            options={
              values.eventRules && values.eventRules.length === 5
                ? []
                : config.eventDetails.rules
            }
            errors={
              errors.eventRules && touched.eventRules
                ? errors.eventRules
                : undefined
            }
          />
          <MultipleSelectInput
            infoText="Information related to dress code for this event"
            isMedium
            limit={3}
            selectedOptions={values.dressCode}
            label="Dress Code"
            name="dressCode"
            defaultValue={values.dressCode}
            value={values.dressCode}
            onChange={value => setFieldValue('dressCode', value)}
            onBlur={() => {
              handleBlur();
              onChange({
                dressCode: values.dressCode
                  ? values.dressCode.map(item => item.value)
                  : [],
              });
            }}
            options={
              values.dressCode && values.dressCode.length === 3
                ? []
                : config.eventDetails.dressCode
            }
            errors={
              errors.dressCode && touched.dressCode
                ? errors.dressCode
                : undefined
            }
          />
          <MultipleSelectInput
            infoText="Information related to the type of music the event will play at the event."
            isMedium
            limit={3}
            selectedOptions={values.musicPolicy}
            label="Music Policy"
            name="musicPolicy"
            value={values.musicPolicy}
            onChange={value => setFieldValue('musicPolicy', value)}
            onBlur={() => {
              handleBlur();
              onChange({
                musicPolicy: values.musicPolicy
                  ? values.musicPolicy.map(item => item.value)
                  : [],
              });
            }}
            options={
              values.musicPolicy && values.musicPolicy.length === 3
                ? []
                : config.eventDetails.musicPolicy
            }
            errors={
              errors.musicPolicy && touched.musicPolicy
                ? errors.musicPolicy
                : undefined
            }
          />
          <Select
            infoText="What is the age policy for this event"
            isMedium
            label="Age"
            options={config.eventDetails.attendingPeopleAge}
            name="attendingPeopleAge"
            type="text"
            placeholder="Select Allowed Age"
            value={values.attendingPeopleAge}
            onChange={handleChange}
            onBlur={() => {
              handleBlur();
              onChange({ attendingPeopleAge: values.attendingPeopleAge });
            }}
            errors={
              errors.attendingPeopleAge && touched.attendingPeopleAge
                ? errors.attendingPeopleAge
                : undefined
            }
          />
        </div>
      </div>

      <p className="buttons is-justify-content-center mt-6 my-6">
        <Button
          onClick={handleBackPress}
          title="Back"
          className="is-rounded button is-medium  px-6"
          type="button"
        />
        <Button
          loading={isSubmitting}
          title="Next"
          className="is-rounded button is-medium is-primary px-6"
          type="submit"
        />
      </p>
    </Container>
  );
};
export default withFormik({
  mapPropsToValues: ({ initialValues }) => ({
    description: initialValues.description || '',
    djs: initialValues.djs
      ? initialValues.djs.map(item => ({ ...item.user }))
      : [],
    partyType:
      initialValues.partyType.length > 0
        ? initialValues.partyType.map(item => ({
            label: item,
            value: item,
          }))
        : [],
    eventRules:
      initialValues.eventRules.length > 0
        ? initialValues.eventRules.map(item => ({
            label: item,
            value: item,
          }))
        : [],
    dressCode:
      initialValues.dressCode.length > 0
        ? initialValues.dressCode.map(item => ({
            label: item,
            value: item,
          }))
        : [],
    musicPolicy:
      initialValues.musicPolicy.length > 0
        ? initialValues.musicPolicy.map(item => ({
            label: item,
            value: item,
          }))
        : [],
    attendingPeopleAge: initialValues.attendingPeopleAge || '',
  }),
  validationSchema: yup.object().shape({
    description: yup
      .string()
      .required('Event info is required')
      .test('len', 'Minimum 10 words required', val => {
        const wordCount = words(val, /[^ ]+/g);
        return wordCount.length > 9;
      })
      .test('len', 'Maximum words limit 250', val => {
        const wordCount = words(val, /[^ ]+/g);
        return wordCount.length < 250;
      })
      .nullable(),
    partyType: yup
      .array()
      .required('Event Type is required')
      .nullable(),
    eventRules: yup
      .array()
      .required('Rules is required')
      .nullable(),
    dressCode: yup
      .array()
      .required('Dress code is required')
      .nullable(),
    musicPolicy: yup
      .array()
      .required('Music policy is required')
      .nullable(),
    attendingPeopleAge: yup
      .string()
      .required('Age is required')
      .nullable(),
  }),

  handleSubmit: (values, { setSubmitting, props }) => {
    // console.log('handle submit', values, props, setSubmitting);
    // eslint-disable-next-line no-param-reassign
    delete values.djs;
    props.onSubmit({
      ...values,
      partyType: values.partyType.map(item => item.value),
      eventRules: values.eventRules.map(item => item.value),
      dressCode: values.dressCode.map(item => item.value),
      musicPolicy: values.musicPolicy.map(item => item.value),
    });
    setSubmitting(false);
  },
  displayName: 'EventTypeDetailsForm', // helps with React DevTools
})(EventTypeDetailsForm);
