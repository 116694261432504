import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  .select.is-small,
  input.is-small {
    vertical-align: middle;
    :after {
      border-color: ${props => props.theme.placeholderColor} !important;
    }
  }

  select,
  input {
    font-size: 10px !important;
    max-width: 70px;
    :active,
    :focus {
      box-shadow: none;
      outline: none;
      border-color: #77777750;
    }
  }
`;

const Select = ({ className, placeholder, options, ...props }) => {
  return (
    <Container className={`control ${className}`}>
      <div className="select is-small">
        <select {...props}>
          <option defaultValue value={null}>
            {placeholder}
          </option>
          {options &&
            options.map(item => (
              <option key={item.value} value={item.value}>
                {item.label ? item.label : item.value}
              </option>
            ))}
        </select>
      </div>
    </Container>
  );
};

export default Select;
