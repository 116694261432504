import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  .card-text {
    ${'' /* font-family: ${props => props.theme.subHeaderFontFamily}; */}
    color: ${props => props.theme.subHeaderColor};
    font-size: 16px;
    line-height: 24px;
  }
`;

const ContentWithIcon = ({ data, icon, className }) => (
  <Wrapper className={className}>
    <p className="card-text">
      <i className={icon} /> {data}
    </p>
  </Wrapper>
);

ContentWithIcon.defaultProps = {
  data: 'Stranger',
  icon: 'fas fa-money-bill-wave-alt',
};

ContentWithIcon.propTypes = {
  data: PropTypes.string,
  icon: PropTypes.string,
};

export default ContentWithIcon;
