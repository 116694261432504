/* eslint-disable no-plusplus */
/* eslint-disable no-underscore-dangle */
/* eslint-disable radix */
import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';

import { Loading } from '../elements';
import {
  generateLastTwelveMonths,
  convertNumberToMonth,
  generateHours,
} from '../../utils/helpers';

const styles = {
  fill: true,
  background:
    'linear-gradient(180deg, #713BDB -47.71%, rgba(255, 255, 255, 0) 100%)',
};

const VisitInsightChart = ({ data, loading, options, isSingleEvent }) => {
  // const obj = {
  //   labels: [],
  //   pending: [],
  //   authorized: [],
  //   checkedIn: [],
  //   rejected: [],
  //   refunded: [],
  // };
  // for (let i = 0; i < 24; i++) {
  //   obj.labels.push(
  //     `${i.toLocaleString('en-US', {
  //       minimumIntegerDigits: 2,
  //       useGrouping: false,
  //     })}:00`,
  //   );
  //   obj.pending.push(0);
  //   obj.authorized.push(0);
  //   obj.checkedIn.push(0);
  //   obj.rejected.push(0);
  //   obj.refunded.push(0);
  // }
  // data.forEach(item => {
  //   const i = parseInt(item.label);
  //   obj.pending[i] = item.pending;
  //   obj.authorized[i] = item.authorized;
  //   obj.refunded[i] = item.refunded;
  //   obj.checkedIn[i] = item.checkedIn;
  //   obj.rejected[i] = item.rejected;
  // });

  const [overAll, setOverAll] = useState({});

  useEffect(() => {
    if (data && data.length !== 0) {
      const param = {
        checkedIn: 0,
        rejected: 0,
        pending: 0,
        authorized: 0,
        refunded: 0,
      };
      // const units = generateLastTwelveMonths(param);
      const units = isSingleEvent
        ? generateHours(param)
        : generateLastTwelveMonths(param);
      const lbl = [];
      const checkedIn = [];
      const rejected = [];
      const pending = [];
      const authorized = [];
      const refunded = [];

      data.forEach(item => {
        if (Object.keys(units).includes(item.label)) {
          units[item.label] = {
            checkedIn: item.checkedIn,
            rejected: item.rejected,
            pending: item.pending,
            authorized: item.authorized,
            refunded: item.refunded,
          };
        }
      });

      if (isSingleEvent) {
        Object.keys(units)
          .sort((a, b) => b - a)
          .forEach(e => {
            lbl.push(`${e}:00`);
            checkedIn.push(units[e].checkedIn);
            rejected.push(units[e].rejected);
            pending.push(units[e].pending);
            authorized.push(units[e].authorized);
            refunded.push(units[e].refunded);
          });
      } else {
        Object.keys(units).forEach(e => {
          lbl.push(convertNumberToMonth(e));
          checkedIn.push(units[e].checkedIn);
          rejected.push(units[e].rejected);
          pending.push(units[e].pending);
          authorized.push(units[e].authorized);
          refunded.push(units[e].refunded);
        });
      }
      // Object.keys(units).forEach(e => {
      //   lbl.push(convertNumberToMonth(e));
      //   checkedIn.push(units[e].checkedIn);
      //   rejected.push(units[e].rejected);
      //   pending.push(units[e].pending);
      //   authorized.push(units[e].authorized);
      //   refunded.push(units[e].refunded);
      // });

      setOverAll({
        labels: lbl.reverse(),
        datasets: [
          {
            label: 'Pending',
            data: pending.reverse(),
            backgroundColor: '#33D69F',
            ...styles,
          },
          {
            label: 'Listed',
            data: authorized.reverse(),
            backgroundColor: '#631FE7',
            ...styles,
          },
          {
            label: 'Accepted',
            data: checkedIn.reverse(),
            backgroundColor: '#F27A54',
            ...styles,
          },
          {
            label: 'Denied',
            data: rejected.reverse(),
            backgroundColor: '#A5227E',
            ...styles,
          },
          {
            label: 'Refunded',
            data: refunded.reverse(),
            fill: true,
            backgroundColor: '#FF0000 ',
            borderColor: '#FF0000',
          },
        ],
      });
    }
  }, [data]);

  if (loading && (!data || data.length === 0)) return <Loading />;

  // if (error && !data) return `Error! ${error.message}`;

  if (!data || data.length === 0) {
    return (
      <div className="column">
        <p className="has-text-centered has-text-weight-semibold is-size-4">
          No data
        </p>
      </div>
    );
  }
  // const overAll = {
  //   labels: obj.labels,
  //   datasets: [
  //     {
  //       label: 'Pending',
  //       data: obj.pending,
  //       borderColor: '#33D69F',
  //       ...styles,
  //     },
  //     {
  //       label: 'Listed',
  //       data: obj.authorized,
  //       borderColor: '#631FE7',
  //       ...styles,
  //     },
  //     {
  //       label: 'Accepted',
  //       data: obj.checkedIn,
  //       borderColor: '#F27A54',
  //       ...styles,
  //     },
  //     {
  //       label: 'Denied',
  //       data: obj.rejected,
  //       borderColor: '#A5227E',
  //       ...styles,
  //     },
  //     {
  //       label: 'Refunded',
  //       data: obj.refunded,
  //       fill: true,
  //       background:
  //         'linear-gradient(180deg, #FF0000 -47.71%, rgba(255, 255, 255, 0) 100%)',
  //       borderColor: '#FF0000',
  //     },
  //   ],
  // };

  return <Bar data={overAll} options={options} backgroundColor="#fff" />;
};

export default VisitInsightChart;
