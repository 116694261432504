import React from 'react';
import ReactLoading from 'react-loading';
import styled from 'styled-components';

import config from '../../utils/config';

const Container = styled.div`
  .loading {
    margin: auto;
  }
  width: 100%;
  margin: 2rem 0;
`;

const Loading = () => (
  <Container className="hero has-text-centered">
    <div className="hero-body">
      <ReactLoading
        type="bars"
        className="loading"
        color={config.secondaryColor}
        height={50}
        width={50}
      />
    </div>
  </Container>
);
export default Loading;
