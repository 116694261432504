import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div``;

const Input = ({ title, className }) => (
  <Wrapper>
    <p className={`title ${className}`}>{title}</p>
  </Wrapper>
);

export default Input;
