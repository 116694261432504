import React from 'react';
import styled from 'styled-components';
import CountUp from 'react-countup';

const Container = styled.div`
  margin-bottom: 2.25rem !important;
  span {
    font-size: 2rem;
    color: black;
    font-weight: bold;
  }
  @media only screen and (max-width: 768px) {
    span {
      font-size: 1.25rem; !important;
    }
  }
`;

const SubscribersCountRow = ({
  followersCount,
  listCount,
  attendanceCount,
  restrictionsCount,
}) => (
  <Container className="columns  is-flex-mobile has-text-centered">
    <div className="column">
      <CountUp delay={2} end={followersCount || 0} />
      <h3 className="has-text-black is-size-7-mobile">Followers</h3>
    </div>
    <div className="column">
      <CountUp delay={2} end={listCount || 0} />
      <h3 className="has-text-black is-size-7-mobile">Total List</h3>
    </div>
    <div className="column">
      <CountUp delay={2} end={attendanceCount || 0} />
      <h3 className="has-text-black is-size-7-mobile">Total Attendance</h3>
    </div>
    <div className="column">
      <CountUp delay={2} end={restrictionsCount || 0} />
      <h3 className="has-text-black is-size-7-mobile">Restrictions</h3>
    </div>
  </Container>
);

export default SubscribersCountRow;
