/* eslint-disable no-plusplus */
import React, { useState } from 'react';
import styled from 'styled-components';
import { withFormik } from 'formik';
import * as yup from 'yup';
import SelectMulti from 'react-select';
import PlacesAutocomplete from 'react-places-autocomplete';
import { phone } from 'phone';

import {
  Field,
  TextBox,
  UploadImageInput,
  MultipleSelectInput,
} from '../elements';
import Button from '../elements/Button';
import config from '../../utils/config';
import SelectCurrencyForm from '../updateEventDetails/forms/SelectCurrencyForm';
import { moment } from '../../utils/helpers';

const Container = styled.div`
  .input,
  .label,
  label,
  .select select {
    color: ${config.placeholderColor};
  }
  .autocomplete-wrapper {
    position: relative;
  }
  .autocomplete-dropdown-container {
    border: 1px solid #b5b5b5;
    position: absolute;
    width: 100%;
    z-index: 99;
    display: block;
    div {
      padding: 0.75rem 2rem;
    }
    &.hidden {
      display: none;
    }
  }
  .text-under-image {
    width: 50%;
    margin: 20px auto 0;
    color: #858585;
    text-align: center;
    strong {
      color: red;
    }
  }
  .error {
    color: red;
  }
  .profile-image {
    margin-top: -5rem;
  }
`;

const Background = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  > .column {
    margin-bottom: 5px;
  }
`;

const UpdateProfileForm = ({
  values,
  handleChange,
  handleBlur,
  handleSubmit,
  loading,
  errors,
  touched,
  setFieldValue,
  me,
}) => {
  const genreArray = [];
  // console.log('errors', errors);
  const [selectedGenre, setSelectedGenre] = useState(genreArray);

  if (values.genre != null && values.genre.length > 1) {
    values.genre.split(',').forEach(item => {
      genreArray.push({ label: item, value: item });
    });
  }

  const ArrayToString = (genre, e) => {
    if (e != null) {
      for (let i = 0; i < e.length; i++) {
        genre.push(e[i].value);
        // console.log('e', e[i].value);
        // console.log('genre', genre);
      }
    }
  };
  // console.log('values', values);
  return (
    <Container className="section">
      <Background className="columns">
        <div className="column is-half">
          <div className="is-hidden-mobile">
            <UploadImageInput
              size="is-5by3"
              value={values.coverImage}
              onChange={image => setFieldValue('coverImage', image)}
            />
          </div>
          <div className="is-hidden-tablet">
            <UploadImageInput
              size="auto"
              value={values.coverImage}
              onChange={image => setFieldValue('coverImage', image)}
            />
          </div>

          <div className="profile-image">
            <UploadImageInput
              aspectRatio={4 / 4}
              flex
              circle
              value={values.image}
              onChange={image => setFieldValue('image', image)}
            />
            {errors.image && touched.image ? (
              <div className="error has-text-centered mt-1">{errors.image}</div>
            ) : null}
          </div>
          {errors.coverImage && touched.coverImage ? (
            <div className="error has-text-centered mt-1">
              {errors.coverImage}
            </div>
          ) : null}
        </div>
        <p className="text-under-image">
          <strong>*</strong> The uploaded image must be 500px wide and 500px
          long
        </p>
        <p className="text-under-image">
          <strong>*</strong> Cover image for brand profile and logo for brand
          profile picture
        </p>
        <div className="column is-half">
          <label className="label has-text-weight-normal is-capitalized mb-3">
            What is your role?
          </label>
          <SelectMulti
            defaultValue={{
              label: values.businessRole,
              value: values.businessRole,
            }}
            onChange={e => setFieldValue('businessRole', e.value)}
            options={config.businessRoles}
          />

          {errors.businessRole && touched.businessRole ? (
            <div className="error">{errors.businessRole}</div>
          ) : null}
        </div>
        <div className="column is-half">
          <Field
            label="Business Name*"
            name="firstName"
            value={values.firstName}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {errors.firstName && touched.firstName ? (
            <div className="error">{errors.firstName}</div>
          ) : null}
        </div>
        <div className="column is-half">
          <Field
            label="Username*"
            name="username"
            value={values.username}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {errors.username && touched.username ? (
            <div className="error">{errors.username}</div>
          ) : null}
        </div>
        <div className="column is-half">
          <Field
            label="Email*"
            name="email"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={!!me?.email}
          />
          {errors.email && touched.email ? (
            <div className="error">{errors.email}</div>
          ) : null}
        </div>
        <div className="column is-half">
          <Field
            label="Phone number*"
            name="telephone"
            value={values.telephone}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {errors.telephone && touched.telephone ? (
            <div className="error">{errors.telephone}</div>
          ) : null}
        </div>

        <div className="column is-half">
          <label className="label has-text-weight-normal is-capitalized mb-3">
            Business Type
          </label>
          <MultipleSelectInput
            defaultValue={selectedGenre}
            isMulti
            noOptionsMessage={() => {
              return selectedGenre.length === 3
                ? 'You have selected the max options available!'
                : 'No options available';
            }}
            onChange={e => {
              setSelectedGenre(e);
              const genre = [];
              ArrayToString(genre, e);
              // console.log('genre', genre.toString());
              setFieldValue('genre', genre.toString());
            }}
            options={
              selectedGenre && selectedGenre.length === 3
                ? []
                : config.businessTypes
            }
          />

          {errors.genre && touched.genre ? (
            <div className="error">{errors.genre}</div>
          ) : null}
        </div>
        <div className="column is-half">
          <label className="label has-text-weight-normal is-capitalized mb-3">
            Default Currency
          </label>
          <SelectCurrencyForm
            isSecondary
            onSubmit={val => setFieldValue('currency', val.currency)}
            initialValues={values}
          />
        </div>
        <div className="column is-half">
          <label className="label has-text-weight-normal is-capitalized mb-3">
            Business Location
          </label>
          <PlacesAutocomplete
            value={values.location}
            onChange={e => setFieldValue('location', e)}
            onSelect={e => setFieldValue('location', e)}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              lloading,
            }) => (
              <div className="autocomplete-wrapper">
                <Field
                  {...getInputProps({
                    placeholder: 'Location',
                    className: 'input is-rounded',
                  })}
                />
                <div
                  className={`autocomplete-dropdown-container ${
                    !suggestions.length ? 'hidden' : ''
                  }`}
                >
                  {lloading && <div>Loading...</div>}
                  {suggestions.map(suggestion => {
                    const className = suggestion.active
                      ? 'suggestion-item--active'
                      : 'suggestion-item';
                    // inline style for demonstration purpose
                    const style = suggestion.active
                      ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                      : { backgroundColor: '#ffffff', cursor: 'pointer' };
                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          className,
                          style,
                        })}
                      >
                        <span>{suggestion.description}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </PlacesAutocomplete>
          {errors.location && touched.location ? (
            <div className="error">{errors.location}</div>
          ) : null}
        </div>
        <div className="column is-half">
          <Field
            type="date"
            label="Business Founded*"
            name="dob"
            // max="2023-07-09"
            max={moment().format('YYYY-MM-DD')}
            value={values.dob}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {errors.dob && touched.dob ? (
            <div className="error">{errors.dob}</div>
          ) : null}
        </div>
        <div className="column is-half">
          <TextBox
            label="Tell me something about your Business (Bio - This will appear on your profile on the user app)"
            name="bio"
            value={values.bio}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
        {/* <div className="column is-half">
          <label className="checkbox">
            <input type="checkbox" className="mr-1" />
            Hey you are invited to the feature allows a brand to upload an
            existing database and gets a link to download the APP. I agree to
            the
            <a href="https://www.listmeapp.co/business-user-terms">
              {` `}terms and conditions
            </a>
          </label>
        </div> */}
        <div className="column">
          <Button
            type="submit"
            title="Continue"
            className={`is-rounded button is-medium ${loading && 'is-loading'}`}
            onClick={handleSubmit}
          />
        </div>
      </Background>
    </Container>
  );
};

const UpdateProfileEnhancedForm = withFormik({
  mapPropsToValues: props => ({
    username: props.me ? props.me.username : '',
    firstName:
      props.me.profile && props.me.profile.firstName
        ? props.me.profile.firstName
        : '',
    businessRole: props.me.businessRole ? props.me.businessRole : '',
    email: props.me ? props.me.email : '',
    telephone: props.me && props.me.telephone ? props.me.telephone : '',
    dob: props.me.profile && props.me.profile.dob ? props.me.profile.dob : '',
    bio: props.me.profile ? props.me.profile.bio : '',
    genre: props.me.genre || '',
    currency:
      props.me.profile && props.me.profile.currency
        ? props.me.profile.currency
        : 'gbp',
    location: props.me.profile.location ? props.me.profile.location : '',
    image:
      props.me.profile && props.me.profile.image ? props.me.profile.image : '',
    coverImage:
      props.me.profile && props.me.profile.coverImage
        ? props.me.profile.coverImage
        : '',
  }),
  validationSchema: yup.object().shape({
    image: yup.string().required('Image is required'),
    coverImage: yup.string().required('Cover image is required'),
    location: yup.string().required('Location is required'),
    username: yup
      .string()
      .matches(RegExp(/^[\S]+$/), 'Space not allowed!')
      .max(20, 'Max 20 characters allowed!')
      .required('Username is required'),
    firstName: yup.string().required('Name is required'),
    email: yup.string().required('Email is required'),
    telephone: yup
      .string()
      .required('Telephone is required')
      .test('allowed', 'Please add country code !', function(values) {
        return values && values.charAt(0) === '+';
      })
      .test('allowed', 'Phone number is not valid!', function(values) {
        return (
          values && phone(values, { strictDetection: true }).isValid === true
        );
      }),
    currency: yup.string().required('Please select default currency'),
    dob: yup.string().required('Date the company was founded is required'),
    genre: yup.string().required('Please select at least one'),
    businessRole: yup.string().required('Please select your role!'),
  }),
  handleSubmit: (values, { setSubmitting, props }) => {
    // console.log('values', values);
    props.onSubmit(values);
    setSubmitting(false);
  },

  displayName: 'UpdateProfileForm',
})(UpdateProfileForm);

export default UpdateProfileEnhancedForm;
