import React from 'react';
import styled from 'styled-components';
import { startCase } from 'lodash';
import InfoToolTip from './InfoToolTip';

const Select = styled.div`
  width: 100%;
  select:focus,
  select:active {
    border-color: #b5b5b5;
    box-shadow: none;
  }
  height: ${props => (props.isMedium ? '2.5rem' : '4rem')} !important;
  border-radius: ${props => props.isMedium && '10px'} !important;
  &:after {
    right: 2rem !important;
    border-color: ${props => props.theme.placeholderColor} !important;
  }
`;
const SelectInput = styled.select`
  width: 100%;
  height: 100% !important;
  font-size: 1rem !important;
  padding-left: 2rem !important;
  padding-right: 3.5rem !important;
  border-radius: ${props => props.isMedium && '10px'} !important;
  color: ${props => props.theme.placeholderColor} !important;
`;

const MySelect = ({
  label,
  options,
  placeholder,
  isMedium,
  value,
  className,
  errors,
  infoText,
  ...props
}) => (
  <>
    <div className="field">
      {(label || infoText) && (
        <label className="label is-capitalized has-text-weight-normal mb-3">
          {label} {infoText && <InfoToolTip data={infoText} />}
        </label>
      )}
      <div className="control">
        <Select
          isMedium={isMedium}
          className={`select ${!isMedium && 'is-rounded'} ${className} `}
        >
          <SelectInput isMedium={isMedium} value={value || ''} {...props}>
            <option value="">{placeholder}</option>
            {options.map(item => (
              <option
                key={item.value ? item.value : item}
                value={item.value ? item.value : item}
              >
                {item.label ? item.label : startCase(item)}
              </option>
            ))}
          </SelectInput>
        </Select>
      </div>
    </div>
    {errors && <p className="help is-danger">{errors}</p>}
  </>
);

export default MySelect;
