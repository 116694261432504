import React from 'react';
import styled from 'styled-components';
import { Modal } from '../elements';
import { moment } from '../../utils/helpers';

const Container = styled.div`
  figure {
    margin: auto;
  }
  .verified {
    color: #4cd964;
  }
  .unverified {
    color: #ff3b30;
  }
  img {
    height: 128px;
    width: 128px;
    object-fit: cover;
  }
`;
const GuestProfileModal = ({ user, isActive, onClose }) => {
  const userProfile = user && user.profile ? user.profile : {};
  return (
    <Modal title="Profile" isActive={isActive} onClose={onClose}>
      <Container className="has-text-centered">
        <figure className="image is-128x128">
          <img
            className="is-rounded"
            src={
              userProfile.image
                ? userProfile.image
                : 'https://bulma.io/images/placeholders/128x128.png'
            }
            alt={userProfile.firstName ? userProfile.firstName : 'listme user'}
          />
        </figure>
        <h1 className="subtitle is-5  has-text-weight-semibold mt-3">
          {userProfile.firstName
            ? `${userProfile.firstName} ${
                userProfile.lastName ? userProfile.lastName : ''
              }`
            : (user && user.username) || ''}
          {userProfile.sex === 'male' && (
            <i className="fas fa-mars has-text-info ml-2" />
          )}
          {userProfile.sex === 'female' && (
            <i className="fas fa-venus has-text-info ml-2" />
          )}
          {userProfile.sex === 'nonBinary' && (
            <i className="fas fa-transgender-alt has-text-info ml-2" />
          )}
          {userProfile && userProfile.dob && (
            <span className="has-text-grey ml-2 has-text-weight-normal">
              {moment().diff(userProfile.dob, 'years', false)}
            </span>
          )}
        </h1>
        {userProfile.bio && (
          <div className="subtitle is-6 has-text-centered has-text-grey">
            {userProfile.bio}
          </div>
        )}
        <div>
          {user && user.verificationStatus === 'approved' && (
            <span className="verified">
              <i className="fas fa-user-check mr-2" /> Verified
            </span>
          )}
          {user && user.verificationStatus !== 'approved' && (
            <span className="unverified">
              <i className="fas fa-user-times mr-2" /> Not Verified
            </span>
          )}
        </div>
      </Container>
    </Modal>
  );
};
export default GuestProfileModal;
