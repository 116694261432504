import React from 'react';

const Heading = ({ title, size, isCentered }) => (
  <div>
    <p
      className={`title is-capitalized is-${size} ${
        isCentered ? 'has-text-centered' : ''
      }`}
    >
      {title}
    </p>
  </div>
);

export default Heading;
