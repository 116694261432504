import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  padding: 2.25rem;
  .featured-img {
    max-width: 350px;
    animation-delay: 0.1s;
  }
  .text-block {
    align-self: center;
    h2 {
      color: ${props => props.theme.buttonColor};
    }
  }
`;

const Dashboard = () => {
  return (
    <Container className="section">
      <div className="columns">
        <div className="column is-5 has-text-right">
          <img
            className="featured-img animated slideInLeft"
            src="images/Iphone1st.png"
            alt="app"
          />
        </div>
        <div className="column is-5 text-block is-offset-1">
          <h1 className="has-text-weight-bold is-size-2">Welcome to ListMe</h1>
          <h2 className="is-size-4 mt-6 mb-4">EVENTS MADE SIMPLE</h2>
          <p className="is-size-5 has-text-weight-light">
            A one-stop system that manages every aspect required for promoting
            and marketing a venue or event. Brand analytics and real-time data
            capture.
          </p>
        </div>
      </div>
    </Container>
  );
};

export default Dashboard;
