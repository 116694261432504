import React from 'react';

import UserTableItem from './UserTableItem';
import TableWithPagination from '../global/TableWithPagination';

const TableData = ({
  columns,
  data,
  activeTab,
  handleChange,
  handleRemove,
  marginRight,
}) => {
  return (
    <TableWithPagination
      columns={columns}
      data={data}
      marginRight={marginRight}
      rowItem={(row, i) => {
        return (
          <UserTableItem
            {...row.getRowProps()}
            key={row.id}
            item={row.original}
            activeTab={activeTab}
            handleChange={val =>
              handleChange(
                val,
                row.original.id,
                row.original.telephone,
                row.original.email,
              )
            }
            handleRemove={() => handleRemove(i)}
          />
        );
      }}
    />
  );
};

export default TableData;
