import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { upperCase } from 'lodash';
import swal from 'sweetalert';

import TextInput from './elements/TextInput';
import TextArea from './elements/TextArea';
import Error from './elements/Error';

const currencyFormatter = require('currency-formatter');

const TableRow = styled.tr`
  box-shadow: ${props =>
    props.isActive ? '0px 5px 14px rgba(0, 0, 0, 0.06)' : 'none'};
  td {
    background: ${props => (props.isActive ? '#FFF' : '#fcfcfc')} !important;
    color: ${props =>
      props.isActive
        ? props.theme.textLightGrey
        : props.theme.textColorLight} !important;
    p {
      color: ${props =>
        props.isActive
          ? props.theme.textLightGrey
          : props.theme.textColorLight} !important;
    }
  }
  input:disabled {
    color: ${props => props.theme.textColorLight} !important;
    background: transparent !important;
  }
  .confirm-button {
    background: ${props => props.theme.bgLinerGradient} !important;
  }
`;

const TablePricingItem = ({
  item,
  // event,
  hideDetails,
  editable,
  hasRules,
  quantityText,
  showErrors,
  currency,
  onChange,
}) => {
  const [label, setLabel] = useState(item.label);
  const [details, setDetails] = useState(item.details);
  const [price, setPrice] = useState(item.price);
  const [quantity, setQuantity] = useState(item.quantity);
  const [isVisible, setIsVisible] = useState(item.isVisible);
  const [isConfirmed, setIsConfirmed] = useState(item.isConfirmed || false);

  // const isDisabled = event.status !== 'draft';
  const isDisabled = isConfirmed === true || isVisible === false;
  const handleSubmit = () => {
    onChange({
      label,
      details,
      price: parseFloat(price || 0),
      quantity: parseInt(quantity, 10),
      bookedQuantity: item.bookedQuantity
        ? parseInt(item.bookedQuantity, 10)
        : 0,
      isVisible,
      isConfirmed,
    });
  };

  useEffect(() => {
    handleSubmit();
  }, [isVisible, isConfirmed]);

  const handleConfirm = () => {
    if (!label || quantity < 1) {
      swal({
        title: '',
        text: 'Please fill required details!',
        icon: 'warning',
      });
    } else {
      swal({
        title: 'Are you sure?',
        text:
          'Once confirm, you will not be able to edit this package name, price and quantity!',
        icon: 'info',
        buttons: ['Cancel', 'Confirm'],
      }).then(willConfirm => {
        if (willConfirm) {
          setIsConfirmed(!isConfirmed);
        }
      });
    }
  };
  return (
    <TableRow isActive={isVisible}>
      {editable ? (
        <td>
          <TextArea
            placeholder="Name"
            disabled={isDisabled}
            value={label}
            onChange={e =>
              setLabel(e.target.value.length > 100 ? label : e.target.value)
            }
            onBlur={handleSubmit}
          />
          {!label && isVisible && showErrors && (
            <Error> Name is required!</Error>
          )}
          {label && label.length === 100 && (
            <div className="error has-text-danger mb-2 is-size-7">
              Max 100 characters allowed!
            </div>
          )}
        </td>
      ) : (
        <td>{item.label || ''}</td>
      )}
      {editable && !hideDetails && (
        <td>
          <TextArea
            name="descriptions"
            placeholder="Ticket Info"
            hasTextCentered
            disabled={!isVisible}
            value={details}
            onChange={e => setDetails(e.target.value)}
            onBlur={handleSubmit}
          />
        </td>
      )}
      <td className="is-flex is-align-items-center is-justify-content-center">
        <p>{currencyFormatter.findCurrency(upperCase(currency)).symbol}</p>
        <TextInput
          disabled={isDisabled}
          placeholder="0.00"
          required
          min={0}
          value={price}
          type="number"
          onChange={e => setPrice(e.target.value)}
          onBlur={handleSubmit}
        />
      </td>
      <td>
        <TextInput
          disabled={isDisabled}
          text={quantityText}
          placeholder="Quantity"
          required
          min={0}
          value={quantity}
          type="number"
          onChange={e => setQuantity(e.target.value)}
          onBlur={handleSubmit}
        />
        {quantity < 1 && showErrors && isVisible && (
          <Error>Please add quantity or disable this package!</Error>
        )}
      </td>
      {hasRules && <td>{item.rules ? item.rules.toString() : 'No Rules'}</td>}
      <td>
        {isConfirmed === true ? (
          <span
            className={`tag ${
              isVisible
                ? 'bg-linear-gradient-green has-text-white'
                : 'is-primary is-light'
            }`}
          >
            Confirmed
          </span>
        ) : (
          <button
            type="button"
            disabled={isVisible === false}
            onClick={handleConfirm}
            className="button is-white is-small confirm-button py-1 has-text-white has-border-radius-small"
          >
            Confirm
          </button>
        )}
      </td>
      <td>
        <button
          type="button"
          onClick={() => setIsVisible(!isVisible)}
          className="button is-white py-0"
        >
          {isVisible === true && (
            <span>
              <i className="fas fa-eye" />
            </span>
          )}
          {isVisible === false && (
            <span>
              <i className="fas fa-eye-slash" />
            </span>
          )}
        </button>
      </td>
    </TableRow>
  );
};

export default TablePricingItem;
