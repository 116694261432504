import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';

import { Loading } from '../elements';
import {
  generateLastTwelveMonths,
  convertNumberToMonth,
  generateHours,
} from '../../utils/helpers';

const AttendanceChart = ({
  data,
  loading,
  options,
  handleAttendanceState,
  isSingleEvent,
}) => {
  const [overAll, setOverAll] = useState({});

  useEffect(() => {
    if (data && data.length !== 0) {
      const param = {
        checkedIn: 0,
        rejected: 0,
        pending: 0,
        authorized: 0,
        refunded: 0,
      };
      const units = isSingleEvent
        ? generateHours(param)
        : generateLastTwelveMonths(param);
      let totalAccepted = 0;
      let totalRejected = 0;
      const lbl = [];
      const checkedIn = [];
      const rejected = [];
      data.forEach(item => {
        if (Object.keys(units).includes(item.label)) {
          units[item.label] = {
            checkedIn: item.checkedIn,
            rejected: item.rejected,
            pending: item.pending,
            authorized: item.authorized,
            refunded: item.refunded,
          };
        }
        totalAccepted += item.checkedIn;
        totalRejected += item.rejected;
      });
      handleAttendanceState({
        enter: totalAccepted,
        deny: totalRejected,
      });

      if (isSingleEvent) {
        Object.keys(units)
          .sort((a, b) => b - a)
          .forEach(e => {
            lbl.push(`${e}:00`);
            checkedIn.push(units[e].checkedIn);
            rejected.push(units[e].rejected);
          });
      } else {
        Object.keys(units).forEach(e => {
          lbl.push(convertNumberToMonth(e));
          checkedIn.push(units[e].checkedIn);
          rejected.push(units[e].rejected);
        });
      }

      setOverAll({
        labels: lbl.reverse(),
        datasets: [
          {
            label: 'Accepted',
            backgroundColor: '#33D69F',
            borderColor: '#33D69F',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(255,99,132,0.4)',
            hoverBorderColor: 'rgba(255,99,132,1)',
            data: checkedIn.reverse(),
            barThickness: 7,
          },
          {
            label: 'Denied',
            backgroundColor: '#A5227E',
            borderColor: '#A5227E',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(255,99,132,0.4)',
            hoverBorderColor: 'rgba(255,99,132,1)',
            data: rejected.reverse(),
            barThickness: 7,
          },
        ],
      });
    }
  }, [data, isSingleEvent, handleAttendanceState]);

  if (loading && (!data || data.length === 0)) return <Loading />;

  // if (error && !data) return `Error! ${error.message}`;

  if (!data || data.length === 0) {
    return (
      <div className="column">
        <p className="has-text-centered has-text-weight-semibold is-size-4">
          No data
        </p>
      </div>
    );
  }

  return <Bar data={overAll} options={options} backgroundColor="#fff" />;
};

export default AttendanceChart;
