import React from 'react';
import { Stepper, Step } from 'react-form-stepper';
import styled from 'styled-components';

const Container = styled.div`
  button {
    :hover {
      background-color: ${props => props.theme.buttonColor} !important;
    }
  }
  .RFS-Label {
    color: #959699;
    font-size: 14px;
    font-weight: 600;
  }
  .RFS-StepButton {
    height: 1.5rem;
    width: 1.5rem;
    margin-top: 10px;
    z-index: 1;
  }

  .RFS-StepCircleContent,
  .RFS-StepCircleContent.active,
  .RFS-StepCircleContent.completed {
    color: transparent;
  }
  .RFS-Connector {
    border-width: 12px;
    border-color: #c5c6c7;
  }
  .RFS-Connector.completed,
  .RFS-Connector.active {
    border-color: ${props => props.theme.buttonColor} !important;
  }
  .RFS-ConnectorContainer {
    top: calc((2em - 1px) / 2);
    left: calc((-50% + 2em) - 22px);
    right: calc((50% + 2em) - 22px);
    position: absolute;
  }
  .RFS-StepButton {
    border: 1px solid #fff;
    background-color: #c5c6c7;
  }
  .RFS-StepButton.active {
    background-color: #fff;
    border: 2px solid ${props => props.theme.buttonColor};
  }
  .RFS-StepButton.completed {
    background-color: ${props => props.theme.buttonColor} !important;
    border: 1px solid ${props => props.theme.buttonColor};
  }

  @media screen and (max-width: 768px) {
    overflow: scroll;
  }
`;

const ProgressSteps = ({ activeStep, setActiveStep }) => {
  return (
    <Container className="has-shadow-large has-border-radius-medium pt-2 is-hidden-mobile">
      <Stepper connectorStateColors activeStep={activeStep}>
        <Step
          disabled={false}
          label="Event Information"
          onClick={() => setActiveStep(0)}
        />
        <Step
          disabled={false}
          label="Entry Type"
          onClick={() => setActiveStep(1)}
        />
        <Step
          disabled={false}
          label="Event Type"
          onClick={() => setActiveStep(2)}
        />
        <Step
          disabled={false}
          label="Location"
          onClick={() => setActiveStep(3)}
        />
        <Step
          disabled={false}
          label="Partners"
          onClick={() => setActiveStep(4)}
        />
        <Step
          disabled={false}
          label="Save/Publish"
          onClick={() => setActiveStep(5)}
        />
      </Stepper>
    </Container>
  );
};

export default ProgressSteps;
