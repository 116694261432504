import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useQuery, useMutation } from 'react-apollo';
import gql from 'graphql-tag';
import swal from 'sweetalert';
import { find, pull, concat, uniq } from 'lodash';

import { Button, Loading } from '../components/elements';
import GuestListApprovalItem from '../components/GuestListApproval/GuestListApprovalItem';
import TopBar from '../components/GuestListApproval/TopBar';
import TableWithPagination from '../components/global/TableWithPagination';

const Container = styled.div`
  padding: 2.25rem;
  > div {
    background-color: #fff;
    border: 2px solid #f0f0f0;
    border-radius: 10px;
    padding: 2%;
  }
  .list-title {
    font-weight: bold;
    font-size: 14px;
    text-align: center;
  }
  .table {
    border-collapse: separate;
    border-spacing: 0 1em;
    td {
      vertical-align: middle;
      text-align: center !important;
      border-top-width: 1px;
      border-bottom-width: 1px !important;
      :first-child {
        border-left-width: 1px;
        border-top-left-radius: 2.5rem;
        border-bottom-left-radius: 2.5rem;
      }
      :last-child {
        border-right-width: 1px;
        border-top-right-radius: 2.5rem;
        border-bottom-right-radius: 2.5rem;
      }
    }
    th {
      text-align: center !important;
      color: black !important;
      padding: 1rem;
      :first-child {
        text-align: center !important;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    padding: 2.25rem 1rem !important;
  }
`;

const meQuery = gql`
  query meTrustedUsers {
    me {
      trustedUsers {
        id
      }
    }
  }
`;

const eventQuery = gql`
  query guestListApprovalEvent($id: ID!) {
    singleEvent(id: $id) {
      name
      pricing {
        privateServices
      }
    }
  }
`;

const guestListApprovalQuery = gql`
  query allBookings(
    $eventId: ID
    $searchQuery: String
    $showParentBookings: Boolean
    $showPrivateServicesBookings: Boolean
    $limit: Int
    $skip: Int
  ) {
    allBookings(
      eventId: $eventId
      searchQuery: $searchQuery
      showParentBookings: $showParentBookings
      showPrivateServicesBookings: $showPrivateServicesBookings
      limit: $limit
      skip: $skip
    ) {
      totalBookings
      totalPendingApprovalBookings
      bookings {
        id
        pricingType
        pricingLabel
        isWalkIn
        status
        paymentStatus
        paymentUrl
        createdAt
        checkInAt
        rejectedAt
        ejectedAt
        paymentMethod
        ejectReason
        walkInData {
          personName
          telephone
        }
        referredBy {
          telephone
          profile {
            firstName
            lastName
          }
        }
        walkInBy {
          profile {
            firstName
            lastName
          }
        }
        rejectedBy {
          profile {
            firstName
            lastName
          }
        }
        checkInBy {
          id
          profile {
            firstName
            lastName
          }
        }
        user {
          id
          username
          telephone
          covidPassport
          verificationStatus
          identification {
            idDocType
            firstName
          }
          profile {
            firstName
            lastName
            image
            sex
            dob
          }
        }
        parent {
          id
        }
        collaborators {
          id
          status
          user {
            id
            username
            telephone
            covidPassport
            verificationStatus
            identification {
              idDocType
              firstName
            }
            profile {
              firstName
              lastName
              image
              dob
              sex
            }
          }
          booking {
            id
            pricingType
            pricingLabel
            isWalkIn
            status
            paymentStatus
            paymentUrl
            createdAt
            checkInAt
            rejectedAt
            ejectedAt
            paymentMethod
            ejectReason
            walkInData {
              personName
              telephone
            }
            referredBy {
              telephone
              profile {
                firstName
                lastName
              }
            }
            walkInBy {
              profile {
                firstName
                lastName
              }
            }
            rejectedBy {
              profile {
                firstName
                lastName
              }
            }
            checkInBy {
              id
              profile {
                firstName
                lastName
              }
            }
            user {
              id
              username
              telephone
              covidPassport
              verificationStatus
              identification {
                idDocType
                firstName
              }
              profile {
                firstName
                lastName
                image
                dob
                sex
              }
            }
          }
        }
      }
    }
  }
`;

const checkInBookingMutation = gql`
  mutation approveBooking(
    $id: ID!
    $userId: ID!
    $eventId: ID!
    $reject: Boolean
  ) {
    approveBooking(id: $id, userId: $userId, eventId: $eventId, reject: $reject)
  }
`;
// const bookingRejectMutation = gql`
//   mutation approveBooking($userId: ID!, $eventId: ID!, $reject: Boolean!) {
//     approveBooking(userId: $userId, eventId: $eventId, reject: $reject)
//   }
// `;
const updateMeMutation = gql`
  mutation updateTrustedUsers($input: UpdateUserInput) {
    updateMe(input: $input) {
      id
    }
  }
`;
const bulkBookingCheckInMutation = gql`
  mutation bulkCheckIn($eventId: ID!, $status: BookingStatus) {
    bulkCheckIn(eventId: $eventId, status: $status) {
      success
    }
  }
`;

const guestsHeadData = [
  { Header: 'Date', accessor: 'createdAt' },
  { Header: 'Event Name' },
  { Header: 'Booking Type', accessor: 'pricingType' },
  { Header: 'Booking Info', accessor: 'pricingLabel' },
  { Header: 'Full Name', accessor: 'user.profile.firstName' },
  { Header: 'Promoter list' },
  { Header: 'View Profile' },
  // { Header: 'Payment Status', accessor: 'paymentStatus' },
  { Header: 'Action', accessor: 'status' },
  { Header: 'Approved By' },
  { Header: 'Approved At' },
  { id: 10, Header: '' },
];

const GuestListApproval = ({ match }) => {
  const { id } = match.params;
  const [skipBookings, setSkipBookings] = useState(0);
  const [bookingsLimit, setBookingsLimit] = useState(20);
  const [search, setSearch] = useState('');
  const { data, loading, error } = useQuery(eventQuery, {
    fetchPolicy: 'no-cache',
    variables: { id },
  });

  const { data: data2, refetch: refetch2 } = useQuery(meQuery, {
    fetchPolicy: 'no-cache',
  });
  const [checkInBooking, { error: checkInBookingError }] = useMutation(
    checkInBookingMutation,
  );
  const {
    data: bookingsData,
    error: bookingsError,
    loading: bookingsLoading,
    refetch,
  } = useQuery(guestListApprovalQuery, {
    fetchPolicy: 'cache-and-network',
    // pollInterval: 100000, // 100 secs
    variables: {
      eventId: id,
      searchQuery: search && search.length > 1 ? search : '',
      showParentBookings: true,
      showPrivateServicesBookings: true,
      limit: bookingsLimit,
      skip: skipBookings * bookingsLimit,
    },
  });
  // const [rejectBooking, { error: rejectBookingError }] = useMutation(
  //   bookingRejectMutation,
  // );
  const [updateTrustedUsers, { error: updateMeError }] = useMutation(
    updateMeMutation,
  );
  const [bulkBookingsCheckIn, { error: bulkCheckInError }] = useMutation(
    bulkBookingCheckInMutation,
  );
  const [trustedUsers, setTrustedUsers] = useState([]);
  const event = data && data.singleEvent ? data.singleEvent : null;
  // const eventBookings = event && event.bookings;
  // const privateServices =
  //   event && event.pricing && event.pricing.privateServices;

  // const pendingApprovalGuests = filter(bookings, o => {
  //   return o.status === 'authorizedPending';
  // });
  const pendingApprovalBookings =
    bookingsData?.allBookings?.totalPendingApprovalBookings || 0;
  const totalBookings = bookingsData?.allBookings?.totalBookings || 0;
  const allBookings = bookingsData?.allBookings?.bookings
    ? bookingsData.allBookings.bookings
    : [];

  useEffect(() => {
    if (data2 && data2.me) {
      const users = data2.me.trustedUsers.map(item => item.id);
      setTrustedUsers(users);
    }
  }, [data2]);

  // useEffect(() => {
  //   if (eventBookings && privateServices) {
  //     const privateBookings = [];
  //     privateServices.map(service => {
  //       eventBookings.map(booking => {
  //         if (service === booking.pricingType) {
  //           privateBookings.push(booking);
  //         }
  //         return null;
  //       });
  //       return null;
  //     });
  //     setBookings(privateBookings);
  //   }
  // }, [eventBookings, privateServices]);

  // console.log('privateBookings', bookings);
  const handleAcceptBooking = values => {
    swal({
      title: 'Are you sure?',
      text: `You want to approve ${values.name}`,
      icon: 'warning',
      buttons: true,
    }).then(async willConfirm => {
      if (willConfirm) {
        const resp = await checkInBooking({
          variables: {
            id: values.id,
            userId: values.userId,
            eventId: id,
            reject: false,
          },
        });
        if (resp) {
          refetch();
        }
      }
    });
  };

  const handleDeniedBooking = values => {
    swal({
      title: 'Are you sure?',
      text: `You want to decline ${values.name}.`,
      icon: 'warning',
      buttons: true,
    }).then(async willConfirm => {
      if (willConfirm) {
        const resp = await checkInBooking({
          variables: {
            id: values.id,
            userId: values.userId,
            eventId: id,
            reject: true,
          },
        });
        if (resp) {
          refetch();
        }
      }
    });
  };

  const handleBulkCheckIn = status => {
    swal({
      title: 'Are you sure?',
      text: `You want to ${
        status === 'rejected' ? 'reject' : 'approved'
      } all bookings.`,
      icon: 'warning',
      buttons: true,
    }).then(async willConfirm => {
      if (willConfirm) {
        await bulkBookingsCheckIn({
          variables: {
            eventId: id,
            status,
          },
        });
        setTimeout(() => refetch(), 200);
      }
    });
  };

  const handleUpdateTrustedUsers = async userId => {
    let trustedUsersArray = [];
    const checkUser = find(trustedUsers, o => {
      return o === userId;
    });
    if (checkUser) {
      trustedUsersArray = pull(trustedUsers, userId);
    } else {
      trustedUsersArray = [...trustedUsers, userId];
    }
    const resp = await updateTrustedUsers({
      variables: {
        input: {
          trustedUsers: trustedUsersArray,
        },
      },
    });
    if (resp) {
      refetch2();
    }
  };

  const handleBulkAutoApproved = async () => {
    const guestListArray = allBookings && allBookings.map(item => item.user.id);
    const allUsersArray = concat(trustedUsers, guestListArray);
    const selectedTrustedUsers = uniq(allUsersArray);
    const resp = await updateTrustedUsers({
      variables: {
        input: {
          trustedUsers: selectedTrustedUsers,
        },
      },
    });
    if (resp) {
      refetch2();
    }
  };

  useEffect(() => {
    if (error) {
      swal('Oops!', error.message, 'error');
    }
    if (checkInBookingError) {
      swal('Oops!', checkInBookingError.message, 'error');
    }
    if (updateMeError) {
      swal('Oops!', updateMeError.message, 'error');
    }
    if (bulkCheckInError) {
      swal('Oops!', bulkCheckInError.message, 'error');
    }
    if (bookingsError) {
      swal('Oops!', bookingsError.message, 'error');
    }
  }, [
    error,
    checkInBookingError,
    updateMeError,
    bulkCheckInError,
    bookingsError,
  ]);

  // console.log('trustedUsers', trustedUsers);
  const columns = React.useMemo(() => guestsHeadData, []);

  return (
    <Container className="section">
      <div>
        <TopBar
          handleBulkAutoApproved={handleBulkAutoApproved}
          handleBulkCheckIn={handleBulkCheckIn}
          eventId={id}
        />
        <div className="table-container px-2">
          {loading && allBookings.length === 0 && <Loading />}

          {(!loading || allBookings.length > 0) && (
            <TableWithPagination
              marginRight="1rem"
              columns={columns}
              data={allBookings}
              loading={bookingsLoading}
              pageLimit={bookingsLimit}
              totalCount={totalBookings}
              manualPagination
              currentPage={skipBookings}
              fetchData={(pageIndex, pageSize) => {
                setBookingsLimit(pageSize);
                setSkipBookings(pageIndex);
              }}
              onSearch={val => setSearch(val)}
              rowItem={row => (
                <GuestListApprovalItem
                  key={row.id}
                  booking={row.original}
                  eventName={event && event.name}
                  trustedUsers={trustedUsers}
                  handleAcceptBooking={handleAcceptBooking}
                  handleDeniedBooking={handleDeniedBooking}
                  handleUpdateTrustedUsers={handleUpdateTrustedUsers}
                />
              )}
            />
          )}
        </div>

        <div className="column is-full">
          <div className="list-title has-text-left ml-5">
            {totalBookings} Requests ({pendingApprovalBookings} Users waiting)
          </div>
        </div>
        <div className="column is-full">
          <div className="list-title ">
            <Button title="Send" className="button is-rounded" />
          </div>
        </div>
      </div>
    </Container>
  );
};
export default GuestListApproval;
