import React, { useState } from 'react';
import styled from 'styled-components';
import { SketchPicker } from 'react-color';

const Container = styled.div`
  .wrapper {
    background: #ffffff;
  }
  .brand-color {
    color: ${props => props.theme.mainBrandColor} !important;
  }
  .modal-content {
    width: fit-content !important;
  }
  .sketch-picker {
    box-shadow: none !important;
  }
`;

const ColorPickerModal = ({ isActive, onClose, handleSubmit }) => {
  const [color, setColor] = useState('#ffffff');
  return (
    <Container className={`modal ${isActive && `is-active`}`}>
      <div className="modal-background">
        <div className="modal-content">
          <div className="p-4 wrapper mt-6">
            <SketchPicker color={color} onChange={val => setColor(val.hex)} />
            <div className="is-flex is-justify-content-flex-end	is-align-items-flex-end">
              <button
                onClick={() => handleSubmit(color)}
                type="button"
                className="button is-white has-text-weight-semibold brand-color"
              >
                Add
              </button>
            </div>
          </div>
        </div>
        <button
          onClick={onClose}
          type="button"
          className="modal-close is-large"
          aria-label="close"
        />
      </div>
    </Container>
  );
};
export default ColorPickerModal;
