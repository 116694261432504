import React, { useState } from 'react';
import styled from 'styled-components';
import { withFormik } from 'formik';
import * as yup from 'yup';
import { Field } from '../elements';
import eyeIcon from '../../assets/icons/eye-icon.svg';
import eyeCloseIcon from '../../assets/icons/eye-close-icon.svg';

const Wrapper = styled.div`
  padding-top: 2rem;
  .button {
    ${'' /* background-color: #a5227e; */}
    background: ${props => props.theme.bgLinerGradient};
  }
  .button:disabled,
  .button[disabled] {
    opacity: 0.8;
  }
`;

const RegisterForm = props => {
  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    loading,
    isValid,
    errors,
    touched,
  } = props;

  const [showPassword, setShowPassword] = useState(false);

  return (
    <Wrapper>
      <Field
        label="Your Email"
        placeholder="john@doe.com"
        name="email"
        type="email"
        value={values.email}
        onChange={handleChange}
        onBlur={handleBlur}
        errors={errors.email && touched.email ? errors.email : undefined}
      />
      <Field
        label="Create Password"
        placeholder="********"
        name="password"
        type={showPassword ? 'text' : 'password'}
        value={values.password}
        eyeIcon={!showPassword ? eyeIcon : eyeCloseIcon}
        onRightIconClick={() => setShowPassword(!showPassword)}
        onChange={handleChange}
        onBlur={handleBlur}
        errors={
          errors.password && touched.password ? errors.password : undefined
        }
      />
      <br />
      <button
        type="submit"
        className={`button is-rounded has-text-white is-medium ${loading &&
          'is-loading'}`}
        style={{ width: '100%' }}
        onClick={handleSubmit}
        disabled={!isValid || loading}
      >
        Register
      </button>
    </Wrapper>
  );
};

export default withFormik({
  mapPropsToValues: () => ({
    email: '',
    password: '',
  }),
  // Custom sync validation
  validationSchema: yup.object().shape({
    email: yup
      .string()
      .email('Invalid email address')
      .required('Email is required!'),
    password: yup.string().required('Password is required!'),
  }),
  // validate: values => {
  //   const errors = {};
  //   if (!values.email) {
  //     errors.email = 'Required';
  //   }
  //   if (!values.password) {
  //     errors.password = 'Required';
  //   }
  //   return errors;
  // },
  handleSubmit: (values, { setSubmitting, props }) => {
    setTimeout(() => {
      props.onSubmit(values);
      setSubmitting(false);
    }, 1000);
  },

  displayName: 'RegisterForm',
})(RegisterForm);
