import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { InfoToolTip } from '../elements';
import CheckBox from './elements/CheckBox';

const Container = styled.div`
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important;
  min-width: max-content;
  box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.06);
  color: ${props => props.theme.textLightGrey} !important;
`;

const TicketPricingRules = ({ rules, onChange }) => {
  const [isReady, setIsReady] = useState(false);
  const [isReleaseFormat, setReleaseFormat] = useState(true);
  const [isOpenFormat, setOpenFormat] = useState(false);

  useEffect(() => {
    setIsReady(true);
    if (!isReady) {
      rules.forEach(str => {
        if (str === 'Release format') {
          setReleaseFormat(true);
        } else {
          setReleaseFormat(false);
        }
        if (str === 'Open format') {
          setOpenFormat(true);
        }
      });
    }
  }, [rules]);

  useEffect(() => {
    const rulesArray = [];
    if (isReleaseFormat === true) {
      rulesArray.push('Release format'); // DO NOT CHANGE TEXT
    }
    if (isOpenFormat === true) {
      rulesArray.push('Open format'); // DO NOT CHANGE TEXT
    }
    onChange(rulesArray);
  }, [isOpenFormat, isReleaseFormat]);

  return (
    <Container className="py-4 has-background-white">
      <div>
        <p>Selling format</p>
        <div className="mt-5 is-flex">
          <CheckBox
            checked={isReleaseFormat}
            onChange={() => {
              setReleaseFormat(!isReleaseFormat);
              setOpenFormat(!isOpenFormat);
            }}
          />
          <p className="ml-5">Release format</p>
          <InfoToolTip data="This means the tickets for purchase to be released based on 1st, 2nd, 3rd release, etc once 1st release is sold out" />
        </div>
        <div className="mt-5 is-flex">
          <CheckBox
            checked={isOpenFormat}
            onChange={() => {
              setOpenFormat(!isOpenFormat);
              setReleaseFormat(!isReleaseFormat);
            }}
          />
          <p className="ml-5">Open Format</p>
          <InfoToolTip data="This means any ticket option is available to be purchased at any time" />
        </div>
      </div>
    </Container>
  );
};
export default TicketPricingRules;
