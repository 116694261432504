import React, { useState } from 'react';
import axios from 'axios';
import gql from 'graphql-tag';
import styled from 'styled-components';
import randomstring from 'randomstring';
import { useMutation } from '@apollo/react-hooks';

import Loading from './Loading';

import ImageCropper from './ImageCropper';
import Button from './Button';

const Wrapper = styled.figure`
  margin: auto;
  position: relative;
  img {
    border-radius: 10px;
    height: 100%;
    object-fit: cover;
  }
  .file-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: ${props =>
      props.hasFile ? 'transparent' : 'rgba(0, 0, 0, 0.45)'};
    border-radius: 10px;
    transition: ease 0.3s;
    :hover {
      background-color: rgba(0, 0, 0, 0.45);
      .icon {
        visibility: visible;
      }
    }
    .file-input {
      cursor: pointer;
    }
    .icon {
      visibility: ${props => (props.hasFile ? 'hidden' : 'visible')};
      height: 100%;
      width: 100%;
      color: white;
      font-size: 24px;
    }
  }
  &.circle {
    img {
      border-radius: 50%;
      border: 1.5px dashed #b5b5b5;
    }
    .file-wrapper {
      border-radius: 50%;
    }
  }
  .loading {
    margin-bottom: 1rem;
  }
  .loading-wrapper {
    margin-top: 35%;
  }
  input {
    height: 100% !important;
  }
  @media screen and (max-width: 768px) {
    input[type='file'] {
      height: 100% !important;
    }
  }
`;

const signFileUploadMutation = gql`
  mutation signFileUpload($fileName: String!, $fileType: String!) {
    signFileUpload(fileName: $fileName, fileType: $fileType) {
      signedUrl
      fileUrl
    }
  }
`;

const UploadImageInput = ({
  size,
  value,
  onChange,
  onBlur,
  circle,
  aspectRatio,
  isSecondary,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [fileName, setFileName] = useState(undefined);
  const [fileType, setFileType] = useState(undefined);
  const [file, setFile] = useState({});
  const [uploadedFileUrl, setUploadedFileUrl] = useState(value || undefined);
  // const [croppedFile, setCroppedFile] = useState(value || dummy);
  const [isUploading, setIsUploading] = useState(false);
  const [signFileUpload] = useMutation(signFileUploadMutation);

  const handleFileSelect = event => {
    const data = event.target.files[0];
    setFileName(data.name);
    setFileType(data.type);
    setTimeout(() => {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setFile(reader.result);
        setModalVisible(true);
      });
      reader.readAsDataURL(data);
    }, 2000);
  };

  const handleUpload = ({ signedUrl, fileUrl, croppedUrl }) => {
    const options = {
      headers: {
        'Content-Type': file.type,
      },
    };
    // console.log('handleUpload', signedUrl, croppedUrl, options);
    axios
      .put(signedUrl, croppedUrl, options)
      .then(() => {
        onChange(fileUrl);
        setUploadedFileUrl(fileUrl);
        setTimeout(() => {
          setIsUploading(false);
        }, 1000);
      })
      .catch(error => {
        console.log(error);
      });
  };

  return (
    <>
      {isSecondary ? (
        <Button
          type="button"
          className="button is-white is-normal select-file has-border-radius-small has-text-weight-semibold"
        >
          <span>{isUploading ? 'Loading...   ' : 'Choose a file'}</span>
          <input
            disabled={isUploading}
            type="file"
            name="image"
            accept="image/*"
            // capture="camera"
            onChange={image => handleFileSelect(image)}
            onBlur={onBlur}
            className="file-input"
          />
          <span className="icon ml-5">
            <i className="fas fa-upload" />
          </span>
        </Button>
      ) : (
        <Wrapper
          hasFile={uploadedFileUrl}
          className={`image ${
            circle ? 'circle is-128x128' : size || 'is-5by4'
          }`}
        >
          <img
            src={
              uploadedFileUrl && uploadedFileUrl.length > 0
                ? uploadedFileUrl
                : '/images/event-placeholder.png'
            }
            alt="event"
          />

          <div className="file-wrapper">
            <input
              disabled={isUploading}
              className="file-input"
              type="file"
              name="resume"
              accept="image/*"
              // capture="camera"
              onChange={event => handleFileSelect(event)}
            />
            {isUploading ? (
              <div className="loading-wrapper">
                <Loading />
              </div>
            ) : (
              <>
                <span className="icon">
                  <i className="fas fa-camera" />
                </span>
              </>
            )}
          </div>
        </Wrapper>
      )}
      {file && (
        <ImageCropper
          isSmall={circle}
          aspectRatio={aspectRatio}
          modalVisible={modalVisible}
          src={file && file.length > 0 ? file : undefined}
          onChange={async croppedUrl => {
            // setCroppedFile(croppedUrl);
            setModalVisible(false);
            setIsUploading(true);
            const res = await signFileUpload({
              variables: {
                fileName: `${randomstring.generate(3)}-${fileName}`,
                fileType,
              },
            });
            // console.log('res', res);
            handleUpload({ ...res.data.signFileUpload, croppedUrl });
          }}
          handleClose={() => setModalVisible(false)}
        />
      )}
    </>
  );
};

export default UploadImageInput;
