import React, { useRef, useEffect } from 'react';
import { exportComponentAsPNG } from 'react-component-export-image';
import { withFormik } from 'formik';
import styled from 'styled-components';
import QRCodeStyling from 'qr-code-styling';

import { Button, UploadImageInput } from '../elements';
import TextInput from '../updateEventDetails/elements/TextInput';
import theme from '../../theme';

const Container = styled.form`
  label {
    color: ${props => props.theme.placeholderColor};
  }
  .select-file {
    background: ${props => props.theme.borderColor};
    margin-top: 1rem;
    :hover {
      background: ${props => props.theme.borderColor};
    }
  }
  .qr-frame {
    width: 45px;
  }
  .wrapper {
    border: 1px solid ${props => props.theme.borderColor};
    /* box-shadow: 1.28413px 1.28413px 2.56827px rgba(0, 0, 0, 0.15); */
  }
`;

const QrCodeStyle = styled.button`
  background: linear-gradient(
        to right,
        ${props => props.borderColor} 8px,
        transparent 8px
      )
      0 0,
    linear-gradient(
        to right,
        ${props => props.borderColor} 7px,
        transparent 7px
      )
      0 100%,
    linear-gradient(to left, ${props => props.borderColor} 7px, transparent 7px)
      100% 0,
    linear-gradient(to left, ${props => props.borderColor} 7px, transparent 7px)
      100% 100%,
    linear-gradient(
        to bottom,
        ${props => props.borderColor} 7px,
        transparent 7px
      )
      0 0,
    linear-gradient(
        to bottom,
        ${props => props.borderColor} 7px,
        transparent 7px
      )
      100% 0,
    linear-gradient(to top, ${props => props.borderColor} 7px, transparent 7px)
      0 100%,
    linear-gradient(to top, ${props => props.borderColor} 7px, transparent 7px)
      100% 100%;
  background-repeat: no-repeat;
  background-size: 30px 30px;
  border: none;
  padding: 10px;
`;

const ColorPicker = styled.button`
  border: none;
  background: linear-gradient(
    180deg,
    ${props => props.startColor} 0%,
    ${props => props.stopColor} 100%
  );
  height: 40px;
  width: 40px;
  border-radius: 100%;
  margin-top: 1.25rem;
`;

const Frame = styled.button`
  border: none;
  background: ${props =>
    props.isActive ? props.theme.mainBrandColor : props.theme.borderColor};
  height: 45px;
  width: 45px;
  border-radius: 5px;
  margin-top: 1.25rem;
`;

const frameOptions = [
  { id: 1, size: '1 : 1' },
  { id: 2, size: '4 : 4' },
  { id: 3, size: '8 : 8' },
  { id: 4, size: '16 : 16' },
];

const colorOptions = [
  { id: 1, startColor: '#E47A99', stopColor: '#F0947C' },
  { id: 2, startColor: '#D5F68E', stopColor: '#B1E6A2' },
  { id: 3, startColor: '#FBDE6F', stopColor: '#F5B363' },
  { id: 4, startColor: '#999BE9', stopColor: '#F1C8D4' },
  { id: 5, startColor: '#BDEB9B', stopColor: '#4F8D91' },
];

const qrCode = new QRCodeStyling({
  width: 220,
  height: 220,
  dotsOptions: {
    gradient: {
      type: 'linear',
      rotation: 180,
      colorStops: [
        { offset: 0, color: 'black' },
        { offset: 1, color: 'black' },
      ],
    },
  },
  imageOptions: {
    crossOrigin: 'anonymous',
    margin: 7,
  },
});

const ComponentToPrint = React.forwardRef(
  ({ values, qrCodeType, qrCodeRef, borderColor }, ref) => {
    const type = qrCodeType === 'walkIn' ? 'Walk-in' : 'Self check-in';

    return (
      <div ref={ref} className="column mt-5 is-4 is-offset-3">
        <div className="wrapper has-border-radius-medium px-4 py-6 has-text-centered">
          <h3 className="subtitle has-text-weight-bold is-3">
            {values.header ? values.header : 'Add your text here'}
          </h3>
          <div className="qr-code-wrapper py-4">
            <QrCodeStyle
              ref={qrCodeRef}
              borderColor={
                borderColor === 'black' ? theme.borderColor : borderColor
              }
            />
          </div>
          <p className="subtitle is-size-7">
            Your Static QR Code will generate automatically
          </p>
          <h3 className="subtitle has-text-weight-bold is-3 mb-5">
            {values.title ? values.title : type}
          </h3>
        </div>
      </div>
    );
  },
);

const GenerateQrCodeForm = props => {
  const {
    qrCodeType,
    userId,
    values,
    handleBlur,
    handleSubmit,
    setFieldValue,
    handleChange,
  } = props;

  const qrCodeRef = useRef(null);
  const qrFrameRef = useRef();

  useEffect(() => {
    qrCode.append(qrCodeRef.current);
  }, []);

  useEffect(() => {
    // console.log('qrCodeType', qrCodeType);
    let qrCodedData = 'https://www.listmeapp.co/';
    if (qrCodeType === 'walkIn') {
      // url to brand
      qrCodedData = `https://www.listmeapp.co/walkIn/${userId}`;
    } else if (qrCodeType === 'selfCheckIn') {
      // data for self check in
      qrCodedData = JSON.stringify({ brandId: userId, type: qrCodeType });
    }
    qrCode.update({
      data: qrCodedData,
    });
  }, [userId, qrCodeType]);

  useEffect(() => {
    qrCode.update({
      image: values.logo,
    });
  }, [values.logo]);

  useEffect(() => {
    qrCode.update({
      dotsOptions: {
        gradient: {
          colorStops: [
            { offset: 0, color: values.color.startColor },
            { offset: 1, color: values.color.stopColor },
          ],
        },
      },
    });
  }, [values.color]);
  return (
    <Container onSubmit={handleSubmit}>
      <div className="columns">
        <div className="column mt-5 is-5">
          <div className="field mb-5">
            <label className="label">Header</label>
            <TextInput
              width="100%"
              hasJustifyStart
              isFullWidth
              name="header"
              placeholder="Add your text here"
              value={values.header}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
          <div className="field mb-5">
            <label className="label">Title</label>
            <TextInput
              width="100%"
              hasJustifyStart
              isFullWidth
              name="title"
              placeholder="Add your text here"
              value={values.title}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
          <div className="field mb-5">
            <label className="label">Color</label>
            <div className="buttons">
              {colorOptions.map(item => (
                <ColorPicker
                  type="button"
                  key={item.id}
                  startColor={item.startColor}
                  stopColor={item.stopColor}
                  onClick={() => {
                    setFieldValue('color.startColor', item.startColor);
                    setFieldValue('color.stopColor', item.stopColor);
                  }}
                  className="button"
                />
              ))}
            </div>
          </div>

          <div className="field mb-5">
            <label className="label">Logo</label>
            <UploadImageInput
              isSecondary
              aspectRatio={4 / 4}
              value={values.logo}
              onChange={image => setFieldValue('logo', image)}
            />
          </div>
          <div className="field mb-5">
            <label className="label">Frames</label>
            <div className="buttons">
              {frameOptions.map(item => (
                <div key={item.id} className="qr-frame has-text-centered mr-4">
                  <Frame
                    isActive={values.qrFrameSize === item.size}
                    type="button"
                    className="button is-size-4 has-text-white"
                    onClick={() => {
                      setFieldValue('qrFrameSize', item.size);
                    }}
                  >
                    <i className="fas fa-qrcode" />
                  </Frame>
                  <p className="is-size-7 mt-3">{item.size}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <ComponentToPrint
          qrCodeRef={qrCodeRef}
          ref={qrFrameRef}
          values={values}
          qrCodeType={qrCodeType}
          borderColor={values.color.startColor}
        />
      </div>

      <div className="buttons mt-5 is-justify-content-center">
        <Button
          onClick={() => exportComponentAsPNG(qrFrameRef)}
          className="is-rounded button is-normal px-6 has-text-weight-semibold"
          type="button"
        >
          Save <i className="fas fa-download ml-3" />
        </Button>
      </div>
    </Container>
  );
};

export default withFormik({
  mapPropsToValues: () => ({
    header: '',
    title: '',
    logo: '',
    qrFrameSize: '',
    color: {
      startColor: 'black',
      stopColor: 'black',
    },
  }),
  displayName: 'GenerateQrCodeForm', // helps with React DevTools
})(GenerateQrCodeForm);
