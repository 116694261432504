import React from 'react';
import styled from 'styled-components';

import CreateEventForm from '../form/CreateEventForm';

const Box = styled.div`
  padding: 2.25rem;
  .title {
    color: #616161;
  }
`;

class CreateEventModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // eventName: '',
    };
  }

  // onNameChange = e => {
  //   this.setState({ eventName: e.target.value });
  // };

  render() {
    // const { eventName } = this.state;
    const { active, handelClose, handleSubmit } = this.props;
    return (
      <div className={`modal ${active && 'is-active'}`}>
        <div className="modal-background" />
        <div className="modal-content">
          <Box className="box">
            <p className="title is-capitalized">Create New Event</p>

            <CreateEventForm
              onSubmit={value => handleSubmit({ name: value.name })}
            />
          </Box>
        </div>
        <button
          type="submit"
          className="modal-close is-large"
          aria-label="close"
          onClick={() => handelClose()}
        />
      </div>
    );
  }
}

export default CreateEventModal;
