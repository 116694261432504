import React, { useState } from 'react';
import styled from 'styled-components';
import { withFormik } from 'formik';
import * as yup from 'yup';

import { Field, Button } from '../elements';
import eyeIcon from '../../assets/icons/eye-icon.svg';
import eyeCloseIcon from '../../assets/icons/eye-close-icon.svg';

const Wrapper = styled.div`
  padding-top: 2rem;
  .button {
    width: 100%;
  }
  .error {
    text-align: right;
  }
`;
const ErrorWrapper = styled.div`
  display: ${props => (props.visible ? 'block' : 'none')};
  text-align: center;
  color: #ea2727;
  margin-top: 10px;
`;

const checkCredentialsError = error => {
  const status =
    error && error.message === 'GraphQL error: Invalid username or password.';
  return Boolean(status);
};
const LoginForm = props => {
  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isLoading,
    isValid,
    error,
    errors,
    touched,
  } = props;
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Wrapper>
      <Field
        label="Email"
        placeholder="john@doe.com"
        name="email"
        type="email"
        value={values.email}
        onChange={handleChange}
        onBlur={handleBlur}
        errors={errors.email && touched.email ? errors.email : undefined}
      />
      <Field
        label="Password"
        eyeIcon={!showPassword ? eyeIcon : eyeCloseIcon}
        onRightIconClick={() => setShowPassword(!showPassword)}
        placeholder="********"
        name="password"
        type={showPassword ? 'text' : 'password'}
        value={values.password}
        onChange={handleChange}
        onBlur={handleBlur}
        errors={
          errors.password && touched.password ? errors.password : undefined
        }
      />
      <Button
        disabled={!isValid}
        className={`button is-rounded is-large is-primary mt-5 ${isLoading &&
          'is-loading'}`}
        onClick={handleSubmit}
      >
        Login
      </Button>
      <ErrorWrapper visible={checkCredentialsError(error)}>
        Sorry, your username or password was incorrect.
      </ErrorWrapper>
    </Wrapper>
  );
};

export default withFormik({
  mapPropsToValues: () => ({
    email: '',
    password: '',
  }),
  // Custom sync validation

  validationSchema: yup.object().shape({
    email: yup
      .string()
      .email('Invalid email address')
      .required('Email is required!'),
    password: yup.string().required('Password is required!'),
  }),

  handleSubmit: (values, { setSubmitting, props }) => {
    setTimeout(() => {
      props.onSubmit(values);
      setSubmitting(false);
    }, 1000);
  },

  displayName: 'LoginForm',
})(LoginForm);
