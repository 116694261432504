import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useQuery, useMutation } from 'react-apollo';

import gql from 'graphql-tag';
import swal from 'sweetalert';
import { filter, find } from 'lodash';

import Tabs from '../components/Invite/Tabs';
import theme from '../theme/index';
import InvitedUsersTable from '../components/Invite/InvitedUsersTable';
// import InviteUsersBtns from '../components/Invite/InviteUsersBtns';
import UsersTable from '../components/Invite/UsersTable';
import { Button } from '../components/elements';
import TextArea from '../components/updateEventDetails/elements/TextArea';
import { cleanString } from '../utils/helpers';

const Layout = styled.div`
  padding: 2.25rem;
  .column-container {
    &.info {
      border-radius: 10px;
      background: #ffffff;
      padding: 2rem 5%;
    }
  }
  strong {
    color: ${props => props.theme.placeholderColor};
  }
  .is-white {
    color: ${props => props.theme.mainBrandColor} !important;
  }
  th:first-child {
    min-width: 200px;
  }
`;

const Container = styled.div`
  padding: 2.25rem;
  padding-top: 1.5rem;
  /* height: 80vh; */
  position: relative;

  .wrapper {
    overflow: auto;
    position: relative
    /* height: 70vh; */
    ::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .select-all-button {
    position: absolute;
    right: 1.5%;
    bottom: -10px;
  }
  .message-wrapper {
    background: #f5f5f5;
    border-radius: 5px;
  }
  .button.is-white,
  textarea {
    background-color: transparent !important;
  }
  textarea:not([rows]) {
    min-height: 4.25rem;
  }
`;

const Icon = styled.i`
  color: ${props => props.iconColor};
`;

const bulkUsersInviteMutation = gql`
  mutation bulkUserInvite($input: BulkUserInviteInput!) {
    bulkUserInvite(input: $input) {
      success
    }
  }
`;

const sendNotificationMutation = gql`
  mutation bulkSendNotification($input: BulkSendNotificationInput!) {
    bulkSendNotification(input: $input) {
      success
    }
  }
`;

const meQuery = gql`
  query meQuery {
    me {
      id
      username
    }
  }
`;

const invitedUsersQuery = gql`
  query invitedUsers {
    invitedUsers {
      id
      email
      telephone
      status
      profile {
        firstName
        lastName
        image
      }
    }
  }
`;

const userStatusOptions = [
  {
    id: 1,
    title: 'Walk - in',
    icon: 'fas fa-walking',
    iconColor: theme.mainBrandColor,
  },
  {
    id: 2,
    title: 'Registered',
    icon: 'fas fa-circle',
    iconColor: theme.mainBrandColor,
  },
  {
    id: 3,
    title: 'Invited',
    icon: 'far fa-circle',
    iconColor: theme.mainBrandColor,
  },
  {
    id: 4,
    title: 'Failed',
    icon: 'fas fa-circle',
    iconColor: theme.borderColor,
  },
];

const Invite = () => {
  const { data, error } = useQuery(meQuery, {
    fetchPolicy: 'cache-and-network',
  });
  const {
    data: invitedUsersData,
    error: invitedUsersError,
    loading: invitedUsersLoading,
    refetch: refetchInvitedUsers,
  } = useQuery(invitedUsersQuery, {
    fetchPolicy: 'cache-and-network',
  });
  const [activeTab, setActiveTab] = useState('usersList');
  const [users, setUsers] = useState([]);
  const [invitedUsers, setInvitedUsers] = useState([]);
  const [message, setMessage] = useState(
    'You can now attend our events using ListMe app. Find the app in this link: https://listmeapp.page.link/AaTt',
  );
  const [sendBulkInvites, res] = useMutation(bulkUsersInviteMutation);
  const [sendNotification, { error: sendNotificationError }] = useMutation(
    sendNotificationMutation,
  );
  const me = data && data.me ? data.me : {};
  // console.log('users', users);

  useEffect(() => {
    if (
      invitedUsersData &&
      invitedUsersData.invitedUsers &&
      invitedUsersData.invitedUsers.length > 0
    ) {
      const usersArray = invitedUsersData.invitedUsers.map(user => ({
        ...user,
        telephone:
          cleanString(user.telephone).charAt(0) !== '+'
            ? cleanString(`+${user.telephone}`)
            : cleanString(user.telephone),
      }));
      setInvitedUsers(usersArray);
    }
    if (invitedUsersData && invitedUsersData.invitedUsers) {
      const usersArray = users.map(item => {
        const isInvited = find(invitedUsersData.invitedUsers, {
          email: item.email,
          telephone: item.telephone,
        });
        return {
          ...item,
          isInvited: !!isInvited,
        };
      });
      setUsers(usersArray);
    }
  }, [invitedUsersData]);

  const handleChangeUsers = (values, id, telephone, email) => {
    const filteredUsers = filter(users, function(o) {
      return o.telephone !== telephone && o.email !== email;
    });
    const selectedUser = find(users, function(o) {
      return o.telephone === telephone && o.email === email;
    });

    // console.log('filteredUsers', filteredUsers);
    // console.log('selectedUser', selectedUser);

    if (values.isChecked === true) {
      setUsers([{ ...selectedUser, ...values }, ...filteredUsers]);
    } else {
      setUsers([...filteredUsers, { ...selectedUser, ...values }]);
    }
    // users[index] = { ...users[index], ...values };
    // setUsers([...users]);
  };

  const handleChangeInvitedUser = (values, id) => {
    const filteredUsers = filter(invitedUsers, function(o) {
      return o.id !== id;
    });
    const selectedUser = find(invitedUsers, function(o) {
      return o.id === id;
    });
    if (values.isChecked === true) {
      setInvitedUsers([{ ...selectedUser, ...values }, ...filteredUsers]);
    } else {
      setInvitedUsers([...filteredUsers, { ...selectedUser, ...values }]);
    }
    // invitedUsers[index] = { ...invitedUsers[index], ...values };
    // setInvitedUsers([...invitedUsers]);
  };

  const addUsers = formData => {
    // console.log('add users', formData);
    // setUsers([...users, ...formData]);
    setUsers(formData);

    swal({
      title: `Confirm`,
      text: `Import ${formData.length} users?`,
      icon: 'warning',
      buttons: true,
    }).then(async willConfirm => {
      if (willConfirm) {
        const resp = await sendBulkInvites({
          variables: {
            input: { users: formData },
          },
        });
        if (resp) {
          refetchInvitedUsers();
          swal({
            title: 'Invitations sent successfully',
            text: 'All users will be sent invite in next few moments',
            icon: 'success',
          });
          setUsers([]);
        }
      }
    });
  };

  const handleRemove = index => {
    const usersArray = users.slice(0);
    usersArray.splice(index, 1);
    setUsers(usersArray);
  };

  const selectAllUsers = () => {
    if (activeTab === 'usersList') {
      const usersArray = users.map(item => ({
        ...item,
        isChecked: true,
      }));
      setUsers(usersArray);
    } else {
      const invitedUsersArray = invitedUsers.map(item => ({
        ...item,
        isChecked: true,
      }));
      setInvitedUsers(invitedUsersArray);
    }
  };

  const handleSubmitMessage = mode => {
    const filteredUsers = filter(users, { isChecked: true });
    const filteredInvitedUsers = filter(invitedUsers, { isChecked: true });
    filteredUsers.forEach(values => {
      // eslint-disable-next-line no-param-reassign
      delete values.isChecked;
      // eslint-disable-next-line no-param-reassign
      delete values.isInvited;
    });

    const selectedInvitedUsers = filteredInvitedUsers.map(item => item.id);
    const selectedUsers =
      activeTab === 'usersList' ? filteredUsers : selectedInvitedUsers;
    if (!message) {
      swal('Please enter message!', '', 'warning');
    } else if (selectedUsers.length === 0) {
      swal('Please select at least one user', '', 'warning');
    } else {
      swal({
        title: `${
          mode ? `Send ${mode === 'email_only' ? 'Email' : 'SMS'}` : 'Confirm'
        } `,
        text: `${
          mode
            ? `Send ${
                mode === 'email_only' ? 'Email' : 'SMS'
              } to all selected users?`
            : 'Send Invite to all selected users?'
        }`,

        icon: 'warning',
        buttons: true,
      }).then(async willConfirm => {
        if (willConfirm && activeTab === 'usersList') {
          const resp = await sendBulkInvites({
            variables: {
              // input: { mode, users: selectedUsers, message },
              input: { users: selectedUsers },
            },
          });
          if (resp) {
            refetchInvitedUsers();
            swal('Invitation sent successfully', {
              icon: 'success',
            });
          }
        } else if (willConfirm && activeTab === 'invitedUsersList') {
          const resp = await sendNotification({
            variables: {
              input: { message, type: mode, userIds: selectedUsers },
            },
          });
          if (resp) {
            swal('Submit successfully', {
              icon: 'success',
            });
          }
        }
      });
    }
  };

  const handleShare = () => {
    if (!message) {
      swal('Please enter message!', '', 'warning');
    } else {
      navigator.share({
        title: 'ListMe',
        text: message,
        url: 'https://apps.apple.com/in/app/listme-app/id1462565783',
      });
    }
  };

  useEffect(() => {
    if (error) {
      swal('Oops!', error.message, 'error');
    }
    if (res.error) {
      swal('Oops!', res.error.message, 'error');
    }
    if (invitedUsersError) {
      swal('Oops!', invitedUsersError.message, 'error');
    }
    if (sendNotificationError) {
      swal('Oops!', sendNotificationError.message, 'error');
    }
  }, [error, res.error, invitedUsersError, sendNotificationError]);

  return (
    <Layout className="section">
      <nav className="level">
        <div className="level-left">
          <div className="level-item">
            <Tabs
              activeTab={activeTab}
              setActiveTab={val => {
                setActiveTab(val);
              }}
            />
          </div>
        </div>
        {activeTab === 'invitedUsersList' && (
          <div className="level-right mr-3">
            {userStatusOptions.map(item => (
              <p key={item.id} className="level-item mr-5">
                <Icon
                  iconColor={item.iconColor}
                  className={`${item.icon} is-size-5`}
                />
                <strong className="ml-3">{item.title}</strong>
              </p>
            ))}
          </div>
        )}
      </nav>
      <Container className="section has-background-white  has-border-radius-medium mt-5">
        <div className="wrapper pb-4">
          {activeTab === 'usersList' && (
            <UsersTable
              users={users}
              activeTab={activeTab}
              addSingleUser={formData => setUsers([...users, formData])}
              selectAllUsers={selectAllUsers}
              handleChange={handleChangeUsers}
              handleRemove={handleRemove}
              addUsers={addUsers}
            />
          )}
          {activeTab === 'usersList' && users.length > 0 && (
            <div>
              <Button
                className="button is-small is-primary has-border-radius-small"
                onClick={() => handleSubmitMessage('')}
              >
                Send Invite
              </Button>
            </div>
          )}
          {activeTab === 'invitedUsersList' && (
            <>
              <div className="p-5 message-wrapper">
                <div className="columns is-align-items-flex-end ">
                  <div className="column is-8 left-column">
                    <p>
                      <span className="has-text-weight-medium">Hello</span>
                      <span className="ml-1 has-text-weight-medium">
                        {me.username ? me.username : null}
                      </span>{' '}
                      <span className="is-size-7 has-text-grey ml-2">
                        (Enter text below to send a msg)
                      </span>
                    </p>
                    <p>
                      <TextArea
                        // disabled={activeTab === 'usersList'}
                        name="message"
                        placeholder="Enter message here"
                        value={message}
                        onChange={e => setMessage(e.target.value)}
                      />
                    </p>
                  </div>
                  <div className="column is-4 has-text-right">
                    {' '}
                    <div className="buttons is-justify-content-flex-end">
                      <Button
                        title="Send Email"
                        className="button half-rounded"
                        onClick={() => handleSubmitMessage('email_only')}
                      />
                      <Button
                        title="Send SMS"
                        className="button half-rounded"
                        onClick={() => handleSubmitMessage('sms_only')}
                      />

                      <button
                        onClick={handleShare}
                        className="button is-white has-text-grey-light py-0 has-background-transparent px-0 ml-3"
                      >
                        <i className="far fa-share-square is-size-4" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <InvitedUsersTable
                invitedUsers={invitedUsers}
                loading={invitedUsersLoading}
                activeTab={activeTab}
                selectAllUsers={selectAllUsers}
                handleChange={handleChangeInvitedUser}
                me={me}
                message={message}
                setMessage={setMessage}
                handleSubmitMessage={handleSubmitMessage}
                handleShare={handleShare}
              />
            </>
          )}
        </div>
      </Container>
    </Layout>
  );
};
export default Invite;
