import React from 'react';
import styled from 'styled-components';
import { startCase } from 'lodash';

const Container = styled.div``;

const Service = styled.div`
  box-shadow: -3px -4px 18px rgba(0, 0, 0, 0.1), 3px 4px 8px rgba(0, 0, 0, 0.1);
  height: 100%;
  flex-wrap: wrap;
  .is-primary {
    background: #daf4ee !important;
    color: #44c8aa !important;
  }
  .icon {
    color: #44c8aa !important;
  }
  @media screen and (min-width: 769px) {
    .icon-text {
      width: 47%;
    }
  }
`;

const EventTypeDetails = ({ event }) => {
  const eventTypes = [
    {
      id: 1,
      values: event.dressCode,
      icon: 'fas fa-tshirt',
    },
    {
      id: 2,
      values: event.musicPolicy,
      icon: 'fas fa-music',
    },
    {
      id: 3,
      values: event.eventRules,
      icon: 'fas fa-tasks',
    },
    {
      id: 4,
      values: event.partyType,
      icon: 'fas fa-tasks',
    },
  ];
  return (
    <Container>
      <div className="columns">
        <div className="column">
          <Service className="box">
            <div className="is-flex is-justify-content-space-between	 mb-2">
              <p className="subtitle is-6 mb-3 is-size-7-mobile">Repeat type</p>
              <span className="tag  is-size-6 is-primary mb-2 is-size-7-mobile is-capitalized">
                {startCase(event.repeatEvent)}
              </span>
            </div>
            <div className="is-flex  is-justify-content-space-between	">
              <p className="subtitle is-6 mt-2 mb-0 is-size-7-mobile">
                Age Allowed
              </p>
              <span className="tag is-size-6 is-primary mt-2 is-size-7-mobile">
                {event.attendingPeopleAge}
              </span>
            </div>
          </Service>
        </div>
        <div className="column">
          <Service className="box is-flex-tablet is-justify-content-space-between">
            {eventTypes.map(item => (
              <div key={item.id} className="icon-text mb-3 is-flex">
                <span className="subtitle mb-0 mr-2 is-capitalized">
                  <i className={`${item.icon} icon is-size-5 mr-3 `} />
                  {item.values && item.values.length > 0
                    ? item.values.map(value => `${value}, `)
                    : 'None'}
                </span>
              </div>
            ))}
          </Service>
        </div>
      </div>
    </Container>
  );
};

export default EventTypeDetails;
