import React, { useState } from 'react';
import styled from 'styled-components';
import queryString from 'query-string';

import UpdateProfile from './UpdateProfile';
import SubscriptionPayment from '../components/SubscriptionPayment';
import Payments from '../components/Payments';
import ProgressBar from '../components/ProgressBar';

const Container = styled.div`
  padding: 2.25rem;
  height: 100%;
  > div {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 2rem;
    background-color: #fff;
    border-radius: 10px;
    border: 2px solid #f0f0f0;
  }
  @media only screen and (max-width: 768px) {
    padding: 2rem 1rem !important;
    > div {
      padding: 2rem 1rem !important;
    }
    .section {
      padding: 3rem 0rem !important;
    }
    .text-under-image {
      width: 100%;
    }
    .column {
      width: 90%;
    }
  }
`;

const Settings = props => {
  const parsedQuery = queryString.parse(props.location.search);
  // console.log('props', parsedQuery);
  const { step, scope } = parsedQuery;
  const [activeStep, setActiveStep] = useState(parseInt(step, 10) || 1);

  return (
    <Container className="section">
      <div>
        <ProgressBar activeStep={activeStep} setActiveStep={setActiveStep} />
        {activeStep === 1 && (
          <UpdateProfile
            onSettingPage
            // onSubmit={() => setActiveStep(2)}
            setActiveStep={setActiveStep}
          />
        )}
        {activeStep === 2 && (
          <Payments
            setActiveStep={setActiveStep}
            history={props.history}
            pathName={props.location.pathname}
            isSuccess={!!scope}
          />
        )}
        {activeStep === 3 && <SubscriptionPayment history={props.history} />}
      </div>
    </Container>
  );
};

export default Settings;
