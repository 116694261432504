import React from 'react';
import styled from 'styled-components';
import MultiSelect from 'react-multi-select-component';

// import config from '../../utils/config';

const Wrapper = styled.div`
  .multi-select > div {
    border-radius: 50px;
    border-color: #dbdbdb;
    &:focus,
    &:active,
    &:hover,
    &:focus-within {
      border-color: #b5b5b5 !important;
      outline: 0;
      box-shadow: none;
    }
    .dropdown-heading-value span {
    }
    .dropdown-heading {
      padding: 2rem;
      height: 4rem;
    }
    .dropdown-content {
      background-color: transparent;
      box-shadow: none;
    }
    .dropdown-heading-dropdown-arrow {
      color: ${props => props.theme.buttonColor};
    }
  }
  .multi-select.bubble .dropdown-heading-value span {
    color: #fff;
    background: ${props => props.theme.bgLinerGradient};
    ${'' /* background-color: ${config.secondaryColor}; */}
    padding: calc(0.5em - 1px) 1em;
    white-space: nowrap;
    border-radius: 20px;
    display: inline-block;
  }
`;

const MyMultiSelect = ({ value, options, onChange }) => (
  <Wrapper className="field">
    <div className="control">
      <MultiSelect
        className={`multi-select ${value.length && 'bubble'}`}
        options={options}
        value={value}
        onChange={onChange}
        labelledBy="Select"
      />
    </div>
  </Wrapper>
);

export default MyMultiSelect;
