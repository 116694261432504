import React, { useEffect } from 'react';
import { useMutation } from 'react-apollo';
import gql from 'graphql-tag';
import swal from 'sweetalert';
import styled from 'styled-components';

import { Heading, HeadingWrapper } from '../components/elements';
import SetPasswordForm from '../components/form/SetPasswordForm';

const Container = styled.div`
  height: 100vh;
  .min-h-full-screen {
    height: 100vh;
  }
@media only screen and (max-width: 768px) {
    .button.is-large {
      font-size: 1.25rem; !important;
      margin-top: 2rem !important
    }
    .logo-image-mobile {
      width: 170px !important
    }
  }
`;

const setPasswordMutation = gql`
  mutation setNewPassword($input: SetNewPassword) {
    setNewPassword(input: $input) {
      success
    }
  }
`;

const SetPassword = ({ history, match }) => {
  const { token } = match.params;
  const [executeMutation, res] = useMutation(setPasswordMutation);
  const handleSubmit = async data => {
    const resp = await executeMutation({
      variables: { input: { password: data.password, token } },
    });
    if (resp) {
      swal('Success', 'Your password changed successfully', 'success').then(
        value => {
          if (value) {
            history.push('/login');
          }
        },
      );
    }
  };

  useEffect(() => {
    if (res.error) swal('Something went wrong!', res.error.message, 'warning');
  }, [res.error]);

  return (
    <Container className="section  py-0 has-background-light">
      <div className="container ">
        <div className="columns is-mobile is-centered is-vcentered min-h-full-screen">
          <div className="column is-6-tablet is-12-mobile px-5 ">
            <div className="has-text-centered mb-6">
              <img
                src="/images/logo.png"
                alt="logo"
                width="250"
                className="logo-image-mobile"
              />
            </div>
            <HeadingWrapper>
              <Heading title="Reset Password" />
            </HeadingWrapper>
            <SetPasswordForm onSubmit={handleSubmit} />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default SetPassword;
