import React from 'react';
import styled from 'styled-components';
import { capitalize } from 'lodash';

const Container = styled.div`
  .subtitle {
    color: ${props => props.theme.mainBrandColor} !important;
  }

  .is-flex {
    flex: 1;
  }
  img {
    height: 64px;
    width: 64px;
    object-fit: cover;
  }
  /* .button {
    border: 1px solid ${props => props.theme.textColorLight};
    color: ${props => props.theme.textColorLight};
    border-radius: 7px;
  } */
`;

const PartnerCardItem = ({ item }) => {
  return (
    <Container className="column is-4-widescreen is-6-desktop is-6-tablet">
      <div className="card has-border-radius-medium">
        <div className="card-content">
          <div className="media">
            <div className="is-flex">
              <div className="media-left">
                <figure className="image is-64x64">
                  <img
                    className="is-rounded"
                    src={
                      item.user.profile && item.user.profile.image
                        ? item.user.profile.image
                        : 'https://bulma.io/images/placeholders/96x96.png'
                    }
                    alt={
                      item.user.profile && item.user.profile.firstName
                        ? item.user.profile.firstName
                        : item.user.username
                    }
                  />
                </figure>
              </div>
              <div className="media-content">
                <p className="title is-5">
                  {item.user.profile
                    ? `${capitalize(item.user.profile.firstName)} ${capitalize(
                        item.user.profile.lastName,
                      )}`
                    : ''}
                </p>
                {item.user && item.user.username && (
                  <p className="subtitle is-6">@{item.user.username}</p>
                )}
              </div>
            </div>
            {/* <a
              className="button is-small my-1"
              onClick={() => handleRemove(item.user.id)}
              style={{ alignSelf: 'flex-end' }}
            >
              remove
            </a> */}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default PartnerCardItem;
