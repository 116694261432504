import React, { useEffect, useState, useMemo } from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import swal from 'sweetalert';
import { filter, differenceBy, concat } from 'lodash';
import { useMutation, useQuery, useLazyQuery } from '@apollo/react-hooks';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { moment } from '../utils/helpers';
import { Loading, Button, Tag } from '../components/elements';
import PeopleCard from '../components/global/PeopleCard';
import UserCard from '../components/global/UserCard';
import PeoplePaymentStats from '../components/global/PeoplePaymentStats';
// import UserSearchForm from '../components/form/UserSearchForm';
import AddPartnersModal from '../components/updateEventDetails/modals/AddPartnersModal';
import TableWithPagination from '../components/global/TableWithPagination';
// import client from '../utils/apolloClient';
import config from '../utils/config';

const Section = styled.div`
  padding: 2.25rem;
  table {
    color: #858585;
    border-collapse: inherit;
    border-spacing: 0 1em;
  }
  .table-container {
    min-height: 45vh;
  }
  @media only screen and (max-width: 768px) {
    padding: 2rem 1.5rem;
    .username .image {
      width: 30px;
      height: 30px;
    }
  }
`;

// const TableColumn = styled.th`
//   color: #858585 !important;
// `;
const HeaderButtons = styled.div`
  .level-left {
    .tag {
      background-color: #0fe133;
      color: #fff;
      margin-left: 10px;
    }
  }
  @media only screen and (max-width: 768px) {
    .button.is-small {
      font-size: 10px !important;
      padding-left: 0.4rem;
      padding-right: 0.4rem;
    }
    .level-item:last-child {
      margin-bottom: 0.75rem;
    }
  }
`;
const Container = styled.div`
  padding: 45px 5% 0;
  background-color: #fff;
  border-radius: 10px;
  border: 2px solid #f0f0f0;

  table th {
    border: none;
    text-align: center !important;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
    :first-child {
      padding-left: 1rem;
      text-align: center !important;
    }
  }
  @media only screen and (max-width: 768px) {
  }
`;

const eventQuery = gql`
  query singleEvent($id: ID!) {
    singleEvent(id: $id) {
      id
      name
      status
      # isPrivate
      currency
      description
      featuredImage
      eventStartTime
      eventEndTime
      pricing {
        privateServices
      }
      createdBy {
        id
        profile {
          firstName
          lastName
        }
      }
      promoters {
        user {
          id
        }
      }
      influencers {
        user {
          id
        }
      }
      partners {
        user {
          id
        }
      }
      doorPickers {
        user {
          id
          username
          profile {
            firstName
            lastName
            image
          }
        }
        commission
      }
    }
  }
`;

const eventGuestListQuery = gql`
  query guestListAllBookings(
    $status: [BookingStatus]
    $eventId: ID
    $searchQuery: String
    $showParentBookings: Boolean
    $limit: Int
    $skip: Int
  ) {
    allBookings(
      status: $status
      eventId: $eventId
      searchQuery: $searchQuery
      showParentBookings: $showParentBookings
      limit: $limit
      skip: $skip
    ) {
      totalBookings
      totalPendingApprovalBookings
      bookings {
        id
        pricingType
        amount
        pricingLabel
        isWalkIn
        status
        paymentStatus
        paymentUrl
        createdAt
        checkInAt
        rejectedAt
        ejectedAt
        paymentMethod
        ejectReason
        walkInData {
          personName
          telephone
        }
        referredBy {
          telephone
          profile {
            firstName
            lastName
          }
        }
        walkInBy {
          profile {
            firstName
            lastName
          }
        }
        rejectedBy {
          profile {
            firstName
            lastName
          }
        }
        checkInBy {
          id
          profile {
            firstName
            lastName
          }
        }
        user {
          id
          username
          telephone
          covidPassport
          verificationStatus
          identification {
            idDocType
            firstName
          }
          profile {
            firstName
            lastName
            image
            sex
            dob
          }
        }
        parent {
          id
        }
        collaborators {
          id
          status
          user {
            id
            username
            telephone
            covidPassport
            verificationStatus
            identification {
              idDocType
              firstName
            }
            profile {
              firstName
              lastName
              image
              dob
              sex
            }
          }
          booking {
            id
            pricingType
            pricingLabel
            isWalkIn
            status
            paymentStatus
            paymentUrl
            createdAt
            checkInAt
            rejectedAt
            ejectedAt
            paymentMethod
            ejectReason
            walkInData {
              personName
              telephone
            }
            referredBy {
              telephone
              profile {
                firstName
                lastName
              }
            }
            walkInBy {
              profile {
                firstName
                lastName
              }
            }
            rejectedBy {
              profile {
                firstName
                lastName
              }
            }
            checkInBy {
              id
              profile {
                firstName
                lastName
              }
            }
            user {
              id
              username
              telephone
              covidPassport
              verificationStatus
              identification {
                idDocType
                firstName
              }
              profile {
                firstName
                lastName
                image
                sex
                dob
              }
            }
          }
        }
      }
    }
  }
`;

const userSearchDataQuery = gql`
  query userSearch($customerType: UserType, $limit: Int, $query: String!) {
    userSearch(customerType: $customerType, limit: $limit, query: $query) {
      id
      email
      rating
      username
      profile {
        firstName
        lastName
      }
    }
  }
`;

const meQuery = gql`
  query meEvents {
    me {
      id
    }
  }
`;

const updateEventMutation = gql`
  mutation updateEvent($id: ID!, $input: EventInput) {
    updateEvent(id: $id, input: $input) {
      id
      name
    }
  }
`;

const updateEventPartnerMutation = gql`
  mutation updateEventPartner(
    $eventId: ID!
    $userId: ID!
    $commission: Float
    $type: UpdateEventPartnerType!
    $method: UpdateEventPartnerMethod!
  ) {
    updateEventPartner(
      eventId: $eventId
      userId: $userId
      type: $type
      method: $method
      commission: $commission
    ) {
      id
    }
  }
`;

const inviteUserMutation = gql`
  mutation inviteUser($input: InviteUserInput!) {
    inviteUser(input: $input) {
      id
    }
  }
`;

const tableHeadData = [
  {
    Header: 'Account Status',
  },
  {
    Header: 'Payment Status',
  },
  {
    Header: 'Booking Type',
    accessor: 'pricingType',
  },
  {
    Header: 'Booking Info',
    accessor: 'pricingLabel',
  },
  {
    Header: 'User Name',
    accessor: 'user.profile.firstName',
  },
  {
    Header: 'ID verified',
  },
  {
    Header: 'COVID Passport',
  },
  {
    Header: 'Age',
  },
  {
    Header: 'Gender',
    accessor: 'user.profile.sex',
  },
  {
    Header: 'Promoter list',
  },
  {
    Header: 'CheckIn By',
  },
  {
    Header: 'CheckIn Time',
  },
  {
    Header: 'Time Stamp',
  },
  {
    Header: 'Action',
  },
];

const GuestListItem = ({ event, refetch }) => {
  const [skipBookings, setSkipBookings] = useState(0);
  const [bookingsLimit, setBookingsLimit] = useState(30);
  const [search, setSearch] = useState('');
  const [userSearchQuery, setUserSearchQuery] = useState('');

  const { data: meData, error, loading } = useQuery(meQuery, {
    fetchPolicy: 'cache-and-network',
  });
  const {
    data: bookingsData,
    error: bookingsError,
    loading: bookingsLoading,
  } = useQuery(eventGuestListQuery, {
    fetchPolicy: 'cache-and-network',
    // pollInterval: 100000, // 100 secs
    variables: {
      status: [
        'pending',
        'authorized',
        'checkedIn',
        'failed',
        'refunded',
        // 'refused',
        'rejected',
      ],
      eventId: event.id,
      searchQuery: search && search.length > 1 ? search : '',
      showParentBookings: true,
      limit: bookingsLimit,
      skip: skipBookings * bookingsLimit,
    },
  });

  const [
    loadUsers,
    { data: data2, error: error2, loading: usersLoading },
  ] = useLazyQuery(userSearchDataQuery, {
    fetchPolicy: 'cache-and-network',
  });

  const [updateEvent, res] = useMutation(updateEventMutation);
  const [updateEventPartner, { error: updateEventPartnerError }] = useMutation(
    updateEventPartnerMutation,
  );
  const [inviteDoorPickerMutation, { error: inviteUserError }] = useMutation(
    inviteUserMutation,
  );
  const [showAssignDoorPickerModal, setShowAssignDoorPickerModal] = useState(
    false,
  );
  const [eventStatus, setEventStatus] = useState(event.status);
  const [doorPickers, setDoorPickers] = useState([]);
  const [allowEventStart, setAllowEventStart] = useState(false);
  const [copied, setCopied] = useState(false);

  const eventEnded = moment().isAfter(event.eventEndTime);
  const eventStarted = moment().isAfter(event.eventStartTime);

  const me = meData && meData.me ? meData.me : {};

  const partnersArray = concat(
    event.promoters,
    event.influencers,
    event.partners,
    doorPickers,
  );

  const allPartners = [];
  if (partnersArray) {
    partnersArray.forEach(item => {
      if (item.user === null) {
        return null;
      }
      return allPartners.push({
        id: item.user.id,
      });
    });
  }

  const allUsers = data2 && data2.userSearch ? data2.userSearch : [];
  // console.log('allUsers', allUsers);
  const usersList = filter(allUsers, function(o) {
    return (
      o.username !== null ||
      (o.profile !== null && o.profile.firstName !== null)
    );
  });
  const filteredUsers = differenceBy(usersList, allPartners, 'id');
  // console.log('allBookings', allBookings);

  useEffect(() => {
    setDoorPickers(event ? event.doorPickers : []);
  }, [event.doorPickers]);
  // console.log('event.doorPickers', event.doorPickers);

  useEffect(() => {
    // allow event to start only before 2 hours of start time
    // const eventOpened = moment().isAfter(
    //   moment(event.eventStartTime).subtract(2, 'hours'),
    // );
    const eventOpened = true;
    if (doorPickers.length > 0 && eventOpened) {
      setAllowEventStart(true);
    }
  }, [doorPickers]);

  useEffect(() => {
    setEventStatus(event.status);
  }, [event.status]);

  useEffect(() => {
    loadUsers({
      variables: {
        limit: 1000,
        customerType: 'customer',
        query:
          userSearchQuery && userSearchQuery.length > 1 ? userSearchQuery : '',
      },
    });
  }, [userSearchQuery]);
  // console.log('dfdfefe', userSearchQuery);

  const toggleModal = () => {
    setShowAssignDoorPickerModal(!showAssignDoorPickerModal);
  };

  const allDoorPickers = [];
  if (doorPickers) {
    doorPickers.forEach(item => {
      if (item.user === null) {
        return null;
      }
      return allDoorPickers.push({
        user: item.user.id,
        commission: item.commission,
      });
    });
  }

  // console.log(allDoorPickers);
  const updateDoorPickers = async formData => {
    const resp = await updateEventPartner({
      variables: {
        eventId: event.id,
        type: 'doorPickers',
        userId: formData.userId,
        commission: 0,
        method: formData.method,
      },
    });
    if (resp) {
      refetch();
      if (formData.method === 'remove') {
        swal({
          title: '',
          text: 'Door picker removed successfully!',
          icon: 'success',
        });
      } else {
        await swal({
          title: '',
          text: 'Assigned a new door picker!',
          icon: 'success',
        });
      }
    }
  };

  const handleCreateUser = async formData => {
    const resp = await inviteDoorPickerMutation({
      variables: {
        input: { ...formData, eventId: event.id, partnerType: 'doorPicker' },
      },
    });
    if (resp) {
      swal({
        title: '',
        text: `Added Successfully!`,
        icon: 'success',
      });
      toggleModal();
      refetch();
    }
  };

  const updateEventStatus = formData => {
    swal({
      title: `Are you sure you wish to ${formData.swalTitle} the event`,
      text: formData.swalSubtitle,
      icon: 'warning',
      // buttons: true,
      buttons: ['No', 'Yes'],
    }).then(async willConfirm => {
      if (willConfirm) {
        const resp = await updateEvent({
          variables: {
            id: event.id,
            input: { status: formData.status },
          },
        });
        if (resp) {
          refetch();
          setEventStatus(formData.status);
        }
      }
    });
  };

  const handleCancelEvent = () => {
    swal({
      title: 'Are you sure?',
      text: 'You wish to cancel this event?',
      icon: 'warning',
      dangerMode: true,
      buttons: ['Cancel', 'Yes'],
    }).then(async willDelete => {
      if (willDelete) {
        const resp = await updateEvent({
          variables: {
            id: event.id,
            input: { status: 'cancelled' },
          },
        });
        if (resp) {
          refetch();
          setEventStatus('cancelled');
          swal('Your event has been cancelled!', {
            icon: 'success',
          });
        }
      }
    });
  };

  useEffect(() => {
    if (res.error) {
      swal('Oops!', res.error.message, 'error');
    }
    if (error) {
      swal('Something went wrong!', error.message, 'error');
    }
    if (error2) {
      swal('Something went wrong!', error2.message, 'error');
    }
    if (updateEventPartnerError) {
      swal('Oops!', updateEventPartnerError.message, 'error');
    }
    if (inviteUserError) {
      swal('Oops!', inviteUserError.message, 'error');
    }
    if (bookingsError) {
      swal('Oops!', bookingsError.message, 'error');
    }
  }, [
    res.error,
    error,
    updateEventPartnerError,
    inviteUserError,
    bookingsError,
  ]);
  // console.log(event);

  const allBookings = bookingsData?.allBookings?.bookings
    ? bookingsData.allBookings.bookings
    : [];

  const pendingApprovalBookings =
    bookingsData?.allBookings?.totalPendingApprovalBookings || 0;
  const totalBookings = bookingsData?.allBookings?.totalBookings || 0;
  const columns = React.useMemo(() => tableHeadData, []);
  const eventStatusLabel = useMemo(() => {
    let val = event?.status;
    if (event?.status === 'open') {
      val = 'Published';
    } else if (event?.status === 'close') {
      val = 'Closed';
    }
    return val;
  }, [event?.status]);
  if (loading || !meData) {
    return <Loading />;
  }

  return (
    <>
      <HeaderButtons>
        <nav className="level">
          <div className="level-left">
            <div className="level-item">
              <h1 className="has-text-weight-semibold is-size-3 is-size-5-mobile">
                {event.name}
              </h1>
              {/* <span className="tag is-medium">£{event.ticketCost}</span> */}
            </div>
          </div>
          {me.id && me.id === event.createdBy.id && (
            <div className="level-right columns is-mobile is-multiline is-justify-content-space-between mx-0">
              {event?.pricing?.privateServices?.length > 0 && (
                <div className="level-item">
                  <Link to={`/guestlist-approval/${event.id}`}>
                    <Button
                      title={`Guests approval (${pendingApprovalBookings})`}
                      className="button is-small half-rounded"
                    />
                  </Link>
                </div>
              )}
              <div className="level-item">
                <Button
                  // title={eventStatus === 'started' ? 'End Event' : 'Start Event'}
                  title="Start Event"
                  className="button is-small half-rounded"
                  // disabled={!allowEventStart || eventEnded}
                  disabled={
                    eventEnded ||
                    !allowEventStart ||
                    eventStatus === 'started' ||
                    eventStatus === 'paused'
                  }
                  onClick={() =>
                    updateEventStatus({
                      status: 'started',
                      swalTitle: 'start',
                    })
                  }
                />
              </div>
              <div className="level-item">
                <Button
                  disabled={(!eventStarted && !allowEventStart) || eventEnded}
                  title={
                    eventStatus === 'started' ? 'Pause Event' : 'Resume Event'
                  }
                  className="button is-small half-rounded"
                  onClick={() => {
                    const newStatus =
                      eventStatus === 'started' ? 'paused' : 'started';
                    const swalTitle =
                      eventStatus === 'started' ? 'pause' : 'resume';
                    updateEventStatus({
                      status: newStatus,
                      swalTitle,
                    });
                  }}
                />
              </div>
              <div className="level-item">
                <Button
                  // disabled={!eventStarted || eventStatus === 'close'}
                  disabled={
                    !eventStarted || eventEnded || eventStatus === 'close'
                  }
                  title="End Event"
                  className="button is-small half-rounded"
                  onClick={() =>
                    updateEventStatus({
                      status: 'close',
                      swalTitle: 'end',
                      swalSubtitle: 'The event cannot be restarted once ended!',
                    })
                  }
                />
              </div>
              <div className="level-item">
                <Button
                  disabled={eventEnded}
                  title="Cancel"
                  className="button is-small half-rounded"
                  onClick={handleCancelEvent}
                />
              </div>
              <div className="level-item">
                <Button
                  disabled={eventEnded}
                  title="Door Picker"
                  className="button is-small half-rounded"
                  onClick={toggleModal}
                />
              </div>
            </div>
          )}
        </nav>
      </HeaderButtons>
      {/* <h2 className="has-text-weight-semibold is-size-5 mt-5">Door Pickers</h2> */}
      <div className="level mt-4 mb-3 columns is-mobile mx-0">
        <div className="level-left">
          <div className="level-item">
            <h2 className="has-text-weight-semibold is-size-5 is-size-6-mobile">
              Door Pickers
            </h2>
          </div>
        </div>
        <div className="level-right">
          <div className="level-item">
            {copied && <span className="mr-2 is-size-7">Copied!</span>}
            <CopyToClipboard
              text={`You are invited for (${event.name}). Click on link for event details:- ${config.websiteUrl}/event/${event.id}`}
              onCopy={() => {
                setCopied(true);
                setTimeout(() => {
                  setCopied(false);
                }, 2000);
              }}
            >
              <button className="tag is-info border-none has-text-weight-medium border-">
                Copy Invite Link
              </button>
            </CopyToClipboard>
          </div>
          <div className="level-item">
            <Tag
              autoWidth
              className={eventStatus}
              size="is-small"
              label={eventStatusLabel}
            />
          </div>
        </div>
      </div>

      {doorPickers.length === 0 ? (
        <p className="mb-6">Please Assign Door Picker to start this event</p>
      ) : (
        <div className="columns is-multiline mt-1 mb-6">
          {doorPickers.map(item => {
            if (item !== null && item.user) {
              return (
                <div className="column is-half" key={item.user.id}>
                  <UserCard
                    isBrandEvent={me.id === event.createdBy.id}
                    data={item.user}
                    removeDoorPicker={userId => {
                      updateDoorPickers({
                        userId,
                        method: 'remove',
                      });
                    }}
                    enableRemoveButton
                  />
                </div>
              );
            }
            return null;
          })}
        </div>
      )}

      <Container>
        <PeoplePaymentStats event={event} />
        <div className="table-container pb-6">
          <TableWithPagination
            marginRight="1rem"
            columns={columns}
            loading={bookingsLoading}
            data={allBookings}
            pageLimit={bookingsLimit}
            totalCount={totalBookings}
            manualPagination
            currentPage={skipBookings}
            fetchData={(pageIndex, pageSize) => {
              setBookingsLimit(pageSize);
              setSkipBookings(pageIndex);
            }}
            onSearch={val => setSearch(val)}
            rowItem={row => (
              <PeopleCard
                key={row.id}
                data={row.original}
                isWalkIn={row.original.isWalkIn}
                status={row.original.status}
                event={event}
                isBrandEvent={me.id === event.createdBy.id}
              />
            )}
          />
        </div>
        <AddPartnersModal
          currency={event.currency}
          title="Add Door Picker"
          hideCommissionOptions
          loading={usersLoading}
          filteredUsers={filteredUsers}
          onClose={() =>
            setShowAssignDoorPickerModal(!showAssignDoorPickerModal)
          }
          onSearch={val => setUserSearchQuery(val)}
          isActive={showAssignDoorPickerModal}
          handleAddPartner={async formData => {
            await updateDoorPickers({
              userId: formData.user,
              method: 'add',
            });
            setShowAssignDoorPickerModal(!showAssignDoorPickerModal);
          }}
          handleCreatePartner={handleCreateUser}
        />
      </Container>
    </>
  );
};

const GuestList = ({ match }) => (
  <Section className="section">
    <Query
      query={eventQuery}
      variables={{
        id: match.params.eventId,
      }}
      fetchPolicy="no-cache"
      // pollInterval={10000}
    >
      {({ loading, error, data, refetch }) => {
        if (loading && !data) return <Loading />;
        if (error && !data) return `Error! ${error.message}`;
        const singleEvent = data && data.singleEvent ? data.singleEvent : null;
        if (singleEvent) {
          return (
            <GuestListItem
              match={match}
              event={singleEvent}
              refetch={refetch}
            />
          );
        }
        return null;
      }}
    </Query>
  </Section>
);
export default GuestList;
