import React from 'react';
import { Modal } from '../elements';
import AddUserForm from '../form/AddUserForm';

const InviteUserModal = ({ onClose, isActive, onSubmit }) => {
  return (
    <Modal isActive={isActive} onClose={onClose} title="Add User">
      <AddUserForm onSubmit={onSubmit} onCancelPress={onClose} />
    </Modal>
  );
};
export default InviteUserModal;
