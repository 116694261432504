/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import swal from 'sweetalert';
import { size, filter, isEmpty, find } from 'lodash';

import { Subtitle, Button } from '../elements';
import EntryTypeOptions from './EntryTypeOptions';
import GuestListPricingTable from './GuestListPricingTable';
import ReservationTable from './ReservationTable';
import TicketsPricingTable from './TicketsPricingTable';
import SelectCurrencyForm from './forms/SelectCurrencyForm';

const Container = styled.div`
  .text {
    padding-top: 0 !important;
  }
  .is-Primary {
    color: ${props => props.theme.mainBrandColor};
  }
`;

const EntryType = ({
  loading,
  event,
  handleBackPress,
  onSubmit,
  onChange,
  handleChangeCurrency,
}) => {
  const [entryType, setEntryType] = useState('guestList');
  if (event && size(event.pricing) > 1) {
    delete event.pricing.__typename;
    event.pricing.guestList &&
      event.pricing.guestList.forEach(item => {
        delete item.__typename;
      });
    event.pricing.ticket &&
      event.pricing.ticket.forEach(item => {
        delete item.__typename;
      });
    event.pricing.reservations &&
      event.pricing.reservations.forEach(item => {
        delete item.__typename;
        delete item.timeSlots.__typename;
        item.timeSlots.forEach(data => {
          delete data.__typename;
        });
      });
    event.pricing.virtual &&
      event.pricing.virtual.forEach(item => {
        delete item.__typename;
      });
  }

  const [pricing, setPricing] = useState(event.pricing || {});
  const [primary, setPrimary] = useState(
    event.pricing ? event.pricing.primary : 'guestList',
  );
  const [privateServices, setPrivateServices] = useState(
    event.pricing ? event.pricing.privateServices : [],
  );

  useEffect(() => {
    onChange({ pricing });
  }, [pricing]);

  useEffect(() => {
    setPricing({ ...pricing, primary });
  }, [primary]);

  useEffect(() => {
    setPricing({ ...pricing, primary, privateServices });
  }, [privateServices]);
  const hasCheckedPricing = {};

  const guestList = filter(pricing.guestList, o => {
    return o.isVisible && o.quantity > 0;
  });
  const ticket = filter(pricing.ticket, o => {
    return o.isVisible && o.quantity > 0;
  });
  const reservations = filter(pricing.reservations, o => {
    return (
      o.label &&
      o.details &&
      o.isVisible &&
      o.quantity > 0 &&
      o.allowedPeopleLimit > 0 &&
      o.timeSlots.length > 0
    );
  });
  const virtual = filter(pricing.virtual, o => {
    return o.isVisible && o.quantity > 0;
  });

  hasCheckedPricing.guestList = !isEmpty(guestList);
  hasCheckedPricing.ticket = !isEmpty(ticket);
  hasCheckedPricing.reservations = !isEmpty(reservations);
  hasCheckedPricing.virtual = !isEmpty(virtual);

  const visibleGuestListPackages = filter(pricing.guestList, 'isVisible');
  const visibleTicketsPackages = filter(pricing.ticket, 'isVisible');
  const visibleReservationsPackages = filter(pricing.reservations, 'isVisible');
  const visibleVirtualPackages = filter(pricing.virtual, 'isVisible');

  const handleSubmit = () => {
    // TODO: check if all values are there
    const alertTitle = 'Something Went Wrong';
    if (
      isEmpty(guestList) &&
      isEmpty(ticket) &&
      isEmpty(reservations) &&
      isEmpty(virtual)
    ) {
      swal(
        alertTitle,
        'Please select at least one entry type and fill all details!',
      );
    } else if (
      !isEmpty(guestList) &&
      size(guestList) !== size(visibleGuestListPackages)
    ) {
      swal(`${alertTitle} in Guestlist`, '');
    } else if (
      !isEmpty(ticket) &&
      size(ticket) !== size(visibleTicketsPackages)
    ) {
      swal(`${alertTitle} in Tickets`, '');
    } else if (
      !isEmpty(virtual) &&
      size(virtual) !== size(visibleVirtualPackages)
    ) {
      swal(`${alertTitle} in Virtual`, '');
    } else if (
      // !isEmpty(reservations) &&
      size(reservations) !== size(visibleReservationsPackages)
    ) {
      swal(`${alertTitle} in Reservations`, '');
    } else {
      onSubmit({ pricing });
    }
  };

  const handleUpdatePrivateServices = val => {
    const privateService = find(privateServices, o => {
      return o === val;
    });
    if (privateService) {
      const privateServicesArray = filter(privateServices, o => {
        return o !== val;
      });
      setPrivateServices(privateServicesArray);
    } else {
      setPrivateServices([...privateServices, val]);
    }
  };

  return (
    <Container className="mt-5">
      <SelectCurrencyForm
        disabled={event.status === 'open'}
        initialValues={event}
        onSubmit={handleChangeCurrency}
      />
      <Subtitle className="text">Please select event entry type</Subtitle>
      <EntryTypeOptions
        primary={primary}
        privateServices={privateServices}
        hasCheckedPricing={hasCheckedPricing}
        setPrimary={val => setPrimary(val)}
        isActive={entryType}
        onClick={val => {
          setEntryType(val);
        }}
        onChange={handleUpdatePrivateServices}
      />
      {entryType === 'guestList' && (
        <GuestListPricingTable
          event={event}
          initialPricing={pricing && pricing.guestList}
          showErrors={!isEmpty(guestList)}
          onChange={values => {
            setPricing({ ...pricing, guestList: values });
          }}
        />
      )}
      {entryType === 'ticket' && (
        <TicketsPricingTable
          event={event}
          initialPricing={pricing && pricing.ticket}
          showErrors={!isEmpty(ticket)}
          onChange={values => {
            setPricing({ ...pricing, ticket: values });
          }}
        />
      )}
      {entryType === 'reservations' && (
        <ReservationTable
          event={event}
          initialPricing={pricing && pricing.reservations}
          onChange={values => {
            setPricing({ ...pricing, reservations: values });
          }}
        />
      )}
      {entryType === 'virtual' && (
        <TicketsPricingTable
          isVirtualPricing
          event={event}
          showErrors={!isEmpty(virtual)}
          initialPricing={pricing && pricing.virtual}
          onChange={values => {
            setPricing({ ...pricing, virtual: values });
          }}
        />
      )}
      <p className="buttons is-justify-content-center mt-5">
        <Button
          disabled={loading}
          onClick={() => {
            onChange({ pricing });
            handleBackPress();
          }}
          title="Back"
          className="is-rounded button is-medium  px-6"
          type="button"
        />
        <Button
          disabled={loading}
          onClick={handleSubmit}
          title="Next"
          className="is-rounded button is-medium is-primary px-6"
          type="button"
        />
      </p>
    </Container>
  );
};

export default EntryType;
