import React, { useState, useEffect } from 'react';

import { slice } from 'lodash';
import TableHeader from '../table/TableHeader';
import TablePricingItem from './TablePricingItem';
import TableWrapper from '../table/TableWrapper';
import EventVirtualLinks from './EventVirtualLinks';
import TicketPricingRules from './TicketPricingRules';

const defaultPricingArray = [
  {
    label: 'First release',
    details: '',
    price: 0,
    quantity: 0,
    isVisible: true,
    rules: [],
    extraInfo: [],
  },
  {
    label: 'Second release',
    details: '',
    price: 0,
    quantity: 0,
    isVisible: true,
    rules: [],
    extraInfo: [],
  },
  {
    label: 'Final release',
    details: '',
    price: 0,
    quantity: 0,
    isVisible: true,
    rules: [],
    extraInfo: [],
  },
  {
    label: 'VIP',
    details: '',
    price: 0,
    quantity: 0,
    isVisible: true,
    rules: [],
    extraInfo: [],
  },
];

const TicketsPricingTable = ({
  initialPricing,
  event,
  onChange,
  showErrors,
  isVirtualPricing,
}) => {
  const virtualPricing = slice(defaultPricingArray, 0, 3);
  const defaultPricing = isVirtualPricing
    ? virtualPricing
    : defaultPricingArray;

  // const virtualPricingHead = filter(ticketTableHeadData, o => {
  //   return o.data !== 'Description';
  // });

  // const tableHead = isVirtualPricing ? virtualPricingHead : ticketTableHeadData;
  const [pricing, setPricing] = useState(
    initialPricing && initialPricing.length > 0
      ? initialPricing
      : defaultPricing,
  );

  const addPackage = async () => {
    const item = {
      label: '',
      details: '',
      price: 0,
      quantity: 0,
      isVisible: true,
      rules: [],
    };
    setPricing([...pricing, item]);
  };

  const ticketTableHeadData = [
    {
      id: 1,
      data: 'Ticket Type',
    },
    {
      id: 2,
      data: 'Ticket Info',
    },
    {
      id: 3,
      data: 'Price',
    },
    {
      id: 4,
      data: 'Quantity',
    },
    {
      id: 4.5,
      data: 'Action',
    },
    {
      id: 5,
      data: <i className="fas fa-plus mr-4" />,
      onClick: addPackage,
    },
  ];

  const handleChange = (values, index) => {
    pricing[index] = { ...pricing[index], ...values };
    setPricing([...pricing]);
  };

  const handleExtraInfoChange = extraInfo => {
    const newExtraInfo = pricing.map(val => ({
      ...val,
      extraInfo,
    }));
    setPricing([...newExtraInfo]);
  };

  const handleRulesChange = rules => {
    const newPricingRules = pricing.map(val => ({
      ...val,
      rules,
    }));
    setPricing([...newPricingRules]);
  };

  useEffect(() => {
    onChange(pricing);
  }, [pricing]);

  return (
    <TableWrapper>
      <table className="table has-background-transparent">
        <TableHeader data={ticketTableHeadData} />
        <tbody>
          {pricing &&
            pricing.map((item, index) => (
              <TablePricingItem
                key={item.label}
                item={item}
                event={event}
                quantityText="Tickets"
                showErrors={showErrors}
                editable
                currency={event.currency}
                onChange={val => handleChange(val, index)}
              />
            ))}
        </tbody>
      </table>
      {isVirtualPricing ? (
        <EventVirtualLinks
          extraInfo={pricing && pricing[0] ? pricing[0].extraInfo : []}
          onChange={handleExtraInfoChange}
        />
      ) : (
        <TicketPricingRules
          rules={pricing && pricing[0] ? pricing[0].rules : []}
          onChange={handleRulesChange}
        />
      )}
    </TableWrapper>
  );
};

export default TicketsPricingTable;
