import React, { useState, useEffect } from 'react';
import { uniqWith, isEqual } from 'lodash';

import TableHeader from '../table/TableHeader';
import EventTablesRules from './EventTablesRules';
import ReservationTableRow from './ReservationTableRow';
import TableWrapper from '../table/TableWrapper';
import { Button } from '../elements';

const ReservationTable = ({ event, initialPricing, onChange }) => {
  const [pricing, setPricing] = useState(
    initialPricing && initialPricing.length > 0 ? initialPricing : [],
  );
  const allTimeSlots = [];
  pricing.map(
    item => item && item.timeSlots.map(slot => allTimeSlots.push(slot)),
  );
  const uniqTimeSlots = uniqWith(allTimeSlots, isEqual);
  const addPackage = async () => {
    const item = {
      label: '',
      details: '',
      price: 0,
      quantity: 0,
      bookedQuantity: 0,
      allowedPeopleLimit: 0,
      isVisible: true,
      rules: [],
      timeSlots: uniqTimeSlots,
    };
    setPricing([...pricing, item]);
  };

  const reservationTableHeadData = [
    {
      id: 1,
      data: 'Package Type',
    },
    {
      id: 2,
      data: 'Package Info',
    },
    {
      id: 2.5,
      data: 'Price',
    },
    {
      id: 3,
      data: 'Quantity',
    },
    {
      id: 4,
      data: 'Max No of PPL',
    },
    {
      id: 5,
      data: 'Time slots',
    },
    {
      id: 5.5,
      data: 'Action',
    },
    {
      id: 6,
      data: <i className="fas fa-plus" />,
      onClick: addPackage,
    },
  ];

  const handleChange = (values, index) => {
    pricing[index] = { ...pricing[index], ...values };
    setPricing([...pricing]);
  };

  const handleRulesChange = rules => {
    const newPricingRules = pricing.map(val => ({
      ...val,
      rules,
    }));
    setPricing([...newPricingRules]);
  };

  useEffect(() => {
    onChange(pricing);
  }, [pricing]);
  return (
    <TableWrapper className="table-wrapper section">
      <table className="table has-background-transparent  mb-0">
        <TableHeader data={reservationTableHeadData} />
        <tbody>
          {pricing.length > 0 &&
            pricing.map((item, index) => {
              const keyValue = index;
              return (
                <ReservationTableRow
                  key={keyValue}
                  item={item}
                  event={event}
                  onChange={val => handleChange(val, index)}
                />
              );
            })}
        </tbody>
      </table>
      {pricing.length === 0 && (
        <p className="has-text-centered has-text-weight-semibold is-size-4 my-5">
          Add Package
          <Button
            className="button is-primary is-small half-rounded ml-3 mt-1"
            onClick={addPackage}
          >
            <span className="icon ">
              <i className="fas fa-plus" />
            </span>
          </Button>
        </p>
      )}
      <EventTablesRules
        rules={pricing && pricing[0] ? pricing[0].rules : []}
        onChange={handleRulesChange}
      />
    </TableWrapper>
  );
};

export default ReservationTable;
