import React from 'react';
import { withFormik } from 'formik';
import * as yup from 'yup';
import { upperCase, lowerCase } from 'lodash';
import { Select } from '../../elements';

const currencyOptions = ['GBP', 'EUR', 'USD', 'CAD', 'AED', 'INR'];

const SelectCurrencyForm = props => {
  const {
    disabled,
    isSecondary,
    values,
    handleSubmit,
    errors,
    touched,
    setFieldValue,
    handleBlur,
  } = props;

  return (
    <div className={`columns is-vcentered ${isSecondary ? 'mb-0' : 'mb-4'}`}>
      <div
        className={`column ${
          isSecondary ? 'is-full' : 'is-3-desktop is-4-tablet'
        } `}
      >
        <Select
          disabled={disabled}
          isMedium
          options={currencyOptions}
          name="currency"
          type="text"
          placeholder="Pick Currency"
          value={values.currency}
          onChange={async e => {
            await setFieldValue('currency', e.target.value);
            handleSubmit();
          }}
          onBlur={handleBlur}
          errors={
            errors.currency && touched.currency ? errors.currency : undefined
          }
        />
      </div>
      {!isSecondary && (
        <div className="column">
          <p className="is-Primary is-size-6 mt-2 has-text-weight-semibold">
            Currency conversion
          </p>
        </div>
      )}
    </div>
  );
};

export default withFormik({
  mapPropsToValues: ({ initialValues }) => ({
    currency: upperCase(initialValues.currency) || '',
  }),
  validationSchema: yup.object().shape({
    currency: yup.string().required('Please select currency'),
  }),

  handleSubmit: (values, { props }) => {
    props.onSubmit({ currency: lowerCase(values.currency) });
  },
  displayName: 'SelectCurrencyForm', // helps with React DevTools
})(SelectCurrencyForm);
