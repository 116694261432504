import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.span`
  &.tag {
    background-color: #0fe133;
    width: ${props => (props.autoWidth ? 'auto' : '7vw')};
    color: #fff;
    text-transform: capitalize;
    &.draft {
      background-color: #b5b5b5;
    }
    &.started {
      background-color: #0fe133;
    }
    &.paused {
      ${'' /* background-color: #a5227e; */}
      background: ${props => props.theme.bgLinerGradient};
    }
    &.open,
    &.invited, &.authorizedPending {
      background-color: #f8c705;
    }
    &.close,
    &.soldOut,
    &.failed, &.unpaid {
      background-color: #f14668;
    }
    &.refunded {
      background-color: #ff002f7a;
    }
  }
`;

const Tag = ({ label, className, autoWidth, size, ...other }) => (
  <Wrapper
    autoWidth={autoWidth}
    className={`tag ${!size ? 'is-medium' : size} ${className}`}
    {...other}
  >
    {label}
  </Wrapper>
);

export default Tag;
