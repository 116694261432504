import React, { useEffect } from 'react';
import { withFormik } from 'formik';
import * as yup from 'yup';
import styled from 'styled-components';
import { upperCase } from 'lodash';
import { Button, MultipleSelectInput } from '../../elements';

const currencyFormatter = require('currency-formatter');

const Commission = styled.span`
  border: 1px solid
    ${props =>
      props.isActive ? props.theme.mainBrandColor : props.theme.borderColor};
  color: ${props => (props.isActive ? '#fff' : '#B7007B90')} !important;
  background: ${props =>
    props.isActive ? props.theme.bgLinerGradient : 'transparent'} !important;
  :hover {
    border-color: ${props => props.theme.mainBrandColor};
  }
`;

const commissionOptions = [
  0,
  1,
  2,
  2.5,
  5,
  10,
  15,
  20,
  25,
  30,
  40,
  50,
  70,
  100,
];

const AddPartnerForm = props => {
  const {
    currency,
    values,
    // isPartnerModal,
    options,
    hideCommissionOptions,
    handleBlur,
    handleSubmit,
    setFieldValue,
    isSubmitting,
    errors,
    touched,
    onInputChange,
    loading,
  } = props;

  useEffect(() => {
    if (hideCommissionOptions) {
      setFieldValue('commission', 0);
    }
  }, []);

  return (
    <form
      onSubmit={handleSubmit}
      className={hideCommissionOptions ? 'pb-6' : 'pb-0'}
    >
      <MultipleSelectInput
        placeholder="Search and Select (Enter at least 2 characters)"
        singleSelect
        isMedium
        name="partner"
        isLoading={loading}
        defaultInputValue={values.partner}
        value={values.partner}
        onBlur={handleBlur}
        onInputChange={onInputChange}
        onChange={value => setFieldValue('partner', value)}
        options={
          options &&
          options.map(item => ({
            id: item.id,
            label:
              item.profile && item.profile.firstName
                ? `${item.profile.firstName} ${
                    item.profile.lastName ? item.profile.lastName : ''
                  } - ${item.username} ( ${item.rating || '0'} ★ )`
                : `${item.username} ( ${item.rating || '0'} ★ )`,
            value: item.profile
              ? `${item.profile.firstName} ${
                  item.profile.lastName ? item.profile.lastName : ''
                }`
              : item.username,
          }))
        }
        errors={errors.partner && touched.partner ? errors.partner : undefined}
      />
      {!hideCommissionOptions && (
        <>
          <div className="py-2">
            Please choose commission for partner per head/per user.
          </div>
          {commissionOptions.map(item => (
            <Commission
              isActive={values.commission === item}
              onClick={() => setFieldValue('commission', item)}
              key={item}
              className="tag mr-3 mb-4 px-4 py-3 is-size-6"
            >
              <span className="mr-1">
                {currencyFormatter.findCurrency(upperCase(currency)).symbol}
              </span>
              <span>{item}</span>
            </Commission>
          ))}
          {errors.commission && touched.commission ? (
            <div className="error has-text-danger">{errors.commission}</div>
          ) : null}
        </>
      )}

      <div
        className={`has-text-centered mt-5 ${
          hideCommissionOptions ? 'pb-6 mb-6' : 'pb-0'
        }`}
      >
        <Button
          loading={isSubmitting}
          title="Submit"
          className="is-rounded button is-normal is-primary px-6"
          type="submit"
        />
      </div>
    </form>
  );
};

export default withFormik({
  mapPropsToValues: () => ({
    partner: undefined,
    commission: undefined,
  }),
  validationSchema: yup.object().shape({
    partner: yup
      .object()
      .nullable()
      .required('Please select a option!'),
    commission: yup
      .number()
      .nullable()
      .required('Please select commission!'),
  }),

  handleSubmit: async (values, { setSubmitting, resetForm, props }) => {
    // console.log('handle submit', values, props);
    await props.onSubmit({
      user: values.partner.id,
      commission: values.commission,
    });

    setSubmitting(false);
    resetForm({
      commission: undefined,
      partner: null,
    });
  },
  displayName: 'AddPartnerForm', // helps with React DevTools
})(AddPartnerForm);
