import React from 'react';
import styled from 'styled-components';

import { Button } from '../elements';

const Container = styled.p``;

const Tabs = ({ activeTab, setActiveTab }) => (
  <Container className="buttons">
    <Button
      onClick={() => setActiveTab('usersList')}
      className={`button has-border-radius-medium mr-4 ${activeTab ===
        'usersList' && 'is-primary'}`}
    >
      Invite
    </Button>
    <Button
      onClick={() => setActiveTab('invitedUsersList')}
      className={`button has-border-radius-medium ${activeTab ===
        'invitedUsersList' && 'is-primary'}`}
    >
      Invited
    </Button>
  </Container>
);

export default Tabs;
