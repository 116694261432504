import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding-bottom: 0.8rem;
`;

const Content = ({ title, data, className }) => (
  <Wrapper>
    <h1 className="heading has-text-weight-semibold">{title}</h1>
    <p className={`is-size-5 is-capitalized ${className}`}>{data}</p>
  </Wrapper>
);

Content.defaultProps = {
  title: 'Stranger',
};

Content.propTypes = {
  title: PropTypes.string,
  data: PropTypes.string.isRequired,
};

export default Content;
