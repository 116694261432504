import React from 'react';
import styled from 'styled-components';

const Container = styled.textarea`
  min-width: 150px;
  border: none;
  box-shadow: none;
  padding: 10px 0 0 0 !important;

  font-size: 14px !important;
  color: ${props =>
    props.isDisabled ? props.theme.textColorLight : props.theme.textLightGrey};
  text-align: ${props => props.hasTextCentered && 'center'};
  resize: none;
  :hover {
    border-color: transparent;
  }
  :active,
  :focus {
    box-shadow: none;
    outline: none;
    border: none;
  }
  :disabled {
    color: ${props => props.theme.textColorLight} !important;
    background: transparent !important;
  }
  :not([rows]) {
    min-height: 3rem;
  }
`;

const TextArea = ({
  hasTextCentered,
  disabled,
  hasJustifyStart,
  textColor,
  ...props
}) => {
  return (
    <Container
      disabled={disabled}
      isDisabled={disabled}
      hasTextCentered={hasTextCentered}
      textColor={textColor}
      className="textarea is-small"
      {...props}
    />
  );
};

export default TextArea;
