/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { withFormik } from 'formik';
import * as yup from 'yup';
import styled from 'styled-components';
import {
  Button,
  Select,
  UploadImageInput,
  Field,
  DateInput,
  Subtitle,
} from '../../elements';
import config from '../../../utils/config';
import { moment } from '../../../utils/helpers';
import CheckBox from '../elements/CheckBox';

const Container = styled.form`
  .tabs.is-toggle li:first-child a {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .tabs.is-toggle li:last-child a {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .tabs a {
    padding: 0.25em 1em;
    border: 1px solid ${props => props.theme.buttonColor};
    :hover {
      border: 1px solid ${props => props.theme.buttonColor};
    }
  }
  .tab a {
    color: ${props => props.theme.buttonColor};
  }
  .is-active a {
    ${
      '' /* background-color: ${props => props.theme.buttonColor} !important; */
    }
    background: ${props => props.theme.bgLinerGradient}!important;
    color: #fff;
    border: 1px solid ${props => props.theme.buttonColor} !important;
  }
  .radio-active {
    color: ${props => props.theme.buttonColor} !important;
  }
  .info-text {
    color: ${props => props.theme.textColorLight};
  }
  .label {
    color: ${props => props.theme.textLightGrey};
  }
  .fa-chevron-left {
    color: ${props => props.theme.buttonColor};
  }
  .radio {
    margin-right: 0.5em;
    margin-left: 0;
  }
  .radio-buttons {
    padding-top: 0.5rem;
  }
  @media only screen and (max-width: 768px) {
    .submit-button-responsive {
      margin-top: 0 !important;
      padding-top: 0 !important;
    }
  }
`;

const EventInformationForm = ({
  values,
  touched,
  errors,
  onChange,
  isSubmitting,
  setFieldValue,
  handleSubmit,
  handleChange,
  handleBlur,
}) => {
  // console.log('isVisible', values.isVisible);
  return (
    <Container onSubmit={handleSubmit} className="mt-5">
      <div className="columns">
        <div className="column is-5 mt-4">
          <button
            onClick={() => window.history.back()}
            type="button"
            className="button is-white is-large p-0 mt-2 mb-2"
          >
            <i className="fas fa-chevron-left" />
          </button>
          <UploadImageInput
            value={values.featuredImage}
            onChange={image => {
              setFieldValue('featuredImage', image);
              onChange({ featuredImage: image });
            }}
          />
          {errors.featuredImage && touched.featuredImage ? (
            <div className="error has-text-danger mt-2">
              {errors.featuredImage}
            </div>
          ) : null}
          <p className="info-text mt-5 has-text-centered">
            <strong>*</strong> The uploaded image must be 600px wide and 480px
            long
          </p>
        </div>
        <div className="column is-4 is-offset-1 is-5-tablet">
          <div className="field is-horizontal">
            <div className="field-body">
              <div className="">
                <div className="control is-expanded ">
                  <div className="subtitle is-5 has-text-weight-bold mb-3">
                    Listed
                  </div>
                  <div className="tabs is-toggle">
                    <ul>
                      <li
                        role="presentation"
                        onClick={() => {
                          setFieldValue('isVisible', !values.isVisible);
                          onChange({ isVisible: !values.isVisible });
                        }}
                        className={`${values.isVisible ? 'is-active' : 'tab'}`}
                      >
                        <a>Visible</a>
                      </li>
                      <li
                        role="presentation"
                        onClick={() => {
                          setFieldValue('isVisible', !values.isVisible);
                          onChange({ isVisible: !values.isVisible });
                        }}
                        className={`${!values.isVisible ? 'is-active' : 'tab'}`}
                      >
                        <a>Hidden</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Subtitle>Please select event Information </Subtitle>
          <div className="field">
            <Select
              isMedium
              label="Repeat Event"
              options={config.eventDetails.repeatEvent}
              name="repeatEvent"
              type="text"
              placeholder="Select Repeat Event Time"
              value={values.repeatEvent}
              onChange={handleChange}
              onBlur={() => {
                handleBlur();
                onChange({ repeatEvent: values.repeatEvent });
              }}
              errors={
                errors.repeatEvent && touched.repeatEvent
                  ? errors.repeatEvent
                  : undefined
              }
            />
          </div>
          <Field
            requiredIcon
            isMedium
            label="Event name"
            placeholder="Add Event Name"
            name="name"
            type="text"
            value={values.name}
            onChange={handleChange}
            onBlur={() => {
              handleBlur();
              onChange({ name: values.name });
            }}
            errors={errors.name && touched.name ? errors.name : undefined}
          />

          <div className="field">
            <DateInput
              requiredIcon
              isMedium
              hasBackgroudColor
              label="Event Start Date & Time"
              name="eventStartTime"
              value={values.eventStartTime}
              allowSameDay={false}
              minTime={new Date()}
              onChange={date => {
                setFieldValue('eventStartTime', moment(date).toISOString());
                onChange({ eventStartTime: moment(date).toISOString() });
              }}
              errors={errors.eventStartTime ? errors.eventStartTime : undefined}
            />
          </div>
          <div className="field">
            <DateInput
              requiredIcon
              isMedium
              hasBackgroudColor
              label="Event End Date & Time"
              name="eventEndTime"
              value={values.eventEndTime}
              minTime={new Date()}
              allowSameDay={false}
              minDate={moment().toDate()}
              onChange={date => {
                setFieldValue('eventEndTime', moment(date).toISOString());
                if (
                  moment(values.eventStartTime).unix() < moment(date).unix()
                ) {
                  onChange({ eventEndTime: moment(date).toISOString() });
                }
              }}
              errors={errors.eventEndTime ? errors.eventEndTime : undefined}
            />
          </div>
          <div className="mt-4">
            <span className="has-text-grey">Upload ID required</span>
            <div className="control mt-2">
              <label className="radio">
                <input
                  type="radio"
                  checked={values.isRequiredUserVerification === true}
                  onChange={() => {
                    setFieldValue('isRequiredUserVerification', true);
                    onChange({ isRequiredUserVerification: true });
                  }}
                />{' '}
                Yes{' '}
                <span className="ml-2 has-text-grey-light is-size-7">
                  (User without ID verified cant get listed)
                </span>
              </label>
              <div>
                <label className="radio">
                  <input
                    type="radio"
                    checked={values.isRequiredUserVerification === false}
                    onChange={() => {
                      setFieldValue('isRequiredUserVerification', false);
                      onChange({ isRequiredUserVerification: false });
                    }}
                  />{' '}
                  No
                </label>
              </div>
            </div>
          </div>
          <div className="mt-5 is-flex is-align-items-center">
            <CheckBox
              checked={values.autoStart}
              onChange={() => setFieldValue('autoStart', !values.autoStart)}
            />
            <span className="ml-5 has-text-grey">Auto Start</span>
            <span className="ml-2 has-text-grey-light is-size-7">
              (Tick this box for your event to auto start from event start time)
            </span>
          </div>
        </div>
      </div>
      <div className="mt-6 pt-6 has-text-centered submit-button-responsive">
        <Button
          loading={isSubmitting}
          title="Next"
          className="is-rounded button is-medium is-primary px-6  mt-5 "
          type="submit"
        />
      </div>
    </Container>
  );
};
export default withFormik({
  mapPropsToValues: ({ initialValues }) => ({
    repeatEvent: initialValues.repeatEvent || '',
    autoStart: initialValues.autoStart,
    isVisible: initialValues.isVisible,
    isRequiredUserVerification: initialValues.isRequiredUserVerification,
    name: initialValues.name || '',
    featuredImage: initialValues.featuredImage || '',
    eventStartTime: initialValues.eventStartTime || '',
    eventEndTime: initialValues.eventEndTime || '',
  }),
  validationSchema: yup.object().shape({
    featuredImage: yup.string().required('Event Image is required'),
    repeatEvent: yup.string().required('Please select a option'),
    name: yup
      .string()
      .required('Name is required')
      .nullable(),
    eventStartTime: yup
      .date()
      // .test('allowed', 'Not allowed!', function(values) {
      //   return moment().unix() < moment(values).unix();
      // })
      .required('Start time is required'),
    eventEndTime: yup
      .date()
      .test('allowed', 'Not allowed!', function(values) {
        return (
          moment(this.parent.eventStartTime).unix() < moment(values).unix()
        );
      })
      .required('End time is required'),
  }),

  handleSubmit: (values, { setSubmitting, props }) => {
    // console.log('handle submit', values, props, setSubmitting);
    props.onSubmit(values);
    setSubmitting(false);
  },
  displayName: 'EventInformationForm', // helps with React DevTools
})(EventInformationForm);
