import React from 'react';
import styled from 'styled-components';

const TableHead = styled.thead`
  th {
    font-weight: 500 !important;
    padding: 0 1rem;
    background: transparent;
    color: ${props =>
      props.textColor
        ? props.textColor
        : props.theme.mainBrandColor} !important;
    border: none;
    text-align: center !important;
    :first-child {
      padding-left: 2.5rem;
      text-align: left !important;
    }
    :last-child {
      padding-right: 2.75rem;
      text-align: right !important;
    }
  }
`;

const Text = styled.span`
  cursor: ${props => (props.isCurserPointer ? 'pointer' : 'text')};
`;

const TableHeader = ({ textColor, data }) => (
  <TableHead textColor={textColor}>
    <tr>
      {data &&
        data.map(item => (
          <th onClick={item.onClick} key={item.id}>
            <Text isCurserPointer={!!item.onClick}>{item.data}</Text>
          </th>
        ))}
    </tr>
  </TableHead>
);

export default TableHeader;
