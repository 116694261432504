import React, { useState } from 'react';
// import XLSX from 'xlsx';
import fileReaderStream from 'filereader-stream';
import csv from 'csv-parser';
import { upperFirst } from 'lodash';

import { Button } from '../elements';
import TableWrapper from '../table/TableWrapper';
import InviteUserModal from './InviteUserModal';
import TableData from './TableData';
import { cleanString } from '../../utils/helpers';

const HeaderButtons = ({ handleImport, addUser }) => (
  <div className="is-flex is-justify-content-flex-end">
    <Button
      onClick={addUser}
      className="button is-small is-primary has-border-radius-small mr-3"
    >
      <span>Add</span>
      <span className="icon">
        <i className="fas fa-plus" />
      </span>
    </Button>
    <Button className="button is-small is-primary has-border-radius-small mr-3">
      <a href="/listme-users.csv" className="has-text-white">
        Download example CSV
      </a>
    </Button>
    <Button className="button is-small has-border-radius-small">
      <span>IMPORT CSV</span>
      <input
        onChange={e => handleImport(e)}
        className="file-input"
        type="file"
        accept=".csv,application/vnd.ms-excel,.xlt,application/vnd.ms-excel,.xla,application/vnd.ms-excel,.xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.xltx,application/vnd.openxmlformats-officedocument.spreadsheetml.template,.xlsm,application/vnd.ms-excel.sheet.macroEnabled.12,.xltm,application/vnd.ms-excel.template.macroEnabled.12,.xlam,application/vnd.ms-excel.addin.macroEnabled.12,.xlsb,application/vnd.ms-excel.sheet.binary.macroEnabled.12"
      />
      <span className="icon">
        <i className="fas fa-upload" />
      </span>
    </Button>
  </div>
);

const UsersTable = ({
  activeTab,
  users,
  addUsers,
  addSingleUser,
  handleRemove,
  handleChange,
  selectAllUsers,
}) => {
  const [inviteUserModal, setInviteUserModal] = useState(false);
  const readCsv = file => {
    const invitedUsersArray = [];
    fileReaderStream(file)
      .pipe(csv())
      .on('data', data => {
        // console.log('invitedUsersArray', data);
        if (data && data.telephone && data.telephone.length > 4) {
          const item = {};
          item.firstName = upperFirst(cleanString(data.firstName));
          item.lastName = upperFirst(cleanString(data.lastName));
          item.telephone =
            cleanString(data.telephone).charAt(0) !== '+'
              ? cleanString(`+${data.telephone}`)
              : cleanString(data.telephone);
          item.email = cleanString(data.email);
          invitedUsersArray.push(item);
        }
      })
      .on('end', () => {
        // console.log('invitedUsersArray 2', invitedUsersArray);
        addUsers(invitedUsersArray);
      });
  };

  // eslint-disable-next-line no-unused-vars
  // const readExcel = file => {
  //   const promise = new Promise((res, rej) => {
  //     const fileReader = new FileReader();
  //     fileReader.readAsArrayBuffer(file);
  //     // console.log('readExcel', file);

  //     fileReader.onload = e => {
  //       const bufferArray = e.target.result;
  //       const wb = XLSX.read(bufferArray, { type: 'buffer' });
  //       const wsname = wb.SheetNames[0];
  //       const ws = wb.Sheets[wsname];
  //       const data2 = XLSX.utils.sheet_to_json(ws);
  //       res(data2);
  //     };

  //     fileReader.onerror = err => {
  //       console.log('readExcel error', err);
  //       rej(err);
  //     };
  //   });

  //   promise.then(d => {
  //     const invitedUsersArray = [];
  //     d.forEach(e => {
  //       const item = {};
  //       item.firstName = e.firstName;
  //       item.lastName = e.lastName;
  //       item.telephone = e.telephone.toString();
  //       item.email = e.email;
  //       invitedUsersArray.push(item);
  //     });
  //     addUsers(invitedUsersArray);
  //   });
  // };

  const handleImport = e => {
    const file = e.target.files[0];
    // readExcel(file);
    readCsv(file);
  };

  const usersTableHeadData = [
    {
      Header: 'Name',
      accessor: 'firstName',
    },
    {
      Header: 'Email ID',
      accessor: 'email',
    },
    {
      id: 3,
      Header: 'Contact',
      accessor: 'telephone',
    },
    {
      id: 4,
      Header: (
        <HeaderButtons
          handleImport={handleImport}
          addUser={() => setInviteUserModal(!inviteUserModal)}
        />
      ),
    },
  ];
  const columns = React.useMemo(() => usersTableHeadData, []);
  return (
    <>
      <TableWrapper className="table-wrapper section py-5 pb-0 has-background-white">
        <TableData
          columns={columns}
          data={users}
          marginRight="1.5rem"
          activeTab={activeTab}
          handleChange={handleChange}
          handleRemove={handleRemove}
        />
      </TableWrapper>

      <InviteUserModal
        isActive={inviteUserModal}
        onClose={() => setInviteUserModal(false)}
        onSubmit={async formData => {
          await addSingleUser(formData);
          setInviteUserModal(false);
        }}
      />
      {users.length > 0 && (
        <div className="has-text-right select-all-button">
          <button
            onClick={() => selectAllUsers(users)}
            className="button is-white has-text-weight-semibold"
          >
            Select all
          </button>
        </div>
      )}
    </>
  );
};

export default UsersTable;
