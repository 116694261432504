import React, { useEffect, useState } from 'react';

import TableHeader from '../table/TableHeader';
import EventEntryRules from './EventEntryRules';
import TablePricingItem from './TablePricingItem';
import TableWrapper from '../table/TableWrapper';

const defaultPricing = [
  {
    label: 'Standard',
    price: 0,
    quantity: 0,
    bookedQuantity: 0,
    isVisible: true,
    rules: [],
  },
  // {
  //   label: 'VIP',
  //   price: 0,
  //   quantity: 0,
  //   isVisible: true,
  //   rules: [],
  // },
];

const GuestListPricingTable = ({
  onChange,
  initialPricing,
  event,
  showErrors,
}) => {
  const [pricing, setPricing] = useState(
    initialPricing && initialPricing.length > 0
      ? initialPricing
      : defaultPricing,
  );

  const addPackage = async () => {
    const item = {
      label: '',
      details: '',
      price: 0,
      quantity: 0,
      isVisible: true,
      rules: [],
    };
    setPricing([...pricing, item]);
  };

  const guestListTableHeadData = [
    {
      id: 1,
      data: 'Guestlist Type',
    },
    {
      id: 2,
      data: 'Price',
    },
    {
      id: 3,
      data: 'Quantity',
    },
    {
      id: 4,
      data: 'Rules',
    },
    {
      id: 4.5,
      data: 'Action',
    },
    {
      id: 5,
      data: <i className="fas fa-plus mx-3" />,
      onClick: addPackage,
    },
  ];

  const handleChange = (values, index) => {
    pricing[index] = { ...pricing[index], ...values };
    setPricing([...pricing]);
  };

  const handleRulesChange = rules => {
    const newPricingRules = pricing.map(val => ({
      ...val,
      rules,
    }));
    setPricing([...newPricingRules]);
  };

  useEffect(() => {
    onChange(pricing);
  }, [pricing]);

  return (
    <TableWrapper className="table-wrapper section">
      <table className="table has-background-transparent mb-0">
        <TableHeader data={guestListTableHeadData} />
        <tbody>
          {pricing &&
            pricing.map((item, index) => (
              <TablePricingItem
                key={item.label}
                currency={event.currency}
                hasRules
                editable
                hideDetails
                showErrors={showErrors}
                event={event}
                quantityText="Available"
                item={item}
                onChange={val => handleChange(val, index)}
              />
            ))}
        </tbody>
      </table>
      <EventEntryRules
        rules={pricing && pricing[0] ? pricing[0].rules : []}
        onChange={handleRulesChange}
      />
    </TableWrapper>
  );
};

export default GuestListPricingTable;
