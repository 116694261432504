export default {
  debug: process.env.NODE_ENV === 'development',
  appName: 'ListMe Dashboard',
  webUri: process.env.REACT_APP_WEB_URL || 'http://app-dev.listmehq.com/',
  websiteUrl: process.env.WEB_URL || 'https://www.listmehq.com',
  graphQlUri: process.env.REACT_APP_API_URL || 'https://listme-api-dev.fly.dev',
  graphQlUriDev: 'https://listme-api.fly.dev',
  // graphQlUriDev: 'http://localhost:8080',
  // graphQlUriDev: 'https://api-v3.listmeapp.co/api/graphql',
  stripeConnectId:
    process.env.REACT_APP_STRIPE_CONNECT_ID ||
    'ca_Ej5OxszBkVXPxAVrO7S2myU2VEOBoDga',
  currency: '£',
  eventDetails: {
    attendingPeopleAge: ['Under 16', '18 & over', '19 & over', '21 & over'],
    musicPolicy: [
      { label: 'HipHop', value: 'HipHop' },
      { label: 'RnB', value: 'RnB' },
      { label: 'Tech', value: 'Tech' },
      { label: 'House', value: 'House' },
      { label: 'Mix', value: 'Mix' },
      { label: 'Minimal tech', value: 'Minimal tech' },
      { label: 'Soulful house', value: 'Soulful house' },
      { label: 'Chilled', value: 'Chilled' },
      { label: 'Jazz', value: 'Jazz' },
      { label: 'DnB', value: 'DnB' },
      { label: 'Reggae', value: 'Reggae' },
      { label: 'Bashment', value: 'Bashment' },
      { label: 'Afrobeats ', value: 'Afrobeats' },
      { label: 'Rock', value: 'Rock' },
      { label: 'Indie', value: 'Indie' },
      { label: 'Alternative', value: 'Alternative' },
      { label: 'Garage', value: 'Garage' },
      { label: 'Dance', value: 'Dance' },
      { label: 'Melodic house', value: 'Melodic house' },
      { label: 'Techno', value: 'Techno' },
      { label: 'None', value: 'None' },
    ],
    partyType: [
      { label: 'Rooftop', value: 'Rooftop' },
      { label: 'Cocktail bar', value: 'Cocktail bar' },
      { label: 'Fashion pop up', value: 'Fashion pop up' },
      { label: 'Pop up', value: 'Pop up' },
      { label: 'Coworking', value: 'Coworking' },
      { label: 'Supperclub', value: 'Supperclub' },
      { label: 'Nightlife', value: 'Nightlife' },
      { label: 'Boujie', value: 'Boujie' },
      { label: 'Brunch', value: 'Brunch' },
      { label: 'Festivals', value: 'Festivals' },
      { label: 'Fun', value: 'Fun' },
      { label: 'Concerts', value: 'Concerts' },
      { label: 'Dining', value: 'Dining' },
      { label: 'Wellness', value: 'Wellness' },
      { label: 'Yoga', value: 'Yoga' },
      { label: 'Retreat', value: 'Retreat' },
      { label: 'Lifestyle', value: 'Lifestyle' },
      { label: 'Football', value: 'Football' },
      { label: 'Sports', value: 'Sports' },
      { label: 'Esports', value: 'Esports' },
      { label: 'Comedy', value: 'Comedy' },
      { label: 'Theatre', value: 'Theatre' },
      { label: 'Tasting', value: 'Tasting' },
      { label: 'Masterclasses', value: 'Masterclasses' },
      { label: 'Karaoke', value: 'Karaoke' },
      { label: 'Networking', value: 'Networking' },
      { label: 'Turn up', value: 'Turn up' },
      { label: 'Virtual', value: 'Virtual' },
      { label: 'Quirky', value: 'Quirky' },
      { label: 'Mystery', value: 'Mystery' },
      { label: 'Live', value: 'Live' },
    ],
    repeatEvent: ['noRepeat', 'daily', 'weekly', 'monthly'],
    rules: [
      { label: 'No hats', value: 'No hats' },
      { label: 'No caps', value: 'No caps' },
      { label: 'No drugs', value: 'No drugs' },
      { label: 'No bad vibes', value: 'No bad vibes' },
      { label: 'No trainers', value: 'No trainers' },
      { label: 'Camera on', value: 'Camera on' },
      { label: 'Names displayed', value: 'Names displayed' },
      { label: 'Good vibes only', value: 'Good vibes only' },
      { label: 'No large groups of guys', value: 'No large groups of guys' },
    ],
    dressCode: [
      { label: 'Smart', value: 'Smart' },
      { label: 'Smart casual', value: 'Smart casual' },
      { label: 'Casual trendy', value: 'Casual trendy' },
      { label: 'Trendy', value: 'Trendy' },
      { label: 'Fancy dress', value: 'Fancy dress' },
      { label: 'No bubbles', value: 'No bubbles' },
      { label: 'No sportswear', value: 'No sportswear' },
      { label: 'Chilled', value: 'Chilled' },
      { label: 'Gym wear', value: 'Gym wear' },
      { label: 'Something comfortable', value: 'Something comfortable' },
      { label: 'No tank tops', value: 'No tank tops' },
      { label: 'No sleeveless t-shirt', value: 'No sleeveless t-shirt' },
      { label: 'No shorts', value: 'No shorts' },
      {
        label: 'No excessive baggy clothing',
        value: 'No excessive baggy clothing',
      },
      {
        label: 'No sandals, flipflop, dirty athletic sneakers',
        value: 'No sandals, flipflop, dirty athletic sneakers',
      },
      {
        label: 'No flats (ladies)',
        value: 'No flats (ladies)',
      },
      {
        label: 'No beach attire',
        value: 'No beach attire',
      },
      {
        label: 'No extra baggy tees',
        value: 'No extra baggy tees',
      },
      {
        label: 'No jersey/athletic wear',
        value: 'No jersey/athletic wear',
      },
    ],
    eventTicketType: ['E-ticket', 'E-guestlist'],
    status: ['draft', 'open', 'close', 'soldOut'],
  },
  businessRoles: [
    { label: 'Event Organizer', value: 'Event organizer' },
    { label: 'Promoter', value: 'Promoter' },
    { label: 'Venue', value: 'Venue' },
  ],
  businessTypes: [
    { label: 'Sports organizer', value: 'Sports organizer' },
    { label: 'Football club', value: 'Football club' },
    { label: 'Rugby club', value: 'Rugby club' },
    { label: 'Brunch club', value: 'Brunch club' },
    { label: 'Restaurant', value: 'Restaurant' },
    { label: 'Coworking space', value: 'Coworking space' },
    { label: 'Bar', value: 'Bar' },
    { label: 'Hotel', value: 'Hotel' },
    { label: 'Nightclub', value: 'Nightclub' },
    { label: 'Festival organizer', value: 'Festival organizer' },
    { label: 'DJ artist', value: 'DJ artist' },
    { label: 'Concert organizer', value: 'Concert organizer' },
    { label: 'Event organizer', value: 'Event organizer' },
    { label: 'Beach club', value: 'Beach club' },
    { label: 'Restaurant', value: 'Restaurant' },
    { label: 'Health & wellness', value: 'Health & wellness' },
    { label: 'Lifestyle', value: 'Lifestyle' },
  ],
  brandColor: '#630948',
  secondaryColor: '#a5227e',
  backgroundColor: '#f1f1f1',
  menuColor: '#ffffff',
  placeholderColor: '#858585',
};
