/* eslint-disable react/no-unknown-property */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { useLocation } from 'react-router-dom';
import { useMutation } from 'react-apollo';
import queryString from 'query-string';
import { useQuery } from '@apollo/react-hooks';

import config from '../utils/config';
import Button from '../components/elements/Button';

const clientId = config.stripeConnectId;

const meQuery = gql`
  {
    me {
      id
      stripeConnectId
    }
  }
`;

const stripeConnectAuthMutation = gql`
  mutation stripeConnectAuth($code: String!) {
    stripeConnectAuth(code: $code) {
      success
    }
  }
`;

const Wrapper = styled.div`
  .columns {
    display: flex;
    align-items: center;
    flex-direction: column;
    > .column {
      margin-bottom: 5px;
    }
  }
  img {
    width: 20rem;
  }
`;

const Payments = props => {
  const { history, setActiveStep, pathName } = props;
  const [isSuccess, setIsSuccess] = useState(props.type === 'success');
  const location = useLocation();
  const [stripeConnectAuth] = useMutation(stripeConnectAuthMutation);
  const { data } = useQuery(meQuery);
  const { me } = data || {};
  console.log('params', pathName);
  useEffect(() => {
    if (isSuccess) {
      const values = queryString.parse(location.search);
      if (values.code) {
        stripeConnectAuth({ variables: { code: values.code } });
      }
    }
  }, [isSuccess]);

  useEffect(() => {
    if (me && me.stripeConnectId && me.stripeConnectId.length > 0) {
      setIsSuccess(true);
    }
  }, [me]);

  return (
    <Wrapper className="section">
      <div className="columns">
        <div className="column">
          <p className="is-uppercase is-size-3 has-text-weight-bold">
            Events Made Simple
          </p>
        </div>
        <div className="column is-8">
          {!isSuccess ? (
            <p className=" is-size-5">
              Start accepting mobile payments right away by simply adding your
              business account to ListMe platform. A one stop system to manage
              every aspect required to promoting and marketing a venue or event.
            </p>
          ) : (
            <div className="has-text-centered">
              <p className=" is-size-5 mb-3">
                Stripe is connected successfully! You can now list events, and
                start accepting payments.
              </p>
              <Button
                title="Continue"
                className="button is-medium is-rounded mt-5"
                type="button"
                onClick={() => {
                  if (pathName === '/settings') {
                    setActiveStep(3);
                  } else history.push('/settings?step=3');
                }}
              />
            </div>
          )}
        </div>
        <div className="column">
          {!isSuccess && (
            <a
              href={`https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${clientId}&scope=read_write`}
              // hrefOld={`https://connect.stripe.com/express/oauth/authorize?redirect_uri=${config.webUrl}/connect-success&client_id=${clientId}&state=null`}
            >
              <Button
                title="Connect To Stripe"
                className="button is-medium is-rounded"
              />
            </a>
          )}
        </div>
      </div>
    </Wrapper>
  );
};

export default Payments;
