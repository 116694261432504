import React, { useEffect } from 'react';
import styled from 'styled-components';
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useAsyncDebounce,
  useFilters,
} from 'react-table';
import { matchSorter } from 'match-sorter';
import { EmptyState, Loading } from '../elements';
import { SearchConsumer } from '../../store/context';

const Table = styled.table`
  width: 100%;
  th:first-child {
    padding-left: 11.5%;
  }
  th:last-child {
    padding-right: ${props => props.marginRight};
  }
`;

const TableHead = styled.thead`
  th {
    font-weight: 500 !important;
    padding: 0 1rem;
    background: transparent;
    color: ${props =>
      props.textColor
        ? props.textColor
        : props.theme.mainBrandColor} !important;
    border: none;
    text-align: center !important;
    :first-child {
      text-align: left !important;
    }
    :last-child {
      text-align: right !important;
    }
  }
`;

function GlobalFilter({
  globalFilter,
  setGlobalFilter,
  removeFilter,
  children,
}) {
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce(val => {
    setGlobalFilter(val || undefined);
  }, 200);

  return (
    <SearchConsumer>
      {({ searchInput = value }) => {
        if (!removeFilter) {
          setValue(searchInput);
          onChange(searchInput);
        }

        return children;
      }}
    </SearchConsumer>
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [row => row.values[id]] });
}

fuzzyTextFilterFn.autoRemove = val => !val;

const TableWithPagination = ({
  rowItem,
  columns,
  data,
  marginRight,
  emptyStateTitle,
  removeFilter,
  pageLimit = 30,
  fetchData,
  totalCount,
  currentPage,
  loading,
  manualPagination = false,
  onSearch,
}) => {
  const controlledPageCount = Math.ceil(totalCount / pageLimit);

  // console.log('dd', Math.ceil(totalCount / pageLimit));
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    [],
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: currentPage || 0,
        pageSize: manualPagination ? pageLimit : 30,
      },
      filterTypes,
      manualPagination,
      pageCount: controlledPageCount,
    },
    useFilters,
    useGlobalFilter,
    usePagination,
  );
  // const firstPageRows = rows.slice(0, 30);

  useEffect(() => {
    if (fetchData) {
      fetchData(pageIndex, pageSize);
    }
  }, [pageIndex, pageSize]);
  return (
    <>
      {loading && <Loading />}
      <GlobalFilter
        // manualPagination={manualPagination}
        globalFilter={state.globalFilter}
        setGlobalFilter={val => {
          if (manualPagination) {
            onSearch(val);
          } else {
            setGlobalFilter(val);
          }
        }}
        removeFilter={removeFilter}
      >
        <Table
          {...getTableProps()}
          marginRight={marginRight || '6%'}
          className="table has-background-transparent mb-0"
        >
          <TableHead textColor={props => props.theme.placeholderColor}>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps()} key={column.id}>
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </TableHead>

          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return rowItem(row, i);
            })}
          </tbody>
        </Table>
      </GlobalFilter>

      {!loading && page.length === 0 && (
        <div className="has-text-centered">
          <EmptyState title={emptyStateTitle} />
        </div>
      )}
      {loading && data?.length > 0 && <Loading />}
      {totalCount > pageLimit && (
        <div className="pagination is-rounded mt-5 mb-3">
          <button
            className="pagination-link"
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            {'<<'}
          </button>
          <button
            className="pagination-link"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            {'<'}
          </button>
          <button
            className="pagination-link"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            {'>'}
          </button>
          <button
            className="pagination-link"
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {'>>'}
          </button>
          <span className="mx-3">
            Page{' '}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{' '}
          </span>
          <span className="mx-3">
            | Go to page:{' '}
            <input
              type="number"
              min={1}
              max={controlledPageCount}
              defaultValue={pageIndex + 1}
              onChange={e => {
                const pageValue = e.target.value
                  ? Number(e.target.value) - 1
                  : 0;
                gotoPage(pageValue);
              }}
              style={{ width: '100px' }}
            />
          </span>{' '}
          <select
            className="mx-3"
            value={pageSize}
            onChange={e => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[5, 10, 20, 30, 40, 50].map(pageSizeVal => (
              <option key={pageSizeVal} value={pageSizeVal}>
                Show {pageSizeVal}
              </option>
            ))}
          </select>
        </div>
      )}
    </>
  );
};

export default TableWithPagination;
