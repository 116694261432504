import React from 'react';
import styled from 'styled-components';
import UpdateEventDetails from '../components/updateEventDetails';

const Container = styled.div`
  padding: 2.25rem;
  height: 100%;
  @media screen and (max-width: 768px) {
    padding: 2rem 1.5rem !important;
  }
`;

const UpdateEvent = ({ match, history }) => {
  return (
    <Container className="section">
      <UpdateEventDetails match={match} history={history} />
    </Container>
  );
};

export default UpdateEvent;
