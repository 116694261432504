import React from 'react';
import styled from 'styled-components';
import { Modal } from '../../elements';
import AddTableTimeSlotForm from '../forms/AddTableTimeSlotForm';
import { moment } from '../../../utils/helpers';

const Container = styled.div`
  .inner-wrapper {
    min-height: 550px !important;
  }
`;
const SelectTimeSlotModal = ({
  event,
  onClose,
  onSubmit,
  subtitle,
  isActive,
}) => {
  // console.log('ergfeerE', event);
  const eventStartDate = moment(event.eventStartTime).format('D MMM YYYY');
  const eventEndDate = moment(event.eventEndTime).format('D MMM YYYY');
  return (
    <Container>
      <Modal
        title="Time Slots"
        subtitle={subtitle}
        isActive={isActive}
        onClose={onClose}
      >
        <div className="subtitle is-6 has-text-centered">
          Timing:{' '}
          {eventStartDate === eventEndDate ? (
            <span className="">
              {moment(event.eventStartTime).format('D MMM YYYY')} From{' '}
              {moment(event.eventStartTime).format('hh:mm A')} Till{' '}
              {moment(event.eventEndTime).format('hh:mm A')}
            </span>
          ) : (
            <span>
              {moment(event.eventStartTime).format('D MMM YYYY @hh:mm A')} -{' '}
              {moment(event.eventEndTime).format('D MMM YYYY  @hh:mm A')}
            </span>
          )}
        </div>
        <AddTableTimeSlotForm onSubmit={onSubmit} event={event} />
      </Modal>
    </Container>
  );
};
export default SelectTimeSlotModal;
