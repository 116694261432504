import React from 'react';
import styled from 'styled-components';

const Container = styled.button`
  border-radius: 0;
  height: 1.5rem !important;
  width: 1.5rem;
  background: transparent;
  border: 1px solid
    ${props =>
      props.checked ? props.theme.mainBrandColor : props.theme.textLightGrey};
  svg {
    vertical-align: middle;
    color: ${props =>
      props.checked ? props.theme.mainBrandColor : 'transparent'};
  }
`;

const CheckBox = ({ checked, onChange }) => {
  return (
    <Container
      type="button"
      checked={checked}
      onClick={onChange}
      className="checkbox is-flex is-justify-content-center is-align-items-center"
    >
      <i className="fas fa-check" />
    </Container>
  );
};

export default CheckBox;
