import React from 'react';
import styled from 'styled-components';
import { isNull } from 'lodash';

import config from '../../utils/config';

const Wrapper = styled.div`
  .input:focus,
  .input:active {
    border-color: #b5b5b5;
    box-shadow: none;
  }
  .fa-star-of-life {
    height: 8px;
  }

  .eye-icon {
    height: 20px !important;
    width: 20px !important;
  }
`;

const Input = styled.input`
  color: ${config.placeholderColor};
  height: ${props => (props.isMedium ? '2.5rem' : '4rem')} !important;
  border-radius: ${props => props.isMedium && '10px'} !important;
  padding-left: 2rem !important;
  padding-right: 2rem !important;
  @media only screen and (max-width: 768px) {
    height: ${props => (props.isMedium ? '1.5rem' : '3rem')} !important;
  }
`;

const Field = ({
  label,
  value,
  icon,
  hasBackgroudColor,
  className,
  isMedium,
  errors,
  requiredIcon,
  onRightIconClick,
  eyeIcon,
  ...inputProps
}) => (
  <>
    <Wrapper className="field" hasBackgroudColor={hasBackgroudColor}>
      {label && (
        <label className="label is-capitalized has-text-weight-normal mb-3 is-flex is-align-items-center">
          {label}{' '}
          {requiredIcon && (
            <i className="fas fa-star-of-life has-text-danger ml-1" />
          )}
        </label>
      )}
      <div className="control has-icons-right">
        <Input
          isMedium={isMedium}
          className={`input  ${!isMedium && 'is-rounded'} ${className}`}
          value={isNull(value) ? '' : value}
          {...inputProps}
        />
        {(icon || eyeIcon) && (
          <span onClick={onRightIconClick} className="icon is-right">
            {eyeIcon ? (
              <img src={eyeIcon} alt="eye-icon" className="eye-icon" />
            ) : (
              <i className={icon} />
            )}
          </span>
        )}
      </div>
    </Wrapper>
    {errors && <div className="error has-text-danger mb-2">{errors}</div>}
  </>
);

export default Field;
