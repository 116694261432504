import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { upperFirst, capitalize } from 'lodash';
import swal from 'sweetalert';
import { useMutation } from 'react-apollo';
import gql from 'graphql-tag';
// import { CopyToClipboard } from 'react-copy-to-clipboard';

import { Tag } from '../elements';
import { moment } from '../../utils/helpers';
import BookingReportModal from '../modals/BookingReportModal';

const Container = styled.tr`
  td {
    background-color: ${props =>
      props.isCollaboratorBooking ? ' #f9f9f9' : '#ffffff'};
    padding-top: ${props =>
      props.isCollaboratorBooking ? '0.25rem' : '0.75rem'} !important;
    padding-bottom: ${props =>
      props.isCollaboratorBooking ? '0.25rem' : '0.75rem'} !important;
  }
  margin-bottom: 10px;
  .tag.pending {
    background-color: #f8c705;
  }
  .tag.rejected,
  .tag.refunded {
    background-color: #ff002f7a;
  }
  .username {
    display: flex;
    align-items: center;
    .image {
      width: 52px;
      height: 52px;
      img {
        object-fit: cover;
        height: 100%;
      }
    }
    span {
      text-align: left !important;
      margin-left: 0.7vw;
    }
  }

  .ellipsis-dropdown {
    text-align: right;
    .button {
      background-color: transparent;
      font-size: 14px;
      border: none;
      color: #858585;
      font-weight: 600;
    }

    .dropdown-item {
      border-bottom: 1px solid transparent;
      &:last-child {
        border-bottom: none;
      }
      &:hover {
        border-bottom-color: #efefef;
        background: ${props => props.theme.bgLinerGradient};
        ${'' /* background-color: ${props => props.theme.buttonColor}; */}
        a {
          color: #fff;
        }
      }
      a {
        display: block;
        color: #858585;
      }
    }
  }
  .walkInIcon {
    border-radius: 2rem;
    width: 52px;
    height: 52px;
    background-color: #f8c705;
  }
  .payment-status {
    height: 2.5em !important;
  }

  @media only screen and (max-width: 768px) {
    .tag {
      width: 50px !important;
    }
  }
`;

const Column = styled.td`
  border-top: 1px solid #f0f0f0 !important;
  border-bottom: 1px solid #f0f0f0 !important;
  vertical-align: middle !important;
  text-align: center !important;
  font-size: 14px;
  :first-child {
    border-left: 1px solid #f0f0f0 !important;
    border-top-left-radius: 2.5rem;
    border-bottom-left-radius: 2.5rem;
  }
  :last-child {
    border-right: 1px solid #f0f0f0 !important;
    border-top-right-radius: 2.5rem;
    border-bottom-right-radius: 2.5rem;
  }
  .is-primary {
    color: ${props => props.theme.mainBrandColor};
  }
`;

const inviteBookingCollaboratorMutation = gql`
  mutation inviteBookingCollaborator(
    $bookingId: ID!
    $userId: ID
    $resendInvite: Boolean
  ) {
    inviteBookingCollaborator(
      bookingId: $bookingId
      userId: $userId
      resendInvite: $resendInvite
    ) {
      success
    }
  }
`;

const resendBookingConfirmationMutation = gql`
  mutation resendBookingConfirmation($bookingId: ID!) {
    resendBookingConfirmation(bookingId: $bookingId) {
      success
    }
  }
`;

const PeopleCardItem = ({
  data,
  event,
  status,
  isWalkIn,
  isBrandEvent,
  invitedBy,
  isCollaboratorBooking,
  isInvitedOnly,
  setShowMore,
  showMore,
  isFreeBooking,
  showResendConfirmationButton,
}) => {
  const [showReportModal, setShowReportModal] = useState(false);
  // const [copied, setCopied] = useState(false);

  const [executeResendInviteMutation, res] = useMutation(
    inviteBookingCollaboratorMutation,
  );

  const [executeResendBookingConfirmation] = useMutation(
    resendBookingConfirmationMutation,
  );

  const checkInBy =
    data.checkInBy && data.checkInBy.profile
      ? `${data.checkInBy.profile.firstName &&
          data.checkInBy.profile.firstName} ${
          data.checkInBy.profile.lastName ? data.checkInBy.profile.lastName : ''
        }`
      : '';
  const walkInBy =
    data.walkInBy && data.walkInBy.profile
      ? `${data.walkInBy.profile.firstName &&
          data.walkInBy.profile.firstName} ${
          data.walkInBy.profile.lastName ? data.walkInBy.profile.lastName : ''
        }`
      : '';

  const rejectedBy =
    data.rejectedBy && data.rejectedBy.profile
      ? `${data.rejectedBy.profile.firstName &&
          data.rejectedBy.profile.firstName} ${
          data.rejectedBy.profile.lastName
            ? data.rejectedBy.profile.lastName
            : ''
        }`
      : '';
  const referBy = `${data?.referredBy?.profile?.firstName ||
    data?.referredBy?.telephone ||
    ''} ${data?.referredBy?.profile?.lastName || ''}
      `;

  // data.referredBy && data.referredBy.profile
  //   ? `${data.referredBy.profile.firstName} ${
  //       data.referredBy.profile.lastName
  //         ? data.referredBy.profile.lastName
  //         : ''
  //     }`
  //   : '';

  const checkInAt = isWalkIn
    ? data.createdAt
    : data.checkInAt || data.rejectedAt;

  const checkInTime = checkInAt
    ? moment(checkInAt).format('D MMM YYYY hh:mm A')
    : '';

  let bookingStatus = '';
  if (isInvitedOnly && status === 'pending') {
    bookingStatus = 'Invited';
  } else if (isInvitedOnly && status === 'rejected') {
    bookingStatus = 'Rejected';
  } else if (status === 'authorizedPending') {
    bookingStatus = 'Approval Pending';
  } else if (
    status !== 'rejected' &&
    status !== 'refunded' &&
    status !== 'failed' &&
    status !== 'pending' &&
    status !== 'authorizedPending' &&
    isFreeBooking
  ) {
    bookingStatus = 'Free';
  } else if (status === 'checkedIn') {
    bookingStatus = 'Accepted';
  } else if (data.pricingType === 'guestList' && status === 'authorized') {
    bookingStatus = 'PostPay';
  } else if (data.pricingType === 'guestList' && status !== 'checkedIn') {
    bookingStatus = status;
  } else if (
    data.pricingType !== 'guestList' &&
    status !== 'rejected' &&
    status !== 'refunded' &&
    status !== 'failed' &&
    status !== 'pending'
  ) {
    bookingStatus = 'PrePaid';
  } else {
    bookingStatus = status;
  }
  // console.log('data', data);

  const handleResendBookingConfirmation = async bookingId => {
    swal({
      title: '',
      text:
        'Do you want to resend the booking confirmation email to the selected user?',
      icon: 'info',
      buttons: ['Cancel', 'Yes'],
    }).then(async willConfirm => {
      if (willConfirm) {
        await executeResendBookingConfirmation({
          variables: {
            bookingId,
          },
        });
        swal(
          'Good job!',
          'Booking confirmation resend successfully',
          'success',
        );
      }
    });
  };

  const handleResendInvitation = values => {
    swal({
      title: '',
      text: 'Do you want to resend the invite to the selected user?',
      icon: 'info',
      buttons: ['Cancel', 'Yes'],
    }).then(async willConfirm => {
      if (willConfirm) {
        await executeResendInviteMutation({
          variables: {
            bookingId: values.bookingId,
            userId: values.userId,
            resendInvite: true,
          },
        });
        swal('Good job!', 'Invite resent successfully', 'success');
      }
    });
  };

  useEffect(() => {
    if (res && res.error) {
      swal('Something went wrong!', res.error.message, 'error');
    }
  }, [res]);

  // useEffect(() => {
  //   if (copied === true) {
  //     setTimeout(() => {
  //       setCopied(false);
  //     }, 2000);
  //   }
  // }, [copied]);

  return (
    <>
      <Container isCollaboratorBooking={isCollaboratorBooking}>
        <Column>
          <div className="is-flex is-align-items-center">
            <Tag
              label={bookingStatus}
              className={`is-rounded ${status}`}
              autoWidth={status === 'authorizedPending'}
            />{' '}
            {isInvitedOnly && (
              <button
                onClick={() =>
                  handleResendInvitation({
                    userId: data.user && data.user.id ? data.user.id : '',
                    bookingId: data.parentBookingId ? data.parentBookingId : '',
                  })
                }
                className="button is-light ml-1 is-small"
              >
                Resend Invite
              </button>
            )}
          </div>
        </Column>

        <Column>
          <div className="is-flex is-align-items-center is-justify-content-center">
            {data.paymentStatus && (
              <span
                className={`tag is-capitalized payment-status px-3 ${
                  data.paymentStatus && data.paymentStatus === 'unpaid'
                    ? 'is-danger'
                    : 'is-success'
                }`}
              >
                {data.paymentStatus ? data.paymentStatus : ''}
              </span>
            )}
            {/* {data.paymentStatus && data.paymentStatus === 'unpaid' && (
              <>
                {!isCollaboratorBooking && (
                  <CopyToClipboard
                    text={data.paymentUrl ? data.paymentUrl : ''}
                    onCopy={() => setCopied(true)}
                  >
                    <button className="button is-info ml-2 py-0 is-small">
                      <i className="fas fa-copy mr-1" />
                      {copied ? 'Copied' : 'Payment Link'}
                    </button>
                  </CopyToClipboard>
                )}

                <button
                  onClick={() => console.log('denied booking')}
                  className="button is-danger ml-2 py-0 is-small"
                >
                  Decline
                </button>
              </>
            )} */}
          </div>
        </Column>
        <Column className="is-capitalized" onClick={setShowMore}>
          {data.pricingType === 'guestList' ? 'Guestlist' : data.pricingType}{' '}
          {data.collaborators && data.collaborators.length > 0 && (
            <>
              <span>({data.collaborators?.length}+)</span>
              {showMore && (
                <span>
                  <i className="fas fa-chevron-up" />
                </span>
              )}
              {!showMore && (
                <span>
                  <i className="fas fa-chevron-down" />
                </span>
              )}
            </>
          )}
        </Column>
        <Column className="is-capitalized">{data.pricingLabel}</Column>
        <Column>
          <div className="username">
            <a
              href={
                !isWalkIn && data.user.profile ? data.user.profile.image : '#'
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              {isWalkIn ? (
                <div className="walkInIcon is-flex">
                  <i className="fas fa-walking has-text-white is-size-5 m-auto" />
                </div>
              ) : (
                <figure className="image is-rounded">
                  <img
                    className="is-rounded"
                    alt="person-1"
                    src={
                      !isWalkIn && data.user.profile && data.user.profile.image
                        ? data.user.profile.image
                        : '/images/avatar.svg'
                    }
                  />
                </figure>
              )}
            </a>
            <>
              {isWalkIn && data.walkInData && (
                <span>{data.walkInData.personName}</span>
              )}
              {!isWalkIn && data.user?.profile && (
                <span>
                  {data.user.profile.firstName}{' '}
                  {data.user.profile.lastName ? data.user.profile.lastName : ''}
                </span>
              )}
              {!isWalkIn && data.user && !data.user.profile && (
                <span>
                  {data.user.username
                    ? data.user.username
                    : data.user.telephone}
                </span>
              )}
            </>
          </div>
        </Column>
        <Column>
          {!isWalkIn && data.user.verificationStatus === 'approved'
            ? 'Yes'
            : 'No'}
        </Column>
        <Column>
          {data.user && data.user.covidPassport ? (
            <span>
              <i className="fas fa-syringe is-primary" /> Yes
            </span>
          ) : (
            <span>
              <i className="fas fa-syringe" /> No
            </span>
          )}
        </Column>
        <Column>
          {!isWalkIn && data?.user?.profile?.dob
            ? moment().diff(data.user.profile.dob, 'years')
            : ''}
        </Column>
        <Column>
          {data?.user?.profile?.sex ? upperFirst(data.user.profile.sex) : ''}
        </Column>
        <Column>{referBy}</Column>
        {data.user && data.checkInBy && data.user.id === data.checkInBy.id ? (
          <Column>Self CheckIn</Column>
        ) : (
          <Column>{checkInBy || walkInBy || rejectedBy}</Column>
        )}
        <Column>{checkInTime}</Column>
        <Column>
          {data.createdAt
            ? moment(data.createdAt).format('D MMM YYYY hh:mm A')
            : ''}
        </Column>
        <Column>
          <div className="is-flex is-align-items-center is-justify-content-center">
            {data.ejectReason && (
              <span
                onClick={() => setShowReportModal(!showReportModal)}
                className="mr-3"
              >
                <i className="fas fa-flag is-primary" />
              </span>
            )}

            <div className="dropdown is-hoverable ellipsis-dropdown is-right">
              <div className="dropdown-trigger">
                <button className="button">
                  <span className="icon is-small">
                    <i className="fas fa-ellipsis-h" />
                  </span>
                </button>
              </div>
              <div className="dropdown-menu">
                <div className="dropdown-content">
                  <div className="dropdown-item">
                    {/* {!isWalkIn && (
                  <a
                    onClick={() => {
                      if (
                        data.user.identification !== null ||
                        data.user.identification > 1
                      ) {
                        swal(
                          `The ID of ${data.user.profile.firstName} ${data.user.profile.lastName}`,
                          `${`${data.user.identification.substr(
                            0,
                            5,
                          )}xxxxxxxxx${data.user.identification.substr(
                            data.user.identification.length - 4,
                            4,
                          )}`}`,
                        );
                      } else {
                        swal('There is no ID for this user');
                      }
                    }}
                  >
                    View ID
                  </a>
                )} */}
                    <a onClick={() => setShowReportModal(!showReportModal)}>
                      View Incident Report
                    </a>
                  </div>
                  {showResendConfirmationButton && (
                    <div className="dropdown-item">
                      <a
                        onClick={() => handleResendBookingConfirmation(data.id)}
                      >
                        Resend Confirmation
                      </a>
                    </div>
                  )}
                  {isBrandEvent && (
                    <div className="dropdown-item">
                      <a href="/#">Report User</a>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* {doorPickers.length > 0 &&
            event.status === 'started' &&
            !eventEnded && (
              <CheckInButton
                userStatus={userStatus}
                setUserStatus={setUserStatus}
                eventId={event.id}
                userId={data.id}
              />
            )} */}
        </Column>
      </Container>
      <BookingReportModal
        event={event}
        ejectedAt={
          data.ejectedAt
            ? moment(data.ejectedAt).format('D MMM YYYY hh:mm A')
            : ''
        }
        invitedBy={invitedBy}
        ejectReason={data.ejectReason}
        status={capitalize(bookingStatus)}
        checkInBy={
          data.user && data.checkInBy && data.user.id === data.checkInBy.id
            ? 'Self CheckIn'
            : checkInBy || walkInBy || rejectedBy
        }
        checkInAt={checkInTime}
        isActive={showReportModal}
        onClose={() => setShowReportModal(false)}
      />
    </>
  );
};

const PeopleCard = ({ data, event, status, isWalkIn, isBrandEvent }) => {
  const [showMore, setShowMore] = useState(false);
  return (
    <>
      <PeopleCardItem
        data={data}
        event={event}
        status={status}
        isWalkIn={isWalkIn}
        isBrandEvent={isBrandEvent}
        isFreeBooking={data.amount === 0}
        setShowMore={() => setShowMore(!showMore)}
        showMore={showMore}
        showResendConfirmationButton={isBrandEvent && status === 'authorized'}
      />
      {showMore &&
        data &&
        data.collaborators &&
        data.collaborators.map(item => {
          const bookingData = item.booking
            ? item.booking
            : {
                paymentStatus: data.paymentStatus,
                pricingLabel: data.pricingLabel,
                pricingType: data.pricingType,
                user: item.user,
                parentBookingId: data && data.id ? data.id : '',
              };
          return (
            <PeopleCardItem
              key={item.id}
              data={bookingData}
              event={event}
              isFreeBooking={data.amount === 0}
              isInvitedOnly={item.status === 'pending'}
              status={item.booking ? item.booking.status : item.status}
              invitedBy={`${data.user.profile.firstName} ${
                data.user.profile.lastName ? data.user.profile.lastName : ''
              }`}
              isWalkIn={item.booking?.isWalkIn}
              isBrandEvent={isBrandEvent}
              isCollaboratorBooking
              showResendConfirmationButton={
                isBrandEvent && item.booking?.status === 'authorized'
              }
            />
          );
        })}
    </>
  );
};

export default PeopleCard;
