import React, { useState, useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import { ApolloProvider } from 'react-apollo';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import 'bulma/css/bulma.css';
import 'animate.css/source/animate.css';

import theme, { GlobalStyle } from './theme';
import apolloClient, { persistor } from './utils/apolloClient';
import { Loading } from './components/elements';
import {
  Login,
  Register,
  Dashboard,
  Events,
  UpdateEvent,
  DataInsight,
  Payments,
  Followers,
  ForgotPassword,
  SetPassword,
  Settings,
  GuestList,
  Messaging,
  Analytics,
  Faq,
  Notification,
  Invite,
  GenerateQrCode,
  Widget,
  GuestlistApproval,
  // UnderDevelopment,
} from './pages';

// import Test from './pages/Test';
import Layout from './components/global/Layout';

const PrivateRoute = ({ component: Component, passProps, ...rest }) => {
  const token = window.localStorage.getItem('token');
  const isLoggedIn = !!token;

  return (
    <Route
      {...rest}
      render={props =>
        isLoggedIn ? (
          <Component {...props} {...passProps} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

const App = () => {
  const [client, setClient] = useState(undefined);

  useEffect(() => {
    async function init() {
      // const apolloCache = await AsyncStorage.getItem('listme-apollo-data');
      // console.log('apolloCache', apolloCache);
      // await persistor.purge();
      await persistor.restore();
      setClient(apolloClient);

      console.log('app ready');
    }

    init().catch(console.error);
  }, []);

  if (!client) {
    return <Loading />;
  }

  return (
    <ThemeProvider theme={theme}>
      <ApolloProvider client={client}>
        <BrowserRouter>
          <Switch>
            <Route path="/login" exact component={Login} />
            <Route path="/register" exact component={Register} />
            <Route exact path="/set-password/:token" component={SetPassword} />
            <Route path="/forgot-password" exact component={ForgotPassword} />
            <Layout>
              {/* <Route path="/test" exact component={Test} /> */}
              <PrivateRoute path="/" exact component={Dashboard} />
              <PrivateRoute path="/events" exact component={Events} />
              <PrivateRoute
                path="/update-event/:eventId"
                exact
                component={UpdateEvent}
              />
              <PrivateRoute path="/invite" exact component={Invite} />
              <PrivateRoute
                path="/generate-qr-code"
                exact
                component={GenerateQrCode}
              />
              <PrivateRoute path="/widget" exact component={Widget} />
              <PrivateRoute path="/settings" exact component={Settings} />
              <PrivateRoute path="/settings/:step" exact component={Settings} />
              <PrivateRoute path="/followers" exact component={Followers} />
              <PrivateRoute
                path="/payments-success"
                exact
                component={Payments}
                passProps={{ type: 'success' }}
              />

              <PrivateRoute
                path="/data-insight"
                exact
                component={DataInsight}
              />
              <PrivateRoute
                path="/guest-list/:eventId"
                exact
                component={GuestList}
              />
              <PrivateRoute path="/messaging" exact component={Messaging} />
              <PrivateRoute
                path={[
                  '/payment-analytics',
                  '/payment-analytics/:eventId',
                  '/payment-analytics/list-view/:eventId',
                ]}
                exact
                component={Analytics}
              />
              <PrivateRoute
                path="/notifications"
                exact
                component={Notification}
              />
              <PrivateRoute path="/faq" exact component={Faq} />
              <PrivateRoute
                path="/guestlist-approval/:id"
                exact
                component={GuestlistApproval}
              />
            </Layout>
          </Switch>
        </BrowserRouter>
        <GlobalStyle />
      </ApolloProvider>
    </ThemeProvider>
  );
};

export default App;
