import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import swal from 'sweetalert';

import QrCodeTypes from '../components/generateQrCode/QrCodeTypes';
import GenerateQrCodeForm from '../components/generateQrCode/GenerateQrCodeForm';

const Layout = styled.div`
  padding: 2.25rem;
  .qrCode-items {
    width: 100%;
  }
  .subtitle,
  .is-white {
    color: ${props => props.theme.placeholderColor};
  }
  .hero.is-large .hero-body {
    padding: 16.5rem 1.5rem;
  }
  .back-icon {
    color: ${props => props.theme.mainBrandColor};
  }
  .hero {
    min-height: 85vh;
  }
`;

const meQuery = gql`
  query meQuery {
    me {
      id
      username
    }
  }
`;

const GenerateQrCode = () => {
  const { data, error } = useQuery(meQuery, {
    fetchPolicy: 'cache-and-network',
  });
  const me = data && data.me ? data.me : {};

  const [qrCodeType, setQrCodeType] = useState('');
  const [showGenerateQrCodeForm, setShowGenerateQrCodeForm] = useState(false);

  useEffect(() => {
    if (error) {
      swal('Oops!', error.message, 'error');
    }
  }, [error]);

  return (
    <Layout className="section">
      {!showGenerateQrCodeForm && (
        <section className="hero has-background-white has-border-radius-medium is-medium">
          <p className="ml-5 mt-5 subtitle has-text-weight-semibold is-size-6-mobile">
            Generate your QR Code for here
          </p>
          <div className="hero-body">
            <QrCodeTypes
              isActive={qrCodeType}
              onClick={val => {
                setQrCodeType(val);
                setTimeout(
                  () => setShowGenerateQrCodeForm(!showGenerateQrCodeForm),
                  500,
                );
              }}
            />
          </div>
        </section>
      )}
      {showGenerateQrCodeForm && (
        <div className="has-background-white py-6 has-border-radius-medium">
          <div className="container px-5">
            <button
              onClick={() => setShowGenerateQrCodeForm(!showGenerateQrCodeForm)}
              className="button is-white px-0"
            >
              <span className="icon">
                <i className="fas fa-chevron-left back-icon" />
              </span>
              <span className="has-text-weight-semibold subtitle is-size-6-mobile">
                Generate your QR Code for here
              </span>
            </button>
            <GenerateQrCodeForm qrCodeType={qrCodeType} userId={me.id} />
          </div>
        </div>
      )}
    </Layout>
  );
};
export default GenerateQrCode;
