import React from 'react';
import { Line } from 'react-chartjs-2';

import { Loading } from '../elements';

const CardChart = ({ data, loading, options }) => {
  if (loading && (!data || data.length === 0)) return <Loading />;

  // if (error && !data) return `Error! ${error.message}`;

  if (!data || data.length === 0) {
    return (
      <div className="column">
        <p className="has-text-centered has-text-weight-semibold is-size-4">
          No data
        </p>
      </div>
    );
  }

  const {
    lbl,
    numb: { card },
  } = data;

  const overAll = {
    labels: lbl.reverse(),
    datasets: [
      {
        label: 'Revenue',
        data: card.reverse(),
        fill: true,
        background:
          'linear-gradient(180deg, #713BDB -47.71%, rgba(255, 255, 255, 0) 100%)',
        borderColor: '#A5227E',
      },
    ],
  };
  return <Line data={overAll} options={options} />;
};

export default CardChart;
