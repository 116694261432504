import React, { useEffect } from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import swal from 'sweetalert';
import { useQuery } from '@apollo/react-hooks';

import WidgetForm from '../components/form/WidgetForm';

const Layout = styled.div`
  padding: 2.25rem;
  .subtitle,
  .is-white {
    color: ${props => props.theme.placeholderColor};
  }
  .back-icon {
    color: ${props => props.theme.mainBrandColor};
  }
  .hero {
    min-height: 85vh;
  }
`;

const meQuery = gql`
  query meEvents {
    me {
      id
    }
  }
`;

const Widget = () => {
  const { data, error } = useQuery(meQuery, {
    fetchPolicy: 'cache-and-network',
  });
  const meData = data && data.me ? data.me : {};

  useEffect(() => {
    if (error) {
      swal('Oops!', error.message, 'error');
    }
  }, [error]);
  return (
    <Layout>
      <div className="has-background-white py-6 has-border-radius-medium">
        <div className="container px-5">
          <div className="columns">
            <div className="column is-7">
              <button
                onClick={() => window.history.back()}
                className="button is-white px-0"
              >
                <span className="icon">
                  <i className="fas fa-chevron-left back-icon" />
                </span>
                <span className="has-text-weight-semibold subtitle is-size-6-mobile">
                  Pop-up color scheme
                </span>
              </button>
              <WidgetForm brandId={meData.id} />
            </div>
            {/* <div className="column is-4" /> */}
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default Widget;
