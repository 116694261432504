import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { upperCase } from 'lodash';
import swal from 'sweetalert';

import TextArea from './elements/TextArea';
import TextInput from './elements/TextInput';
import SelectedTimeSlotsModal from './modals/SelectedTimeSlotsModal';
import SelectTimeSlotModal from './modals/SelectTimeSlotModal';
import Error from './elements/Error';

const currencyFormatter = require('currency-formatter');

const TableRow = styled.tr`
  box-shadow: ${props =>
    props.isActive ? '0px 5px 14px rgba(0, 0, 0, 0.06)' : 'none'};
  td {
    background: ${props => (props.isActive ? '#FFF' : '#fcfcfc')} !important;
    color: ${props =>
      props.isActive
        ? props.theme.textLightGrey
        : props.theme.textColorLight} !important;
    p {
      color: ${props =>
        props.isActive
          ? props.theme.textLightGrey
          : props.theme.textColorLight} !important;
    }
  }
  input:disabled {
    color: ${props => props.theme.textColorLight} !important;
    background: transparent !important;
  }
  .add-time-slot {
    vertical-align: middle;
    color: ${props =>
      props.isActive
        ? props.theme.mainBrandColor
        : props.theme.textColorLight} !important;
    :hover {
      color: ${props => props.theme.mainBrandColor};
    }
  }
  .show-slot {
    color: ${props =>
      props.isActive
        ? props.theme.textLightGrey
        : props.theme.textColorLight} !important;
  }
  .confirm-button {
    ${'' /* background: ${props => props.theme.mainBrandColor} !important; */}
    background: ${props => props.theme.bgLinerGradient} !important;

  }
`;

const ReservationTableRow = ({ event, item, onChange }) => {
  const [label, setLabel] = useState(item.label);
  const [details, setDetails] = useState(item.details);
  const [allowedPeopleLimit, setAllowedPeopleLimit] = useState(
    item.allowedPeopleLimit,
  );
  const [price, setPrice] = useState(item.price);
  const [quantity, setQuantity] = useState(item.quantity);
  const [isVisible, setIsVisible] = useState(item.isVisible);
  const [timeSlots, setTimeSlots] = useState(item.timeSlots || []);
  const [isConfirmed, setIsConfirmed] = useState(item.isConfirmed || false);
  const [showSelectedTimeSlotsModel, setShowSelectedTimeSlotsModel] = useState(
    false,
  );
  const [showSelectTimeSlotsModel, setShowSelectTimeSlotsModel] = useState(
    false,
  );
  const isDisabled = isConfirmed === true || isVisible === false;

  const handleSubmit = () => {
    onChange({
      label,
      details,
      price: parseFloat(price || 0),
      quantity: parseInt(quantity, 10),
      bookedQuantity: item.bookedQuantity
        ? parseInt(item.bookedQuantity, 10)
        : 0,
      allowedPeopleLimit: parseInt(allowedPeopleLimit, 10),
      timeSlots,
      isVisible,
      isConfirmed,
    });
  };

  useEffect(() => {
    handleSubmit();
  }, [timeSlots, isVisible, isConfirmed]);
  // console.log(label);

  const handleConfirm = () => {
    if (
      !label ||
      !details ||
      quantity < 1 ||
      quantity < 1 ||
      allowedPeopleLimit < 1 ||
      timeSlots.length === 0
    ) {
      swal({
        title: '',
        text: 'Please fill required details!',
        icon: 'warning',
      });
    } else {
      swal({
        title: 'Are you sure?',
        text:
          'Once confirm, you will not be able to edit this package package name, price and quantity!',
        icon: 'info',
        buttons: ['Cancel', 'Confirm'],
      }).then(willConfirm => {
        if (willConfirm) {
          setIsConfirmed(!isConfirmed);
        }
      });
    }
  };

  return (
    <>
      <TableRow isActive={isVisible}>
        <td>
          <TextArea
            placeholder="Package Name"
            disabled={isDisabled}
            value={label}
            onChange={e =>
              setLabel(e.target.value.length > 25 ? label : e.target.value)
            }
            onBlur={handleSubmit}
          />
          {label && label.length > 90 && (
            <Error>Max 25 characters allowed!</Error>
          )}
          {!label && isVisible && <Error>Package Name is required!</Error>}
        </td>
        <td>
          <TextArea
            name="details"
            placeholder="Package Info."
            hasTextCentered
            disabled={!isVisible}
            value={details}
            onChange={e => setDetails(e.target.value)}
            onBlur={handleSubmit}
          />
          {!details && isVisible && <Error>Please add package info!</Error>}
        </td>
        <td>
          <div className="is-flex is-align-items-center is-justify-content-center">
            <p>
              {currencyFormatter.findCurrency(upperCase(event.currency)).symbol}
            </p>

            <TextInput
              disabled={isDisabled}
              required
              min="0"
              value={price}
              type="number"
              onChange={e => setPrice(e.target.value)}
              placeholder="0.00"
              onBlur={handleSubmit}
            />
          </div>
        </td>
        <td>
          <TextInput
            disabled={isDisabled}
            text="Available"
            required
            min="0"
            value={quantity}
            type="number"
            onChange={e => setQuantity(e.target.value)}
            placeholder="Quantity"
            onBlur={handleSubmit}
          />
          {quantity < 1 && isVisible && (
            <Error>Please add available quantity!</Error>
          )}
        </td>
        <td>
          <TextInput
            isSecondary
            hasTextCentered
            disabled={!isVisible}
            required
            value={allowedPeopleLimit}
            min="0"
            type="number"
            onChange={e => setAllowedPeopleLimit(e.target.value)}
            onBlur={handleSubmit}
          />
          {allowedPeopleLimit < 1 && isVisible && (
            <Error>Please add max no. of peoples!</Error>
          )}
        </td>
        <td>
          <div className="is-flex is-justify-content-center">
            <button
              disabled={!isVisible}
              className="button is-white p-0 mr-2 show-slot"
              onClick={() =>
                setShowSelectedTimeSlotsModel(!showSelectedTimeSlotsModel)
              }
            >
              View
            </button>
            <button
              disabled={!isVisible}
              className="button is-white p-0 add-time-slot is-size-6"
              onClick={() =>
                setShowSelectTimeSlotsModel(!showSelectTimeSlotsModel)
              }
            >
              <i className="fas fa-plus" />
            </button>
          </div>
          {timeSlots.length === 0 && isVisible && (
            <Error>Please add available time slots!</Error>
          )}

          <SelectedTimeSlotsModal
            subtitle={label}
            isActive={showSelectedTimeSlotsModel}
            selectedTimeSlots={timeSlots}
            onClose={() =>
              setShowSelectedTimeSlotsModel(!showSelectedTimeSlotsModel)
            }
            onRemoveTimeSlot={index => {
              const timeSlotsArray = timeSlots.slice(0);
              timeSlotsArray.splice(index, 1);
              setTimeSlots(timeSlotsArray);
            }}
          />
          <SelectTimeSlotModal
            event={event}
            subtitle={label}
            isActive={showSelectTimeSlotsModel}
            onClose={() =>
              setShowSelectTimeSlotsModel(!showSelectTimeSlotsModel)
            }
            onSubmit={formData => {
              setTimeSlots([...timeSlots, formData]);
              setShowSelectTimeSlotsModel(!showSelectTimeSlotsModel);
            }}
          />
        </td>
        <td>
          {isConfirmed === true ? (
            <span
              className={`tag ${
                isVisible
                  ? 'bg-linear-gradient-green has-text-white'
                  : 'is-primary is-light'
              }`}
            >
              Confirmed
            </span>
          ) : (
            <button
              disabled={isVisible === false}
              type="button"
              onClick={handleConfirm}
              className="button  py-1 is-white is-small confirm-button  has-text-white has-border-radius-small"
            >
              Confirm
            </button>
          )}
        </td>
        <td>
          <button
            type="button"
            onClick={() => {
              setIsVisible(!isVisible);
            }}
            className="button is-white px-0"
          >
            {isVisible === true && (
              <span>
                <i className="fas fa-eye" />
              </span>
            )}
            {isVisible === false && (
              <span>
                <i className="fas fa-eye-slash" />
              </span>
            )}
          </button>
        </td>
      </TableRow>
    </>
  );
};

export default ReservationTableRow;
