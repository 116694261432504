import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import { isNaN } from 'lodash';
import moment from 'moment';
import CheckBox from './elements/CheckBox';
import Select from './elements/Select';

const Container = styled.div`
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important;
  min-width: max-content;
  box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.06);
  color: ${props => props.theme.textLightGrey} !important;

  input.is-small {
    vertical-align: middle;
    :after {
      border-color: ${props => props.theme.placeholderColor} !important;
    }
  }

  input {
    font-size: 10px !important;
    max-width: 70px;
    :active,
    :focus {
      box-shadow: none;
      outline: none;
      border-color: #77777750;
    }
  }
`;

const freeGirlsLimitOptions = [
  { value: 25 },
  { value: 50 },
  { value: 75 },
  { value: 100 },
];

const EventEntryRules = ({ rules, onChange }) => {
  const [isReady, setIsReady] = useState(false);
  const [girlsNightFree, setGirlsNightFree] = useState(false);
  const [girlsFreeTill, setGirlsFreeTill] = useState('');

  const [freeGirlsLimit, setFreeGirlsLimit] = useState(0);
  // console.log(girlsFreeTill, rules);

  useEffect(() => {
    setIsReady(true);
    if (!isReady) {
      rules.forEach(str => {
        if (str === 'Girls free all night') {
          setGirlsNightFree(true);
        }
        // if (str.search('Girls free till')) {
        //   setGirlsFreeTill(moment(str.replace('Girls free till ', '')));
        // }
        if (str.search('Girls free till') !== -1) {
          setGirlsFreeTill(str.replace('Girls free till ', ''));
        }
        if (str.search('First girls free')) {
          const girlsLimit = str.replace('First girls free', '');
          if (!isNaN(parseInt(girlsLimit, 10))) {
            setFreeGirlsLimit(parseInt(girlsLimit, 10));
          }
        }
      });
    }
  }, [rules]);

  useEffect(() => {
    const rulesArray = [];
    if (girlsNightFree === true) {
      rulesArray.push('Girls free all night'); // DO NOT CHANGE TEXT
    }
    // if (girlsFreeTill !== undefined) {
    //   const formattedTime = moment(girlsFreeTill);
    //   if (formattedTime.isValid()) {
    //     rulesArray.push(`Girls free till ${formattedTime.format('h:mm a')}`); // DO NOT CHANGE TEXT
    //   }
    // }
    if (girlsFreeTill !== '') {
      const formattedTime = moment(girlsFreeTill);
      if (formattedTime.isValid()) {
        rulesArray.push(`Girls free till ${formattedTime.format('h:mm a')}`); // DO NOT CHANGE TEXT
      } else {
        rulesArray.push(`Girls free till ${girlsFreeTill}`); // DO NOT CHANGE TEXT
      }
    }
    if (freeGirlsLimit !== 0) {
      rulesArray.push(` ${freeGirlsLimit} First girls free`); // DO NOT CHANGE TEXT
    }
    onChange(rulesArray);
  }, [girlsNightFree, girlsFreeTill, freeGirlsLimit]);

  return (
    <Container className="py-4 has-background-white">
      <div>
        <p>Rules</p>
        <div className="mt-5 is-flex">
          <CheckBox
            checked={girlsNightFree}
            onChange={() => {
              setGirlsNightFree(!girlsNightFree);
              setGirlsFreeTill('');
            }}
          />
          <p className="ml-5">Girls free all night </p>
        </div>
        <div className="mt-5 is-flex">
          <CheckBox
            checked={girlsFreeTill}
            onChange={() => {
              setGirlsFreeTill(girlsFreeTill ? '' : girlsFreeTill);
            }}
          />

          <p className="ml-5">Girls free till </p>
          <div className="control mx-3">
            <DatePicker
              placeholderText="Select"
              className="input is-small"
              // selected={girlsFreeTill}
              value={
                moment(girlsFreeTill).isValid()
                  ? moment(girlsFreeTill).format('h:mm a')
                  : girlsFreeTill
              }
              onChange={e => {
                setGirlsFreeTill(e);
                setGirlsNightFree(false);
              }}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={30}
              timeCaption="Time"
              dateFormat="h:mm aa"
            />
          </div>
          <span className="ml-3 has-text-grey-light"> (select a time)</span>
        </div>
        <div className="mt-5 is-flex">
          <CheckBox
            checked={freeGirlsLimit}
            onChange={() => {
              setFreeGirlsLimit(freeGirlsLimit ? 0 : freeGirlsLimit);
            }}
          />

          <div className="ml-5 is-flex">
            First{' '}
            <Select
              className="mx-3"
              placeholder="0"
              value={freeGirlsLimit}
              onChange={e => setFreeGirlsLimit(parseInt(e.target.value, 10))}
              options={freeGirlsLimitOptions}
            />
            girls free{' '}
          </div>
          <span className="ml-3 has-text-grey-light	">
            (select quantity up to 25, 50, 75, 100)
          </span>
        </div>
      </div>
    </Container>
  );
};
export default EventEntryRules;
