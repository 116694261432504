import React, { useState } from 'react';
import { withFormik } from 'formik';
import * as yup from 'yup';
import styled from 'styled-components';
import { getGeocode } from 'use-places-autocomplete';
import PlacesAutocomplete from 'react-places-autocomplete';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import swal from 'sweetalert';
import { isEmpty } from 'lodash';
import { Button, Field } from '../../elements';
import GoogleMap from '../../global/GoogleMap';
import EventAddress from '../EventAddress';

const Container = styled.form`
  .label {
    color: ${props => props.theme.textLightGrey};
  }
  button.is-fullwidth {
    color: ${props => props.theme.textColorLight};
    padding: 0 2rem !important;
  }

  .address {
    color: ${props => props.theme.textColorLight};
    padding-left: 2rem;
    padding-right: 1.25rem;
    border: 1px solid ${props => props.theme.borderColor};
    padding-top: calc(0.5em - 1px);
    padding-bottom: calc(0.5em - 1px);
  }
  .autocomplete-wrapper {
    position: relative;
  }
  .autocomplete-dropdown-container {
    border: 1px solid #b5b5b5;
    position: absolute;
    width: 100%;
    z-index: 99;
    display: block;
    div {
      padding: 0.75rem 2rem;
    }
    &.hidden {
      display: none;
    }
  }
  .select select {
    border-radius: 10px;
  }
  .columns.is-variable.is-1 {
    --columnGap: 0.4rem !important;
  }
  .fa-star-of-life {
    height: 8px;
  }
  .full-address {
    min-height: 5rem;
  }
  .has-border-radius-normal {
    border-radius: 8px !important;
  }
  @media only screen and (max-width: 768px) {
    .map-responsive {
      max-height: 250px !important;
      > div {
        max-height: 250px !important;
      }
    }
  }
`;

const normalizeAddress = (data, locationName) => {
  const response = {
    name: locationName,
    addressLine1: '',
    city: '',
    state: '',
    postcode: '',
    country: '',
    location: {
      coordinates: [],
    },
    formattedAddress: '',
  };
  if (!data[0]) {
    return response;
  }
  const matchedItem = data[0];

  if (matchedItem.geometry && matchedItem.geometry.location) {
    // response.location.type = matchedItem.geometry.location_type;
    response.location.coordinates = [
      matchedItem.geometry.location.lng(),
      matchedItem.geometry.location.lat(),
    ];
  }
  let addressLine1 = '';
  response.formattedAddress = matchedItem.formatted_address;
  matchedItem.address_components.forEach(item => {
    // console.log(item);
    const key = item.types[0];
    switch (key) {
      case 'street_number':
        addressLine1 = item.long_name;
        break;
      case 'neighborhood':
        addressLine1 = addressLine1
          ? `${addressLine1}, ${item.long_name}`
          : item.long_name;
        break;
      case 'route':
        addressLine1 = addressLine1
          ? `${addressLine1}, ${item.long_name}`
          : item.long_name;
        break;
      case 'postal_town':
        response.city = item.long_name;
        break;
      case 'locality':
        response.city = response.city ? response.city : item.long_name;
        break;
      case 'country':
        response.country = item.long_name;
        break;
      case 'postal_code':
        response.postcode = item.long_name;
        break;
      case 'administrative_area_level_2':
        response.state = item.long_name;
        break;
      default:
    }
  });
  response.addressLine1 = addressLine1;

  return response;
};

const EventLocationForm = ({
  values,
  errors,
  onChange,
  isSubmitting,
  handleSubmit,
  handleChange,
  handleBlur,
  setFieldValue,
  isVirtualOnly,
  handleBackPress,
  handleSkipStep,
}) => {
  // toString(values.genre.map((item))
  const [search, setSearch] = useState('');
  const [edit, setEdit] = useState(false);
  //
  const mapLatLng = {
    lat: undefined,
    lng: undefined,
  };
  if (
    values.address &&
    values.address.location &&
    values.address.location.coordinates
  ) {
    // eslint-disable-next-line prefer-destructuring
    mapLatLng.lat = values.address.location.coordinates[1];
    // eslint-disable-next-line prefer-destructuring
    mapLatLng.lng = values.address.location.coordinates[0];
  }

  const onSavePress = () => {
    if (errors.address) {
      swal('Oops!', 'Please fill complete address!', 'error');
    } else {
      setFieldValue('venueAddress', values.address);
      setEdit(false);
      setSearch('');
      onChange({ venueAddress: values.address });
    }
  };

  const visibleAddressForm = search || edit;
  return (
    <Container onSubmit={handleSubmit} className="mt-3">
      <div className="columns ">
        <div className="column is-5 mt-4">
          <label className="label has-text-weight-normal is-capitalized mb-3">
            Search Address
          </label>

          {/* <button className="button is-fullwidth has-border-radius-medium is-justify-content-space-between	mb-3">
            <span>Add New</span>
            <span className="icon is-small">
              <i className="fas fa-plus" />
            </span>
          </button> */}
          <div className="">
            <PlacesAutocomplete
              name="address.name"
              value={search}
              searchOptions={{
                types: ['establishment', 'geocode'],
              }}
              onChange={e => {
                setSearch(e);
              }}
              onSelect={e => {
                // console.log('on select', e);
                const nameArr = e.split(',');
                const locationName = nameArr[0];
                setSearch(locationName);

                getGeocode({ address: e })
                  .then(results => {
                    // console.log('📍 Coordinates: ', results);
                    const formattedAddress = normalizeAddress(
                      results,
                      locationName,
                    );
                    // console.log('formattedAddress', formattedAddress);
                    setFieldValue('address', formattedAddress);
                  })
                  .catch(error => {
                    console.log('Error: ', error);
                  });
              }}
              onBlur={handleBlur}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div className="autocomplete-wrapper">
                  <Field
                    icon="fas fa-search"
                    isMedium
                    {...getInputProps({
                      placeholder: 'Search for address',
                    })}
                  />
                  <div
                    className={`autocomplete-dropdown-container ${
                      suggestions.length > 0 ? '' : 'hidden'
                    }`}
                  >
                    {loading && <div>Loading...</div>}
                    {suggestions.map(suggestion => {
                      const className = suggestion.active
                        ? 'suggestion-item--active'
                        : 'suggestion-item';
                      // inline style for demonstration purpose
                      const style = suggestion.active
                        ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                      return (
                        <div
                          key={suggestion.placeId + suggestion.description}
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style,
                          })}
                        >
                          {suggestion.description}
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
          </div>
          {!search && !edit && (
            <div
              onClick={() => setEdit(!edit)}
              className="address has-border-radius-medium is-fullwidth is-flex is-justify-content-space-between	 full-address"
            >
              {values.venueAddress.name ? (
                <EventAddress address={values.venueAddress} />
              ) : (
                <span>Event Location</span>
              )}
              <div
                onClick={() => setEdit(!edit)}
                className="icon is-small ml-3 mt-1"
              >
                <i className="fas fa-pencil-alt" />
              </div>
            </div>
          )}

          {visibleAddressForm && (
            <>
              <label className="label has-text-weight-normal is-capitalized mb-3">
                Manual Address Entry
              </label>
              <div className="address has-border-radius-medium is-fullwidth py-5">
                <Field
                  label="Name"
                  requiredIcon
                  className="mb-4"
                  isMedium
                  name="address.name"
                  placeholder="Name of venue"
                  type="text"
                  value={values.address.name}
                  onChange={handleChange}
                />
                <Field
                  label="Address Line 1"
                  requiredIcon
                  className="mb-4"
                  isMedium
                  name="address.addressLine1"
                  placeholder="Address Line 1"
                  type="text"
                  value={values.address.addressLine1}
                  onChange={handleChange}
                />
                <div className="field-body">
                  <div className="field mb-3">
                    <p className="control is-expanded ">
                      <Field
                        className="mb-4"
                        label="City"
                        requiredIcon
                        isMedium
                        name="address.city"
                        placeholder="Name of City"
                        type="text"
                        value={values.address.city}
                        onChange={handleChange}
                      />
                    </p>
                  </div>
                  <div className="field mb-3">
                    <p className="control is-expanded ">
                      <Field
                        className="mb-4"
                        label="Postcode"
                        requiredIcon
                        isMedium
                        name="address.postcode"
                        placeholder="Postcode"
                        type="text"
                        value={values.address.postcode}
                        onChange={handleChange}
                      />
                    </p>
                  </div>
                </div>
                <div className="columns is-variable is-1">
                  <div className="field column is-6">
                    <label className="label is-capitalized has-text-weight-normal mb-3 is-flex is-align-items-center">
                      Country
                      <i className="fas fa-star-of-life has-text-danger ml-1" />
                    </label>
                    <p className="control is-expanded ">
                      <div className="select">
                        <CountryDropdown
                          name="address.country"
                          className="has-border-radius-medium mb-4"
                          value={values.address.country}
                          onChange={e => setFieldValue('address.country', e)}
                          onBlur={handleBlur}
                        />
                      </div>
                    </p>
                  </div>
                  <div className="field column is-6">
                    <label className="label is-capitalized has-text-weight-normal mb-3 is-flex is-align-items-center">
                      State
                    </label>
                    <p className="control is-expanded ">
                      <div className="select has-border-radius-medium  is-fullwidth mb-4">
                        <RegionDropdown
                          name="address.state"
                          className="has-border-radius-medium"
                          country={values.address.country}
                          value={values.address.state}
                          onChange={e => setFieldValue('address.state', e)}
                        />
                      </div>
                    </p>
                  </div>
                </div>
                <div className="field is-grouped is-justify-content-center mt-0 mb-4">
                  <p className="control">
                    <Button
                      onClick={() => {
                        setSearch('');
                        setEdit(false);
                      }}
                      type="reset"
                      title="Cancel"
                      className="button is-uppercase is-normal has-border-radius-normal"
                    />
                  </p>
                  <p className="control">
                    <Button
                      type="button"
                      onClick={onSavePress}
                      title="Save"
                      className="button is-normal is-primary is-uppercase px-5 has-border-radius-normal"
                    />
                  </p>
                </div>
              </div>
            </>
          )}
        </div>

        <div className="column is-6 is-offset-1 mt-4 map-responsive">
          <GoogleMap location={mapLatLng} address={values.address} />
        </div>
      </div>

      <p className="buttons is-justify-content-center mt-6 my-6">
        <Button
          onClick={handleBackPress}
          title="Back"
          className="is-rounded button is-medium  px-6"
          type="submit"
        />
        <Button
          onClick={() => {
            if (isVirtualOnly) {
              handleSkipStep();
            } else if (
              (!isEmpty(errors) && !isVirtualOnly) ||
              (!values.venueAddress.name && !isVirtualOnly)
            ) {
              swal('Oops!', 'Please fill complete address!', 'error');
            } else {
              handleSkipStep();
            }
          }}
          loading={isSubmitting}
          title="Next"
          className="is-rounded button is-medium is-primary px-6"
          type="button"
        />
      </p>
    </Container>
  );
};
export default withFormik({
  mapPropsToValues: ({ initialValues }) => ({
    address: {
      name: initialValues.venueAddress ? initialValues.venueAddress.name : '',
      addressLine1: initialValues.venueAddress
        ? initialValues.venueAddress.addressLine1
        : '',
      addressLine2: initialValues.venueAddress
        ? initialValues.venueAddress.addressLine2
        : '',
      city: initialValues.venueAddress ? initialValues.venueAddress.city : '',
      state: initialValues.venueAddress ? initialValues.venueAddress.state : '',
      postcode: initialValues.venueAddress
        ? initialValues.venueAddress.postcode
        : '',
      country: initialValues.venueAddress
        ? initialValues.venueAddress.country
        : '',
      formattedAddress: initialValues.venueAddress
        ? initialValues.venueAddress.formattedAddress
        : '',
      location: {
        coordinates: initialValues.venueAddress
          ? initialValues.venueAddress.location &&
            initialValues.venueAddress.location.coordinates.map(item => item)
          : [-0.137163, 50.82253000000001],
      },
    },
    venueAddress: {
      name: initialValues.venueAddress ? initialValues.venueAddress.name : '',
      addressLine1: initialValues.venueAddress
        ? initialValues.venueAddress.addressLine1
        : '',
      addressLine2: initialValues.venueAddress
        ? initialValues.venueAddress.addressLine2
        : '',
      city: initialValues.venueAddress ? initialValues.venueAddress.city : '',
      state: initialValues.venueAddress ? initialValues.venueAddress.state : '',
      postcode: initialValues.venueAddress
        ? initialValues.venueAddress.postcode
        : '',
      country: initialValues.venueAddress
        ? initialValues.venueAddress.country
        : '',
      formattedAddress: initialValues.venueAddress
        ? initialValues.venueAddress.formattedAddress
        : '',
      location: {
        coordinates: initialValues.venueAddress
          ? initialValues.venueAddress.location &&
            initialValues.venueAddress.location.coordinates.map(item => item)
          : [-0.137163, 50.82253000000001],
      },
    },
  }),
  validationSchema: yup.object().shape({
    address: yup.object().shape({
      name: yup.string().required('Venue name is required'),
      addressLine1: yup.string(),
      city: yup.string().required('City is required'),
      state: yup.string(),
      postcode: yup.string().required('Post code is required'),
      country: yup.string().required('Country is required'),
    }),
  }),

  handleSubmit: (values, { setSubmitting, props }) => {
    // console.log('handle submit', values, props, setSubmitting);
    props.onSubmit({ venueAddress: values.venueAddress });
    setSubmitting(false);
  },
  displayName: 'EventLocationForm', // helps with React DevTools
})(EventLocationForm);
