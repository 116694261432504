/* eslint-disable no-underscore-dangle */
import React from 'react';
import styled from 'styled-components';

import { Button, Tag } from '../elements';
import { moment } from '../../utils/helpers';

const Container = styled.tr`
  background-color: ${({ bgColor }) => bgColor};
  .accept-button {
    ${'' /* background: ${props => props.theme.buttonColor}; */}
    background: ${props => props.theme.bgLinerGradient};
    font-size: 0.9vw;
    border: 1px solid #a5227e;
    box-sizing: border-box;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
    margin-right: 8px;
  }
  .deny-button {
    font-size: 0.9vw;
    padding: 0 22px;
    background: #ffffff;
    border: 1px solid ${props => props.theme.buttonColor};
    box-sizing: border-box;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
    color: ${props => props.theme.buttonColor};
  }
  .col {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    word-break: break-all;
  }
  .ellipsis-dropdown {
    .dropdown-trigger {
      .button {
        border: none;
        background-color: transparent;
      }
    }
    .dropdown-menu {
      min-width: 7.5rem;
      a {
        display: block;
      }
      .button {
        width: 100%;
        justify-content: flex-end;
      }
    }
  }
`;

const Column = styled.td`
  font-size: 1rem;
`;

const PaymentCard = ({ data }) => {
  const user = data && data.user ? data.user : {};
  // console.log('data', data.paymentStatus);
  return (
    <Container className="grid">
      <Column>
        <div className="col">{data.code ? data.code : ''}</div>
      </Column>
      <Column>{`${user.profile ? user.profile.firstName : ''}  ${
        user.profile && user.profile.lastName ? user.profile.lastName : ''
      }`}</Column>
      <Column>
        <div className="col">{data.amount ? data.amount : '0'}</div>
      </Column>
      <Column>
        <div className="col">{moment(data.createdAt).format('DD/MM/YYYY')}</div>
      </Column>
      <Column>
        <div className="col">
          <Tag
            label={
              data.status === 'refunded' ? data.status : data.paymentStatus
            }
            className={`is-rounded ${
              data.status === 'refunded' ? data.status : data.paymentStatus
            }`}
          />
        </div>
      </Column>
      <Column>
        <div className="col">{user.email ? user.email : ''}</div>
      </Column>
      <Column>
        <div className="col">
          <div className="dropdown is-hoverable ellipsis-dropdown is-right">
            <div className="dropdown-trigger">
              <button className="button">
                <span className="icon is-small">
                  <i className="fas fa-ellipsis-h" />
                </span>
              </button>
            </div>
            <div className="dropdown-menu">
              <a href="/#">
                <Button
                  title="View"
                  className="button is-primary is-small half-rounded"
                />
              </a>
            </div>
          </div>
        </div>
      </Column>
    </Container>
  );
};

export default PaymentCard;
