import React from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';

import { Heading, HeadingWrapper } from '../components/elements';
import RegisterForm from '../components/form/RegisterForm';

const Container = styled.section``;
const Wrapper = styled.div`
  && {
    padding: 2rem;
    background-color: #ffffff;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-blend-mode: exclusion;
    background-position: center !important;
  }
  .control.has-icons-right .icon.is-right {
    right: 4px;
    height: 4rem;
    color: gray;
    pointer-events: auto !important;

  }
  @media only screen and (max-width: 768px) {
    img {
      width: 170px !important;
    }
    .heading-wrapper {
      padding-bottom: 0rem !important;
    }
    .control.has-icons-right .icon.is-right {
      height: 3rem; !important;
    }
  }
`;

const ImageWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  padding-bottom: 4rem;
  img {
    width: 250px;
    height: auto;
  }
  @media only screen and (max-width: 768px) {
    padding-bottom: 2.5rem !important;
  }
`;

const TermsWrapper = styled.div`
  text-align: center;
  color: #858585;
  margin-top: 20px;
  a {
    color: #a5227e;
  }
`;

const ToLoginWrapper = styled.div`
  text-align: center;
  color: #858585;
  margin-top: 1rem;
  a {
    color: #a5227e;
  }
`;

const registerMutation = gql`
  mutation register($input: RegisterInput) {
    register(input: $input) {
      jwt
      user {
        id
        username
        email
        telephone
        profile {
          firstName
          lastName
          image
          dob
          sex
          relationship
          bio
        }
      }
    }
  }
`;

export default class Register extends React.Component {
  handleLoginSuccess = () => {
    // window.localStorage.setItem('token', data.telephoneLogin.jwt);
    // console.log('telephoneLogin', data);
  };

  onRegisterSuccess = async (cache, { data: { register } }) => {
    // console.log('onRegisterSuccess', register);

    await window.localStorage.setItem('token', register.jwt);
  };

  registerOnComplete = () => {
    const { history } = this.props;
    history.push('/settings');
  };

  render() {
    return (
      <Container className="hero is-fullheight">
        <div className="hero-body">
          <div className="container">
            <div className="columns is-centered">
              <div className="column is-half">
                <Wrapper>
                  <ImageWrapper>
                    <img src="/images/logo.png" alt="logo" />
                  </ImageWrapper>
                  <HeadingWrapper>
                    <Heading title="Register" />
                  </HeadingWrapper>
                  <Mutation
                    mutation={registerMutation}
                    update={this.onRegisterSuccess}
                    // onError={error => swal(error.message)}
                    onError={error => {
                      if (
                        error &&
                        error.message.search('already registered') > 0
                      ) {
                        swal(
                          'email is already registered with ListMe business, try another!',
                        );
                      } else {
                        swal(error.message);
                      }
                    }}
                    onCompleted={data => this.registerOnComplete(data)}
                  >
                    {(register, { loading, error }) => (
                      <RegisterForm
                        loading={loading}
                        error={error}
                        onSubmit={formData => {
                          register({
                            variables: { input: formData },
                          });
                        }}
                      />
                    )}
                  </Mutation>
                  <TermsWrapper>
                    By clicking Register you agree to our{' '}
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://www.listmeapp.co/business-user-terms"
                    >
                      Terms and conditions.
                    </a>
                  </TermsWrapper>
                  <ToLoginWrapper>
                    Already have and account <Link to="/login">Login</Link>
                  </ToLoginWrapper>
                </Wrapper>
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}
