import { createGlobalStyle } from 'styled-components';

export const elements = { mobileBreakpoint: 800, ipadBreakpoint: 1024 };

// http://chir.ag/projects/name-that-color/
export const colors = {
  black: '#000000',
  riptide: '#0AF9C4',
  grey: '#616161',
  magenta: '#a5227e',
};

const theme = {
  mainBrandColor: colors.magenta,
  mobileBreakpoint: `${elements.mobileBreakpoint}px`,
  ipadBreakpoint: `${elements.ipadBreakpoint}px`,
  primaryColor: colors.black,
  secondaryColor: colors.riptide,
  buttonColor: colors.magenta,
  subHeaderColor: colors.grey,
  textColorLight: '#BBBDC1',
  // primaryFontFamily: '"Quicksand", sans-serif',
  // secondaryFontFamily: '"Open Sans", sans-serif',
  // subHeaderFontFamily: '"IBM Plex Sans", sans-serif',
  // contentFontFamily: '"Inter", sans-serif',
  // labelFontFamily: '"Poppins", sans-serif',
  robotoFont: '"Roboto", sans-serif',
  fontWeightLight: '300',
  fontWeightRegular: '400',
  fontWeightSemiBold: '600',
  fontWeightBold: '700',
  fontSizeReguar: '400',
  subHeaderFontSize: '15px',
  subHeaderLineHeight: '24px',
  menuFontLineHeight: '19px',
  subHeaderLetterSpacing: '-0.1px',
  borderColor: '#D6D6D6',
  textLightGrey: '#959699',
  placeholderColor: '#858585',
  backgroundLight: '#f6f6f6',
  bgLinerGradient: 'linear-gradient(73.04deg, #b7007b 19.16%, #d5a21b 95.52%)',
};

export default theme;

export const GlobalStyle = createGlobalStyle`
  html, div#root, .hero {
    height: 100%;
  }
  body {
    margin: 0;
    padding: 0;
    height: 100%;
    background: #ffff;
    overflow: auto;
    -ms-overflow-style: none;
    font-family: ${theme.robotoFont};
  }
  body::-webkit-scrollbar {
    display: none;
  }
  .min-h-full {
    min-height: 100%;
  }
  .hero-body{
    padding:0;
  }
  .has-shadow-large {
    box-shadow: -3px -4px 8px rgba(0, 0, 0, 0.1), 3px 4px 8px rgba(0, 0, 0, 0.1);
  }
  .has-border-radius-medium {
    border-radius: 10px;
  }
  .pagination {
    justify-content: center !important;
  }
  .pagination-link {
    ${'' /* background-color: ${props => props.theme.mainBrandColor}; */}
    background: ${theme.bgLinerGradient};
    color: #fff;
    :hover {
      color: #fff;
    }
  }
  .has-border-radius-small {
    border-radius: 5px !important;
  }
  select:focus,
    select:active {
      border-color: #b5b5b5 !important;
      box-shadow: none !important;
    }
    .react-datepicker {
      border: 1px solid ${theme.mainBrandColor} !important;
    }
    .react-datepicker__time-container {
      border-left: 1px solid ${theme.mainBrandColor} !important;
    }
    .react-datepicker__header {
    text-align: center;
    ${'' /* background-color: ${theme.mainBrandColor} !important; */}
    background: ${theme.bgLinerGradient};
    border-bottom: 1px solid ${theme.mainBrandColor} !important;
  }
  .react-datepicker__day-name, .react-datepicker__current-month, .react-datepicker-time__header {
    color: #fff !important;
  }
  .react-datepicker__navigation--next {
    border-left-color: #fff !important;
  }
  .react-datepicker__day--selected, .react-datepicker__time-list-item--selected {
    background: ${theme.bgLinerGradient};
    ${'' /* background-color: ${theme.mainBrandColor} !important; */}
    color: #fff !important;
  }

  .react-datepicker__day--disabled, .react-datepicker__month-text--disabled, .react-datepicker__quarter-text--disabled, .react-datepicker__year-text--disabled {
    cursor: default;
    color: #ccc !important;
  }
  .react-datepicker__time {
    overflow: hidden !important;
  }
  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
    border-bottom-color: ${theme.mainBrandColor} !important;
  }

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before, .react-datepicker__month-read-view--down-arrow::before, .react-datepicker__month-year-read-view--down-arrow::before {
    bottom: -1px;
    border-top-color: ${theme.mainBrandColor} !important;;
}
.bg-linear-gradient-green {
  background: linear-gradient(67.13deg, #44CAAC 35.17%, #7281D3 100%);
}
.bg-linear-gradient-purple {
  background: ${theme.bgLinerGradient};
}

.border-none {
  border: none;
}
@media only screen and (max-width: 768px) {
  th, td {
    font-size: 9px !important;
    padding-top: 6px !important;
    padding-bottom: 6px !important;
    padding-left: 3px !important;
    padding-right: 3px !important;
    .tag {
      min-width: max-content !important;
      font-size: 9px !important;
    }
  }
}
`;
