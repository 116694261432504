import React from 'react';
import styled from 'styled-components';

const StarContainer = styled.div`
  .active {
    color: ${props => (props.hasWhite ? '#fff' : 'black')};
  }
  .inactive {
    color: ${props => (props.hasWhite ? '#ffffff80' : 'grey')};
  }
`;

const Stars = props => {
  const starArray = [];
  let count = props.starNum;

  // Creates the green active stars
  while (count > 0) {
    starArray.push(<i className="fas fa-star active" key={count} />);
    count -= 1;
  }

  // Creates the remaining grey stars to total 5
  while (props.starNum < 5 && starArray.length < 5) {
    starArray.push(
      <i className="fas fa-star inactive" key={starArray.length} />,
    );
  }

  return (
    <StarContainer hasWhite={props.hasWhite}>
      {starArray.map(star => {
        return star;
      })}
    </StarContainer>
  );
};

export default Stars;
