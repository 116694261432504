import React from 'react';
import { withFormik } from 'formik';
import styled from 'styled-components';
import Switch from 'react-switch';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { Button } from '../elements';
import theme from '../../theme';
import ColorPickerModal from '../global/ColorPickerModal';

const Container = styled.form`
  background: ${props => props.theme.backgroundLight};
  label {
    color: ${props => props.theme.placeholderColor};
  }
  .buttons .button:not(:last-child):not(.is-fullwidth) {
    margin-right: 0.75rem;
  }
  .brand-color {
    color: ${props => props.theme.mainBrandColor} !important;
  }
  .edit {
    height: 2.25rem;
    width: 2.25rem;
  }
  .is-light {
    :hover {
      background: transparent !important;
    }
  }
`;

const ColorPicker = styled.button`
  border: none;
  border-radius: 5px;
  background: ${props =>
    props.gradient
      ? `linear-gradient(
      180deg,
    ${props.color},
    ${props.color}80
  )`
      : props.color};
  height: 40px;
  width: 40px;
`;

const WidgetForm = props => {
  const { values, setFieldValue, brandId } = props;
  const editable = !!values.editable;
  return (
    <Container className="mt-6 is-6 has-border-radius-medium p-6">
      <ColorPickerModal
        isActive={values.showSelectColorModel}
        onClose={() => setFieldValue('editable', !values.editable)}
        handleSubmit={val => {
          setFieldValue('colors', [...values.colors, val]);
          setFieldValue('showSelectColorModel', !values.showSelectColorModel);
        }}
      />
      <div className="field mb-5">
        <div className="is-flex is-justify-content-space-between">
          <label className="label mb-5">
            Color (Solid) 100%{' '}
            {editable && (
              <span className="icon brand-color">
                <i className="fas fa-eye-dropper" />
              </span>
            )}
          </label>
          <Button
            type="button"
            onClick={() => setFieldValue('editable', !values.editable)}
            className={`${
              editable ? 'is-primary' : 'is-white'
            } is-rounded button is-normal edit p-0`}
          >
            <i
              className={`fas fa-pencil-alt ${!editable && 'has-text-gray'}`}
            />
          </Button>
        </div>
        {!editable && (
          <div className="mb-4">
            {values.colors
              .slice(
                0,
                values.showMore === false ? 3 : values.colors.length + 1,
              )
              .map(item => (
                <div key={item} className="mb-3 is-flex is-align-items-center">
                  <ColorPicker
                    type="button"
                    color={item}
                    onClick={() => setFieldValue('selectedColor', item)}
                    className="button"
                  />
                  <span className="ml-5 has-text-grey">{item} 100%</span>
                </div>
              ))}
            {!values.showMore && values.colors.length > 3 && (
              <span
                onClick={() => setFieldValue('showMore', !values.showMore)}
                className="ml-4 has-text-grey-light"
              >
                <i className="fas fa-ellipsis-v mr-2" /> see all{' '}
                {values.colors.length} colors
              </span>
            )}
          </div>
        )}
        {editable && (
          <div className="buttons">
            {values.colors.map(item => (
              <ColorPicker
                type="button"
                key={item}
                color={item}
                onClick={() => setFieldValue('selectedColor', item)}
                className="button"
              />
            ))}
            <button
              onClick={() =>
                setFieldValue(
                  'showSelectColorModel',
                  !values.showSelectColorModel,
                )
              }
              type="button"
              className="button is-light"
            >
              <i className="fas fa-plus" />
            </button>
          </div>
        )}
      </div>
      {editable && (
        <div className="field mb-5">
          <label className="label mb-4">Hex Code</label>
          <p className="has-text-grey-light">{values.selectedColor}</p>
        </div>
      )}
      {editable && (
        <div className="field mb-5">
          <label className="label mb-5">Color (Gradient) 100%</label>
          <div className="buttons">
            {values.colors.map(item => (
              <ColorPicker
                type="button"
                key={item}
                color={item}
                gradient
                onClick={() => setFieldValue('selectedColor', item)}
                className="button"
              />
            ))}
          </div>
        </div>
      )}
      <div className="field is-horizontal">
        <div className="field-body mb-5">
          <div className="field ">
            <label className="label mb-5">Background</label>
            <div className="is-flex is-align-items-center">
              <Switch
                name="isDark"
                onColor={theme.mainBrandColor}
                onChange={() => setFieldValue('isDark', !values.isDark)}
                checked={values.isDark}
              />
              <span className="ml-2 ">Dark</span>
            </div>
          </div>
          {editable && (
            <div className="field is-flex is-justify-content-flex-end	is-align-items-flex-end">
              <button
                onClick={() => setFieldValue('editable', !values.editable)}
                type="button"
                className="button is-light has-text-weight-semibold p-0 brand-color"
              >
                Done
              </button>
            </div>
          )}
        </div>
      </div>
      {!editable && (
        <div className="field mb-3">
          <label className="label mb-3">Widget Code</label>
          <CopyToClipboard
            text={`<div id="listme-widget" data-brand-id=${brandId} data-style-height="100%" data-style-width="100%" data-color=${values.selectedColor}><script type="text/javascript" src="https://widget.listmeapp.co/listme-widget.js"></script></div>`}
            onCopy={() => setFieldValue('copied', true)}
          >
            <button
              type="button"
              className="button brand-color has-text-weight-semibold is-light p-0"
            >
              Click here to copy the code {values.copied && `(Copied!)`}
            </button>
          </CopyToClipboard>
        </div>
      )}
    </Container>
  );
};

export default withFormik({
  mapPropsToValues: () => ({
    showSelectColorModel: false,
    selectedColor: '#b6237d',
    colors: ['#b6237d'],
    isDark: false,
    editable: false,
    copied: false,
    showMore: false,
  }),
  displayName: 'WidgetForm', // helps with React DevTools
})(WidgetForm);
