import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
// import moment from 'moment';
import CheckBox from './elements/CheckBox';
import TextInput from './elements/TextInput';

const Container = styled.div`
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important;
  min-width: max-content;
  box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.06);
  color: ${props => props.theme.textLightGrey} !important;
  input {
    min-width: 250px;
  }
`;

const EventVirtualLinks = ({ extraInfo, onChange }) => {
  const [googleUrl, setGoogleUrl] = useState(undefined);
  const [zoomUrl, setZoomUrl] = useState(undefined);
  const [manualUrl, setManualUrl] = useState(undefined);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    setIsReady(true);
    if (!isReady) {
      const googleMeetUrl = extraInfo.find(str => {
        return str.search('meet.google.com') >= 0;
      });
      const zoomMeetUrl = extraInfo.find(str => {
        return str.search('zoom.us') >= 0;
      });
      const manualMeetUrl = extraInfo.find(str => {
        return str !== googleMeetUrl && str !== zoomMeetUrl;
      });
      if (googleMeetUrl) {
        setGoogleUrl(googleMeetUrl);
      }
      if (zoomMeetUrl) {
        setZoomUrl(zoomMeetUrl);
      }
      if (manualMeetUrl) {
        setManualUrl(manualMeetUrl);
      }
    }
  }, [extraInfo]);

  useEffect(() => {
    const extraInfoArray = [];
    if (googleUrl) {
      extraInfoArray.push(googleUrl); // DO NOT CHANGE TEXT
    }
    if (zoomUrl) {
      extraInfoArray.push(zoomUrl); // DO NOT CHANGE TEXT
    }
    if (manualUrl) {
      extraInfoArray.push(manualUrl); // DO NOT CHANGE TEXT
    }
    onChange(extraInfoArray);
  }, [googleUrl, zoomUrl, manualUrl]);

  return (
    <Container className="py-4 has-background-white">
      <p>Add your virtual link</p>
      <div>
        <div className="mt-5 is-flex is-align-items-center">
          <CheckBox
            checked={googleUrl}
            onChange={() => setGoogleUrl(googleUrl ? '' : googleUrl)}
          />
          <span className="ml-5 mr-3">Connect with google</span>{' '}
          <TextInput
            placeholder="Add link here"
            value={googleUrl}
            onChange={e => setGoogleUrl(e.target.value)}
          />
        </div>
        <div className="mt-5 is-flex is-align-items-center">
          <CheckBox
            checked={zoomUrl}
            onChange={() => setZoomUrl(zoomUrl ? '' : zoomUrl)}
          />
          <span className="ml-5 mr-3">Connect with zoom</span>{' '}
          <TextInput
            placeholder="Add link here"
            value={zoomUrl}
            onChange={e => setZoomUrl(e.target.value)}
          />
        </div>
        <div className="mt-5 is-flex is-align-items-center">
          <CheckBox
            checked={manualUrl}
            onChange={() => setManualUrl(manualUrl ? '' : manualUrl)}
          />
          <span className="ml-5 mr-3">Add Link manually</span>{' '}
          <TextInput
            placeholder="Add link here"
            value={manualUrl}
            onChange={e => setManualUrl(e.target.value)}
          />
        </div>
      </div>
    </Container>
  );
};
export default EventVirtualLinks;
