import React from 'react';
import styled from 'styled-components';
import { head, upperCase } from 'lodash';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

const Container = styled.div`
  justify-content: space-between;
  .column {
    flex: none;
  }
  .wrapper {
    padding: 7px 15px;
    display: inline-block;
    border: 1px solid #a5227e;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    color: #a5227e;
    font-weight: 600;
  }
  .wrapper.total {
    ${'' /* background-color: #a5227e; */}
    background: ${props => props.theme.bgLinerGradient};
    color: #fff;
  }
  .heading {
    margin-bottom: 0;
  }
  @media only screen and (max-width: 768px) {
    .heading {
      font-size: 9px !important;
    }
    .column {
      padding: 6px 5px !important;
    }
  }
`;

// const allBookingsQuery = gql`
//   query allBookings($eventId: ID!) {
//     allBookings(eventId: $eventId) {
//       bookings {
//         status
//         paymentMethod
//         collaborators {
//           status
//         }
//       }
//     }
//   }
// `;
const analyticsQuery = gql`
  query analytics($filters: AnalyticsFilter) {
    analytics(filters: $filters) {
      attendance {
        pending
        checkedIn
        authorized
        rejected
        refunded
      }
      revenue {
        label
        total
        card
        cash
      }
    }
  }
`;
const currencyFormatter = require('currency-formatter');

const PeoplePaymentStats = ({ event }) => {
  // const [pendingInvites, setPendingInvites] = useState(0);
  // const { data } = useQuery(allBookingsQuery, {
  //   fetchPolicy: 'cache-and-network',
  //   variables: {
  //     eventId: event.id,
  //   },
  // });
  const { data: eventAnalyticsData } = useQuery(analyticsQuery, {
    fetchPolicy: 'cache-and-network',
    variables: { filters: { eventId: event.id } },
  });

  const eventAllAnalytics =
    eventAnalyticsData && eventAnalyticsData.analytics
      ? eventAnalyticsData.analytics
      : {};

  // const bookings = data && data.allBookings ? data.allBookings : [];
  // const bookings = useMemo(() => data?.allBookings?.bookings || [], [data]);
  // console.log('allBookings', bookings);
  // const totalCardPayments = filter(bookings, o => {
  //   return (
  //     o.paymentMethod === 'stripe' &&
  //     o.status !== 'refused' &&
  //     o.status !== 'authorizedPending'
  //   );
  // });
  // const totalCashPayments = filter(bookings, o => {
  //   return (
  //     o.paymentMethod === 'cash' &&
  //     o.status !== 'refused' &&
  //     o.status !== 'authorizedPending'
  //   );
  // });
  // const totalAttendance = filter(bookings, o => {
  //   return o.status !== 'refused' && o.status !== 'authorizedPending';
  // }).length;
  const attendance = head(eventAllAnalytics.attendance) || {};
  const revenue = head(eventAllAnalytics.revenue) || {};

  // const checkedInAttendance = attendance.checkedIn || 0;
  // const rejectedAttendance = attendance.rejected || 0;
  // const refundedAttendance = attendance.refunded || 0;
  // const pendingAttendance = attendance.pending || 0;
  // const total = revenue.total || 0;
  // const pendingInvites = attendance.pending || 0;

  // console.log('pendingInvites', pendingInvites);

  // const totalAttendance =
  //   checkedInAttendance +
  //   rejectedAttendance +
  //   refundedAttendance +
  //   pendingAttendance;

  // useEffect(() => {
  //   let count = 0;
  //   bookings.forEach(element => {
  //     element.collaborators.forEach(collaborator => {
  //       if (collaborator.status === 'pending') {
  //         count += 1;
  //       }
  //     });
  //   });
  //   setPendingInvites(count);
  // }, [bookings]);
  const totalAttendance =
    (attendance?.pending || 0) +
    (attendance?.checkedIn || 0) +
    (attendance?.rejected || 0) +
    (attendance?.refunded || 0) +
    (attendance?.authorized || 0);

  return (
    <Container className="columns is-mobile is-multiline is-justify-content-flex-start	">
      <div className="column">
        <div className="wrapper">
          <p className="heading">Total Sold {totalAttendance || '0'}</p>
        </div>
      </div>
      <div className="column">
        <div className="wrapper">
          <p className="heading">Pending {attendance?.pending || '0'}</p>
        </div>
      </div>
      <div className="column">
        <div className="wrapper">
          <p className="heading">Enter {attendance?.checkedIn || '0'}</p>
        </div>
      </div>
      <div className="column">
        <div className="wrapper">
          <p className="heading">Denied {attendance?.rejected || '0'}</p>
        </div>
      </div>
      <div className="column">
        <div className="wrapper">
          <p className="heading">Card {revenue?.card || '0'}</p>
        </div>
      </div>
      <div className="column">
        <div className="wrapper">
          <p className="heading">Cash {revenue?.cash || '0'}</p>
        </div>
      </div>
      <div className="column">
        <div className="wrapper">
          <p className="heading">Refund {attendance?.refunded || '0'}</p>
        </div>
      </div>
      <div className="column">
        <div className="wrapper total">
          <p className="heading">
            Total Rev{' '}
            {revenue?.total
              ? currencyFormatter.findCurrency(upperCase(event.currency))
                  .symbol + revenue?.total || 0
              : '0'}
          </p>
        </div>
      </div>
    </Container>
  );
};

export default PeoplePaymentStats;
