import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import config from '../utils/config';

const Progress = styled.div`
  margin: 0rem 2rem;
  .column {
    font-size: 18px;
    color: #b5b5b5;
    padding: 0 0 10px;
    border-bottom: 5px solid #b5b5b5;
    cursor: pointer;
    text-align: center;
    &.active,
    &:hover {
      font-weight: bold;
      color: ${config.secondaryColor};
      border-bottom-color: ${config.secondaryColor};
    }
  }
`;

const ProgressBar = ({ activeStep, setActiveStep }) => (
  <Progress className="columns">
    <div
      className={`column is-size-7-mobile ${activeStep === 1 ? 'active' : ''}`}
      onClick={() => setActiveStep(1)}
    >
      Update Profile
    </div>
    <div
      className={`column is-size-7-mobile ${activeStep === 2 ? 'active' : ''}`}
      onClick={() => setActiveStep(2)}
    >
      Payments
    </div>
    <div
      className={`column is-size-7-mobile ${activeStep === 3 ? 'active' : ''}`}
      onClick={() => setActiveStep(3)}
    >
      Activate
    </div>
  </Progress>
);

ProgressBar.defaultProps = {
  activeStep: 1,
};

ProgressBar.propTypes = {
  activeStep: PropTypes.number,
};

export default ProgressBar;
