/* eslint-disable no-underscore-dangle */
/* eslint-disable no-plusplus */
import momentjs from 'moment-timezone';
import { toLower } from 'lodash';

export const moment = data => {
  const deviceTimeZone = momentjs.tz.guess();
  return momentjs(data).tz(deviceTimeZone);
};

export const dateFormatter = date => moment(date).format('DD MMM YYYY hh:mm A');

export const generateLastTwelveMonths = defaultValue => {
  const result = new Map();
  const d = new Date();
  let n = d.getMonth() + 1;
  let y = d.getFullYear();
  for (let i = 1; i <= 12; i++) {
    const s = `${y}-${`0${n}`.slice(-2)}`;
    result[s] = defaultValue;
    n--;
    if (n === 0) {
      n = 12;
      y--;
    }
  }
  return result;
};

export const generateHours = defaultValue => {
  const result = new Map();
  for (let i = 0; i < 24; i++) {
    const s = `${`0${i}`.slice(-2)}`;
    result[s] = defaultValue;
  }
  return result;
};

export const convertNumberToMonth = s => {
  const names = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  // eslint-disable-next-line radix
  const n = parseInt(s.split('-')[1]);
  return names[n - 1];
};

export const extractLablesAndDataByMonth = data => {
  if (!data || data.length === 0) return [];

  const months = generateLastTwelveMonths({
    cash: 0,
    card: 0,
    all: 0,
  });
  const total = {
    cash: 0,
    card: 0,
    all: 0,
  };
  data.forEach(item => {
    if (Object.keys(months).includes(item.label)) {
      months[item.label] = {
        cash: item.cash,
        card: item.card,
        all: item.total,
      };
    }
    total.cash += item.cash;
    total.card += item.card;
    total.all += item.total;
  });
  const lbl = [];
  const numb = {
    cash: [],
    card: [],
    all: [],
  };
  Object.keys(months).forEach(e => {
    lbl.push(convertNumberToMonth(e));
    numb.cash.push(months[e].cash);
    numb.card.push(months[e].card);
    numb.all.push(months[e].all);
  });

  return {
    lbl,
    numb,
    total,
  };
};

export const extractLablesAndDataByDay = data => {
  if (!data || data.length === 0) return [];

  const hours = generateHours({
    cash: 0,
    card: 0,
    all: 0,
  });
  const total = {
    cash: 0,
    card: 0,
    all: 0,
  };
  data.forEach(item => {
    if (Object.keys(hours).includes(item.label)) {
      hours[item.label] = {
        cash: item.cash,
        card: item.card,
        all: item.total,
      };
    }
    total.cash += item.cash;
    total.card += item.card;
    total.all += item.total;
  });
  const lbl = [];
  const numb = {
    cash: [],
    card: [],
    all: [],
  };
  Object.keys(hours)
    .sort((a, b) => b - a)
    .forEach(e => {
      lbl.push(`${e}:00`);
      numb.cash.push(hours[e].cash);
      numb.card.push(hours[e].card);
      numb.all.push(hours[e].all);
    });
  return {
    lbl,
    numb,
    total,
  };
};

export const cleanString = str => {
  let s = str;
  s = String(str);
  s = s.replace(/\s/g, '');
  // s = s.replace(/[^A-Z0-9+]/gi, '');
  // s = _.deburr(s);
  // s = _.unescape(s);
  s = toLower(s);
  return s;
};
