import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const MyButton = styled.button`
  color: #a5227e;
  border-color: #a5227e;
  background-color: #fff;
  &:hover,
  &:focus {
    opacity: 0.75;
    border-color: #a5227e;
    color: #a5227e;
    background-color: #fff;
    box-shadow: none !important;
  }
  &.is-primary[disabled],
  &.is-primary {
    color: #ffffff;
    ${'' /* background-color: #a5227e; */}
    background: ${props => props.theme.bgLinerGradient};

    &:hover,
    &:focus {
      box-shadow: none !important;
      background: ${props => props.theme.bgLinerGradient};
      ${'' /* background-color: #a5227e !important; */}
    }
  }
  &.half-rounded {
    border-radius: 10px !important;
  }
`;

const Button = ({
  title,
  className,
  onClick,
  type,
  disabled,
  loading,
  children,
  ...props
}) => (
  <MyButton
    className={` ${loading ? 'is-loading button is-primary' : ''} ${className}`}
    onClick={onClick}
    type={type}
    disabled={disabled || loading}
    {...props}
  >
    {title}
    {children}
  </MyButton>
);

Button.defaultProps = {
  title: '',
};

Button.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
};
export default Button;
