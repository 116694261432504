import React from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import styled from 'styled-components';
import { isNull } from 'lodash';
import { Link } from 'react-router-dom';

import video from '../assets/landingPage.mp4';
import LoginForm from '../components/form/LoginForm';

const Container = styled.section`
  position: relative;
  color: #fff;
  video {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .label {
    color: #fff;
  }
  .logo-image-login {
    display: block;
    margin: auto;
    width: 250px;
  }
  .logo-image-mobile {
    display: block;
    margin: auto;
    width: 170px;
  }
  .wrapper {
    padding: 0 25px;
  }
  .control.has-icons-right .icon.is-right {
    right: 4px;
    height: 4rem;
    color: gray;
    pointer-events: auto !important;
    @media only screen and (max-width: 768px) {
      height: 3rem; !important;
    }
  }
  .button.is-large {
    @media only screen and (max-width: 768px) {
      font-size: 1.25rem; !important;
    }
  }
`;

const RegisterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;

  .forgot-pass {
    color: #a5227e;
  }

  .register {
    color: #858585;
  }

  .register a {
    color: #a5227e;
  }
`;

const loginMutation = gql`
  mutation login($input: LoginInput) {
    login(input: $input) {
      jwt
      user {
        id
        username
        email
        telephone
        profile {
          firstName
          lastName
          image
          dob
          sex
          relationship
          bio
        }
      }
    }
  }
`;

export default class Login extends React.Component {
  constructor(props) {
    super(props);
    this.videoRef = React.createRef();
  }

  componentDidMount() {
    this.videoRef.current.playbackRate = 0.9;
  }

  handleLoginSuccess = () => {
    // window.localStorage.setItem('token', data.telephoneLogin.jwt);
    // console.log('telephoneLogin', data);
  };

  onLoginSuccess = async (cache, { data: { login } }) => {
    // console.log('onLoginSuccess', telephoneLogin);

    await window.localStorage.setItem('token', login.jwt);
  };

  loginOnComplete = data => {
    const { history } = this.props;
    const { username, profile, email } = data.login.user;

    if (isNull(username) || isNull(profile) || isNull(email)) {
      history.push('/settings');
    } else {
      history.push('/');
    }
  };

  render() {
    const token = window.localStorage.getItem('token');
    const isLoggedIn = !!token;
    const { history } = this.props;
    if (isLoggedIn) history.push('/');

    return (
      <Container className="hero is-fullheight">
        <video muted autoPlay loop ref={this.videoRef}>
          <source src={video} type="video/mp4" />
        </video>
        <div className="hero-body">
          <div className="container">
            <div className="columns is-centered is-mobile">
              <div className="column is-5-desktop is-6-tablet">
                <div className="wrapper">
                  <img
                    src="/images/logo.png"
                    alt="logo"
                    className="is-hidden-mobile logo-image-login"
                  />
                  <img
                    src="/images/logo.png"
                    alt="logo"
                    width={20}
                    className="is-hidden-tablet logo-image-mobile"
                  />
                  <h2 className="is-size-4 is-size-5-mobile has-text-centered mt-4 mb-4">
                    EVENTS MADE SIMPLE
                  </h2>
                  <p className="is-size-5 has-text-weight-light is-size-6-mobile">
                    A one-stop system that manages every aspect required for
                    promoting and marketing a venue or event. Brand analytics
                    and real-time data capture.
                  </p>
                  <Mutation
                    mutation={loginMutation}
                    update={this.onLoginSuccess}
                    onError={error => console.log(error)}
                    onCompleted={data => this.loginOnComplete(data)}
                  >
                    {(login, { loading, error }) => (
                      <LoginForm
                        isLoading={loading}
                        error={error}
                        onSubmit={formData => {
                          login({
                            variables: { input: formData },
                          });
                          console.log('error', error);
                        }}
                      />
                    )}
                  </Mutation>
                  <RegisterContainer>
                    <a
                      href="/forgot-password"
                      className="forgot-pass has-text-weight-semibold is-size-7-mobile"
                    >
                      Forgot password?
                    </a>
                    <div className="register has-text-weight-semibold is-size-7-mobile">
                      New to ListMe? <Link to="/register">Register</Link>
                    </div>
                  </RegisterContainer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}
