/* eslint-disable no-underscore-dangle */
import React from 'react';
import { Doughnut } from 'react-chartjs-2';

import { Loading } from '../elements';

const GenderChart = ({ data, loading, options }) => {
  if (loading && (!data || data.length === 0)) return <Loading />;

  // if (error && !data) return `Error! ${error.message}`;
  if (!data || data.length === 0) {
    return (
      <div className="column">
        <p className="has-text-centered has-text-weight-semibold is-size-4">
          No data
        </p>
      </div>
    );
  }

  let male = 0;
  let female = 0;
  let nonBinary = 0;
  data.forEach(item => {
    if (item.key === 'male') male += item.value;
    if (item.key === 'female') female += item.value;
    if (item.key === 'nonBinary') nonBinary += item.value;
  });

  if (male === 0 && female === 0 && nonBinary === 0) {
    return (
      <div className="column">
        <p className="has-text-centered has-text-weight-semibold is-size-4">
          No data
        </p>
      </div>
    );
  }

  const overAll = {
    labels: ['Male', 'Female', 'Non-Binary'],
    datasets: [
      {
        data: [male, female, nonBinary],
        backgroundColor: ['#33D69F', '#631FE7', '#a5227e'],
        hoverBackgroundColor: ['#33D69F', '#631FE7', '#a5227e'],
      },
    ],
  };

  return <Doughnut data={overAll} options={options} />;
};

export default GenderChart;
