import InstagramAPI from 'instagram-api';
import axios from 'axios';

const accessToken = '3134967374.1677ed0.8a7621642cd544fbb58c13274a2519ae';

const instagramAPI = new InstagramAPI(accessToken);

export const getInstagramSelf = () =>
  new Promise((resolve, reject) => {
    instagramAPI.userSelf().then(
      result => {
        resolve(result);
      },
      err => {
        reject(err);
      },
    );
  });

export const searchInstagramUsers = query =>
  new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: 'https://www.instagram.com/web/search/topsearch/?context=blended',
      params: {
        query,
        rank_token: '0.370171934578875',
        include_reel: true,
      },
    }).then(
      result => {
        resolve(result.data);
      },
      err => {
        reject(err);
      },
    );
  });
