/* eslint-disable no-plusplus */
import React, { useState } from 'react';
import styled from 'styled-components';
import { withFormik } from 'formik';
import * as yup from 'yup';
import swal from 'sweetalert';

import Button from '../elements/Button';

const PlanCard = styled.div`
  padding: 26px 0;
  box-shadow: 1px 1px 27px rgba(0, 0, 0, 0.11);
  border-radius: 10px;
  text-align: center;
  overflow: hidden;
  position: relative;
  margin: 34px 0 14px;
  border: 1px solid #fff;
  cursor: pointer;
  &:hover,
  &.active {
    border: 1px solid ${props => props.theme.buttonColor};
  }
  .save-tag {
    padding: 13px 4px 13px 13px;
    position: absolute;
    right: 0;
    bottom: 0;
    ${'' /* background-color: ${props => props.theme.buttonColor}; */}
    background: ${props => props.theme.bgLinerGradient};
    border-radius: 40px 0 0;
    color: #fff;
    font-size: 8px;
    font-weight: 700;
    text-align: center;
  }
  .price {
    font-weight: 900;
    font-size: 20px;
    margin-bottom: 15px;
  }
  .unit {
    font-weight: 500;
    font-size: 16px;
    text-transform: uppercase;
  }
`;

const CreditCardForm = ({
  values,
  handleChange,
  handleSubmit,
  setFieldValue,
  isLoading,
  touched,
  errors,
}) => {
  const [selectedPlanId, setPlanId] = useState('');

  return (
    <>
      <div className="column is-10">
        <h3 className="section-title">Pick a plan</h3>
        <div className="columns is-justify-content-space-around">
          {values.plans &&
            values.plans.map(plan => (
              <div className="column is-4" key={plan.id}>
                <PlanCard
                  className={selectedPlanId === plan.id ? 'active' : ''}
                  onClick={() => {
                    setPlanId(plan.id);
                    setFieldValue('selectedPlanId', plan.id);
                  }}
                >
                  <div className="price">£{plan ? plan.price : '0'}</div>
                  <div className="unit">
                    {plan ? `/ ${plan.name}` : '/ MONTH'}
                  </div>
                  <span className="save-tag">
                    Save up to
                    <br />
                    15%
                  </span>
                </PlanCard>
              </div>
            ))}
        </div>
        <p className=" is-size-7">
          * Choose a plan for a minimum duration of 12 months.
        </p>
        <br />
        <p className=" is-size-7">
          * ListMe takes a fixed percentage against any transactions that are
          made through the user app for your events. (subject to domestic or
          international foreign cards) approx.10%
        </p>
      </div>
      <div className="column is-10">
        <h3 className="section-title">Discount Code</h3>
        <div className="control">
          <input
            placeholder="Have a referral code?"
            className="input is-rounded"
            value={values.discountCode}
            onChange={handleChange}
            name="discountCode"
          />
        </div>
      </div>
      <div className="column is-10">
        <h3 className="section-title">Card Details</h3>
        <label>Card Information</label>
        <div className="columns is-gapless is-justify-content-space-between is-multiline">
          <div className="column is-full">
            <div className="control">
              <input
                placeholder="1234 1234 1234 1234"
                className="input"
                value={values.number}
                onChange={handleChange}
                name="number"
                type="number"
                maxLength="16"
              />
              {errors.number && touched.number ? (
                <div className="error">{errors.number}</div>
              ) : null}
            </div>
          </div>
          <div className="column is-3">
            <div className="control">
              <input
                placeholder="Expiry Month"
                className="input"
                value={values.expMonth}
                onChange={handleChange}
                name="expMonth"
                type="number"
                maxLength="2"
              />
              {errors.expMonth && touched.expMonth ? (
                <div className="error">{errors.expMonth}</div>
              ) : null}
            </div>
          </div>
          <div className="column is-3">
            <div className="control">
              <input
                placeholder="Expiry Year"
                className="input"
                value={values.expYear}
                onChange={handleChange}
                name="expYear"
                type="number"
                maxLength="4"
              />
              {errors.expYear && touched.expYear ? (
                <div className="error">{errors.expYear}</div>
              ) : null}
            </div>
          </div>
          <div className="column is-5">
            <div className="control">
              <input
                placeholder="CVC"
                className="input"
                value={values.cvc}
                onChange={handleChange}
                name="cvc"
                type="number"
                maxLength="3"
              />
              {errors.cvc && touched.cvc ? (
                <div className="error">{errors.cvc}</div>
              ) : null}
            </div>
          </div>
        </div>
      </div>

      <div className="column is-10">
        <label>Name on card</label>

        <div className="control">
          <input
            placeholder="Add name on the card here"
            className="input"
            value={values.name}
            onChange={handleChange}
            name="name"
          />
          {errors.name && touched.name ? (
            <div className="error is-da">{errors.name}</div>
          ) : null}
        </div>
      </div>

      <div className="column">
        <Button
          type="submit"
          onClick={handleSubmit}
          title="Subscribe"
          className={`button is-medium is-rounded ${isLoading &&
            'is-loading is-primary'}`}
          loading={isLoading}
          disabled={isLoading}
        />
      </div>
    </>
  );
};

const CreditCardFormEnhancedForm = withFormik({
  mapPropsToValues: props => ({
    plans: props.packages,
    discountCode: '',
    number: '',
    expMonth: '',
    expYear: '',
    cvc: '',
    name: '',
    selectedPlanId: '',
  }),
  validationSchema: yup.object().shape({
    number: yup.string().required('Number is required.'),
    expMonth: yup.string().required('Month is required.'),
    expYear: yup.string().required('Year is required.'),
    cvc: yup.string().required('CVC is required.'),
    name: yup.string().required('Name is required.'),
  }),
  handleSubmit: (values, { setSubmitting, props }) => {
    if (!values.selectedPlanId) {
      swal('Please pick a plan!', {
        icon: 'error',
      });
    } else {
      // console.log('values', values);
      props.onSubmit(values);
      setSubmitting(false);
    }
  },

  displayName: 'CreditCardForm',
})(CreditCardForm);

export default CreditCardFormEnhancedForm;
