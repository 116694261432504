import React from 'react';
import styled from 'styled-components';
import { Modal } from '../../elements';
import { moment } from '../../../utils/helpers';

const TimeSlot = styled.div`
  border: 1px solid ${props => props.theme.mainBrandColor};
  color: ${props => props.theme.mainBrandColor} !important;
  background: transparent !important;
  font-size: 13px;
  position: relative;
  .button {
    position: absolute;
    top: 2.5px;
    right: 2.5px;
    font-size: 8px;
    ${'' /* background: ${props => props.theme.mainBrandColor}; */}
    background: ${props => props.theme.bgLinerGradient};
    border-radius: 8px !important;
    height: 12px;
    width: 12px;
    :hover {
      background: ${props => props.theme.bgLinerGradient};
      ${'' /* background: ${props => props.theme.mainBrandColor}; */}
    }
  }
`;

const SelectedTimeSlotsModal = ({
  subtitle,
  isActive,
  onClose,
  onRemoveTimeSlot,
  selectedTimeSlots,
}) => {
  return (
    <Modal
      title="Selected Time Slots"
      subtitle={subtitle}
      isActive={isActive}
      onClose={onClose}
    >
      <h1 className="subtitle is-5  has-text-weight-semibold  has-text-left">
        Time slots
      </h1>
      <div className="columns is-multiline">
        {selectedTimeSlots && selectedTimeSlots.length > 0 ? (
          selectedTimeSlots.map((item, index) => (
            <div key={item.startTime} className="column is-4">
              <TimeSlot className="px-3 py-2 has-border-radius-medium">
                {moment(item.startTime).format('hh:mm A')}-
                {moment(item.endTime).format('hh:mm A')}
                <button
                  type="button"
                  onClick={() => onRemoveTimeSlot(index)}
                  className="button is-white is-small px-0 py-0 has-text-white p-1"
                  aria-label="close"
                >
                  <i className="fas fa-times" />
                </button>
              </TimeSlot>
            </div>
          ))
        ) : (
          <p className="subtitle is-6 ml-3 has-text-weight-normal">
            No data available
          </p>
        )}
      </div>
    </Modal>
  );
};
export default SelectedTimeSlotsModal;
