import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  padding: 2.25rem;
  > div {
    background-color: #fff;
    border: 2px solid #f0f0f0;
    border-radius: 10px;
    padding: 35px 5% 5%;
  }
  .title {
    padding-bottom: 1.5rem;
    border-bottom: 2px solid #f1f1f1;
  }
  .wrapper {
    display: flex;
    align-items: center;
    padding-bottom: 22px;
    border-bottom: 1px solid #f1f1f1;
    .time {
      color: #858585;
      margin-left: auto;
    }
    .image {
      border: 2px solid #a5227ec2;
      border-radius: 50%;
      padding: 4px;
      margin-right: 20px;
    }
  }
`;

const data = [
  {
    id: '1',
    status: 'unseen',
    time: '24 mins ago',
    image: '',
    message: 'started following you',
    username: 'Kel',
  },
  {
    id: '2',
    status: 'unseen',
    time: '21 mins ago',
    image: '',
    message: 'hungout at 101',
    username: 'Alison',
  },
  {
    id: '3',
    status: 'unseen',
    time: '24 mins ago',
    image: '',
    message: 'dropped you a DM',
    username: 'Kel',
  },
  {
    id: '4',
    status: 'seen',
    time: '24 mins ago',
    image: '',
    message: 'started following you',
    username: 'Kel',
  },
  {
    id: '5',
    status: 'seen',
    time: '23 mins ago',
    image: '',
    message: 'hungout at 101',
    username: 'Alison',
  },
  {
    id: '6',
    status: 'seen',
    time: '24 mins ago',
    image: '',
    message: 'dropped you a DM',
    username: 'Kel',
  },
];

const Notification = () => {
  const unseenList = [];
  const seenList = [];
  data.forEach(item => {
    const list = (
      <div className="column is-full" key={item.id}>
        <div className="wrapper">
          <figure className="image is-64x64">
            <img
              className="is-rounded"
              src={item.image || '/images/avatar.svg'}
              alt="avatar"
            />
          </figure>
          <div className="username mr-1 has-text-weight-bold">
            {item.username}
          </div>
          <div className="messages">{item.message}</div>
          <div className="time">{item.time}</div>
        </div>
      </div>
    );
    if (item.status === 'unseen') {
      unseenList.push(list);
    } else {
      seenList.push(list);
    }
  });

  return (
    <Container className="section">
      <div>
        <h2 className="title has-text-weight-semibold is-size-3">Unseen</h2>
        <div className="columns is-multiline">{unseenList}</div>
        <h2 className="title has-text-weight-semibold is-size-3 mt-6">Seen</h2>
        <div className="columns is-multiline">{seenList}</div>
      </div>
    </Container>
  );
};

export default Notification;
