import React from 'react';
import styled from 'styled-components';

const Container = styled.p`
  color: #959699;
`;

const Subtitle = ({ className, children }) => (
  <Container className={`py-5 ${className}`}>{children}</Container>
);

export default Subtitle;
