import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useLazyQuery, useMutation } from 'react-apollo';
import gql from 'graphql-tag';
import swal from 'sweetalert';
import { upperCase, differenceBy, lowerCase, filter } from 'lodash';

import AddPartnersModal from './modals/AddPartnersModal';
import { Stars } from '../elements';

const currencyFormatter = require('currency-formatter');

const Container = styled.div`
  .add-partner,
  .loading {
    position: absolute;
    font-size: 4rem;
    color: ${props => props.theme.textLightGrey};
    top: 50%;
    left: 50%;
    -moz-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .add {
    height: 100%;
    /* min-height: 207px; */
    background-color: ${props => props.theme.borderColor};
  }
  .partner-card {
    position: relative;
  }
  .figure {
    backface-visibility: hidden;
  }
  .name {
    position: absolute;
    bottom: 0;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    width: 100%;
    background: linear-gradient(
      186.52deg,
      rgba(205, 204, 204, 0.58) 5.13%,
      rgba(47, 47, 47, 0.38) 106.53%
    );
  }
  .middle {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
        180deg,
        rgba(109, 108, 108, 0.81) 0%,
        rgba(153, 151, 151, 0.72) 100%
      ),
      url(.jpg);
    -moz-transition: all 0.4s ease-in-out 0s;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -ms-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
  }

  .partner-card:hover .image {
    opacity: 0.3;
  }
  .partner-card:hover .middle {
    opacity: 1;
  }
  .middle {
    position: absolute;
    background: linear-gradient(
        180deg,
        rgba(109, 108, 108, 0.71) 0%,
        rgba(153, 151, 151, 0.62) 100%
      ),
      url(.jpg);
    -moz-transition: all 0.4s ease-in-out 0s;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -ms-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
  }
  .remove-button {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    -moz-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .remove-button {
    border: none !important;
    background: transparent !important;
    border: none;
    color: #fff;
  }
  .ribbon {
    width: 100px;
    height: 44px;
    position: absolute;
    top: 15%;
    background-image: url('/images/ribbon.png');
    left: -2%;
    z-index: 1;
  }
  img {
    object-fit: cover;
  }
`;

// const allUsersQuery = gql`
//   query allUsers($brandsOnly: Boolean, $customersOnly: Boolean) {
//     allUsers(brandsOnly: $brandsOnly, customersOnly: $customersOnly) {
//       id
//       email
//       rating
//       username
//       profile {
//         firstName
//         lastName
//       }
//     }
//   }
// `;

const userSearchDataQuery = gql`
  query userSearch($customerType: UserType, $limit: Int, $query: String!) {
    userSearch(customerType: $customerType, limit: $limit, query: $query) {
      id
      email
      rating
      username
      profile {
        firstName
        lastName
      }
    }
  }
`;

const inviteUserMutation = gql`
  mutation inviteUser($input: InviteUserInput!) {
    inviteUser(input: $input) {
      id
    }
  }
`;

const PartnersList = ({
  refetch,
  eventId,
  currency,
  allPartners,
  title,
  partners,
  modelTitle,
  handleAddPartner,
  handleRemove,
}) => {
  const [userSearchQuery, setUserSearchQuery] = useState('');
  const [addPartnerModal, setAddPartnerModal] = useState(false);
  const [executeMutation, res] = useMutation(inviteUserMutation);
  const [loadUsers, { data: data2, error: error2, loading }] = useLazyQuery(
    userSearchDataQuery,
    {
      fetchPolicy: 'cache-and-network',
    },
  );

  useEffect(() => {
    loadUsers({
      variables: {
        limit: 1000,
        customerType: title === 'Partner' ? 'brand' : 'customer',
        query:
          userSearchQuery && userSearchQuery.length > 1 ? userSearchQuery : '',
      },
    });
  }, [userSearchQuery]);

  // console.log('userSearchQuery', userSearchQuery);

  useEffect(() => {
    // if (error) {
    //   swal('Oops!', error.message, 'error');
    // }
    if (res.error) {
      swal('Oops!', res.error.message, 'error');
    }
    if (error2) {
      swal('Oops!', error2.message, 'error');
    }
  }, [res.error, error2]);

  // const me = data && data.me ? data.me : {};
  const allUsers = data2 && data2.userSearch ? data2.userSearch : [];

  // const filteredFollowers = differenceBy(me.follower, allPartners, 'id');
  const usersList = filter(allUsers, function(o) {
    return (
      o.username !== null ||
      (o.profile !== null && o.profile.firstName !== null)
    );
  });
  // console.log('usersList', usersList);
  const filteredUsers = differenceBy(usersList, allPartners, 'id');

  const handleCreateUser = async formData => {
    const resp = await executeMutation({
      variables: {
        input: { ...formData, eventId, partnerType: lowerCase(title) },
      },
    });
    if (resp) {
      swal({
        title: '',
        text: `Added Successfully!`,
        icon: 'success',
      });
      setAddPartnerModal(false);
      refetch();
    }
  };

  // console.log(me);
  return (
    <Container className="my-3">
      <h4 className="subtitle is-4 has-text-weight-semibold">{title}</h4>{' '}
      <div className="columns is-mobile px-4 is-multiline">
        {partners &&
          partners.map(item => (
            <div
              key={item.user.id}
              className="column is-one-fifth-tablet is-half-mobile"
            >
              <div className="partner-card">
                <figure className="image is-square has-border-radius-medium">
                  <img
                    className="has-border-radius-medium"
                    src={
                      item.user.profile && item.user.profile.image
                        ? item.user.profile.image
                        : 'https://bulma.io/images/placeholders/480x480.png'
                    }
                    alt={item.name}
                  />
                </figure>

                <div className="ribbon has-text-white py-1 px-3">
                  <div className="is-size-7">
                    <i className="fas fa-percentage mr-3" />
                    {title !== 'Partner' &&
                      currencyFormatter.findCurrency(upperCase(currency))
                        .symbol}{' '}
                    {item.commission}
                  </div>
                  <div className="is-size-7">
                    <Stars
                      hasWhite
                      starNum={
                        item.user && item.user.rating ? item.user.rating : 0
                      }
                    />
                  </div>
                </div>
                {item.user.profile && item.user.profile.firstName && (
                  <h4 className="subtitle is-6 mb-0 has-text-weight-semibold name px-2 py-3 has-text-white has-text-centered">
                    {`${item.user.profile.firstName}
                    ${
                      item.user.profile.lastName
                        ? item.user.profile.lastName
                        : ''
                    }
                    `}{' '}
                    {item.user.username && `(${item.user.username})`}
                  </h4>
                )}
                <div className="middle has-text-centered has-border-radius-medium">
                  <button
                    onClick={() => handleRemove(item.user.id)}
                    className="button remove-button has-text-weight-semibold"
                  >
                    Remove
                  </button>
                </div>
              </div>
            </div>
          ))}

        <div className="column is-one-fifth-tablet is-half-mobile">
          <div
            onClick={() => setAddPartnerModal(!addPartnerModal)}
            className="has-border-radius-medium add "
          >
            <figure className="image is-square has-border-radius-medium">
              <i className="fas fa-plus add-partner" />
            </figure>
          </div>
        </div>
      </div>
      <AddPartnersModal
        currency={currency}
        title={modelTitle}
        // followers={filteredFollowers}
        loading={loading}
        filteredUsers={filteredUsers}
        onSearch={val => setUserSearchQuery(val)}
        onClose={() => setAddPartnerModal(!addPartnerModal)}
        isActive={addPartnerModal}
        handleAddPartner={async formData => {
          await handleAddPartner(formData);
          setAddPartnerModal(!addPartnerModal);
        }}
        handleCreatePartner={handleCreateUser}
      />
    </Container>
  );
};

export default PartnersList;
