/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import { sortBy, lowerFirst } from 'lodash';
import InfoToolTip from './InfoToolTip';

const SelectInput = styled.div`
  label {
    color: ${props => props.theme.textLightGrey};
  }

  .css-yk16xz-control {
    border-color: #dbdbdb !important;
    border-radius: 10px;
    box-shadow: none;
    min-height: 2.5rem;
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }

  .css-1pahdxg-control {
    min-height: 2.5rem;
    box-shadow: none;
    border-color: #b5b5b5 !important;
    border-radius: 10px;
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
  .css-1pahdxg-control:hover {
    border-color: #b5b5b5 !important;
    border-radius: 10px;
    box-shadow: none;
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
  .css-1wa3eu0-placeholder {
    color: rgba(54, 54, 54, 0.3);
    font-size: 1rem;
  }

  @media only screen and (max-width: 768px) {
    .css-2b097c-container {
      font-size: 1.2rem;
    }
  }
`;

const MultipleSelectInput = ({
  errors,
  options,
  selectedOptions,
  label,
  limit,
  infoText,
  singleSelect,
  ...otherProps
}) => {
  const sortedOptions = sortBy(options, [
    o => {
      return lowerFirst(o.label);
    },
  ]);
  return (
    <SelectInput className="field">
      {label && (
        <label className="label has-text-weight-normal">
          {label}
          {infoText && <InfoToolTip data={infoText} />}
        </label>
      )}

      <div className="control">
        <Select
          isMulti={!singleSelect}
          name="colors"
          className="basic-multi-select "
          classNamePrefix="select"
          noOptionsMessage={() => {
            return selectedOptions && selectedOptions.length === limit
              ? 'You have selected the max options available!'
              : 'No options available';
          }}
          isClearable
          options={sortedOptions}
          {...otherProps}
        />
      </div>
      {errors && <p className="help is-danger">{errors}</p>}
    </SelectInput>
  );
};

export default MultipleSelectInput;
