import React from 'react';

const DataInsight = () => (
  <div>
    <div>
      <h1>DataInsight</h1>
    </div>
  </div>
);
export default DataInsight;
