/* eslint-disable no-underscore-dangle */
import React from 'react';
import { Doughnut } from 'react-chartjs-2';

import { Loading } from '../elements';

const LocationChart = ({ data, loading, options }) => {
  if (loading && (!data || data.length === 0)) return <Loading />;

  // if (error && !data) return `Error! ${error.message}`;

  if (!data || data.length === 0) {
    return (
      <div className="column">
        <p className="has-text-centered has-text-weight-semibold is-size-4">
          No data
        </p>
      </div>
    );
  }

  const labels = [];
  const values = [];
  data.forEach(item => {
    values.push(item.value);
    labels.push(item.key ? item.key : '');
  });

  const overAll = {
    labels,
    datasets: [
      {
        data: values,
        backgroundColor: ['#33D69F', '#631FE7', '#F27A54'],
        hoverBackgroundColor: ['#33D69F', '#631FE7', '#F27A54'],
      },
    ],
  };

  return <Doughnut data={overAll} options={options} />;
};

export default LocationChart;
