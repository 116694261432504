import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.div`
  border-radius: 0.5rem;
  margin-bottom: 2vw;
  position: relative;
  text-align: center;
  border: 1px solid #e6e6e6;
  :hover {
    box-shadow: 0 0.5em 3em 0.5em rgba(0, 0, 0, 0.1),
      0 0px 0 1px rgba(0, 0, 0, 0.02);
  }
  img {
    height: 15rem;
    object-fit: cover;
  }
  .image {
    justify-self: center;
  }
  .tinted {
    filter: brightness(50%);
  }

  .title {
    display: none;
    position: absolute;
    top: 50%;
    width: 100%;
  }
  .title p {
    color: white;
  }
  .centered {
    position: absolute;
    top: 20%;
    left: 0;
    right: 0;
    text-align: center;
    ${'' /* font-family: 'Times New Roman', Times, serif; */}
    text-transform: uppercase;
    color: white;
    font-size: 3rem;
  }
  .line {
    width: 4.5rem;
    height: 5px;
    background-color: #fff;
    position: absolute;
    top: 55%;
    left: 0;
    right: 0;
    margin: auto;
  }
  @media only screen and (max-width: 768px) {
    img {
      height: 9rem !important;
    }
  }
`;

const LastEvent = () => {
  return (
    <Container className="banner">
      <div className="banner-image">
        <Link to="#">
          <figure className="image">
            <img
              className="tinted"
              src="https://cdn2.fatsoma.com/media/W1siZiIsInB1YmxpYy8yMDE4LzEvMjQvMi8zNC81NS80OTgvTElWRS1FVkVOVFMuanBnIl1d"
              alt="event dummy"
            />
            <div className="centered is-size-5-mobile">Latest Events</div>
            <div className="line" />
            {/* {event.featuredImage ? (
              <img
                src={event.featuredImage || '/images/landing-background.jpg'}
                alt={event.name}
              />
            ) : (
              <img
                src="/images/event-placeholder.png"
                alt="event dummy image"
              />
            )} */}
          </figure>
        </Link>
        <div className="title">
          <p>Latest Events</p>
        </div>
      </div>
    </Container>
  );
};

export default LastEvent;
