import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { find, capitalize } from 'lodash';

import { moment } from '../../utils/helpers';
import GuestProfileModal from './GuestProfileModal';

const Container = styled.tr`
  td {
    background-color: ${props =>
      props.isCollaboratorBooking ? ' #f9f9f9' : '#ffffff'};
    padding-top: ${props =>
      props.isCollaboratorBooking ? '0.6rem' : '1rem'} !important;
    padding-bottom: ${props =>
      props.isCollaboratorBooking ? '0.6rem' : '1rem'} !important;
    font-size: 12px;
    padding-right: 10px !important;
    padding-left: 10px !important;
    text-align: center !important;
    :first-child,
    :last-child {
      padding-right: 10px !important;
      padding-left: 10px !important;
      text-align: center !important;
    }
    a {
      color: #5df888;
      font-weight: 500;
    }

    input[type='checkbox'] {
      vertical-align: text-bottom;
    }

    .checkbox {
      &.active {
        color: ${props => props.theme.buttonColor};
      }
    }
  }
  .buttons.has-addons .button:last-child {
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
  }
  .buttons.has-addons .button:first-child {
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
  }
  .is-primary {
    ${'' /* background-color: ${props => props.theme.mainBrandColor}; */}
    background: ${props => props.theme.bgLinerGradient};
    :hover,
    :disabled {
      ${'' /* background-color: ${props => props.theme.mainBrandColor}; */}
      background: ${props => props.theme.bgLinerGradient};
      opacity: 1;
    }
  }
  .buttons {
    flex-wrap: nowrap;
  }
  .buttons .button {
    margin-bottom: 0 !important;
  }
  .buttons:last-child {
    margin-bottom: 0;
  }
`;

const GuestListApprovalItemRow = ({
  booking,
  eventName,
  trustedUsers,
  isInvitedBooking,
  handleDeniedBooking,
  handleAcceptBooking,
  handleUpdateTrustedUsers,
  isCollaboratorBooking,
  disableApprovalButtons,
}) => {
  const [isTrusted, setTrusted] = useState(false);
  const [showUserProfileModal, setShowUserProfileModal] = useState(false);

  const guest = booking && booking.user ? booking.user : {};
  const approveBy =
    booking &&
    booking.approveBy &&
    booking.approveBy.profile &&
    booking.approveBy.profile.firstName
      ? `${booking.approveBy.profile.firstName} ${
          booking.approveBy.profile.lastName !== null
            ? booking.approveBy.profile.lastName
            : ''
        }`
      : '';

  const userFullName = booking.isWalkIn
    ? booking.walkInData.personName
    : `${guest.profile && guest.profile.firstName} ${
        guest.profile && guest.profile.lastName ? guest.profile.lastName : ''
      }`;
  // const referBy =
  //   booking.referredBy && booking.referredBy.profile
  //     ? `${booking.referredBy.profile.firstName} ${
  //         booking.referredBy.profile.lastName
  //           ? booking.referredBy.profile.lastName
  //           : ''
  //       }`
  //     : '';

  const referBy = `${booking?.referredBy?.profile?.firstName ||
    booking?.referredBy?.telephone ||
    ''} ${booking?.referredBy?.profile?.lastName || ''}
      `;

  useEffect(() => {
    const trustedUser = find(trustedUsers, o => {
      return o === guest.id;
    });
    setTrusted(!!trustedUser);
  }, [trustedUsers]);

  // const approvedBooking =
  //   booking.status === 'authorized' || booking.status === 'checkedIn';

  return (
    <>
      <Container isCollaboratorBooking={isCollaboratorBooking}>
        <td>
          {booking.createdAt
            ? moment(booking.createdAt).format('D MMM YYYY hh:mm A')
            : ''}
        </td>
        <td>
          <div className="item-wrapper is-flex">
            {eventName || ''}{' '}
            {booking.collaborators && booking.collaborators.length > 0 && (
              <i className="fas fa-chevron-down ml-1 mt-1" />
            )}
          </div>
        </td>
        <td>
          <div className="item-wrapper is-capitalized">
            {booking.pricingType ? (
              <>
                {booking.pricingType === 'guestList'
                  ? 'Guestlist'
                  : booking.pricingType}
              </>
            ) : (
              ''
            )}
          </div>
        </td>
        <td>
          <div className="item-wrapper is-capitalized">
            {booking.pricingLabel || ''}
          </div>
        </td>
        <td className="is-capitalized">{userFullName}</td>
        <td>{referBy}</td>
        <td style={{ width: '86px' }}>
          <div onClick={() => setShowUserProfileModal(!showUserProfileModal)}>
            <a className="mr-2">Profile link</a>
            <span className="icon is-small">
              <i className="fa fa-comments" />
            </span>
          </div>
        </td>
        {/* <td>
          {booking.paymentStatus && (
            <span
              className={`tag is-capitalized payment-status px-3 ${
                booking.paymentStatus && booking.paymentStatus === 'unpaid'
                  ? 'is-danger'
                  : 'is-success'
              }`}
            >
              {booking.paymentStatus ? booking.paymentStatus : ''}
            </span>
          )}
        </td> */}
        <td>
          {isInvitedBooking ? (
            <span>Invited</span>
          ) : (
            <>
              {booking.status !== 'authorizedPending' ? (
                <span>{capitalize(booking.status)}</span>
              ) : (
                <div className="buttons has-addons is-centered action-btn">
                  <button
                    disabled={disableApprovalButtons}
                    className="button is-small"
                    onClick={() =>
                      handleDeniedBooking({
                        userId: guest.id,
                        name: userFullName,
                        id: booking.id,
                      })
                    }
                  >
                    Decline
                  </button>
                  <button
                    disabled={disableApprovalButtons}
                    className="button is-small"
                    onClick={() =>
                      handleAcceptBooking({
                        userId: guest.id,
                        name: userFullName,
                        id: booking.id,
                      })
                    }
                  >
                    Approve
                  </button>
                </div>
              )}
            </>
          )}
        </td>
        <td>{approveBy}</td>
        <td>
          {booking.approveAt
            ? moment(booking.approveAt).format('D MMM YYYY hh:mm A')
            : ''}
        </td>
        <td>
          {!isCollaboratorBooking && (
            <label className="checkbox is-justify-center">
              <input
                className="mr-1"
                type="checkbox"
                checked={isTrusted}
                onChange={() => handleUpdateTrustedUsers(guest.id)}
              />
              Auto approve next time
            </label>
          )}
        </td>
      </Container>
      <GuestProfileModal
        isActive={showUserProfileModal}
        onClose={() => setShowUserProfileModal(false)}
        user={guest}
      />
    </>
  );
};
const GuestListApprovalItem = ({
  booking,
  eventName,
  trustedUsers,
  handleDeniedBooking,
  handleAcceptBooking,
  handleUpdateTrustedUsers,
}) => {
  return (
    <>
      <GuestListApprovalItemRow
        booking={booking}
        eventName={eventName}
        trustedUsers={trustedUsers}
        handleDeniedBooking={handleDeniedBooking}
        handleAcceptBooking={handleAcceptBooking}
        handleUpdateTrustedUsers={handleUpdateTrustedUsers}
      />
      {booking &&
        booking.collaborators &&
        booking.collaborators.map(item => {
          const bookingData = item.booking
            ? item.booking
            : {
                pricingLabel: booking.pricingLabel,
                pricingType: booking.pricingType,
                user: item.user,
              };
          return (
            <GuestListApprovalItemRow
              key={item.id}
              booking={bookingData}
              eventName={eventName}
              isInvitedBooking={item.status === 'pending'}
              trustedUsers={trustedUsers}
              disableApprovalButtons={booking.status !== 'authorized'}
              handleDeniedBooking={handleDeniedBooking}
              handleAcceptBooking={handleAcceptBooking}
              handleUpdateTrustedUsers={handleUpdateTrustedUsers}
              isCollaboratorBooking
            />
          );
        })}
    </>
  );
};

export default GuestListApprovalItem;
