import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

const Wrapper = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  > div {
    width: 80%;
  }
  a {
    display: flex;
    align-items: center;
  }
  span {
    color: #a2a4b9;
    ${'' /* font-family: ${props => props.theme.contentFontFamily}; */}
    font-size: 16px;
  }
  .sub-item {
    padding: 0.3rem 0.6rem;
    span {
      font-size: 0.8rem;
    }
  }
  .icon {
    font-size: 1rem;
  }
  .item:hover span,
  .item.is-active span {
    color: #a5227e;
  }

  .item-text {
    margin-left: 20px;
  }
`;

const LinkWrapper = styled(NavLink)`
  padding: 0.6rem;
`;

const menuItems = [
  // {
  //   key: 'notifications',
  //   label: 'Notifications',
  //   to: '/notifications',
  //   icon: 'fas fa-bell',
  // },
  {
    key: 'paymentAnalytics',
    label: 'Analytics',
    to: '/payment-analytics',
    icon: 'fas fa-chart-line',
  },
  {
    key: 'events',
    label: 'Events',
    to: '/events',
    icon: 'far fa-calendar',
    subItems: [
      {
        key: 'partnersEvents',
        label: 'Partner Events',
        to: '/events?partners',
        icon: 'fas fa-users',
        level: 2,
      },
      {
        key: 'pastEvents',
        label: 'Past Events',
        to: '/events?past',
        icon: 'far fa-calendar-check',
        level: 2,
      },
      // {
      //   key: 'guestlistApproval',
      //   label: 'Guestlist Approval',
      //   to: '/guestlist-approval',
      //   icon: 'fas fa-list-ul',
      //   level: 2,
      // },
    ],
  },
  {
    key: 'followers',
    label: 'Followers',
    to: '/followers',
    icon: 'fas fa-user',
  },
  // {
  //   key: 'messaging',
  //   label: 'Messaging',
  //   to: '/messaging',
  //   icon: 'fas fa-envelope',
  // },
  // {
  //   key: 'invite',
  //   label: 'Invite',
  //   to: '/invite',
  //   icon: 'fas fa-user-friends',
  // },
  {
    key: 'settings',
    label: 'Settings',
    to: '/settings',
    icon: 'fas fa-cog',
  },
  { key: 'faq', label: 'FAQ', to: '/faq', icon: 'fas fa-question' },
];

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSubTab: false,
    };
  }

  static handleActive(url, location) {
    if (url === location.pathname + location.search) return true;
    return false;
  }

  render() {
    return (
      <Wrapper>
        {menuItems.map(item => (
          <div key={item.key}>
            <LinkWrapper
              className="item"
              to={item.to}
              activeClassName="is-active"
              exact
              isActive={(match, location) =>
                Sidebar.handleActive(item.to, location)
              }
              onClick={() => {
                if (item.to === '/events')
                  this.setState(prev => ({
                    ...prev,
                    activeSubTab: !prev.activeSubTab,
                  }));
              }}
            >
              <span className="icon">
                <i className={item.icon} />
              </span>
              <span className="item-text">{item.label}</span>
            </LinkWrapper>
            {this.state.activeSubTab &&
              item.subItems &&
              item.subItems.map(subItem => (
                <LinkWrapper
                  key={subItem.key}
                  className="item sub-item"
                  to={subItem.to}
                  activeClassName="is-active"
                  exact
                  isActive={(match, location) =>
                    Sidebar.handleActive(subItem.to, location)
                  }
                >
                  <span className="icon">
                    <i className={subItem.icon} />
                  </span>
                  <span className="item-text">{subItem.label}</span>
                </LinkWrapper>
              ))}
          </div>
        ))}
      </Wrapper>
    );
  }
}

export default Sidebar;
