import React from 'react';
import styled from 'styled-components';

import { SearchConsumer } from '../../store/context';

const Container = styled.div`
  .input {
    min-width: 14rem;
    border: 1px solid #a5227e;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    @media only screen and (max-width: 768px) {
      min-width: 6rem !important;
      max-width: 9rem !important;
    }
  }
`;

const SearchInput = props => {
  const { placeholder } = props;

  return (
    <SearchConsumer>
      {({ searchInput, handleSearch }) => {
        return (
          <Container className="field">
            <div className="control has-icons-left">
              <input
                className="input is-small"
                type="text"
                placeholder={placeholder}
                value={searchInput}
                onChange={e => handleSearch(e.target.value)}
              />
              <span className="icon is-left">
                <i className="fas fa-search" />
              </span>
            </div>
          </Container>
        );
      }}
    </SearchConsumer>
  );
};

export default SearchInput;
