import React, { useState, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
// import { filter } from 'lodash';
import swal from 'sweetalert';

import PaymentCard from './global/PaymentCard';
// import { SearchConsumer } from '../store/context';
import TableWithPagination from './global/TableWithPagination';
// import { Loading } from './elements';

const Container = styled.div`
  &.table-container {
    padding: 10px 5% 45px;
    background-color: #fff;
    border-radius: 10px;
    border: 2px solid #f0f0f0;
  }
  .table {
    color: #858585;
    border-collapse: inherit;
    border-spacing: 0 1em;
  }
  table th {
    border: none;
    text-align: center !important;
    padding-bottom: 5px;
    padding-top: 20px;
    :first-child {
      padding-left: 1rem;
      text-align: center !important;
    }
  }

  .table td {
    vertical-align: middle !important;
    border-top: 1px solid ${props => props.theme.borderColor} !important;
    border-bottom: 1px solid ${props => props.theme.borderColor} !important;
    border-bottom-width: 1px !important;
    padding-top: 26px;
    padding-bottom: 26px;
    color: ${props => props.theme.placeholderColor};
    :first-child {
      border-top-left-radius: 2.75rem;
      border-bottom-left-radius: 2.75rem;
      border-left: 1px solid ${props => props.theme.borderColor} !important;
    }
    :last-child {
      border-top-right-radius: 2.75rem;
      border-bottom-right-radius: 2.75rem;
      border-right: 1px solid ${props => props.theme.borderColor} !important;
    }
  }

  .flex-wrapper {
    margin-top: 6px;
    display: flex;
    .filter-dropdown {
      margin-left: 26px;
      .button {
        font-size: 14px;
        border: none;
        color: #858585;
        font-weight: 600;
      }
      .dropdown-item {
        border-bottom: 1px solid transparent;
        color: #858585;
        &:last-child {
          border-bottom: none;
        }
        &:hover, &.active {
          border-bottom-color: #efefef;
          ${'' /* background-color: ${props => props.theme.buttonColor}; */}
          background: ${props => props.theme.bgLinerGradient};
          color: #fff;
        }
      }
    }
    .button-wrapper {
      flex: 1;
      .view-button {
        display: block;
        margin: auto;
        transform: translateX(-55px);
      }
    }
  }
  @media only screen and (max-width: 768px) {
    &.table-container {
        padding: 10px 3% 45px;
      }
    }
`;

const analyticsListOfBookingsQuery = gql`
  query allBookings(
    $showBrandBookings: Boolean
    $eventId: ID
    $status: [BookingStatus]
    $searchQuery: String
    $limit: Int
    $skip: Int
  ) {
    allBookings(
      showBrandBookings: $showBrandBookings
      status: $status
      eventId: $eventId
      searchQuery: $searchQuery
      limit: $limit
      skip: $skip
    ) {
      totalBookings
      bookings {
        id
        amount
        createdAt
        code
        event {
          id
        }
        user {
          email
          profile {
            firstName
            lastName
          }
        }
        status
        paymentStatus
        paymentChargeId
      }
    }
  }
`;

const tableHeadData = [
  {
    Header: 'Payment ID',
    accessor: 'code',
  },
  {
    Header: 'User',
    accessor: 'user.profile.firstName',
  },
  {
    Header: 'Amount',
    accessor: 'amount',
  },
  {
    Header: 'Date',
    accessor: 'createdAt',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: 'Email',
    accessor: 'user.email',
  },
  {
    id: 7,
    Header: 'Action',
  },
];

const ListView = ({ match }) => {
  const { eventId } = match.params || null;
  // eslint-disable-next-line no-unused-vars
  // const [itemsPerPage, setItemsPerPage] = useState(4);
  const [status, setStatus] = useState('all');
  // console.log('eventId', eventId);
  // TODO: user allBookings query

  const [skipBookings, setSkipBookings] = useState(0);
  const [bookingsLimit, setBookingsLimit] = useState(10);
  const [search, setSearch] = useState('');

  const bookingStatusEnums = useMemo(() => {
    let array = ['pending', 'authorized', 'checkedIn', 'rejected', 'refunded'];
    if (status === 'paid') {
      array = ['authorized', 'checkedIn', 'rejected', 'refunded'];
    } else if (status !== 'paid' && status !== 'all') {
      array = [status];
    }
    return array;
  }, [status]);

  const { data, loading, error } = useQuery(analyticsListOfBookingsQuery, {
    fetchPolicy: 'no-cache',
    variables: {
      showBrandBookings: !eventId,
      eventId: eventId || null,
      status: bookingStatusEnums,
      searchQuery: search && search.length > 1 ? search : '',
      limit: bookingsLimit,
      skip: skipBookings * bookingsLimit,
    },
  });

  // const allBookings = data && data.allBookings ? data.allBookings : [];
  const bookings = useMemo(() => data?.allBookings?.bookings || [], [data]);
  const totalBookings = useMemo(() => data?.allBookings?.totalBookings || 0, [
    data,
  ]);

  // TODO:  need to fix this query with pagination
  // const filteredBookings = filter(allBookings, o => {
  //   return eventId
  //     ? o.status !== 'refused' &&
  //         o.status !== 'authorizedPending' &&
  //         o.event.id === eventId
  //     : o.status !== 'refused' && o.status !== 'authorizedPending';
  // });
  // const bookings =
  //   status === 'all' ? filteredBookings : filter(filteredBookings, { status });

  const columns = React.useMemo(() => tableHeadData, []);

  useEffect(() => {
    if (error) {
      swal('Oops!', error.message, 'error');
    }
  }, [error]);

  useEffect(() => {
    setSkipBookings(0);
  }, [status]);

  return (
    // <SearchConsumer>
    //   {({ searchInput }) => {
    //     return (
    <Container className="table-container">
      {/* {loading && bookings.length === 0 && (
        <div className="has-text-centered has-text-weight-semibold is-size-4">
          <Loading />
        </div>
      )} */}
      {/* {!loading && bookings && ( */}
      <TableWithPagination
        columns={columns}
        data={bookings}
        loading={loading}
        pageLimit={bookingsLimit}
        totalCount={totalBookings}
        manualPagination
        currentPage={skipBookings}
        fetchData={(pageIndex, pageSize) => {
          setBookingsLimit(pageSize);
          setSkipBookings(pageIndex);
        }}
        onSearch={val => setSearch(val)}
        rowItem={row => <PaymentCard key={row.id} data={row.original} />}
      />
      {/* )} */}
      <div className="flex-wrapper">
        <div className="dropdown is-up is-hoverable filter-dropdown">
          <div className="dropdown-trigger">
            <button
              className="button"
              aria-haspopup="true"
              aria-controls="dropdown-menu7"
            >
              <span>FILTER</span>
              <span className="icon is-small">
                <i className="fas fa-angle-up" aria-hidden="true" />
              </span>
            </button>
          </div>
          <div className="dropdown-menu" id="dropdown-menu7" role="menu">
            <div className="dropdown-content">
              <a
                onClick={() => setStatus('all')}
                className={`dropdown-item ${
                  status === 'all' ? 'active' : 'not-active'
                }`}
              >
                All Bookings
              </a>
              <a
                onClick={() => setStatus('paid')}
                className={`dropdown-item ${
                  status === 'paid' ? 'active' : 'not-active'
                }`}
              >
                Paid
              </a>
              <a
                onClick={() => setStatus('refunded')}
                className={`dropdown-item ${
                  status === 'refunded' ? 'active' : 'not-active'
                }`}
              >
                Refund
              </a>
              {/* <div className="dropdown-item">
                      <a>Age</a>
                    </div> */}
              <a
                onClick={() => setStatus('checkedIn')}
                className={`dropdown-item ${
                  status === 'checkedIn' ? 'active' : 'not-active'
                }`}
              >
                Accepted
              </a>
              <a
                onClick={() => setStatus('rejected')}
                className={`dropdown-item ${
                  status === 'rejected' ? 'active' : 'not-active'
                }`}
              >
                Rejected
              </a>
            </div>
          </div>
        </div>
        {/* <div className="button-wrapper">
          {itemsPerPage < bookings.length && (
            <Button
              title="View More"
              className="button is-rounded view-button is-primary"
              onClick={handleFetchMore}
            />
          )}
        </div> */}
      </div>
    </Container>
    //     );
    //   }}
    // </SearchConsumer>
  );
};

export default ListView;
