/* eslint-disable react/no-unused-state */
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const Context = React.createContext({
  searchInput: '',
  handleSearch: () => {},
});

// export class SearchProvider extends React.Component {
//   // eslint-disable-next-line react/state-in-constructor
//   // state = {
//   //   searchInput: '',
//   //   handleSearch: this.handleSearch(),
//   // };

//   // handleSearch = val => {
//   //   this.setState({ searchInput: val });
//   // };

//   // eslint-disable-next-line react/state-in-constructor
//   state = {
//     searchInput: '',
//     handleSearch: this.handleSearch = val => {
//       this.setState({ searchInput: val });
//     },
//   };

//   render() {
//     return (
//       <Context.Provider value={this.state}>
//         {this.props.children}
//       </Context.Provider>
//     );
//   }
// }
export const SearchProvider = ({ children }) => {
  const location = useLocation();
  const [searchInput, setSearchInput] = useState('');

  const handleSearch = val => {
    setSearchInput(val);
  };

  useEffect(() => {
    handleSearch('');
  }, [location.pathname]);

  return (
    <Context.Provider value={{ searchInput, handleSearch }}>
      {children}
    </Context.Provider>
  );
};

export const SearchConsumer = Context.Consumer;
