import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  background-color: #ffffff;
  margin: 2.25rem;
  border: 2px solid #f0f0f0;
  border-radius: 10px;
  display: grid;
  grid-template-columns: 25% 75%;
  color: #858585;
  .admin-status {
    border-bottom: 2px solid #f0f0f0;
    padding: 1em;
    display: flex;
    justify-content: center;
  }
  img {
    width: 4em;
    height: 3em;
  }
  .user-name {
    font-style: normal;
    font-weight: bold;
    font-size: 1vw;
  }
  .user-status {
    font-size: 0.9em;
  }
  .user-chat {
    padding: 1em 0 1em 1em;
    display: flex;
    height: max-content;
    cursor: pointer;
  }
  .chats {
    justify-content: center;
  }
  .typing {
    font-size: 0.8em;
    align-self: flex-end;
    padding-bottom: 0.3em;
    margin-right: 1em;
  }
  .notif-grid {
    display: grid;
  }
  .notificationn {
    ${'' /* background-color: ${props => props.theme.buttonColor}; */}
    background: ${props => props.theme.bgLinerGradient};
    font-size: 0.7em;
    border-radius: 10px;
    padding: 3px 5px;
    height: min-content;
    align-self: center;
    padding-bottom: 0.3em;
    margin-right: 1em;
    color: #ffffff;
    font-weight: bold;
    width: 20px;
    display: flex;
    justify-content: center;
  }
  .user-info {
    width: -moz-available;
  }
  .on-off-online {
    background-color: #8cee5d;
    border-radius: 10px;
    height: 10px;
    margin-left: -12px;
    margin-top: 27px;
    margin-right: 1.2em;
    color: transparent;
    padding: 1px;
  }
  .on-off-offline {
    background-color: #dedede;
    border-radius: 10px;
    height: 10px;
    margin-left: -12px;
    margin-top: 27px;
    margin-right: 1.2em;
    color: transparent;
    padding: 1px;
  }
  .newchat-button {
    background: #a5227e;
    border-radius: 100px;
    padding: 13px 40px;
    width: max-content;
    color: #fff;
    justify-self: center;
    align-self: flex-end;
    font-size: 0.8em;
    cursor: pointer;
  }
  .button-cont {
    display: flex;
    justify-content: center;
  }
  .chats-container {
    display: grid;
    padding-bottom: 2.5em;
    border-right: 2px solid #f0f0f0;
  }
  .chat-container {
    max-height: 70vh;
    overflow-y: scroll;
  }
  .message-cont {
    padding: 2em 2em 0 2em;
  }
  .message-cont-received {
    padding: 2em 2em 0 2em;
    display: grid;
    justify-content: flex-end;
    text-align: end;
  }
  .message-time {
    font-size: 0.8em;
  }
  .message-text {
    background: #f6f6f6;
    border-radius: 10px;
    padding: 1em 1.5em;
    font-size: 0.9em;
    width: max-content;
    margin-top: 0.9em;
  }
  .input-cont-cont {
    padding: 2em;
  }
  .input-cont {
    width: 100%;
    padding: 0.9em 1.5em;
    border-radius: 100px;
    border: 1px solid #f0f0f0;
    display: grid;
    grid-template-columns: 80% auto;
  }
  .message-input {
    color: #bfbfbf;
    border: none;
  }
  .input-send {
    display: flex;
    justify-content: flex-end;
  }
  .send-button {
    ${'' /* background: #a5227e; */}
    background: ${props => props.theme.bgLinerGradient};
    border-radius: 100px;
    padding: 0.4em 1.4em;
    color: #fff;
    font-size: 0.9em;
    font-weight: bold;
    width: max-content;
    justify-self: flex-end;
    cursor: pointer;
    margin-left: 1.4em;
  }
  .fa-paperclip {
    font-size: large;
    align-self: center;
    cursor: pointer;
  }
  .unread-cont {
    display: grid;
    grid-template-columns: 10% auto;
    padding: 2em;
    align-items: center;
    color: ${props => props.theme.buttonColor};
    font-size: 0.9em;
  }
  hr {
    ${'' /* background: #a5227e; */}
    background: ${props => props.theme.bgLinerGradient};
  }
`;
const Messaging = () => {
  return (
    <Container>
      <div className="chats-container">
        <div>
          <div className="admin-status">
            <img
              className="is-rounded"
              alt="person-1"
              src="/images/avatar.svg"
            />
            <div className="on-off-online">0</div>
            <div className="user-info">
              <div className="user-name">Ramon Ridwan</div>
              <div className="user-status">Online</div>
            </div>
          </div>
          <div className="chats">
            <div className="user-chat">
              <img
                className="is-rounded"
                alt="person-1"
                src="/images/avatar.svg"
              />
              <div className="on-off-online">0</div>
              <div className="user-info">
                <div className="user-name">Agent Rue</div>
                <div className="user-status">Online</div>
              </div>
              <div className="typing">typing...</div>
            </div>
            <div className="user-chat">
              <img
                className="is-rounded"
                alt="person-1"
                src="/images/avatar.svg"
              />
              <div className="on-off-offline">0</div>
              <div className="user-info">
                <div className="user-name">Agent Daniel</div>
                <div className="user-status">Offline</div>
              </div>
              <div className="notificationn">10</div>
            </div>
            <div className="user-chat">
              <img
                className="is-rounded"
                alt="person-1"
                src="/images/avatar.svg"
              />
              <div className="on-off-offline">0</div>
              <div className="user-info">
                <div className="user-name">Agent Rose</div>
                <div className="user-status">Offline</div>
              </div>
              <div className="notif-grid">
                <div className="notificationn">9</div>
              </div>
            </div>
            <div className="user-chat">
              <img
                className="is-rounded"
                alt="person-1"
                src="/images/avatar.svg"
              />
              <div className="on-off-online">0</div>
              <div className="user-info">
                <div className="user-name">Agent William</div>
                <div className="user-status">Online</div>
              </div>
            </div>
          </div>
        </div>
        <div className="newchat-button">New Chat</div>
      </div>
      <div>
        <div className="chat-container">
          <div className="message-cont">
            <div className="message-time">Me, 10:22</div>
            <div className="message-text">
              Hello am new to this system can i get a breakdown on how it works?
            </div>
          </div>
          <div className="message-cont-received">
            <div className="message-time">Agent Rue, 10:24</div>
            <div className="message-text">
              Yes we currently run both on our platform here
            </div>
          </div>
          <div className="message-cont">
            <div className="message-time">Me, 3 minutes ago</div>
            <div className="message-text">
              Please, can you go into details bout the service
            </div>
          </div>
          <div className="unread-cont">
            <div className="unread-unread">UNREAD</div>
            <hr />
          </div>
          <div className="message-cont">
            <div className="message-time">Me, 3 minutes ago</div>
            <div className="message-text">
              Hello am new to this system can i get a breakdown on how it works?
            </div>
          </div>
          <div className="message-cont-received">
            <div className="message-time">Agent Rue, 10:24</div>
            <div className="message-text">
              Yes we currently run both on our platform here
            </div>
          </div>
        </div>
        <div className="input-cont-cont">
          <div className="input-cont">
            <input placeholder="Start typing here" className="message-input" />
            <div className="input-send">
              <i className="fas fa-paperclip" />
              <div className="send-button">SEND</div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};
export default Messaging;
