import React from 'react';
import { withFormik } from 'formik';
import styled from 'styled-components';
import * as yup from 'yup';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { phone } from 'phone';

const Container = styled.form`
  background: #ffffff;
  box-shadow: inset 4px 4px 84px rgba(0, 0, 0, 0.15);

  svg {
    color: ${props => props.theme.textColorLight};
  }
  input {
    background-color: transparent;
    border-top: none;
    border-right: none;
    border-left: none;
    border-radius: 0;
    box-shadow: none;
    font-size: 1rem;
    height: 2.5em;
    border-color: #bbbdc1;
    padding-left: 1rem;
    border-width: 1px;
    color: ${props => props.theme.textColorLight};
    ::placeholder {
      color: ${props => props.theme.textColorLight};
    }
    :active,
    :focus {
      border-color: ${props => props.theme.textColorLight};
      outline: none;
      box-shadow: none;
    }
  }
  .PhoneInputCountryIcon {
    height: 13px;
    width: 18px;
  }

  .PhoneInputCountrySelectArrow,
  .PhoneInputCountryIcon {
    margin-top: 20px;
  }
  .icon {
    margin-right: 1.18rem !important;
  }
  button {
    background-color: transparent !important;
    color: ${props => props.theme.mainBrandColor} !important;
  }
`;

const AddUserForm = props => {
  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    errors,
    touched,
    isSubmitting,
    setFieldValue,
    onCancelPress,
    emailPlaceholder,
  } = props;
  return (
    <Container onSubmit={handleSubmit} className="p-5">
      <div className="field is-horizontal">
        <div className="field-body">
          <div className="field">
            <p className="control is-expanded is-flex is-align-items-flex-end">
              <span className="icon is-small is-left">
                <i className="fas fa-user" />
              </span>
              <input
                className="input"
                name="firstName"
                type="text"
                placeholder="First Name"
                value={values.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </p>
            {errors.firstName && touched.firstName && (
              <div className="mt-2 is-size-7  has-text-danger">
                {errors.firstName}
              </div>
            )}
          </div>
          <div className="field">
            <p className="control is-expanded ">
              <input
                className="input"
                name="lastName"
                type="text"
                placeholder="Last Name"
                value={values.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.lastName && touched.lastName && (
                <div className="mt-2 is-size-7  has-text-danger">
                  {errors.lastName}
                </div>
              )}
            </p>
          </div>
        </div>
      </div>

      <div className="field ">
        <PhoneInput
          international
          type="text"
          placeholder="Enter phone number"
          name="telephone"
          defaultCountry="US"
          value={values.telephone}
          onChange={e => setFieldValue('telephone', e)}
          onBlur={handleBlur}
        />
        {errors.telephone && touched.telephone && (
          <div className="mt-2 is-size-7 has-text-danger">
            {errors.telephone}
          </div>
        )}
      </div>
      <div className="field is-flex is-align-items-flex-end">
        <span className="icon is-small is-left  ">
          <i className="fas fa-envelope" />
        </span>
        <input
          className="input"
          name="email"
          type="email"
          placeholder={emailPlaceholder || 'Email Address'}
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </div>
      {errors.email && touched.email && (
        <div className="mt-2 is-size-7  has-text-danger">{errors.email}</div>
      )}

      <div className="my-4 is-justify-content-flex-end buttons">
        <button
          onClick={onCancelPress}
          type="button"
          className="button is-white has-text-weight-semibold px-0 mr-5"
        >
          Cancel
        </button>
        <button
          disabled={isSubmitting}
          type="submit"
          className="button is-white has-text-weight-semibold px-0 "
        >
          {isSubmitting ? 'Loading...' : 'Done'}
        </button>
      </div>
    </Container>
  );
};

export default withFormik({
  mapPropsToValues: () => ({
    firstName: '',
    lastName: '',
    telephone: '',
    email: '',
  }),
  validationSchema: yup.object().shape({
    firstName: yup.string().required('First name is required!'),
    lastName: yup.string().required('Last name is required!'),
    telephone: yup
      .string()
      .required('Telephone is required')
      .test('allowed', 'Please add country code!', function(values) {
        return values && values.charAt(0) === '+';
      })
      .test('allowed', 'Phone number is not valid!', function(values) {
        return (
          values && phone(values, { strictDetection: true }).isValid === true
        );
      }),
    email: yup
      .string()
      .email('Invalid email address!')
      .required('Email is required!'),
  }),

  handleSubmit: async (values, { setSubmitting, resetForm, props }) => {
    // console.log('handle submit', values, props);
    await props.onSubmit(values);
    setSubmitting(false);
    resetForm();
  },
  displayName: 'AddUserForm', // helps with React DevTools
})(AddUserForm);
