import React from 'react';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import config from '../../utils/config';
import { moment } from '../../utils/helpers';

const Section = styled.div`
  .react-datepicker-wrapper {
    width: 100%;
  }
  .fa-star-of-life {
    height: 8px;
  }
`;

const Wrapper = styled.div`
  .input:focus,
  .input:active {
    border-color: #b5b5b5;
    box-shadow: none;
  }
  .react-datepicker__close-icon {
    display: block;
    height: 16px;
    width: 16px;
    right: 2rem;
    padding: 0;
    bottom: 0;
    margin: auto;
    border-radius: 50%;
    background: ${props => props.theme.bgLinerGradient} !important;

    ${'' /* background-color: ${props => props.theme.buttonColor} !important; */}
    &:after {
      display: block;
      font-size: 13px;
      line-height: 16px;
      background-color: transparent;
      padding: 0;
    }
  }
`;
const DatePickerStyle = styled(DatePicker)`
  color: ${config.placeholderColor};
  height: ${props => (props.isMedium ? '2.5rem' : '4rem')} !important;
  border-radius: ${props => props.isMedium && '10px'} !important;
  padding-left: 2rem !important;
  padding-right: 3.5rem !important;
`;
class MyDatePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: props.value ? props.value : new Date(),
    };
  }

  handleChange = date => {
    this.setState({ date });
    this.props.onChange(date);
  };

  render() {
    const {
      label,
      hasBackgroudColor,
      allowSameDay,
      requiredIcon,
      isMedium,
      errors,
      otherProps,
    } = this.props;
    const { date } = this.state;
    const minTime = moment().add(1, 'minutes');
    return (
      <>
        <Section className="field">
          <label className="label is-capitalized has-text-weight-normal mb-3 is-flex is-align-items-center">
            {label}{' '}
            {requiredIcon && (
              <i className="fas fa-star-of-life has-text-danger ml-1" />
            )}
          </label>
          <Wrapper className="control" hasBackgroudColor={hasBackgroudColor}>
            <DatePickerStyle
              isMedium={isMedium}
              selected={new Date(date)}
              onChange={this.handleChange}
              className="input is-rounded"
              showTimeSelect
              timeIntervals={15}
              dateFormat="MMMM d, yyyy h:mm aa"
              timeCaption="Time"
              allowSameDay={allowSameDay}
              minDate={moment().toDate()}
              {...(moment(minTime).isSame(moment(new Date(date)), 'day') && {
                minTime: new Date(moment(minTime)),
                maxTime: new Date(moment().endOf('day')),
              })}
              {...otherProps}
            />
          </Wrapper>
        </Section>
        {errors && <div className="error has-text-danger mb-2">{errors}</div>}
      </>
    );
  }
}

export default MyDatePicker;
