import React from 'react';
import styled from 'styled-components';

const Container = styled.span`
  span {
    margin-right: 8px;
  }
`;

const EventAddress = ({ address }) => {
  return (
    <Container>
      {address.name && <span>{address.name},</span>}
      {address.addressLine1 && <span>{address.addressLine1},</span>}
      {address.addressLine2 && <span>{address.addressLine2},</span>}
      {address.city && <span>{address.city},</span>}
      {address.postcode && <span>{address.postcode},</span>}
      {address.state && <span>{address.state},</span>}
      {address.country && <span>{address.country}</span>}
    </Container>
  );
};

export default EventAddress;
