import React from 'react';
import styled from 'styled-components';

import config from '../../utils/config';

const Card = styled.div`
  border-radius: 2rem;
  img {
    height: 4rem;
    width: 4rem;
    border-radius: 4rem;
    border: 4px solid ${config.brandColor};
    object-fit: cover;
  }
  .media {
    align-items: center;
  }
`;

const UserCard = ({
  data,
  enableRemoveButton,
  isBrandEvent,
  removeDoorPicker,
}) => {
  if (data === null) {
    return null;
  }

  return (
    <Card className="card">
      <div className="card-content">
        <div className="media">
          <div className="media-left">
            <figure className="image is-64x64">
              {data.profile && data.profile.image ? (
                <img
                  className="is-rounded"
                  src={data.profile.image || '/images/avatar.svg'}
                  alt={data.profile.firstName}
                />
              ) : (
                <img
                  className="is-rounded"
                  src="/images/avatar.svg"
                  alt="user-image"
                />
              )}
            </figure>
          </div>
          <div className="media-content">
            {data.profile && (
              <p className="title is-5">
                {data.profile.firstName && data.profile.firstName}{' '}
                {data.profile.lastName && data.profile.lastName}
              </p>
            )}
            {data.username && <p className="subtitle is-6">@{data.username}</p>}
          </div>
          {enableRemoveButton && isBrandEvent && (
            <div className="is-pulled-right">
              <button
                className="button is-large is-rounded is-danger"
                onClick={() => removeDoorPicker(data.id)}
              >
                <i className="fas fa-user-times" />
              </button>
            </div>
          )}
        </div>
      </div>
    </Card>
  );
};

export default UserCard;
