import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Query, Mutation, useMutation } from 'react-apollo';
import gql from 'graphql-tag';
import { Link, useHistory } from 'react-router-dom';
import swal from 'sweetalert';

import { sortBy } from 'lodash';
import { SearchConsumer } from '../store/context';
import { Loading, Button } from '../components/elements';
import EventItem from '../components/events/EventItem';
import LastEvent from '../components/events/LastEvent';
import CreateEventModal from '../components/events/CreateEventModal';

const Container = styled.div`
  padding: 2.25rem;
`;

const eventsQuery = gql`
  query brandEvents(
    $showPast: Boolean
    $showArchived: Boolean
    $showFuture: Boolean
    $showDraft: Boolean
    $showPartnerEvents: Boolean
  ) {
    brandEvents(
      showPast: $showPast
      showArchived: $showArchived
      showFuture: $showFuture
      showDraft: $showDraft
      showPartnerEvents: $showPartnerEvents
    ) {
      id
      name
      isArchived
      eventStartTime
      eventEndTime
      featuredImage
      venueAddress {
        name
        addressLine1
        city
        state
        postcode
        country
        formattedAddress
      }
      status
    }
  }
`;

// const meQuery = gql`
//   query meEvents {
//     me {
//       id
//       subscription {
//         status
//       }
//     }
//   }
// `;

const createEventMutation = gql`
  mutation createEvent($name: String!) {
    createEvent(input: { name: $name }) {
      id
      name
    }
  }
`;

const removeEventMutation = gql`
  mutation deleteEvent($id: ID!) {
    deleteEvent(id: $id) {
      success
    }
  }
`;

const Events = props => {
  const { location } = props;
  const showPastEvents = location.search === '?past';
  const showArchivedEvents = location.search === '?archived';
  const partnerEvents = location.search === '?partners';
  const showFutureEvents = location.search === '';
  const [activeModal, setActiveModal] = useState(false);

  // const { data: meData } = useQuery(meQuery, {
  //   fetchPolicy: 'cache-and-network',
  // });
  const [removeEvent, { error: removeError }] = useMutation(
    removeEventMutation,
  );

  // const me = meData && meData.me ? meData.me : {};
  // console.log('medata', me);

  const toggleModal = () => {
    // if (
    //   (me && me.subscription === null) ||
    //   (me && me.subscription && me.subscription.status !== 'active')
    // ) {
    //   swal(
    //     'Oops!',
    //     'You can not create an event until your subscription is activated!',
    //     'error',
    //   );
    // } else {
    setActiveModal(!activeModal);
    // }
  };

  const history = useHistory();

  const onCreateEventSuccess = data4 => {
    toggleModal();
    history.push(`/update-event/${data4.createEvent.id}`);
  };

  const handleRemove = id => {
    swal({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this event!',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then(async willDelete => {
      if (willDelete) {
        await removeEvent({
          variables: { id },
        });
        swal('Poof! Your event has been deleted!', {
          icon: 'success',
        });
      }
    });
  };

  useEffect(() => {
    if (removeError) {
      swal({
        icon: 'error',
        title: '',
        text: removeError.message,
      });
    }
  }, [removeError]);

  return (
    <SearchConsumer>
      {({ searchInput }) => {
        return (
          <Container className="section">
            {showFutureEvents && (
              <div>
                <LastEvent />
              </div>
            )}

            <div className="columns is-multiline">
              <Query
                query={eventsQuery}
                fetchPolicy="cache-and-network"
                variables={{
                  showPast: showPastEvents,
                  showArchived: showArchivedEvents,
                  showFuture: showFutureEvents,
                  showDraft: !partnerEvents,
                  showPartnerEvents: partnerEvents,
                }}
              >
                {({ loading, error, data }) => {
                  if (loading || !data.brandEvents) return <Loading />;
                  if (error) {
                    swal('Something went wrong!', `${error.message}`, 'error');
                  }
                  if (data && data.brandEvents.length === 0) {
                    return (
                      <div className="column has-text-centered">
                        {location.search === '' && (
                          <a onClick={toggleModal}>
                            <span className="has-text-centered has-text-weight-semibold is-size-4 has-text-black">
                              Create an event{' '}
                              <Button className="button is-primary is-small half-rounded ml-3 mt-1">
                                <span className="icon ">
                                  <i className="fas fa-plus" />
                                </span>
                              </Button>
                            </span>
                          </a>
                        )}
                        <Mutation
                          mutation={createEventMutation}
                          onCompleted={data2 => {
                            onCreateEventSuccess(data2);
                          }}
                          onError={createError =>
                            swal('Oops!', createError.message, 'error')
                          }
                        >
                          {(createEvent, { loading: createEventLoading }) => {
                            return (
                              <CreateEventModal
                                active={activeModal}
                                onLoading={createEventLoading}
                                handelClose={toggleModal}
                                handleSubmit={data3 => {
                                  return createEvent({
                                    variables: {
                                      ...data3,
                                    },
                                  });
                                }}
                              />
                            );
                          }}
                        </Mutation>
                      </div>
                    );
                  }
                  const eventsList = data?.brandEvents || [];
                  const brandEvents = showPastEvents
                    ? sortBy(eventsList, ['eventStartTime'])
                    : eventsList;

                  return (
                    <>
                      {brandEvents
                        .map(event => {
                          const eventName = event.name
                            ? event.name.toLowerCase()
                            : '-';
                          if (
                            searchInput &&
                            eventName.search(searchInput.toLowerCase()) !== -1
                          ) {
                            return (
                              <div
                                className="column is-one-third"
                                key={event.id}
                              >
                                <EventItem
                                  event={event}
                                  isPartnerEvent={partnerEvents}
                                  isPast={showPastEvents}
                                  handleRemove={handleRemove}
                                />
                              </div>
                            );
                          }
                          if (
                            searchInput &&
                            eventName.search(searchInput.toLowerCase()) === -1
                          ) {
                            return null;
                          }
                          if (!searchInput) {
                            return (
                              <div
                                className="column is-one-third"
                                key={event.id}
                              >
                                <EventItem
                                  event={event}
                                  isPast={showPastEvents}
                                  isPartnerEvent={partnerEvents}
                                  handleRemove={handleRemove}
                                />
                              </div>
                            );
                          }
                          return null;
                        })
                        .reverse()}
                    </>
                  );
                }}
              </Query>
            </div>
            {!showArchivedEvents && (
              <Link to="/events?archived" className="button is-text">
                Show archived events
              </Link>
            )}
          </Container>
        );
      }}
    </SearchConsumer>
  );
};

export default Events;
