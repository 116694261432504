import React from 'react';
import { Doughnut } from 'react-chartjs-2';

import { Loading } from '../elements';

const AgeChart = ({ data, loading, options }) => {
  if (loading && (!data || data.length === 0)) return <Loading />;

  // if (error && !data) return `Error! ${error.message}`;

  if (!data || data.length === 0) {
    return (
      <div className="column">
        <p className="has-text-centered has-text-weight-semibold is-size-4">
          No data
        </p>
      </div>
    );
  }

  const list = [];
  data.slice(0, 3).forEach(item => list.push(item.value));

  const overAll = {
    labels: ['18 to 30', '31 to 35', '36 >'],
    datasets: [
      {
        data: list,
        backgroundColor: ['#33D69F', '#631FE7', '#F27A54'],
        hoverBackgroundColor: ['#33D69F', '#631FE7', '#F27A54'],
      },
    ],
  };

  return <Doughnut data={overAll} options={options} />;
};

export default AgeChart;
