import React, { useEffect } from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import swal from 'sweetalert';

import { Tag } from '../elements';

const TableRow = styled.tr`
  td {
    vertical-align: middle !important;
    border-top: 1px solid ${props => props.theme.borderColor} !important;
    border-bottom: 1px solid ${props => props.theme.borderColor} !important;
    border-bottom-width: 1px !important;
    padding: 0.75em 0.75em !important;
    color: ${props => props.theme.placeholderColor};
    :first-child {
      border-top-left-radius: 2.75rem;
      border-bottom-left-radius: 2.75rem;
      border-left: 1px solid ${props => props.theme.borderColor} !important;
    }
    :last-child {
      border-top-right-radius: 2.75rem;
      border-bottom-right-radius: 2.75rem;
      border-right: 1px solid ${props => props.theme.borderColor} !important;
    }
  }
  .is-primary {
    color: ${props => props.theme.mainBrandColor};
  }

  .tag {
    margin-left: 1vw;
    background-color: red;
    &.active {
      background-color: #5df888;
    }
    &.notactive {
      background-color: orange;
    }
  }
  .user-name {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    .image {
      width: 52px;
      height: 52px;
      img {
        object-fit: cover;
        width: 52px;
        height: 52px;
      }
    }
    span {
      color: #858585;
      margin-left: 0.7vw;
    }
  }
  .account-status {
    .checkbox {
      vertical-align: middle;
      input {
        height: 18px;
        width: 18px;
      }
    }
  }
  .email {
    color: #858585;
  }
  .action {
    & > div {
      text-align: right;
      .icon {
        cursor: pointer;
        &:last-child {
          margin-left: 3%;
        }
      }
      .ellipsis-dropdown {
        .button {
          font-size: 14px;
          border: none;
          color: #858585;
          font-weight: 600;
        }
        .dropdown-item {
          border-bottom: 1px solid transparent;
          &:last-child {
            border-bottom: none;
          }
          &:hover {
            border-bottom-color: #efefef;
            background: linear-gradient(
              73.04deg,
              #b7007b 19.16%,
              #d5a21b 95.52%
            );
            ${'' /* background-color: ${props => props.theme.buttonColor}; */}
            a,
            .menu-item {
              color: #fff;
            }
          }
          a {
            display: block;
            color: #858585;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 768px) {
  .user-name .image,  .user-name .image img{
      height: 30px !important;
      width: 30px !important;
  }
  td .pr-5 {
    padding-right: 0 !important;
  }
`;
const removeFollowerMutation = gql`
  mutation removeFollower($userId: ID!) {
    removeFollower(userId: $userId) {
      success
    }
  }
`;

const reportFollowerMutation = gql`
  mutation reportUser(
    $userId: ID!
    $reason: String!
    $unblock: Boolean
    $reportOnly: Boolean
  ) {
    reportUser(
      userId: $userId
      reason: $reason
      unblock: $unblock
      reportOnly: $reportOnly
    ) {
      success
    }
  }
`;

const TableRowItem = ({ follower, blockedUser, refetch }) => {
  const [checked, setChecked] = React.useState(false);
  const [removeFollower] = useMutation(removeFollowerMutation);
  const [reportUser, res] = useMutation(reportFollowerMutation);

  const reportBlock = (reason, reportOnly, unblockUser) => {
    if (reportOnly) {
      reportUser({
        variables: {
          userId: follower.id,
          reason,
          unblock: true,
          reportOnly,
        },
      });
    }
    if (unblockUser) {
      reportUser({
        variables: {
          userId: follower.id,
          unblock: true,
          reason,
        },
      });
    } else {
      reportUser({
        variables: {
          userId: follower.id,
          reason,
          unblock: false,
          reportOnly,
        },
      });
    }
  };
  const handleRemoveClick = () => {
    swal({
      title: `Are you sure you want to remove ${follower.profile.firstName}?`,
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then(willDelete => {
      if (willDelete) {
        // console.log(follower.id);
        const resp = removeFollower({ variables: { userId: follower.id } });
        if (resp) {
          refetch();
          swal(`Poof! ${follower.profile.firstName} has been deleted!`, {
            icon: 'success',
          });
        }
      }
    });
  };

  const handleUnblockClick = () => {
    swal({
      title: `Are you sure you want to unblock ${follower.profile.firstName}?`,
      icon: 'warning',
      buttons: {
        cancel: {
          cancel: true,
          text: 'Cancel',
          visible: true,
          closeModal: true,
        },
        unblock: true,
      },
      dangerMode: true,
    }).then(value => {
      if (value !== null) {
        swal('what is the reason:', {
          content: 'input',
        }).then(v => {
          reportBlock(v, true);
          swal(`Poof! ${follower.profile.firstName} has been unblock!`, {
            icon: 'success',
          });
          refetch();
        });
      }
    });
  };

  const handleBlockClick = () => {
    swal({
      title: `Are you sure you want to block ${follower.profile.firstName}?`,
      icon: 'warning',
      buttons: {
        cancel: {
          cancel: true,
          text: 'Cancel',
          visible: true,
          closeModal: true,
        },
        block: true,
        reportOnly: true,
      },
      dangerMode: true,
    }).then(value => {
      if (value !== null) {
        swal('What is the reason:', {
          content: 'input',
        }).then(v => {
          if (value === 'block') {
            reportBlock(v, false);
            swal(
              `Poof! ${follower.profile.firstName} has been reported & blocked!`,
              {
                icon: 'success',
              },
            );
            refetch();
          } else {
            reportBlock(v, true);
            swal(
              `Poof! ${follower.profile.firstName} has been reported only!`,
              {
                icon: 'success',
              },
            );
            refetch();
          }
        });
      }
    });
  };
  useEffect(() => {
    if (res && res.data) {
      refetch();
    }
  }, [res]);
  // console.log('follower.verificationStatus', follower.verificationStatus);
  return (
    <TableRow>
      <td className=" account-status">
        <label className="checkbox ml-5">
          <input
            type="checkbox"
            checked={checked}
            onChange={() => {
              setChecked(!checked);
            }}
          />
        </label>
        <Tag
          label={blockedUser ? 'block' : follower.status}
          className={`is-rounded ${
            blockedUser ? 'block' : follower.status.replace(' ', '')
          }`}
        />
      </td>
      <td>
        <div className="user-name">
          <a
            href={follower.profile.image}
            target="_blank"
            rel="noopener noreferrer"
          >
            <figure className="image">
              <img
                className="is-rounded"
                alt="person-1"
                src={follower.profile.image || '/images/avatar.svg'}
              />
            </figure>
          </a>
          {follower.profile && follower.profile.firstName ? (
            <span>
              {`${
                follower.profile.firstName ? follower.profile.firstName : ''
              } `}
              {follower.profile.lastName ? follower.profile.lastName : ''}
            </span>
          ) : (
            <span>{follower.username ? follower.username : ''}</span>
          )}
        </div>
      </td>
      <td className="">
        {follower.verificationStatus === 'approved' ? (
          <span className="ml-1">
            <i className="fas fa-user-check is-primary mr-1" /> Yes
          </span>
        ) : (
          <span className="ml-1">
            <i className="fas fa-user-times mr-1" /> No
          </span>
        )}
      </td>
      <td>
        {follower.covidPassport ? (
          <span className="ml-1">
            <i className="fas fa-syringe is-primary" /> Yes
          </span>
        ) : (
          <span className="ml-1">
            <i className="fas fa-syringe" /> No
          </span>
        )}
      </td>
      <td className="is-1 action">
        <div style={{ display: checked ? 'block' : 'none' }} className="pr-5">
          <span className="icon" onClick={handleRemoveClick}>
            <i className="fas fa-trash" />
          </span>
          {/* <span className="icon">
            <i className="fas fa-pen" />
          </span> */}
        </div>
        <div style={{ display: !checked ? 'block' : 'none' }} className="pr-5">
          <div className="dropdown is-hoverable ellipsis-dropdown is-right">
            <div className="dropdown-trigger">
              <button
                className="button"
                aria-haspopup="true"
                aria-controls="dropdown-menu"
              >
                <span className="icon is-small">
                  <i className="fas fa-ellipsis-h" aria-hidden="true" />
                </span>
              </button>
            </div>
            <div className="dropdown-menu" id="dropdown-menu" role="menu">
              <div className="dropdown-content">
                <div className="dropdown-item">
                  <p>View</p>
                </div>
                {blockedUser ? (
                  <div className="dropdown-item">
                    <span className="menu-item" onClick={handleUnblockClick}>
                      Unblock User
                    </span>
                  </div>
                ) : (
                  <div className="dropdown-item">
                    <span className="menu-item" onClick={handleBlockClick}>
                      Block User
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </td>
    </TableRow>
  );
};

export default TableRowItem;
