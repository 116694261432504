import React from 'react';
import gql from 'graphql-tag';
import styled from 'styled-components';
import { Query, Mutation } from 'react-apollo';
import swal from 'sweetalert';

import UpdateProfileForm from '../components/form/UpdateProfileForm';
import { Loading } from '../components/elements';

const Container = styled.section`
  background: grey;
  background-image: url('/images/landing-background.jpg');
`;
const ImageWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  padding-bottom: 2rem;
  margin-top: 2rem;
  img {
    width: 250px;
    height: auto;
  }
`;
const Box = styled.div`
  background: white;
`;

const meQuery = gql`
  query meUpdate {
    me {
      id
      username
      email
      telephone
      genre
      businessRole
      profile {
        image
        coverImage
        firstName
        lastName
        dob
        sex
        relationship
        currency
        bio
        location
      }
    }
  }
`;

const updateMeMutation = gql`
  mutation updateMe($input: UpdateUserInput) {
    updateMe(input: $input) {
      id
      username
      email
      telephone
      genre
      businessRole
      profile {
        image
        coverImage
        firstName
        lastName
        dob
        sex
        relationship
        bio
        location
      }
    }
  }
`;

const Form = ({ onUpdate }) => {
  return (
    <Query query={meQuery}>
      {({ data, loading, error }) => {
        if (loading) {
          return <Loading />;
        }
        if (error && !data) return <p> `Error! ${error.message}`</p>;
        return (
          <Mutation
            mutation={updateMeMutation}
            update={onUpdate}
            onCompleted={() => {
              swal('Good job!', 'Profile Successfully Updated!', 'success');
            }}
            onError={error2 => swal('Awww!', error2.message, 'error')}
          >
            {(updateMe, { loading: loading2 }) => (
              <UpdateProfileForm
                me={data?.me}
                loading={loading2}
                onSubmit={input => {
                  // console.log('update profile', input);
                  updateMe({ variables: { input } });
                }}
              />
            )}
          </Mutation>
        );
      }}
    </Query>
  );
};

const UpdateProfile = ({ onSettingPage, setActiveStep }) => {
  const onUpdate = (cache, { data: { me } }) => {
    // console.log('onUpdate', onUpdate);
    const data = { __typename: 'User', ...me };
    cache.writeQuery({ query: meQuery, data });
    swal('Profile updated!');
    setActiveStep(2);
  };

  return (
    <>
      {!onSettingPage ? (
        <Container className="hero is-fullheight">
          <div className="hero-body">
            <div className="container">
              <Box>
                <div className="columns is-centered">
                  <div className="column is-8">
                    <ImageWrapper>
                      <img src="/images/listmelogo.png" alt="logo" />
                    </ImageWrapper>
                    <Form onUpdate={onUpdate} />
                  </div>
                </div>
              </Box>
            </div>
          </div>
        </Container>
      ) : (
        <Form onUpdate={onUpdate} />
      )}
    </>
  );
};

export default UpdateProfile;
