import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useQuery, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import swal from 'sweetalert';
import { useHistory } from 'react-router-dom';

import CreditCardForm from './form/CreditCardForm';
import Button from './elements/Button';

const Wrapper = styled.div`
  flex: 1;
  .section-title {
    font-weight: 600;
    font-size: 20px;
    margin-top: 20px;
  }
  img {
    width: 20rem;
  }
  label {
    display: block;
    margin-top: 26px;
  }
  .input {
    padding: 16px 29px;
    margin-top: 12px;
    width: 100%;
    color: #858585;
    border-radius: 10px;
    height: 52px;
    background-color: #f8f8f8;
    box-shadow: inset 0px 4px 30px rgba(0, 0, 0, 0.07);
    border: none;
  }
  .cancel-btn {
    cursor: pointer;
    display: inline-block;
    color: ${props => props.theme.buttonColor};
    position: relative;
    &:after {
      content: '';
      left: 0;
      position: absolute;
      height: 2px;
      width: 100%;
      background-color: #e5e5e5;
      bottom: -5px;
    }
  }
`;

const Columns = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  /* height: 100%; */
  > .column {
    margin-bottom: 5px;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
  .error {
    color: red;
  }
`;

const Box = styled.div`
  text-align: center;
  padding: 2.25rem;
  .title {
    color: #616161;
  }
  span {
    color: ${props => props.theme.buttonColor};
  }
`;

const subscriptionQuery = gql`
  query subscription {
    packages {
      id
      name
      durationInMonths
      price
    }
    me {
      id
      subscription {
        name
        status
      }
    }
  }
`;

const verifyCardMutation = gql`
  mutation verifyCard($input: VerifyCardInput!) {
    verifyCard(input: $input) {
      token
    }
  }
`;

const addCardMutation = gql`
  mutation addCard($sourceToken: String!) {
    addCard(sourceToken: $sourceToken) {
      success
    }
  }
`;

const createSubscriptionMutation = gql`
  mutation createSubscription($packageId: String!, $discountCode: String) {
    createSubscription(packageId: $packageId, discountCode: $discountCode) {
      success
    }
  }
`;

const handleError = e => {
  swal(`Something is wrong`, e.message.replace('GraphQL error:', '').trim());
};

const Activate = () => {
  const history = useHistory();
  const [isCancelModelActive, setCancelModelActive] = useState(false);
  const [isSubscription, setSubscription] = useState(false);

  const { data } = useQuery(subscriptionQuery);
  const [verifyCard, verifyCardData] = useMutation(verifyCardMutation);
  const [addCard, addCardData] = useMutation(addCardMutation);
  const [createSubscription, createSubscriptionData] = useMutation(
    createSubscriptionMutation,
  );

  const packages = data && data.packages ? data.packages : [];
  const me = data && data.me ? data.me : {};
  // console.log('me', me);

  useEffect(() => {
    setSubscription(
      me && me.subscription && me.subscription.status === 'active',
    );
  }, [data, me]);

  return (
    <Wrapper className="section">
      <Columns className="columns">
        <div className="column">
          <p className="is-uppercase is-size-3 is-size-6-mobile has-text-weight-bold">
            {isSubscription
              ? 'Subscribed Successfully!'
              : 'Activate your ListMe Solution by subscribing below'}
          </p>
        </div>
        {isSubscription ? (
          <>
            <div className="column">
              <p className="is-size-5 is-size-7-mobile">
                Thank you, your account is now activated. Go create your first
                event!
              </p>
            </div>
            <div className="column">
              <Button
                title="Continue"
                className="button is-medium is-rounded"
                onClick={() => history.push('/events')}
              />
            </div>
            <div className="column">
              <span
                className="cancel-btn"
                onClick={() => setCancelModelActive(true)}
              >
                Cancel Subscription
              </span>
            </div>
          </>
        ) : (
          <>
            <div className="column is-10">
              <p className="is-size-5">
                By now you have connected your business bank account. The final,
                step is to subscribe to our Licence fee. A platform for
                businesses to understand their customers through real-time data
                analytics and raise productivity levels by streamlining ID
                verification and venue entry.
              </p>
            </div>
            {packages && packages.length !== 0 && (
              <CreditCardForm
                packages={packages}
                isLoading={
                  verifyCardData.loading ||
                  addCardData.loading ||
                  createSubscriptionData.loading
                }
                onSubmit={params => {
                  const input = {
                    number: String(params.number),
                    expMonth: String(params.expMonth),
                    expYear: String(params.expYear),
                    cvc: String(params.cvc),
                  };
                  verifyCard({
                    variables: { input },
                  })
                    .then(res => {
                      const token =
                        res.data &&
                        res.data.verifyCard &&
                        res.data.verifyCard.token
                          ? res.data.verifyCard.token
                          : '';
                      if (token) {
                        addCard({ variables: { sourceToken: token } })
                          .then(addCardRes => {
                            console.log('res', res);
                            if (
                              addCardRes.data &&
                              addCardRes.data.addCard &&
                              addCardRes.data.addCard.success
                            ) {
                              createSubscription({
                                variables: {
                                  packageId: params.selectedPlanId,
                                  discountCode: params.discountCode,
                                },
                              })
                                .then(createSubscriptionRes => {
                                  if (
                                    createSubscriptionRes.data &&
                                    createSubscriptionRes.data
                                      .createSubscription &&
                                    createSubscriptionRes.data
                                      .createSubscription.success
                                  ) {
                                    setSubscription(true);
                                  }
                                })
                                .catch(e => handleError(e));
                            }
                          })
                          .catch(e => handleError(e));
                      }
                    })
                    .catch(e => handleError(e));
                }}
              />
            )}
          </>
        )}
      </Columns>
      <div className={`modal ${isCancelModelActive && 'is-active'}`}>
        <div className="modal-background" />
        <div className="modal-content">
          <Box className="box">
            <p className="title is-capitalized">Cancel Subscription</p>
            <p>
              To cancel your subscription please email <br />
              <span>support@listmeapp.co</span>
            </p>
            <br />
            <Button
              className="button is-rounded is-medium"
              title="OK"
              type="submit"
              onClick={() => setCancelModelActive(false)}
            />
          </Box>
        </div>
        <button
          type="submit"
          className="modal-close is-large"
          onClick={() => setCancelModelActive(false)}
        />
      </div>
    </Wrapper>
  );
};

export default Activate;
